export const styles = {
  title: {
    fontWeight: "bold",
    fontSize: "30px",
    marginBottom: "30px",
    marginTop: "20px",
  },
  formControl: {
    width: {
      xs: "80%",
      md: "70%",
      lg: "50%",
    },
  },
  field: {
    width: "99%",
    margin: "20px auto",
    backgroundColor: "white",
    borderRadius: "20px",
    [`& fieldset`]: {
      borderRadius: "20px",
      border: "1px",
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    },
  },
  submitButton: {
    display: "block",
    margin: "20px auto",
  },
  loading: {
    display: "block",
    margin: "10px auto",
  },
  icon: {
    color: "#58738D",
  },
  error: {
    fontSize: "13px",
    color: "#8B0000",
  },
  imgAdornment: {
    marginRight: "10px",
    borderRadius: "10%",
  },
};
