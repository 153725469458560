export const styles = {
  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    mb: 3,
  },

  card: {
    p: 3,
    mt: 2,
    mr: 2,
    ml: 2,
    mb: 9,
    width: "80%",
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    borderRadius: "20px",
    textAlign: "center",
  },

  flexColumnCenter: {
    my: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },

  accordionStyle: {
    p: "2px",
    width: "88%",
    boxShadow: "rgba(0, 0, 0, 0.24) 1px 2px 5px",
  },

  title: {
    textAlign: "center",
    fontSize: "17px",
  },

  subTitle: {
    margin: "auto",
    fontWeight: "bold",
    fontStyle: "italic",
    mb: 2,
    mt: 4,
  },

  colorPicker: {
    margin: "auto",
    height: "120px",
    marginBottom: "10px",
  },

  uploaderContainer: {
    mt: 4,
    mb: 2,
    mr: "15%",
    ml: "15%",
  },

  backArrow: {
    display: "flex",
    justifyContent: "start",
    m: { lg: 2, md: 1, xs: 0 },
    fontSize: "230px",
    color: "#58738D",
  },

  titleContainer: {
    marginTop: { xs: 3, lg: -7, md: -5 },
    marginBottom: 3,
  },

  errorText: {
    color: "#8B0000",
    fontWeight: "bold",
    fontSize: 12,
  },
};
