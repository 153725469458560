import { getQrCode } from "../api/qrCodes/qrCodesHandler";
import { QR_CODE_TYPE, SITE_BASE_URL } from "./constants/Constants";

export const RGBToHex = (rgb) => {
  let sep = rgb.indexOf(",") > -1 ? "," : " ";
  rgb = rgb.substr(4).split(")")[0].split(sep);

  let r = (+rgb[0]).toString(16),
    g = (+rgb[1]).toString(16),
    b = (+rgb[2]).toString(16);

  if (r.length === 1) r = "0" + r;
  if (g.length === 1) g = "0" + g;
  if (b.length === 1) b = "0" + b;

  return "#" + r + g + b;
};

export const getVisibleColor = (iHexColor) => {
  const aDarkColor = "#000000";
  const aLightColor = "#ffffff";
  let aColor =
    iHexColor.charAt(0) === "#" ? iHexColor.substring(1, 7) : iHexColor;
  var r = parseInt(aColor.substring(0, 2), 16); // hexToR
  var g = parseInt(aColor.substring(2, 4), 16); // hexToG
  var b = parseInt(aColor.substring(4, 6), 16); // hexToB
  return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? aDarkColor : aLightColor;
};

export const getVisibleDraggableColor = (iHexColor) => {
  const aDarkColor = "#6B6B6B";
  const aLightColor = "#AFAFAF";
  let aColor =
    iHexColor.charAt(0) === "#" ? iHexColor.substring(1, 7) : iHexColor;
  var r = parseInt(aColor.substring(0, 2), 16); // hexToR
  var g = parseInt(aColor.substring(2, 4), 16); // hexToG
  var b = parseInt(aColor.substring(4, 6), 16); // hexToB
  return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? aDarkColor : aLightColor;
};

function hexToRgb(hex) {
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

function luminance(r, g, b) {
  let a = [r, g, b].map(function (v) {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
  });
  return 0.2126 * a[0] + 0.7152 * a[1] + 0.0722 * a[2];
}

export function isLightColor(hexColor) {
  let rgb = hexToRgb(hexColor);
  if (!rgb) return false;
  let l = luminance(rgb.r, rgb.g, rgb.b);
  return l > 0.5;
}

const buildQrCodeOptions = (iQrCode, qrData) => {
  const isLightDotsColor = isLightColor(iQrCode.dotsColor);
  const isLightSquaresColor = isLightColor(iQrCode.cornerSquaresColor);
  const isLightDotColor = isLightColor(iQrCode.cornerDotColor);

  const width =
    (isLightDotsColor || isLightSquaresColor || isLightDotColor) &&
    iQrCode.backgroundColor === "transparent"
      ? 185
      : 235;

  const height =
    (isLightDotsColor || isLightSquaresColor || isLightDotColor) &&
    iQrCode.backgroundColor === "transparent"
      ? 185
      : 235;

  const qrCodeOptions = {
    _id: iQrCode._id,
    width: width,
    height: height,
    type: "svg",
    data: qrData,
    image: iQrCode.picture,
    dotsOptions: {
      type: iQrCode.dotsType,
      color: iQrCode.dotsColor,
    },
    cornersDotOptions: {
      type: iQrCode.cornerDotType,
      color: iQrCode.cornerDotColor,
    },
    cornersSquareOptions: {
      type: iQrCode.cornerSquaresType,
      color: iQrCode.cornerSquaresColor,
    },
    backgroundOptions: {
      color: iQrCode.backgroundColor,
    },
    imageOptions: {
      crossOrigin: "anonymous",
      imageSize: 0.3,
      margin: 5,
    },
  };

  return qrCodeOptions;
};

export const updateQrCodeOptions = async (iQrCode) => {
  const qrData = `${SITE_BASE_URL}render/${iQrCode._id}`;

  const qrCodeOptions = buildQrCodeOptions(iQrCode, qrData);

  localStorage.setItem(iQrCode._id, JSON.stringify(qrCodeOptions));
};

export const retrieveQrCodeOptions = async (iQrCode) => {
  return new Promise(async (resolve, reject) => {
    let qrCodeOptions = JSON.parse(localStorage.getItem(iQrCode._id));

    if (qrCodeOptions) {
      delete qrCodeOptions._id;
      resolve(qrCodeOptions);
    } else {
      const aQrCodeDetails = await getQrCode(iQrCode._id);
      const aQrContent = aQrCodeDetails.content;
      let qrData = "";
      if (iQrCode.type === QR_CODE_TYPE.VIRTUAL_CARD) {
        qrData = `BEGIN:VCARD\r\nVERSION:3.0\r\nN:${aQrContent.firstName} ${aQrContent.lastName}\r\nFN:${aQrContent.fullName}\r\nORG:${aQrContent.organization}\r\nTITLE:${aQrContent.title}\r\nTEL;TYPE=WORK,VOICE:${aQrContent.phoneNumber.work}\r\nTEL;TYPE=CELL:${aQrContent.phoneNumber.cell}\r\nTEL;TYPE=HOME,VOICE:${aQrContent.phoneNumber.home}\r\nEMAIL:${aQrContent.email}\r\nEND:VCARD\r\n`;
      } else if (iQrCode.type === QR_CODE_TYPE.WIFI) {
        qrData = `WIFI:S:${aQrContent.ssid};T:${aQrContent.securityType};P:${aQrContent.password};;`;
      } else {
        qrData = `${SITE_BASE_URL}render/${iQrCode._id}`;
      }

      qrCodeOptions = buildQrCodeOptions(iQrCode, qrData);

      try {
        localStorage.setItem(iQrCode._id, JSON.stringify(qrCodeOptions));
        delete qrCodeOptions._id;
        resolve(qrCodeOptions);
      } catch (error) {
        reject(error);
      }
    }
  });
};

export const removeObjectWithId = (arr, id) => {
  const objWithIdIndex = arr.findIndex((obj) => obj.id === id);

  if (objWithIdIndex > -1) {
    arr.splice(objWithIdIndex, 1);
  }

  return arr;
};

export const buildId = (length) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const capitalizeFirstLetter = (iWord) => {
  return iWord.charAt(0).toUpperCase() + iWord.slice(1).toLowerCase();
};

export const sortArrayByPosition = (iArray) => {
  return iArray.sort((a, b) => a.position - b.position);
};

export const generateColorArray = (num) => {
  const colors = [
    "#58738D",
    "#3E93AC",
    "#1DB3B5",
    "#9FE98A",
    "#9B6C95",
    "#E49D23",
    "#009687",
    "#826D54",
    "#7AFAC4",
    "#AA585C",
    "#EEE8A9",
    "#009CF9",
  ];
  let colorArray = [];

  for (let i = 0; i < num; i++) {
    let randomIndex = Math.floor(Math.random() * colors.length);
    while (
      colorArray.includes(colors[randomIndex]) &&
      colorArray
        .slice(Math.max(colorArray.length - 3, 0))
        .includes(colors[randomIndex])
    ) {
      randomIndex = Math.floor(Math.random() * colors.length);
    }
    colorArray.push(colors[randomIndex]);
  }

  return colorArray;
};
