import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
} from "@mui/material";
import { OctopusQRButton, RedButton } from "../../../utils/styled/Styled";
import { styles } from "../main/QRCodes.style";

const AddQrFolder = ({ open, onClose, onSave }) => {
  const [folderName, setFolderName] = useState("");

  const handleChange = (event) => {
    setFolderName(event.target.value);
  };

  const handleSave = () => {
    onSave(folderName);
    setFolderName("");
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>Nouveau dossier</DialogTitle>
      <DialogContent>
        <TextField
          sx={{
            ...styles.searchBar,
            backgroundColor: "white",
            minWidth: "250px",
            width: "100%",
          }}
          label="Nom du dossier"
          value={folderName}
          onChange={handleChange}
          fullWidth
          autoFocus
        />
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <RedButton onClick={onClose} sx={{ width: "150px" }}>
          Annuler
        </RedButton>
        <OctopusQRButton
          onClick={handleSave}
          disabled={!folderName.trim()}
          sx={{ width: "150px" }}
        >
          Enregistrer
        </OctopusQRButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddQrFolder;
