export const styles = {
  title: {
    textAlign: "center",
    m: 1,
  },
  button: {
    width: "85%",
    display: "block",
    margin: "0px auto",
  },
  center: {
    margin: "10px auto",
    display: "block",
  },
  centeredIcon: {
    margin: "0px auto",
    display: "block",
    color: "#58738D",
  },
  error: {
    textAlign: "center",
    fontSize: "16px",
    color: "#8B0000",
  },
};
