import { useNavigate } from "react-router-dom";

import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import HelpIcon from "@mui/icons-material/Help";

const styles = {
  title: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "30px",
    marginBottom: "0px",
    marginTop: "0px",
  },
  centeredIcon: {
    margin: "0px auto",
    display: "block",
    color: "#58738D",
  },
  commonText: {
    textAlign: "justify",
    m: 3,
  },
  closeIcon: {
    position: "absolute",
    right: 1,
    top: 8,
    color: (theme) => theme.palette.grey[500],
  },
};

export default function MailHelp({ iFromPage }) {
  const navigate = useNavigate();

  const handleClose = () => {
    navigate(iFromPage);
  };

  return (
    <Dialog open={true} onClose={handleClose}>
      <DialogTitle>
        <IconButton onClick={handleClose} sx={styles.closeIcon}>
          <CloseIcon />
        </IconButton>

        <Typography style={styles.title}>Adresse mail</Typography>
      </DialogTitle>
      <DialogContent>
        <HelpIcon sx={styles.centeredIcon} />
        <Typography sx={styles.commonText}>
          Votre adresse mail vous sera utile pour vous connecter. Elle est
          unique et ne peut donc pas être utilisée pour deux comptes différents.
          {/* Une fois votre compte crée, vous ne pouvez plus modifier votre adresse
          mail à moins de nous contacter. Un mail de vérification vous sera
          envoyer, il est nécessaire de vérifier votre mail afin d'accéder à nos
          services. */}
          <br />
          <br />
          Si vous désirez changer d'adresse mail, merci d'envoyer un mail à
          l'adresse suivante <br />
          <a href="mailto:support@octopusqr.com">support@octopusqr.com</a>
        </Typography>
      </DialogContent>
    </Dialog>
  );
}
