import api from "../../../../../api/axios";

export const postWorksiteControlForm = async (iUserId, iFormId, iBody) => {
  const aResponse = await api.post(
    `/eiffage/${iUserId}/forms/${iFormId}/answers`,
    iBody
  );
  return aResponse.data;
};

export const getWorksiteControlForm = async (iUserId, iFormId) => {
  const aResponse = await api.get(`/eiffage/${iUserId}/forms/${iFormId}`);
  return aResponse.data;
};
