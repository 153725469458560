import api from "../../axios";

export const postVirtualCard = async (iUserId, iQrCodeId, iData) => {
  const aResponse = await api.post(
    `/users/${iUserId}/qrcodes/${iQrCodeId}/vcards`,
    iData
  );
  return aResponse.data;
};

export const putVirtualCard = async (iUserId, iQrCodeId, iVCardId, iData) => {
  const aResponse = await api.put(
    `/users/${iUserId}/qrcodes/${iQrCodeId}/vcards/${iVCardId}`,
    iData
  );
  return aResponse.data;
};

export const verifyVirtualCardData = (iData) => {
  const aErrorValues = {
    lastNameText: "",
    hasLastNameError: false,
    firstNameText: "",
    hasFirstNameError: false,
    fullNameText: "",
    hasFullNameError: false,
    workText: "",
    hasWorkError: false,
    cellText: "",
    hasCellError: false,
    homeText: "",
    hasHomeError: false,
  };
  let hasFormErrors = false;

  if (!iData.lastName) {
    aErrorValues.lastNameText = "Champs requis";
    aErrorValues.hasLastNameError = true;
    hasFormErrors = true;
  }

  if (!iData.firstName) {
    aErrorValues.firstNameText = "Champs requis";
    aErrorValues.hasFirstNameError = true;
    hasFormErrors = true;
  }

  if (!iData.fullName) {
    aErrorValues.fullNameText = "Champs requis";
    aErrorValues.hasFullNameError = true;
    hasFormErrors = true;
  }

  const aPhoneNumberRegex = /^\+?\d+$/;

  if (iData.work && !aPhoneNumberRegex.test(iData.work)) {
    aErrorValues.workText = "Numéro de téléphone invalide";
    aErrorValues.hasWorkError = true;
    hasFormErrors = true;
  }

  if (iData.cell && !aPhoneNumberRegex.test(iData.cell)) {
    aErrorValues.cellText = "Numéro de téléphone invalide";
    aErrorValues.hasCellError = true;
    hasFormErrors = true;
  }

  if (iData.home && !aPhoneNumberRegex.test(iData.home)) {
    aErrorValues.homeText = "Numéro de téléphone invalide";
    aErrorValues.hasHomeError = true;
    hasFormErrors = true;
  }

  return { aErrorValues, hasFormErrors };
};
