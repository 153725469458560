import {
  verifyMail,
  verifyName,
  verifyPhoneNumber,
} from "../../utils/forms/FormsVerification";
import api from "../axios";

export const registerUserAdmin = async (iUserId, iData) => {
  const aResponse = await api.post(`/admin/${iUserId}/users/register`, iData);
  return aResponse.data;
};

export const verifyRegisterUserData = (iFormValues) => {
  const aErrorValues = {
    hasNameError: false,
    name: "",
    hasPhoneNumberError: false,
    phoneNumber: "",
    hasMailError: false,
    mail: "",
    hasPushError: false,
    hasSubscriptionPlanError: false,
    hasIsTrialError: false,
  };
  let aHasError = false;

  if (verifyName(iFormValues.name, aErrorValues)) {
    aHasError = true;
  }

  if (verifyPhoneNumber(iFormValues.phoneNumber, aErrorValues)) {
    aHasError = true;
  }

  if (verifyMail(iFormValues.mail, aErrorValues)) {
    aHasError = true;
  }

  if (iFormValues.hasPush === "") {
    aHasError = true;
    aErrorValues.hasPushError = true;
  }

  if (!iFormValues.subscriptionPlan) {
    aHasError = true;
    aErrorValues.hasSubscriptionPlanError = true;
  }

  if (!iFormValues.isTrial) {
    aHasError = true;
    aErrorValues.hasIsTrialError = true;
  }

  return { aErrorValues, aHasError };
};

export const getUsers = async (
  iUserId,
  iPageNumber,
  iNumberPerPage,
  iSearch
) => {
  const aReponse = await api.get(
    `/admin/${iUserId}/users?pageNumber=${iPageNumber}&numberPerPage=${iNumberPerPage}&search=${iSearch}`
  );
  return aReponse.data;
};

export const putUser = async (iUserId, iUserToPutId, iData) => {
  const aResponse = await api.put(
    `/admin/${iUserId}/users/${iUserToPutId}`,
    iData
  );
  return aResponse.data;
};

export const verifyPutData = (iFormValues) => {
  const aErrorValues = {
    hasNameError: false,
    nameText: "",
    hasMailError: false,
    mailText: "",
    hasPhoneNumberError: false,
    phoneNumberText: "",
  };

  let aHasError = false;

  if (verifyName(iFormValues.name, aErrorValues)) {
    aHasError = true;
  }

  if (verifyPhoneNumber(iFormValues.phoneNumber, aErrorValues)) {
    aHasError = true;
  }

  if (verifyMail(iFormValues.mail, aErrorValues)) {
    aHasError = true;
  }

  return { aErrorValues, aHasError };
};

export const deleteUser = async (iUserId, iUserToDeleteId) => {
  const aResponse = await api.delete(
    `/admin/${iUserId}/users/${iUserToDeleteId}`
  );
  return aResponse.data;
};
