import {
  FORM_INPUT_TYPE_FR,
  QR_CODE_TYPE,
} from "../../../../../utils/constants/Constants";
import DeviceWrapper from "../../../add/content/deviceWrapper/DeviceWrapper";
import { styles } from "../../QRCodeView.style";
import {
  Box,
  TextField,
  FormControl,
  Typography,
  RadioGroup,
  Checkbox,
  Radio,
  Select,
  MenuItem,
  Card,
} from "@mui/material";
import { capitalizeFirstLetter } from "../../../../../utils/Utils";

export default function FormPreview({ qrCode, qrContent }) {
  if (qrCode?.type !== QR_CODE_TYPE.FORM || !qrContent?.items) {
    return (
      <Box sx={{ maxWidth: "300px", margin: "auto" }}>
        <Typography
          variant="h6"
          sx={{ textAlign: "justify", mt: 6, color: "#8B0000" }}
        >
          Vous devez importer du contenu d'un QR Code de type Formulaire avant
          d'accéder à cette page.
        </Typography>
      </Box>
    );
  }

  const items = qrContent?.items;
  let fields = [];
  for (const [itemKey, itemValue] of Object.entries(items)) {
    switch (itemValue.type) {
      case FORM_INPUT_TYPE_FR.RADIO:
        fields.push(
          <>
            <Typography variant="h5" sx={{ mb: 2 }}>
              {itemValue.question}
            </Typography>
            <Box sx={styles.flexCenter}>
              <RadioGroup>
                {Object.entries(itemValue?.entries).map(
                  ([key, value], index) => {
                    return (
                      <Box
                        sx={{ ...styles.flexCenter, minWidth: "300px" }}
                        key={index}
                      >
                        <Radio value={value} />
                        <Box sx={{ flexBasis: "100%" }}>
                          <Typography variant="overline">{value}</Typography>
                        </Box>
                      </Box>
                    );
                  }
                )}
              </RadioGroup>
            </Box>
          </>
        );
        break;
      case FORM_INPUT_TYPE_FR.CHECKBOX:
        fields.push(
          <>
            <Typography variant="h5" sx={{ mb: 2 }}>
              {itemValue.question}
            </Typography>
            <Box sx={styles.flexColumnCenter}>
              {Object.entries(itemValue?.entries).map(([key, value], index) => {
                return (
                  <Box
                    sx={{ ...styles.flexCenter, minWidth: "300px" }}
                    key={index}
                  >
                    <Checkbox value={value} />
                    <Box sx={{ flexBasis: "100%" }}>
                      <Typography variant="overline">{value}</Typography>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </>
        );
        break;
      case FORM_INPUT_TYPE_FR.SHORT_ANSWER:
        fields.push(
          <>
            <Typography variant="h5" sx={{ mb: 2 }}>
              {itemValue.question}
            </Typography>
            <FormControl sx={{ width: "100%" }}>
              <TextField
                name={itemKey}
                label={itemValue.type}
                required={true}
                variant="outlined"
                sx={styles.formField}
              />
            </FormControl>
          </>
        );
        break;
      case FORM_INPUT_TYPE_FR.LONG_ANSWER:
        fields.push(
          <>
            <Typography variant="h5" sx={{ mb: 2 }}>
              {itemValue.question}
            </Typography>
            <FormControl sx={{ width: "100%" }}>
              <TextField
                name={itemKey}
                label={itemValue.type}
                required={true}
                variant="outlined"
                sx={styles.formField}
                multiline={true}
                rows={3}
              />
            </FormControl>
          </>
        );
        break;
      case FORM_INPUT_TYPE_FR.SELECT:
        fields.push(
          <>
            <Typography variant="h5" sx={{ mb: 2 }}>
              {itemValue.question}
            </Typography>
            <Select
              sx={{ ...styles.formField, width: "100%" }}
              MenuProps={styles.menuProps}
              defaultValue={""}
              displayEmpty
            >
              <MenuItem value={""} disabled>
                Sélectionnez votre réponse
              </MenuItem>
              {Object.entries(itemValue?.entries).map(([key, value], index) => {
                return (
                  <MenuItem value={value} key={index}>
                    <Box sx={styles.flexCenter}>
                      <Typography>{capitalizeFirstLetter(value)}</Typography>
                    </Box>
                  </MenuItem>
                );
              })}
            </Select>
          </>
        );
        break;
      default:
        break;
    }
  }
  let deviceContent = (
    <Box sx={{ ...styles.flexColumnCenter, my: 5, width: "100%" }}>
      <Typography variant="h4" sx={{ textAlign: "center", mt: 2 }}>
        {qrContent.name}
      </Typography>
      {fields.map((aField, index) => {
        return (
          <Box key={index} sx={{ width: "98%", my: 3 }}>
            <Card sx={{ borderRadius: "20px", px: 4, py: 3, mx: 2 }}>
              {aField}
            </Card>
          </Box>
        );
      })}
    </Box>
  );
  return (
    <Box sx={{ ...styles.flexCenter, mt: { xs: 0, md: 3, lg: 6 }, mb: 3 }}>
      <Box sx={{ display: { xs: "none", md: "block" } }}>
        <DeviceWrapper content={deviceContent} />
      </Box>
      <Box sx={{ display: { xs: "block", md: "none" } }}>{deviceContent}</Box>
    </Box>
  );
}
