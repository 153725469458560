import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./App.css";

import Home from "./routes/home/Home";
import Login from "./routes/login/Login";
import GenerateToken from "./routes/login/generateToken/GenerateToken";
import Register from "./routes/register/Register";
import Profile from "./routes/profile/Profile";
import UpdatePassword from "./routes/profile/updatePassword/UpdatePassword";
import DeleteAccount from "./routes/profile/deleteAccount/DeleteAccount";
import ResetPassword from "./routes/resetPassword/ResetPassword";
import UploadLogo from "./routes/profile/uploadLogo/UploadLogo";
import ActionSuccess from "./routes/actionSuccess/ActionSuccess";
import DeleteLogo from "./routes/profile/deleteLogo/DeleteLogo";
import Welcome from "./routes/profile/welcome/Welcome";
import MailVerification from "./routes/mailVerification/MailVerification";
import Legal from "./routes/company/Legal";
import Privacy from "./routes/company/Privacy";
import Terms from "./routes/company/Terms";
import MailHelp from "./components/help/MailHelp";
import PhoneNumberHelp from "./components/help/PhoneNumberHelp";
import WorksiteControl from "./routes/privateCustomers/eiffage/forms/control/WorksiteControl";
import FormSucceed from "./routes/privateCustomers/eiffage/forms/FormSucceed";
import NotFoundWithMenu from "./routes/notFound/NotFoundWithMenu";
import QRCodes from "./routes/qrcodes/main/QRCodes";
import AddQRCode from "./routes/qrcodes/add/AddQRCode";
import QRCodeView from "./routes/qrcodes/view/QRCodeView";
import Render from "./routes/qrcodes/render/Render";
import AnswerSent from "./routes/qrcodes/render/display/form/AnswerSent";
import UnsubscribeUser from "./routes/qrcodes/view/notify/UnsubscribeUser";
import Admin from "./routes/admin/Admin";

// LEGACY PART
import OldQrRender from "./routes/privateCustomers/ondesEtBienEtre/OldQrRender";
import LegacyQr from "./routes/privateCustomers/octopusqr/LegacyQr";
import LegacyCdartQr from "./routes/privateCustomers/octopusqr/LegacyCdartQr";
import QRFolder from "./routes/qrcodes/qrFolders/QRFolder";
import ScrollToTop from "./utils/ScrollToTop";
import Contact from "./routes/contact/Contact";
import Faq from "./routes/faq/Faq";
import About from "./routes/about/About";
import Press from "./routes/press/Press";
// LEGACY PART END

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        {/* USER RELATED ROUTES  */}
        <Route path="/" element={<Home />}>
          <Route
            path="/action-success"
            element={
              <ActionSuccess
                iFrom={"/"}
                iMessage={"Action effectuée avec succès"}
                iHasPreviewButton={false}
              />
            }
          ></Route>
          <Route path="/welcome" element={<Welcome />}></Route>
        </Route>
        <Route path="/login" element={<Login />}>
          <Route
            path="/login/generate-reset-password-token"
            element={<GenerateToken />}
          />
          <Route
            path="/login/action-success"
            element={
              <ActionSuccess
                iFrom={"/login"}
                iMessage={"Action effectuée avec succès"}
                iHasPreviewButton={false}
              />
            }
          />
        </Route>
        <Route path="/reset-password/:tokenId" element={<ResetPassword />} />
        <Route path="/register" element={<Register />}>
          <Route
            path="/register/mail-help"
            element={<MailHelp iFromPage={"/register"} />}
          />
          <Route
            path="/register/phone-number-help"
            element={<PhoneNumberHelp iFromPage={"/register"} />}
          />
        </Route>
        <Route path="/verify-email/:id" element={<MailVerification />} />
        <Route path="/profile" element={<Profile />}>
          <Route path="/profile/upload-logo" element={<UploadLogo />}></Route>
          <Route path="/profile/delete-logo" element={<DeleteLogo />}></Route>
          <Route
            path="/profile/update-password"
            element={<UpdatePassword />}
          ></Route>
          <Route
            path="/profile/delete-account"
            element={<DeleteAccount />}
          ></Route>
          <Route
            path="/profile/action-success"
            element={
              <ActionSuccess
                iFrom={"/profile"}
                iMessage={"Modifications du profil enregistrées avec succès"}
                iHasPreviewButton={false}
              />
            }
          ></Route>
          <Route
            path="/profile/mail-help"
            element={<MailHelp iFromPage={"/profile"} />}
          />
        </Route>

        <Route
          path="/:userId/qrcodes/:qrCodeId/custom-pages/:customPageId/unsubscribe"
          element={<UnsubscribeUser />}
        ></Route>
        {/* USER RELATED ROUTES END  */}

        {/* ADMIN ROUTES */}
        <Route path="/admin" element={<Admin />} />
        {/* ADMIN ROUTES END */}

        {/* QRs RELATED ROUTES */}
        <Route path="answer-sent" element={<AnswerSent />} />
        <Route path="/qrcodes/folder/:folderId" element={<QRFolder />} />
        <Route path="/qrcodes" element={<QRCodes />} />
        <Route path="/qrcodes/:qrCodeId" element={<QRCodeView />} />
        <Route path="/qrcodes/add" element={<AddQRCode />} />
        {/* QRs RELATED ROUTES END */}

        {/* RENDER ROUTES */}
        <Route path="/render/:qrCodeId" element={<Render />} />
        {/* RENDER ROUTES END */}

        {/* FOOTER ROUTES */}
        <Route path="/terms" element={<Terms />} />
        <Route path="/legal" element={<Legal />} />
        <Route path="/press" element={<Press />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/about" element={<About />} />
        {/* FOOTER ROUTES END */}

        <Route path="*" element={<NotFoundWithMenu />} />
        {/* PRIVATE CUSTOMERS ROUTES */}
        {/* EIFFAGE */}
        <Route
          path="/forms/eiffage/cdg-express/:userId/:formId"
          element={<WorksiteControl />}
        />
        <Route path="/forms/eiffage/success" element={<FormSucceed />} />
        {/* EIFFAGE END  */}
        {/* ONDES ET BIEN ETRE */}
        <Route path="/ondes_et_bien_etre" element={<OldQrRender />} />
        {/* ONDES ET BIEN ETRE END  */}
        {/* OCTOPUSQR LEGACY */}
        <Route path="/octopusqr" element={<LegacyQr />} />
        <Route
          path="/customer/6284a4af2d6c631f63f46722"
          element={<LegacyCdartQr />}
        />
        {/* OCTOPUSQR LEGACY END */}
        {/* PRIVATE CUSTOMERS ROUTES */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
