import { Box, Typography } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import { styles } from "../../Render.style";
export default function AnswerSent() {
  return (
    <Box
      sx={{ ...styles.flexColumnCenter, width: "330px", margin: "auto", mt: 5 }}
    >
      <DoneIcon />
      <Typography
        sx={{ fontSize: 20, textAlign: "justify", mt: 2 }}
        variant="overline"
      >
        Votre réponse a bien été enregistrée.
      </Typography>
    </Box>
  );
}
