import { Typography, Box, Divider } from "@mui/material";
import { QR_CODE_TYPE } from "../../../../utils/constants/Constants";
import NotFound from "../../../notFound/NotFound";
import RichTextEditor from "./RichTextEditor";

const styles = {
  pageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    p: 3,
  },
};

const NotifySubscribers = ({ user, qrCode, qrContent }) => {
  if (qrCode.type !== QR_CODE_TYPE.CUSTOM_PAGE) {
    return <NotFound />;
  }

  const mails = qrContent?.customPage?.mails;

  return (
    <Box sx={styles.pageContainer}>
      <Typography variant="h4" sx={{ mt: 3 }}>
        Notifiez vos clients
      </Typography>
      <Typography variant="overline" sx={{ mb: 4 }}>
        Vous avez actuellement {mails.length} abonné(s)
      </Typography>
      <RichTextEditor user={user} qrCode={qrCode} qrContent={qrContent} />
      <Divider sx={{ my: 3 }} />
    </Box>
  );
};

export default NotifySubscribers;
