import React from "react";

import logo from "../../assets/footer/OQRpolicewhite.png";
import { Grid, Box, Typography, IconButton } from "@mui/material";
import {
  CustomFooterLink,
  CustomRouterFooterLink,
} from "../../utils/styled/Styled";

import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const styles = {
  footer: {
    borderTop: "1px solid #F9F9F9",
    width: "100%",
    backgroundColor: "#282828",
    color: "white",
    clear: "both",
    position: "relative",
    zIndex: 1,
  },
  subFooter: {
    backgroundColor: "#191919",
    color: "white",
    padding: "20px",
    clear: "both",
    position: "relative",
    zIndex: 1,
  },
  title: {
    color: "#58738D",
    fontSize: "30px",
    fontWeight: "bold",
  },
  subTitle: {
    fontSize: "24px",
    fontWeight: "bold",
  },
  footerText: {
    py: 2,
    gap: "10px",
  },
  middleTextContainer: {
    py: 2,
    display: "flex",
    justifyContent: "start",
    alignItems: "start",
    flexDirection: "column",
  },
  endTextContainer: {
    py: 2,
    pl: { xs: 2, lg: 0 },
    display: "flex",
    justifyContent: { lg: "end", xs: "start" },
    alignItems: { lg: "end", xs: "start" },
    flexDirection: "column",
  },
  iconButton: {
    color: "white",
    mr: 1,
  },
  startBox: {
    py: 2,
    pl: { xs: 2, md: 4 },
    display: "flex",
    justifyContent: "start",
    flexDirection: "column",
    alignItems: "start",
  },
  endBox: {
    pb: 2,
    pr: 4,
    display: "flex",
    justifyContent: { lg: "center", xs: "start" },
    alignItems: { lg: "end", xs: "start" },
    flexDirection: "column",
  },
  middleBox: {
    p: 2,
    display: "flex",
    justifyContent: { lg: "center", xs: "start" },
    alignItems: { lg: "center", xs: "start" },
    flexDirection: "column",
  },
  icon: { "&:hover": { color: "#58738D" }, fontSize: "50px" },
};

export default function Footer() {
  return (
    <footer style={styles.footer}>
      <Box sx={styles.footer}>
        <Grid container spacing={0}>
          <Grid item xs={12} md={4} lg={4}>
            <Box sx={styles.startBox}>
              <Typography sx={styles.title}>Support et contact</Typography>

              <CustomFooterLink href="mailto:support@octopusqr.com">
                support@octopusqr.com
              </CustomFooterLink>
              <CustomFooterLink href="tel:06.64.80.54.27">
                06.64.80.54.27
              </CustomFooterLink>
              <Box sx={{ py: 2 }} />
              <Typography sx={styles.title}>Suivez nous sur</Typography>
              <Box sx={{ display: "flex", justifyContent: "start" }}>
                <CustomFooterLink
                  href="https://www.instagram.com/octopus_qr/"
                  target="_blank"
                >
                  <IconButton sx={styles.iconButton}>
                    <InstagramIcon sx={styles.icon} />
                  </IconButton>
                </CustomFooterLink>
                <CustomFooterLink
                  href="https://www.linkedin.com/company/77627119"
                  target="_blank"
                >
                  <IconButton sx={styles.iconButton}>
                    <LinkedInIcon sx={styles.icon} />
                  </IconButton>
                </CustomFooterLink>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Box sx={styles.middleBox}>
              <Typography sx={styles.title}>À propos d'OctopusQR</Typography>
              <Box sx={styles.middleTextContainer}>
                <Typography>Siret : 907 635 205 00012</Typography>
                <Typography>Montauroux</Typography>
                <Box sx={{ py: 2 }} />
                <Typography>Société spécialisée dans l’informatique</Typography>
                <Box sx={{ py: 2 }} />
                <Typography>Marque déposée INPI</Typography>
                <Typography>
                  L’ensemble des éléments visuels et textuels de ce site fait
                  l’objet d’un dépôt INPI-Soleau
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Box sx={styles.endBox}>
              <img src={logo} width={200} alt="whiteLogo" />
              <Box sx={styles.endTextContainer}>
                <Typography sx={styles.subTitle}>Assistance</Typography>
                <Typography>
                  <CustomRouterFooterLink to="/contact" target="_blank">
                    Contact
                  </CustomRouterFooterLink>{" "}
                  /{" "}
                  <CustomRouterFooterLink to="/faq" target="_blank">
                    FAQ
                  </CustomRouterFooterLink>{" "}
                  /{" "}
                  <CustomRouterFooterLink to="/press" target="_blank">
                    Kit presse
                  </CustomRouterFooterLink>
                </Typography>
                <Box sx={{ py: 2 }} />
                <Typography sx={styles.subTitle}>Légal</Typography>
                <Typography>
                  <CustomRouterFooterLink to="/terms" target="_blank">
                    CGU
                  </CustomRouterFooterLink>{" "}
                  /{" "}
                  <CustomRouterFooterLink to="/privacy" target="_blank">
                    Politique de confidentialité
                  </CustomRouterFooterLink>{" "}
                  /{" "}
                  <CustomRouterFooterLink to="/legal" target="_blank">
                    Mentions légales
                  </CustomRouterFooterLink>
                </Typography>
              </Box>
            </Box>
            {/* <CustomRouterLink to="/terms" style={styles.link} target="_blank">
            Conditions générales d'utilisation
          </CustomRouterLink>
          <br />
          <br />
          <CustomRouterLink to="/privacy" style={styles.link} target="_blank">
            Politique de confidentialité
          </CustomRouterLink>
          <br />
          <br />
          <CustomRouterLink to="/legal" style={styles.link} target="_blank">
            Mentions légales
          </CustomRouterLink> */}
          </Grid>
        </Grid>
      </Box>
      <Box sx={styles.subFooter}>
        <Typography variant="overline">
          © Copyright 2023 . Tous droits réservés. OctopusQR
        </Typography>
      </Box>
    </footer>
  );
}
