import api from "../../axios";

export const postWifiQr = async (iUserId, iQrCodeId, iData) => {
  const aResponse = await api.post(
    `/users/${iUserId}/qrcodes/${iQrCodeId}/wifis`,
    iData
  );
  return aResponse.data;
};

export const putWifiQr = async (iUserId, iQrCodeId, iWifiId, iData) => {
  const aResponse = await api.put(
    `/users/${iUserId}/qrcodes/${iQrCodeId}/wifis/${iWifiId}`,
    iData
  );
  return aResponse.data;
};

export const verifyWifiData = (iData) => {
  const aErrorValues = {
    hasSsidError: false,
    ssidErrorText: "",
    hasSecurityTypeError: false,
    securityTypeText: "",
  };
  let hasFormErrors = false;

  if (!iData.ssid) {
    aErrorValues.ssidErrorText = "Champs requis";
    aErrorValues.hasSsidError = true;
    hasFormErrors = true;
  }

  if (!iData.securityType) {
    aErrorValues.securityTypeText = "Champs requis";
    aErrorValues.hasSecurityTypeError = true;
    hasFormErrors = true;
  }

  return { aErrorValues, hasFormErrors };
};
