import { Box, IconButton } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import PinterestIcon from "@mui/icons-material/Pinterest";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import RedditIcon from "@mui/icons-material/Reddit";
import TwitterIcon from "@mui/icons-material/Twitter";
import {
  TikTok,
  Snapchat,
  Tripadvisor,
  CustomLink,
} from "../../../../../../utils/styled/Styled";
import { putStatistic } from "../../../../../../api/qrCodes/statisticsHandler";
import GoogleIcon from "@mui/icons-material/Google";

export default function SocialNetworksDisplay({ socialNetworks, customPage }) {
  const updateStatistic = async (iElemId) => {
    await putStatistic(iElemId);
  };

  const SOCIAL_NETWORK_ICON_MAP = {
    LINKEDIN: (
      <LinkedInIcon sx={{ fontSize: customPage?.socialNetworksSize }} />
    ),
    INSTAGRAM: (
      <InstagramIcon sx={{ fontSize: customPage?.socialNetworksSize }} />
    ),
    FACEBOOK: (
      <FacebookIcon sx={{ fontSize: customPage?.socialNetworksSize }} />
    ),
    YOUTUBE: <YouTubeIcon sx={{ fontSize: customPage?.socialNetworksSize }} />,
    TRIPADVISOR: (
      <Tripadvisor
        color={customPage?.socialNetworksColor}
        size={customPage?.socialNetworksSize}
      />
    ),
    GOOGLE: <GoogleIcon sx={{ fontSize: customPage?.socialNetworksSize }} />,
    REDDIT: <RedditIcon sx={{ fontSize: customPage?.socialNetworksSize }} />,
    TWITTER: <TwitterIcon sx={{ fontSize: customPage?.socialNetworksSize }} />,
    PINTEREST: (
      <PinterestIcon sx={{ fontSize: customPage?.socialNetworksSize }} />
    ),
    TIKTOK: (
      <TikTok
        color={customPage?.socialNetworksColor}
        size={customPage?.socialNetworksSize}
      />
    ),
    SNAPCHAT: (
      <Snapchat
        color={customPage?.socialNetworksColor}
        size={customPage?.socialNetworksSize}
      />
    ),
  };
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        justifyContent: "space-evenly",
        width: "100%",
      }}
    >
      {socialNetworks.map((aSocialNetwork, index) => {
        return (
          <CustomLink
            onClick={() => {
              updateStatistic(aSocialNetwork._id);
            }}
            key={index}
            href={aSocialNetwork.path}
            target="_blank"
          >
            <IconButton sx={{ color: customPage?.socialNetworksColor }}>
              {SOCIAL_NETWORK_ICON_MAP[aSocialNetwork.type]}
            </IconButton>
          </CustomLink>
        );
      })}
    </Box>
  );
}
