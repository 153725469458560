import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { OctopusQRButton } from "../../../utils/styled/Styled";
import { styles } from "./GenerateToken.style";

import {
  Dialog,
  Grid,
  Box,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  TextField,
  FormControl,
  InputAdornment,
} from "@mui/material";
import LockResetIcon from "@mui/icons-material/LockReset";
import CloseIcon from "@mui/icons-material/Close";
import EmailIcon from "@mui/icons-material/Email";
import {
  postToken,
  verifyPostTokenData,
} from "../../../api/user/password/token/postToken";

export default function GenerateToken() {
  const [formValues, setFormValues] = useState({
    mail: "",
  });
  const [errorValues, setErrorValues] = useState({
    hasMailError: false,
    mail: "",
  });
  const [isMailSended, setIsMailSended] = useState(false);
  const navigateTo = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    const aErrorValues = verifyPostTokenData(formValues);
    setErrorValues({
      ...aErrorValues,
    });

    if (aErrorValues.hasMailError) {
      return;
    }

    setIsMailSended(true);
    postToken({
      mail: formValues.mail,
    });
  };

  const handleClose = () => {
    navigateTo("/login");
  };

  return (
    <Dialog open={true} onClose={handleClose}>
      <Box
        component="form"
        sx={{ "& > :not(style)": { m: 0 } }}
        noValidate
        autoComplete="off"
      >
        <DialogTitle>
          {/* {isMailSended ? <DoneIcon style={styles.doneIcon} /> : null} */}
          <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <LockResetIcon style={styles.centeredIcon} />
          {isMailSended ? null : (
            <Typography style={styles.title}>
              Réinitialisation de mot de passe
            </Typography>
          )}
        </DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          {isMailSended ? (
            <>
              <Typography variant="overline" sx={{ mb: 2 }}>
                Un mail a été envoyé. Vérifiez bien vos spams.
              </Typography>
            </>
          ) : (
            <>
              <FormControl style={styles.formControl}>
                <TextField
                  id="mail-reset-input"
                  name="mail"
                  type="email"
                  label="Adresse Mail"
                  required={true}
                  variant="outlined"
                  value={formValues.mail}
                  error={errorValues.hasMailError}
                  helperText={errorValues.mail}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                  sx={styles.field}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailIcon style={styles.icon} />
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
              <Grid container spacing={0}>
                <Grid item xs={1} sm={2} md={3} />
                <Grid item xs={10} sm={8} md={6}>
                  <OctopusQRButton style={styles.button} onClick={handleSubmit}>
                    Réinitialiser
                  </OctopusQRButton>
                </Grid>
                <Grid item xs={1} sm={2} md={3} />
              </Grid>
            </>
          )}
        </DialogContent>
      </Box>
    </Dialog>
  );
}
