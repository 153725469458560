import { useNavigate } from "react-router-dom";
import { useState } from "react";

import { ADD_QR_ACTIONS } from "../../../../../utils/constants/Constants";
import { styles } from "./FormContent.style";

import {
  deleteQrCode,
  postQrCode,
  postQrLogo,
} from "../../../../../api/qrCodes/qrCodesHandler";
import Loading from "../../../../../components/loading/Loading";
import { STORAGE_USER_ID } from "../../../../../utils/constants/Constants";
import { OctopusQRButton } from "../../../../../utils/styled/Styled";
import {
  postFormQr,
  verifyFormData,
} from "../../../../../api/qrCodes/form/formQrHandler";
import {
  Box,
  Card,
  Typography,
  IconButton,
  FormControl,
  TextField,
  InputAdornment,
} from "@mui/material";
import WestIcon from "@mui/icons-material/West";
import FormSortable from "./formSortable/FormSortable";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AbcIcon from "@mui/icons-material/Abc";

export default function FormContent({
  currentAction,
  QRCode,
  file,
  putQRCode,
}) {
  const [formData, setFormData] = useState({
    formTitle: "",
    items: {
      item_1: {
        question: "Question",
        type: "Réponse courte",
        isRequired: true,
      },
    },
  });

  const updateFormData = (iValue) => {
    setFormData({ ...formData, ...iValue });
  };

  const [errorValues, setErrorValues] = useState({
    formTitle: "",
    hasFormTitleError: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const navigateTo = useNavigate();

  const [numberOfItemsCreated, setNumberOfItemsCreated] = useState(1);
  const [items, setItems] = useState([
    {
      id: "item_1",
      position: 1,
    },
  ]);

  const handleInputChange = (iEvent) => {
    setFormData({ ...formData, formTitle: iEvent.target.value });
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    setHasError(false);
    setErrorText("");

    const aErrorValues = verifyFormData(formData);

    setErrorValues({ ...aErrorValues });
    if (aErrorValues.hasFormTitleError) {
      setHasError(true);
      setErrorText("Veuillez ajouter un titre au formulaire");
      setIsLoading(false);
      return;
    }

    const aItems = Array.from(items);
    aItems.forEach((aItem) => {
      formData.items[aItem.id].position = aItem.position;
    });

    const aUserId = localStorage.getItem(STORAGE_USER_ID);
    if (!aUserId) {
      setHasError(true);
      setErrorText("Vous n'êtes pas autorisé à faire cela");
      setIsLoading(false);
      return;
    }

    let aQRCodeId;
    try {
      if (!QRCode?._id) {
        const aQRCode = await postQrCode(aUserId, QRCode);
        aQRCodeId = aQRCode._id;
        if (file !== null) {
          const aQrLogoData = new FormData();
          aQrLogoData.append("file", file);
          await postQrLogo(aUserId, aQRCodeId, aQrLogoData);
        }
      } else {
        await putQRCode();
        aQRCodeId = QRCode._id;
      }
      await postFormQr(aUserId, aQRCodeId, formData);
      setIsLoading(false);
      navigateTo("/qrcodes");
    } catch {
      if (!QRCode?._id) {
        await deleteQrCode(aUserId, aQRCodeId);
      }
      setHasError(true);
      setErrorText("Une erreur est survenue, veuillez réessayer");
      setIsLoading(false);
      return;
    }
  };

  const addItem = async () => {
    const aNewId = `item_${numberOfItemsCreated + 1}`;
    setFormData({
      ...formData,
      items: {
        ...formData.items,
        [aNewId]: {
          question: "Question",
          type: "Réponse courte",
          entries: {},
          isRequired: true,
        },
      },
    });
    setItems([
      ...items,
      {
        id: aNewId,
        position: items.length + 1,
      },
    ]);
    setNumberOfItemsCreated(numberOfItemsCreated + 1);
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Box
        sx={{
          ...styles.flexCenter,
          width: "100%",
        }}
      >
        <Card sx={styles.card}>
          <IconButton
            sx={styles.backArrow}
            onClick={() => {
              currentAction(ADD_QR_ACTIONS.CUSOMIZATION);
            }}
          >
            <WestIcon />
          </IconButton>
          <Typography variant="h4" sx={styles.titleContainer}>
            Création du formulaire
          </Typography>
          <br />
          <FormControl sx={styles.formControl}>
            <TextField
              id="form-title-input"
              name="formTitle"
              label="Titre"
              required={true}
              variant="outlined"
              value={formData.formTitle}
              error={errorValues.hasFormTitleError}
              helperText={errorValues.formTitle}
              onChange={handleInputChange}
              sx={styles.field}
              placeholder="Questionnaire"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AbcIcon />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
          <br />
          <FormSortable
            items={items}
            setItems={setItems}
            formData={formData}
            updateFormData={updateFormData}
          />

          <br />
          <IconButton onClick={addItem}>
            <AddCircleOutlineIcon />
          </IconButton>
          <br />
          <OctopusQRButton sx={styles.button} onClick={handleSubmit}>
            Terminer
          </OctopusQRButton>
          <br />
          {hasError ? (
            <Typography variant="overline" sx={styles.errorText}>
              {errorText}
            </Typography>
          ) : null}
        </Card>
      </Box>
    </>
  );
}
