import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { styles } from "./SocialNetworkContent.style";
import {
  postSocialNetworkQr,
  verifySocialNetworksData,
} from "../../../../../api/qrCodes/socialNetwork/socialNetworkQrHandler";
import {
  deleteQrCode,
  postQrLogo,
  postQrCode,
} from "../../../../../api/qrCodes/qrCodesHandler";
import Loading from "../../../../../components/loading/Loading";
import {
  SOCIAL_NETWORK_ARRAY,
  ADD_QR_ACTIONS,
  STORAGE_USER_ID,
  SOCIAL_NETWORK_ICON_MAP,
  SOCIAL_NETWORK_LINK_MAP,
} from "../../../../../utils/constants/Constants";
import { OctopusQRButton } from "../../../../../utils/styled/Styled";

import {
  Box,
  Card,
  Typography,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  IconButton,
  TextField,
} from "@mui/material";
import WestIcon from "@mui/icons-material/West";
import { capitalizeFirstLetter } from "../../../../../utils/Utils";

export default function SocialNetworkContent({
  currentAction,
  QRCode,
  file,
  putQRCode,
}) {
  const [socialNetworkSelected, setSocialNetworkSelected] =
    useState("INSTAGRAM");

  const updateSocialNetworkSelected = (iEvent) => {
    setSocialNetworkSelected(iEvent.target.value);
  };

  const [formValues, setFormValues] = useState({ pseudonym: "" });
  const [errorValues, setErrorValues] = useState({
    pseudonym: "",
    hasPseudonymError: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const navigateTo = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const aErrorValues = verifySocialNetworksData(formValues.pseudonym);
    setErrorValues({ ...aErrorValues });
    if (aErrorValues.hasPseudonymError) {
      setIsLoading(false);
      return;
    }

    const aUserId = localStorage.getItem(STORAGE_USER_ID);
    if (!aUserId) {
      setHasError(true);
      setIsLoading(false);
      return;
    }

    let aQRCodeId;
    try {
      if (!QRCode?._id) {
        const aQRCode = await postQrCode(aUserId, QRCode);
        aQRCodeId = aQRCode._id;
        if (file !== null) {
          const aQrLogoData = new FormData();
          aQrLogoData.append("file", file);
          await postQrLogo(aUserId, aQRCodeId, aQrLogoData);
        }
      } else {
        await putQRCode();
        aQRCodeId = QRCode._id;
      }
      await postSocialNetworkQr(aUserId, aQRCodeId, {
        qrCodeId: aQRCodeId,
        path:
          SOCIAL_NETWORK_LINK_MAP[socialNetworkSelected] + formValues.pseudonym,
        type: socialNetworkSelected.toUpperCase(),
      });
      navigateTo("/qrcodes");
    } catch {
      if (!QRCode?._id) {
        await deleteQrCode(aUserId, aQRCodeId);
      }
      setHasError(true);
      setIsLoading(false);
      return;
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Box
        sx={{
          ...styles.flexCenter,
          width: "100%",
        }}
      >
        <Card sx={styles.card}>
          <IconButton
            sx={styles.backArrow}
            onClick={() => {
              currentAction(ADD_QR_ACTIONS.CUSOMIZATION);
            }}
          >
            <WestIcon />
          </IconButton>
          <Typography variant="h4" sx={styles.titleContainer}>
            Choix du réseau
          </Typography>
          <FormControl sx={styles.formControl}>
            <InputLabel id="font-label">Votre choix</InputLabel>
            <Select
              labelId="font-label"
              value={socialNetworkSelected}
              sx={styles.field}
              MenuProps={styles.menuProps}
              onChange={updateSocialNetworkSelected}
              label="Votre choix"
            >
              {SOCIAL_NETWORK_ARRAY.map((aSocialNetwork) => {
                return (
                  <MenuItem value={aSocialNetwork} key={aSocialNetwork}>
                    <Box sx={styles.flexCenter}>
                      {SOCIAL_NETWORK_ICON_MAP[aSocialNetwork]}

                      <Typography sx={{ ml: 2 }}>
                        {capitalizeFirstLetter(aSocialNetwork)}
                      </Typography>
                    </Box>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <Box
            sx={{
              display: { xs: "block", md: "flex" },
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="overline">
              {SOCIAL_NETWORK_LINK_MAP[socialNetworkSelected]}
            </Typography>
            <FormControl sx={styles.pseudonymForm}>
              <TextField
                id="pseudonym-input"
                name="pseudonym"
                label="Pseudonyme"
                required={true}
                variant="outlined"
                value={formValues.pseudonym}
                error={errorValues.hasPseudonymError}
                helperText={errorValues.pseudonym}
                onChange={handleInputChange}
                sx={styles.field}
              />
            </FormControl>
          </Box>
          <OctopusQRButton sx={styles.button} onClick={handleSubmit}>
            Terminer
          </OctopusQRButton>
          <br />
          {hasError ? (
            <Typography variant="overline" sx={styles.errorText}>
              Une erreur est survenue, veuillez réessayer
            </Typography>
          ) : null}
        </Card>
      </Box>
    </>
  );
}
