import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  deleteQrFolder,
  getQrFolder,
  postQrFolder,
  renameQrFolder,
} from "../../../api/qrCodes/qrFolder/qrFoldersHandler";
import { getUser } from "../../../api/user/getUser";
import { patchQrCodeFolder } from "../../../api/qrCodes/qrCodesHandler";

import { styles } from "./QRFolder.style";

import Footer from "../../../components/footer/Footer";
import LoadingWithMenu from "../../../components/loading/LoadingWithMenu";
import NavMenu from "../../../components/navMenu/NavMenu";
import QRCodeCard from "../main/display/QRCodeCard";
import DeleteQRFolder from "./DeleteQRFolder";
import QRToolbar from "../main/QRToolbar";

import { STORAGE_USER_ID } from "../../../utils/constants/Constants";

import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";

import { Box, IconButton, Typography, Link, MenuItem } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import FolderIcon from "@mui/icons-material/Folder";
import EditIcon from "@mui/icons-material/Edit";
import ModifyQrFolder from "./ModifyQrFolder";
import ErrorCardWithMenu from "../../../components/error/ErrorCardWithMenu";
import PhoneQRFolderDisplay from "../main/qrFolder/PhoneQRFoldersDisplay";
import QRFoldersDisplay from "../main/qrFolder/QRFoldersDisplay";
import AddQrFolder from "./AddQrFolder";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const NavigationBar = ({ parents, folder }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        mx: 3,
        mt: 1,
      }}
    >
      <Link color="inherit" href="/qrcodes">
        <Typography variant="overline">Accueil</Typography>
      </Link>
      <ArrowForwardIosIcon sx={{ mx: 1, fontSize: "10px" }} />
      {parents.map((parent, index) => {
        return (
          <Box key={index}>
            <Link color="inherit" href={`/qrcodes/folder/${parent._id}`}>
              <Typography variant="overline">{parent.name}</Typography>
            </Link>
            <ArrowForwardIosIcon sx={{ mx: 1, fontSize: "10px" }} />
          </Box>
        );
      })}
      <Typography variant="overline">{folder.name}</Typography>
    </Box>
  );
};

const QRFolder = () => {
  const { folderId } = useParams();
  const [hasApiError, setHasApiError] = useState(false);
  const [initialQrCodes, setInitialQrCodes] = useState();
  const [qrCodes, setQrCodes] = useState();
  const [folder, setFolder] = useState("");
  const [user, setUser] = useState();
  const navigateTo = useNavigate();
  const [searchValue, setSearchValue] = useState("");

  const [isFolderDialogOpen, setIsFolderDialogOpen] = useState(false);
  const [isDeleteFolderOpen, setIsDeleteFolderOpen] = useState(false);
  const [isMofidyFolderOpen, setIsModifyFolderOpen] = useState(false);
  const [selectedQrs, setSelectedQrs] = useState([]);
  const [parents, setParents] = useState("home");
  const [children, setChildren] = useState([]);

  const closeDeleteFolder = () => {
    setIsDeleteFolderOpen(false);
  };

  const moveCard = async (cardId, folderId) => {
    if (!cardId || !folderId) return;
    const aQrCodes = qrCodes.filter((qrCode) => qrCode._id !== cardId);
    setQrCodes(aQrCodes);
    if (aQrCodes.length === 0) {
      getData();
    }
    await patchQrCodeFolder(user._id, cardId, {
      folderId: folderId,
    });
  };

  const moveCards = async (cards, folderId) => {
    if (!cards || !folderId) return;
    const aQrCodes = qrCodes.filter(
      (qrCode) => !cards.some((card) => card._id === qrCode._id)
    );
    setQrCodes(aQrCodes);
    if (aQrCodes.length === 0) {
      getData();
    }
    await Promise.all(
      cards.map((card) =>
        patchQrCodeFolder(user._id, card._id, {
          folderId: folderId,
        })
      )
    );
  };

  const handleDelete = async (folder) => {
    try {
      await deleteQrFolder(user._id, folder._id);
      folder.parent !== "home"
        ? navigateTo(`/qrcodes/folder/${folder.parent}`)
        : navigateTo("/qrcodes");
    } catch {
      setHasApiError(true);
    }
  };

  const closeModifyFolder = () => {
    setIsModifyFolderOpen(false);
  };

  const handleModify = async (iFolderName) => {
    try {
      await renameQrFolder(user._id, folder._id, { name: iFolderName });
      setIsModifyFolderOpen(false);
      setSelectedQrs([]);
      await getFolders();
    } catch {}
  };

  const getData = useCallback(async () => {
    try {
      const aUser = await getUser(localStorage.getItem(STORAGE_USER_ID));
      setUser(aUser);
      const aQrFolder = await getQrFolder(aUser._id, folderId);
      setQrCodes(aQrFolder.qrCodes);
      setInitialQrCodes(aQrFolder.qrCodes);
      setParents(aQrFolder.parents);
      setChildren(aQrFolder.children);
      setFolder(aQrFolder.current);
    } catch {
      setHasApiError(true);
    }
  }, [folderId]);

  useEffect(() => {
    getData();
  }, [getData]);

  const handleSearchChange = (iEvent) => {
    setSearchValue(iEvent.target.value);
    if (iEvent.target.value.length <= 2) {
      setQrCodes(initialQrCodes);
      return;
    }
    setQrCodes(
      qrCodes.filter((qrCode) =>
        qrCode.name
          .toLowerCase()
          .normalize()
          .includes(iEvent.target.value.toLowerCase().normalize())
      )
    );
  };

  const handleOpenFolderDialog = () => {
    setIsFolderDialogOpen(true);
  };

  const handleCloseFolderDialog = () => {
    setIsFolderDialogOpen(false);
  };

  const handleSaveFolder = async (folderName) => {
    await postQrFolder(user._id, { name: folderName, parent: folder._id });
    setSelectedQrs([]);
    await getFolders();
  };

  const getFolders = async () => {
    try {
      const userId = localStorage.getItem(STORAGE_USER_ID);
      const aQrFolder = await getQrFolder(userId, folderId);
      setParents(aQrFolder.parents);
      setChildren(aQrFolder.children);
      setFolder(aQrFolder.current);
    } catch {
      setHasApiError(true);
    }
  };

  if (hasApiError) {
    return <ErrorCardWithMenu />;
  }

  if (!qrCodes || !user || !folder) {
    return <LoadingWithMenu />;
  }

  return (
    <>
      <Box style={styles.pageContainer}>
        <NavMenu />
        <PhoneQRFolderDisplay qrFolders={children} isHome={false} />
        <IconButton
          onClick={() => {
            folder.parent !== "home"
              ? navigateTo(`/qrcodes/folder/${folder.parent}`)
              : navigateTo("/qrcodes");
          }}
          sx={{ width: "50px", height: "50px", m: 3, position: "absolute" }}
        >
          <ArrowBackIcon sx={{ fontSize: "40px" }} />
        </IconButton>

        <IconButton
          onClick={() => setIsDeleteFolderOpen(true)}
          sx={{
            width: "50px",
            height: "50px",
            m: 3,
            position: "absolute",
            right: 0,
          }}
        >
          <DeleteForeverIcon sx={{ fontSize: "40px", color: "#8B0000" }} />
        </IconButton>

        <Box sx={{ ...styles.flexColumnCenter, ml: { md: 0, lg: "345px" } }}>
          <FolderIcon sx={{ color: "#58738D", fontSize: "70px" }} />
          <Typography variant="overline">{folder.name}</Typography>
          <IconButton onClick={() => setIsModifyFolderOpen(true)}>
            <EditIcon />
          </IconButton>
        </Box>

        <QRToolbar
          searchValue={searchValue}
          handleSearchChange={handleSearchChange}
          selectedQrs={selectedQrs}
          setSelectedQrs={setSelectedQrs}
          qrFolders={children}
          moveCards={moveCards}
          isHome={false}
          parent={parents[parents.length - 1]}
          qrCodes={qrCodes}
        />
        <NavigationBar parents={parents} folder={folder} />
        <Box sx={{ display: "flex", height: "100%" }}>
          <DndProvider backend={HTML5Backend}>
            <QRFoldersDisplay
              user={user}
              getFolders={getFolders}
              handleOpen={handleOpenFolderDialog}
              qrFolders={children}
              isHome={false}
              parent={parents[parents.length - 1]}
              moveCard={moveCard}
              setSelectedQrs={setSelectedQrs}
            />
            {qrCodes.length === 0 ? (
              <>
                {children.length === 0 ? (
                  <Box sx={{ margin: "auto" }}>
                    <Typography variant="overline">
                      Aucun QR Code dans ce dossier pour le moment
                    </Typography>
                  </Box>
                ) : (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        justifyContent: "center",
                        margin: "auto",
                      }}
                    >
                      <Typography variant="overline" sx={{ mx: 1, mt: 2 }}>
                        Vous n'avez aucun QR Codes dans ce dossier, consultez
                        vos sous-dossiers.
                      </Typography>
                      <Box sx={{ display: "flex" }}>
                        {children.map((aQrFolder) => {
                          return (
                            <MenuItem
                              sx={{
                                width: "150px",
                                borderRadius: "20px",
                                mx: 2,
                                ...styles.flexColumnCenter,
                              }}
                              key={aQrFolder._id}
                              onClick={() =>
                                navigateTo(`/qrcodes/folder/${aQrFolder._id}`)
                              }
                            >
                              <FolderIcon
                                sx={{ color: "#58738D", fontSize: "100px" }}
                              />
                              <Typography variant="overline">
                                {aQrFolder.name}
                              </Typography>
                            </MenuItem>
                          );
                        })}
                      </Box>
                    </Box>
                  </>
                )}
              </>
            ) : (
              <Box sx={styles.cardContainer}>
                {qrCodes.map((aQrCode, index) => {
                  return (
                    <QRCodeCard
                      user={user}
                      qrCode={aQrCode}
                      index={index}
                      selectedQrs={selectedQrs}
                      setSelectedQrs={setSelectedQrs}
                      qrCodes={qrCodes}
                      key={aQrCode._id}
                    />
                  );
                })}
              </Box>
            )}
          </DndProvider>
        </Box>
      </Box>
      <Footer />
      <DeleteQRFolder
        open={isDeleteFolderOpen}
        onClose={closeDeleteFolder}
        folder={folder}
        handleDelete={handleDelete}
      />
      <ModifyQrFolder
        open={isMofidyFolderOpen}
        onClose={closeModifyFolder}
        qrFolder={folder}
        handleModify={handleModify}
      />
      <AddQrFolder
        open={isFolderDialogOpen}
        onClose={handleCloseFolderDialog}
        onSave={handleSaveFolder}
      />
    </>
  );
};

export default QRFolder;
