import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import WhiteLogo from "../../assets/white_logo.png";

import LoggedMenu from "./loggedMenu/LoggedMenu.js";
import NotLoggedMenu from "./notLoggedMenu/NotLoggedMenu.js";

import { AppBar, Box, Toolbar, Typography } from "@mui/material";

export default function NavMenu() {
  const user = useSelector((state) => state.userReducer);

  return (
    <header>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="sticky"
          sx={{ color: "black", background: "white" }}
          enableColorOnDark
        >
          <Toolbar>
            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <Link
                to="/"
                sx={{
                  justifyContent: "center",
                }}
              >
                <img src={WhiteLogo} width="120" alt="logo" />
              </Link>
            </Box>
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <Link
                to="/"
                sx={{
                  justifyContent: "center",
                }}
              >
                <img src={WhiteLogo} width="200" alt="logo" />
              </Link>
            </Box>

            <Typography sx={{ flexGrow: 1 }}></Typography>
            {user.loggedIn ? <LoggedMenu user={user} /> : <NotLoggedMenu />}
          </Toolbar>
        </AppBar>
      </Box>
    </header>
  );
}
