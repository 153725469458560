import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { styles } from "../QRCodeView.style";

import {
  QR_CODE_TYPE,
  DYNAMIC_QR_TYPE,
} from "../../../../utils/constants/Constants";

import MenuIcon from "@mui/icons-material/Menu";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import { Box, Typography, MenuItem, Menu, IconButton } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export default function PhoneViewMenu({
  modifySearchParams,
  qrCode,
  qrContent,
}) {
  const navigateTo = useNavigate();
  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const isMenuOpen = Boolean(anchorElMenu);

  const handleClickMenu = (event) => {
    setAnchorElMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  return (
    <Box sx={styles.menuContainer}>
      <IconButton
        onClick={() => {
          if (qrCode.folderId) {
            navigateTo(`/qrcodes/folder/${qrCode.folderId}`);
          } else {
            navigateTo(`/qrcodes`);
          }
        }}
        sx={{ ml: 2, color: "white" }}
      >
        <ArrowBackIcon />
      </IconButton>
      <Box sx={{ flexGrow: 1 }} />
      <IconButton
        onClick={handleClickMenu}
        size="small"
        sx={{ mr: 2 }}
        aria-controls={isMenuOpen ? "menu" : undefined}
        aria-haspopup="true"
        aria-expanded={isMenuOpen ? "true" : undefined}
      >
        <MenuIcon sx={{ color: "white" }} />
      </IconButton>
      <Menu
        anchorEl={anchorElMenu}
        id="-menu"
        open={isMenuOpen}
        onClose={handleCloseMenu}
        onClick={handleCloseMenu}
        slotProps={{ paper: styles.paperMenuProps }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          sx={styles.menuItem}
          onClick={() => {
            modifySearchParams("customize");
          }}
        >
          <QrCode2Icon sx={{ mr: 1, color: "#58738D" }} />
          <Typography variant="overline" sx={styles.menuTypo}>
            Personnaliser
          </Typography>
        </MenuItem>

        {DYNAMIC_QR_TYPE.includes(qrCode.type) && [
          <MenuItem
            key="editPhone"
            sx={styles.menuItem}
            onClick={() => {
              modifySearchParams("edit");
            }}
          >
            <EditIcon sx={{ mr: 1, color: "#58738D" }} />
            <Typography variant="overline" sx={styles.menuTypo}>
              Éditer
            </Typography>
          </MenuItem>,

          <MenuItem
            key="typePhone"
            sx={styles.menuItem}
            onClick={() => {
              modifySearchParams("type");
            }}
          >
            <GroupWorkIcon sx={{ mr: 1, color: "#58738D" }} />
            <Typography variant="overline" sx={styles.menuTypo}>
              Type
            </Typography>
          </MenuItem>,

          <MenuItem
            key="statsPhone"
            sx={styles.menuItem}
            onClick={() => {
              modifySearchParams("statistics");
            }}
          >
            <QueryStatsIcon sx={{ mr: 1, color: "#58738D" }} />
            <Typography variant="overline" sx={styles.menuTypo}>
              Statistiques
            </Typography>
          </MenuItem>,
        ]}

        {qrCode.type === QR_CODE_TYPE.CUSTOM_PAGE &&
          qrContent?.customPage?.hasPush && (
            <MenuItem
              sx={styles.menuItem}
              onClick={() => {
                modifySearchParams("notify");
              }}
            >
              <NotificationsIcon sx={{ mr: 1, color: "#58738D" }} />
              <Typography variant="overline" sx={styles.menuTypo}>
                Notifications
              </Typography>
            </MenuItem>
          )}

        {qrCode.type === QR_CODE_TYPE.FORM && [
          <MenuItem
            key="previewPhone"
            sx={styles.menuItem}
            onClick={() => {
              modifySearchParams("preview");
            }}
          >
            <RemoveRedEyeIcon sx={{ mr: 1, color: "#58738D" }} />
            <Typography variant="overline" sx={styles.menuTypo}>
              Rendu
            </Typography>
          </MenuItem>,

          <MenuItem
            key="answersPhone"
            sx={styles.menuItem}
            onClick={() => {
              modifySearchParams("answers");
            }}
          >
            <QuestionAnswerIcon sx={{ mr: 1, color: "#58738D" }} />
            <Typography variant="overline" sx={styles.menuTypo}>
              Réponses
            </Typography>
          </MenuItem>,
        ]}

        <MenuItem
          sx={styles.menuItem}
          onClick={() => {
            modifySearchParams("delete");
          }}
        >
          <DeleteForeverIcon sx={{ mr: 1, color: "#58738D" }} />
          <Typography variant="overline" sx={styles.menuTypo}>
            Supprimer
          </Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
}
