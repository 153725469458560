import { OctopusQRButton } from "../../../../../../../utils/styled/Styled";
import LinkIcon from "@mui/icons-material/Link";
import { Box } from "@mui/material";
import { FILE_ICON_MAP } from "../../../../../../../utils/constants/Constants";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { getVisibleColor } from "../../../../../../../utils/Utils";
import { CustomLink } from "../../../../../../../utils/styled/Styled";

export default function LinksAndFilesDeviceComponent({
  content,
  buttonStyle,
  customization,
}) {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <DragIndicatorIcon
          sx={{ color: getVisibleColor(customization.backgroundColor) }}
        />
        <Box sx={{ width: "100%" }}>
          {content.map((aLinkAndFile, index) => {
            if (aLinkAndFile.isLink) {
              return (
                <CustomLink
                  href={aLinkAndFile.path}
                  key={index}
                  target="_blank"
                >
                  <OctopusQRButton style={buttonStyle}>
                    <LinkIcon />
                    <Box sx={{ ml: 2 }} />
                    {aLinkAndFile.name}
                  </OctopusQRButton>
                </CustomLink>
              );
            } else {
              return (
                <Box key={index}>
                  {aLinkAndFile.file ? (
                    <OctopusQRButton style={buttonStyle}>
                      {FILE_ICON_MAP[aLinkAndFile.type]}
                      <Box sx={{ ml: 2 }} />
                      {aLinkAndFile.name}
                    </OctopusQRButton>
                  ) : (
                    <CustomLink href={aLinkAndFile.path} target="_blank">
                      <OctopusQRButton style={buttonStyle}>
                        {FILE_ICON_MAP[aLinkAndFile.type]}
                        <Box sx={{ ml: 2 }} />
                        {aLinkAndFile.name}
                      </OctopusQRButton>
                    </CustomLink>
                  )}
                </Box>
              );
            }
          })}
        </Box>
        <Box sx={{ width: "40px" }} />
      </Box>
    </>
  );
}
