import { Box, MenuItem, Typography } from "@mui/material";
import { styles } from "./Admin.style";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
// import ArticleIcon from "@mui/icons-material/Article";
// import RequestPageIcon from "@mui/icons-material/RequestPage";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

const AdminMenu = ({ setSearchParams }) => {
  return (
    <Box sx={styles.menuContainer}>
      <MenuItem
        sx={styles.menuItem}
        onClick={() => {
          setSearchParams({ viewType: "register" });
        }}
      >
        <PersonAddIcon sx={{ mr: 1 }} />
        <Typography variant="overline" sx={styles.menuTypo}>
          Inscription
        </Typography>
      </MenuItem>
      <MenuItem
        sx={styles.menuItem}
        onClick={() => {
          setSearchParams({ viewType: "management" });
        }}
      >
        <ManageAccountsIcon sx={{ mr: 1 }} />
        <Typography variant="overline" sx={styles.menuTypo}>
          Gestion des utilisateurs
        </Typography>
      </MenuItem>
      {/* <MenuItem
        sx={styles.menuItem}
        onClick={() => {
          setSearchParams({ viewType: "quotation" });
        }}
      >
        <ArticleIcon sx={{ mr: 1 }} />
        <Typography variant="overline" sx={styles.menuTypo}>
          Devis
        </Typography>
      </MenuItem>
      <MenuItem
        sx={styles.menuItem}
        onClick={() => {
          setSearchParams({ viewType: "billing" });
        }}
      >
        <RequestPageIcon sx={{ mr: 1 }} />
        <Typography variant="overline" sx={styles.menuTypo}>
          Facture
        </Typography>
      </MenuItem> */}
    </Box>
  );
};

export default AdminMenu;
