export const verifyName = (iName, iErrorValues) => {
  let aHasError = false;
  let aErrorMessage = "";
  if (!iName) {
    aHasError = true;
    aErrorMessage = "Champ obligatoire";
  }
  iErrorValues.hasNameError = aHasError;
  iErrorValues.name = aErrorMessage;
  return aHasError;
};

export const verifyPhoneNumber = (iPhoneNumber, iErrorValues) => {
  const aPhoneRegex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/;
  let aHasError = false;
  let aErrorMessage = "";
  if (!iPhoneNumber) {
    aHasError = true;
    aErrorMessage = "Champ obligatoire";
  } else if (!aPhoneRegex.test(iPhoneNumber)) {
    aHasError = true;
    aErrorMessage = "Format Incorrect";
  }

  iErrorValues.hasPhoneNumberError = aHasError;
  iErrorValues.phoneNumber = aErrorMessage;

  return aHasError;
};

export const verifyMail = (iMail, iErrorValues) => {
  const anEmailRegex = /\S+@\S+\.\S+/;
  let aHasError = false;
  let aErrorMessage = "";
  if (!iMail) {
    aHasError = true;
    aErrorMessage = "Champ obligatoire";
  } else if (!anEmailRegex.test(iMail)) {
    aHasError = true;
    aErrorMessage = "Format incorrect";
  }

  iErrorValues.hasMailError = aHasError;
  iErrorValues.mail = aErrorMessage;

  return aHasError;
};

export const verifyPassword = (iPassword, iErrorValues) => {
  const aMediumPasswordRegex = /^((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]))(?=.{6,})/;
  let aHasError = false;
  let aErrorMessage = "";
  if (!iPassword) {
    aHasError = true;
    aErrorMessage = "Champ obligatoire";
  } else if (!aMediumPasswordRegex.test(iPassword)) {
    aHasError = true;
    aErrorMessage =
      "Minimum requis : 6 caractères | 1 majuscule | 1 minuscule | 1 chiffre";
  }
  iErrorValues.hasPasswordError = aHasError;
  iErrorValues.password = aErrorMessage;

  return aHasError;
};
