import api from "../../axios";

export const postSocialNetworkQr = async (iUserId, iQRCodeId, iData) => {
  const aResponse = await api.post(
    `/users/${iUserId}/qrcodes/${iQRCodeId}/social-networks`,
    iData
  );
  return aResponse.data;
};

export const putSocialNetworkQr = async (
  iUserId,
  iQRCodeId,
  iSocialNetworkId,
  iData
) => {
  const aResponse = await api.put(
    `/users/${iUserId}/qrcodes/${iQRCodeId}/social-networks/${iSocialNetworkId}`,
    iData
  );
  return aResponse.data;
};

export const verifySocialNetworksData = (iPseudonym) => {
  const aErrorValues = {
    pseudonym: "",
    hasPseudonymError: false,
  };

  if (!iPseudonym) {
    aErrorValues.hasPseudonymError = true;
    aErrorValues.pseudonym = "Champs requis";
  }

  return aErrorValues;
};
