import React from "react";
import { Box } from "@mui/material";

import NavMenu from "../../components/navMenu/NavMenu";

import Footer from "../../components/footer/Footer";
import Benefits from "./tabs/Benefits";
import Features from "./tabs/Features";
import HowToUse from "./tabs/HowToUse";
import HomeHeader from "./tabs/HomeHeader";

import CustomPage from "./tabs/CustomPage";

export default function Home() {
  return (
    <>
      <NavMenu />
      <Box
        sx={{
          margin: "auto",
        }}
      >
        <HomeHeader />
        <Features />
        <Benefits />

        <CustomPage />

        <HowToUse />
      </Box>
      <Footer />
    </>
  );
}
