export const styles = {
  pageContainer: {
    display: "flex",
    justifyContent: "center",
  },
  container: {
    maxWidth: "390px",
    textAlign: "center",
  },
};
