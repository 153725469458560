import { useState } from "react";

import { styles } from "../WorksiteControl.style";
import { RedButton } from "../../../../../../utils/styled/Styled";
import { processEscalibSumbit } from "../WorksiteUtils";

import {
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  TextField,
  Divider,
} from "@mui/material";
import { postWorksiteControlForm } from "../apiWorksiteHandler";
import { useNavigate } from "react-router-dom";

export default function Escalib({
  userId,
  formId,
  name,
  formValues,
  setFormValues,
}) {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [isPlanActive, setIsPlanActive] = useState(false);
  const handleFormChange = (iEvent) => {
    const { name, value } = iEvent.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    setErrorMessage("");
    const isSubmitCorrect = processEscalibSumbit(formValues, isPlanActive);
    if (!isSubmitCorrect) {
      setErrorMessage("Vous n'avez pas rempli tous les champs requis");
      return;
    }
    try {
      await postWorksiteControlForm(userId, formId, formValues);
      navigate(
        `/forms/eiffage/success?formName=${"Chantier Charles de Gaulle Express"}&lastName=${
          formValues.lastName
        }&firstName=${formValues.firstName}`
      );
    } catch {
      setErrorMessage("Une erreur est survenue, merci de réessayer");
    }
  };

  const handleActivePlan = () => {
    setIsPlanActive(true);
  };

  const handleDeActivePlan = () => {
    setIsPlanActive(false);
  };

  const buildRadioGroup = (iTitle, iName) => {
    return (
      <>
        <Divider sx={styles.divider} />

        <Typography variant="overline" fontSize={"13px"} sx={styles.radioTypo}>
          {iTitle}
        </Typography>

        <RadioGroup
          row
          sx={styles.eiffageRadio}
          name={iName}
          onChange={handleFormChange}
        >
          <Box sx={styles.radioContainer}>
            <FormControlLabel
              value="Conforme"
              control={<Radio />}
              label="Conforme"
            />
            <FormControlLabel
              value="Non conforme"
              control={<Radio />}
              label="Non conforme"
            />
          </Box>
        </RadioGroup>
        <Box sx={{ mt: 2 }} />
        <TextField
          placeholder="Observations"
          name={`${iName}_obs`}
          multiline
          rows={2}
          onChange={handleFormChange}
          sx={styles.inputText}
        />
      </>
    );
  };

  return (
    <>
      <Typography variant="overline" fontSize={"13px"} sx={{ mt: 3 }}>
        PV de réception Escalier & ESCALIB N°
      </Typography>
      <TextField
        sx={styles.inputText}
        name="numeroEscal"
        value={name}
        disabled
      />
      <Typography variant="overline" fontSize={"13px"} sx={{ mt: 3 }}>
        Type de contrôle
      </Typography>
      <RadioGroup
        row
        sx={styles.eiffageRadio}
        name="controlType"
        onChange={handleFormChange}
      >
        <FormControlLabel
          sx={{ flexBasis: "50%" }}
          value="Déplacement"
          control={<Radio />}
          label="Déplacement"
        />
        <FormControlLabel
          sx={{ flexBasis: "50%" }}
          value="Modification"
          control={<Radio />}
          label="Modification"
        />
        <FormControlLabel
          sx={{ flexBasis: "50%" }}
          value="Mise en service"
          control={<Radio />}
          label="Mise en service"
        />
      </RadioGroup>

      <Typography variant="overline" fontSize={"13px"} sx={{ mt: 3 }}>
        Localisation
      </Typography>
      <TextField
        sx={styles.inputText}
        name="loc"
        value={formValues.loc}
        onChange={handleFormChange}
      />

      {buildRadioGroup("Affichage du PV de mise en service", "aff_pv")}

      <Typography variant="overline" fontSize={"13px"} sx={{ mt: 3 }}>
        Montage suivant
      </Typography>
      <RadioGroup
        row
        sx={styles.eiffageRadio}
        name="mont"
        onChange={handleFormChange}
      >
        <Box sx={styles.radioContainer}>
          <FormControlLabel
            value="Notice technique"
            control={<Radio onChange={handleDeActivePlan} />}
            label="Notice technique"
          />
          <FormControlLabel
            value="Plan"
            control={<Radio onChange={handleActivePlan} />}
            label="Plan"
          />
        </Box>
      </RadioGroup>
      {isPlanActive ? (
        <Box sx={styles.planContainer}>
          <Box sx={{ flexBasis: "80%", display: "flex" }}>
            <Typography variant="overline">Plan N°</Typography>
            <TextField
              sx={styles.inputText}
              placeholder="..."
              name="mont_num"
              value={formValues.mont_num}
              onChange={handleFormChange}
            />
          </Box>
          <Box sx={{ flexBasis: "80%", display: "flex" }}>
            <Typography variant="overline" sx={{ ml: 2, mr: "15px" }}>
              du
            </Typography>
            <TextField
              sx={styles.inputText}
              type="date"
              name="mont_dat"
              value={formValues.mont_dat}
              onChange={handleFormChange}
            />
          </Box>
        </Box>
      ) : null}

      <Divider sx={styles.divider} />

      {buildRadioGroup("Assise au sol / Réglage des pieds", "floor")}

      {buildRadioGroup("Horizontalité / verticalité de l’Escalib", "horiz")}

      {buildRadioGroup(
        "Entrée basse d'ESCALIB entièrement sécurisée",
        "ent_sec"
      )}

      {buildRadioGroup(
        "Assemblage des modules et embase : Présence des 4 vis serrées",
        "assembl"
      )}

      {buildRadioGroup(
        "Présence du garde-corps de fermeture en tête d'ESCALIB boulonné",
        "presence"
      )}

      {buildRadioGroup(
        "Verrouillage correct de toutes les lisses garde-corps",
        "verrou"
      )}

      <Divider sx={styles.divider} />

      <Typography variant="overline" fontSize={"13px"} sx={styles.radioTypo}>
        Amarrage de l’Escalib (dès 3e modules)
      </Typography>

      <RadioGroup
        row
        sx={styles.eiffageRadio}
        name="amarr"
        onChange={handleFormChange}
      >
        <FormControlLabel
          sx={{ flexBasis: "50%" }}
          value="Conforme"
          control={<Radio />}
          label="Conforme"
        />
        <FormControlLabel
          sx={{ flexBasis: "50%" }}
          value="Non conforme"
          control={<Radio />}
          label="Non conforme"
        />
        <FormControlLabel
          sx={{ flexBasis: "50%" }}
          value="Non concerné"
          control={<Radio />}
          label="Non concerné"
        />
      </RadioGroup>
      <Box sx={{ mt: 2 }} />
      <TextField
        placeholder="Observations"
        name="amarr_obs"
        multiline
        rows={2}
        onChange={handleFormChange}
        sx={styles.inputText}
      />

      {buildRadioGroup(
        "Sortie haute d'ESCALIB entièrement sécurisée",
        "sort_sec"
      )}

      {buildRadioGroup(
        "Absence de déformation ou de corrosion de la structure",
        "absenc"
      )}

      <Divider sx={styles.divider} />
      <Divider sx={styles.divider} />

      <Typography variant="overline" fontSize={"13px"} sx={{ mt: 3 }}>
        Commentaires
      </Typography>
      <TextField
        sx={styles.inputText}
        multiline
        rows={3}
        name="comm"
        value={formValues.comm}
        onChange={handleFormChange}
      />

      <Divider sx={styles.divider} />

      <Typography variant="overline" fontSize={"13px"} sx={styles.radioTypo}>
        Autorisation d'utilisation
      </Typography>

      <RadioGroup
        row
        sx={styles.eiffageRadio}
        name="autor"
        onChange={handleFormChange}
      >
        <Box sx={styles.radioContainer}>
          <FormControlLabel value="Oui" control={<Radio />} label="Oui" />
          <FormControlLabel value="Non" control={<Radio />} label="Non" />
        </Box>
      </RadioGroup>
      {errorMessage ? (
        <Typography variant="overline" color="red" sx={{ mt: 3 }}>
          {errorMessage}
        </Typography>
      ) : null}
      <RedButton sx={styles.submitButton} onClick={handleSubmit}>
        Envoyer
      </RedButton>
    </>
  );
}
