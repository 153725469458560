import React from "react";
import { useNavigate } from "react-router-dom";

import { OctopusQRButton } from "../../../utils/styled/Styled.js";

import { Card, Box, Grid, Typography } from "@mui/material";

const styles = {
  title: {
    fontWeight: "bold",
    fontSize: "30px",
    marginBottom: "20px",
    marginTop: "20px",
  },
  card: {
    marginTop: "30px",
    borderRadius: "30px",
    textAlign: "center",
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
  },
  buttonAlone: {
    width: "250px",
    display: "block",
    margin: "30px auto",
  },
  button: {
    width: "95%",
    display: "block",
    margin: "30px auto",
  },
  logoStyle: {
    borderRadius: "4%",
    maxWidth: "300px",
    maxHeight: "180px",
  },
};

export default function ProfileLogo({ iLogoPath }) {
  const navigate = useNavigate();

  return (
    <Box sx={{ "& > :not(style)": { m: 3 } }} noValidate autoComplete="off">
      <Card style={styles.card}>
        <Typography style={styles.title}>Votre logo</Typography>
        {iLogoPath ? (
          <>
            <img
              src={iLogoPath}
              alt="logo-utilisateur"
              style={styles.logoStyle}
            ></img>
            <br />
            <Grid container spacing={0}>
              <Grid item sm={1} md={1} lg={2} />
              <Grid item xs={6} sm={5} md={4} lg={3}>
                <OctopusQRButton
                  style={styles.button}
                  onClick={() => {
                    navigate("/profile/upload-logo");
                  }}
                >
                  Modifier
                </OctopusQRButton>
              </Grid>
              <Grid item md={2} lg={2} />
              <Grid item xs={6} sm={5} md={4} lg={3}>
                <OctopusQRButton
                  style={styles.button}
                  onClick={() => {
                    navigate("/profile/delete-logo");
                  }}
                >
                  Supprimer
                </OctopusQRButton>
              </Grid>
              <Grid item sm={1} md={1} lg={2} />
            </Grid>
          </>
        ) : (
          <div style={{ backgroundColor: "white" }}>
            <Typography>
              Vous n'avez pas encore importé de logo. <br /> Ajoutez-en un !
            </Typography>
            <OctopusQRButton
              style={styles.buttonAlone}
              onClick={() => {
                navigate("/profile/upload-logo");
              }}
            >
              Ajouter un logo
            </OctopusQRButton>
          </div>
        )}
      </Card>
    </Box>
  );
}
