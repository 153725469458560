import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Loading from "../../../assets/adr_loading.gif";
import FrenchFlag from "../../../assets/icons/french_flag.svg";

import { OctopusQRButton } from "../../../utils/styled/Styled.js";

import {
  Box,
  FormControl,
  TextField,
  InputAdornment,
  Typography,
  IconButton,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { styles } from "./ProfileForm.style";
import { patchUser, verifyPatchUserData } from "../../../api/user/patchUser";

export default function ProfileForm({ iUserProfile }) {
  const [formValues, setFormValues] = useState({
    name: iUserProfile.name,
    phoneNumber: iUserProfile.phoneNumber,
    mail: iUserProfile.mail,
  });
  const [errorValues, setErrorValues] = useState({
    hasNameError: false,
    name: "",
    hasMailError: false,
    mail: "",
    hasPhoneNumberError: false,
    phoneNumber: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [hasInputChanged, setHasInputChanged] = useState(false);

  const navigate = useNavigate();

  const handleInputChange = (e) => {
    setHasInputChanged(true);
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handlePhoneChange = (e) => {
    setHasInputChanged(true);
    let target = e.target;
    let position = target.selectionEnd;
    let length = target.value.length;
    if (length >= 15) {
      return;
    }

    target.value = target.value
      .replace(/[^\d]/g, "")
      .replace(/(.{2})/g, "$1 ")
      .trim();
    target.selectionEnd = position +=
      target.value.charAt(position - 1) === " " &&
      target.value.charAt(length - 1) === " " &&
      length !== target.value.length
        ? 1
        : 0;
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  const handleSubmit = () => {
    setIsLoading(true);
    setErrorMessage("");
    const aErrorValues = verifyPatchUserData(formValues);
    setErrorValues({
      ...aErrorValues,
    });
    if (
      aErrorValues.hasNameError ||
      aErrorValues.hasPhoneNumberError ||
      aErrorValues.hasPasswordError ||
      aErrorValues.hasMailError
    ) {
      setIsLoading(false);
      return;
    }

    patchUser(iUserProfile._id, formValues)
      .then(() => {
        setIsLoading(false);
        setHasInputChanged(false);
        navigate("/profile/action-success");
      })
      .catch(() => {
        setErrorMessage("Une erreur est survenue, veuillez réessayer");
        setIsLoading(false);
      });
  };

  return (
    <Box
      component="form"
      sx={{ "& > :not(style)": { m: 3 }, textAlign: "center" }}
      noValidate
      autoComplete="off"
    >
      <Typography sx={styles.title}>Vos informations</Typography>

      <FormControl sx={styles.formControl}>
        <TextField
          id="name-input"
          name="name"
          type="text"
          label="Nom d'utilisateur"
          required={true}
          variant="outlined"
          value={formValues.name}
          error={errorValues.hasNameError}
          helperText={errorValues.name}
          onChange={handleInputChange}
          sx={styles.field}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PersonIcon style={styles.icon} />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          id="phone-input"
          name="phoneNumber"
          type="phone"
          label="Numéro de  téléphone"
          required={true}
          variant="outlined"
          placeholder="01 23 45 67 89"
          value={formValues.phoneNumber}
          error={errorValues.hasPhoneNumberError}
          helperText={errorValues.phoneNumber}
          onChange={handlePhoneChange}
          sx={styles.field}
          InputProps={{
            startAdornment: (
              <img
                src={FrenchFlag}
                style={styles.imgAdornment}
                width="23"
                alt="french-flag"
              />
            ),
          }}
        />
        <TextField
          id="mail-input"
          name="mail"
          type="email"
          label="Adresse Mail"
          required={true}
          variant="outlined"
          value={formValues.mail}
          error={errorValues.hasMailError}
          helperText={errorValues.mail}
          onChange={handleInputChange}
          sx={styles.field}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EmailIcon style={styles.icon} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="Aide mail"
                  onClick={() => {
                    navigate("/profile/mail-help");
                  }}
                >
                  <QuestionMarkIcon sx={styles.icon} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          disabled
        />

        {isLoading ? (
          <img src={Loading} style={styles.loading} height="50" alt="Loading" />
        ) : null}
        {errorMessage ? <p style={styles.error}>{errorMessage}</p> : null}
      </FormControl>
      {hasInputChanged ? (
        <OctopusQRButton style={styles.submitButton} onClick={handleSubmit}>
          Enregistrer les informations
        </OctopusQRButton>
      ) : (
        <OctopusQRButton
          style={styles.submitButton}
          onClick={handleSubmit}
          disabled
        >
          Enregistrer les informations
        </OctopusQRButton>
      )}
    </Box>
  );
}
