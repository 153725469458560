import "./device-preview.css";

export default function DeviceWrapper({
  content,
  backgroundColor = "#FFFFFF",
}) {
  return (
    <div
      className="marvel-device iphone-x"
      style={{
        margin: "20px auto",
      }}
    >
      <div className="notch">
        <div className="camera"></div>
        <div className="speaker"></div>
      </div>
      <div className="top-bar"></div>
      <div className="sleep"></div>
      <div className="bottom-bar"></div>
      <div className="volume"></div>
      <div className="overflow">
        <div className="shadow shadow--tr"></div>
        <div className="shadow shadow--tl"></div>
        <div className="shadow shadow--br"></div>
        <div className="shadow shadow--bl"></div>
      </div>
      <div className="inner-shadow"></div>
      <div
        className="screen"
        style={{
          overflow: "scroll",
          background: backgroundColor,
        }}
      >
        {content}
      </div>
    </div>
  );
}
