import { useState } from "react";
import { Card, Box, Typography } from "@mui/material";
import { styles } from "../QRCodeView.style";
import { OctopusQRButton } from "../../../../utils/styled/Styled";
import QRCodeCustomizeView from "../../main/customization/QRCodeCustomizeView";
import { QR_CODE_LOGO_OPTIONS } from "../../../../utils/constants/Constants";
import EditQRCodeName from "./EditQRCodeName";
import { useNavigate } from "react-router-dom";

export default function EditQRCodeCustomization({
  user,
  qrCode,
  qrCodeCustomization,
  setQrCodeCustomization,
  putQRCode,
}) {
  const navigateTo = useNavigate();
  const initialLogoError = {
    hasError: false,
    errorText: "",
  };
  const [logoError, setLogoError] = useState(initialLogoError);

  const saveCustomizationEdit = async () => {
    setLogoError(initialLogoError);
    if (
      qrCodeCustomization.logoOptionChosen === QR_CODE_LOGO_OPTIONS.PERSONNAL &&
      !qrCodeCustomization.file
    ) {
      setLogoError({
        hasError: true,
        errorText:
          "Si vous ajoutez un logo, il est nécessaire de l'importer. Section 'Logo'",
      });
      return;
    }
    await putQRCode();
    if (qrCode.folderId) {
      navigateTo(`/qrcodes/folder/${qrCode.folderId}`);
    } else {
      navigateTo(`/qrcodes`);
    }
  };

  return (
    <Box
      sx={{
        ...styles.flexColumnCenter,
        margin: "auto",
        mt: 6,
        px: 4,
      }}
    >
      <Card sx={styles.card}>
        <EditQRCodeName user={user} qrCode={qrCode} />
      </Card>

      <Card sx={styles.card}>
        <Typography variant="h4" sx={{ m: 1 }}>
          Personnalisation du QR Code
        </Typography>
        <QRCodeCustomizeView
          qrCode={qrCode}
          user={user}
          customizationValues={qrCodeCustomization}
          setCustomizationValues={setQrCodeCustomization}
        />
        <OctopusQRButton
          sx={{ mb: 2, mt: 5, width: "150px" }}
          onClick={saveCustomizationEdit}
        >
          Sauvegarder
        </OctopusQRButton>
        <br />
        {logoError.hasError ? (
          <>
            <br />
            <Typography variant="overline" sx={styles.errorText}>
              {logoError.errorText}
            </Typography>
          </>
        ) : null}
      </Card>
    </Box>
  );
}
