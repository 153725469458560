import { useNavigate } from "react-router-dom";
import { styles } from "../QRCodeView.style";

import QrCode2Icon from "@mui/icons-material/QrCode2";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Box, Typography, MenuItem, IconButton } from "@mui/material";
import {
  QR_CODE_TYPE,
  DYNAMIC_QR_TYPE,
} from "../../../../utils/constants/Constants";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export default function WideScreenViewMenu({
  modifySearchParams,
  qrContent,
  qrCode,
}) {
  const navigateTo = useNavigate();
  return (
    <Box sx={styles.menuContainer}>
      <IconButton
        onClick={() => {
          if (qrCode.folderId) {
            navigateTo(`/qrcodes/folder/${qrCode.folderId}`);
          } else {
            navigateTo(`/qrcodes`);
          }
        }}
        sx={{ color: "white", marginRight: 1 }}
      >
        <ArrowBackIcon />
      </IconButton>
      <MenuItem
        sx={styles.menuItem}
        onClick={() => {
          modifySearchParams("customize");
        }}
      >
        <QrCode2Icon sx={{ mr: 1 }} />
        <Typography variant="overline" sx={styles.menuTypo}>
          Personnaliser
        </Typography>
      </MenuItem>

      {DYNAMIC_QR_TYPE.includes(qrCode.type) && [
        <MenuItem
          key="edit"
          sx={styles.menuItem}
          onClick={() => {
            modifySearchParams("edit");
          }}
        >
          <EditIcon sx={{ mr: 1 }} />
          <Typography variant="overline" sx={styles.menuTypo}>
            Éditer
          </Typography>
        </MenuItem>,

        <MenuItem
          key="type"
          sx={styles.menuItem}
          onClick={() => {
            modifySearchParams("type");
          }}
        >
          <GroupWorkIcon sx={{ mr: 1 }} />
          <Typography variant="overline" sx={styles.menuTypo}>
            Type
          </Typography>
        </MenuItem>,

        <MenuItem
          key="statistics"
          sx={styles.menuItem}
          onClick={() => {
            modifySearchParams("statistics");
          }}
        >
          <QueryStatsIcon sx={{ mr: 1 }} />
          <Typography variant="overline" sx={styles.menuTypo}>
            Statistiques
          </Typography>
        </MenuItem>,
      ]}
      {qrCode.type === QR_CODE_TYPE.CUSTOM_PAGE &&
        qrContent?.customPage?.hasPush && (
          <MenuItem
            sx={styles.menuItem}
            onClick={() => {
              modifySearchParams("notify");
            }}
          >
            <NotificationsIcon sx={{ mr: 1 }} />
            <Typography variant="overline" sx={styles.menuTypo}>
              Notifications
            </Typography>
          </MenuItem>
        )}
      {qrCode.type === QR_CODE_TYPE.FORM && [
        <MenuItem
          key="answers"
          sx={styles.menuItem}
          onClick={() => {
            modifySearchParams("answers");
          }}
        >
          <QuestionAnswerIcon sx={{ mr: 1 }} />
          <Typography variant="overline" sx={styles.menuTypo}>
            Réponses
          </Typography>
        </MenuItem>,
        <MenuItem
          key="preview"
          sx={styles.menuItem}
          onClick={() => {
            modifySearchParams("preview");
          }}
        >
          <RemoveRedEyeIcon sx={{ mr: 1 }} />
          <Typography variant="overline" sx={styles.menuTypo}>
            Rendu
          </Typography>
        </MenuItem>,
      ]}

      <MenuItem
        sx={styles.menuItem}
        onClick={() => {
          modifySearchParams("delete");
        }}
      >
        <DeleteForeverIcon sx={{ mr: 1 }} />
        <Typography variant="overline" sx={styles.menuTypo}>
          Supprimer
        </Typography>
      </MenuItem>
    </Box>
  );
}
