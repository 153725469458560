import api from "../../../axios";
import { verifyPassword } from "../../../../utils/forms/FormsVerification";

export const resetPassword = async (iTokenId, iData) => {
  const aResponse = await api.patch(
    `/users/password/tokens/${iTokenId}`,
    iData
  );
  return aResponse.data;
};

export const verifyResetPasswordData = (iFormValues) => {
  const aErrorValues = {
    hasPasswordError: false,
    password: "",
    hasConfirmedPasswordError: false,
    confirmedPassword: "",
  };

  if (iFormValues.password !== iFormValues.confirmedPassword) {
    aErrorValues.hasPasswordError = true;
    aErrorValues.hasConfirmedPasswordError = true;
    aErrorValues.confirmedPassword = "Les mots de passe ne correspondent pas";
  }
  if (!iFormValues.confirmedPassword) {
    aErrorValues.hasConfirmedPasswordError = true;
    aErrorValues.confirmedPassword = "Champ obligatoire";
  }
  verifyPassword(iFormValues.password, aErrorValues);

  return aErrorValues;
};
