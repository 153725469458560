import LoadingImg from "../../assets/adr_loading.gif";
import { Box, Typography } from "@mui/material";
export default function Loading() {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        margin: "auto",
        mt: 1,
      }}
    >
      <img
        src={LoadingImg}
        alt="loading"
        style={{ display: "block" }}
        width="70px"
      />
      <Typography variant="overline">
        Le chargement peut durer plusieurs secondes.
      </Typography>
    </Box>
  );
}
