export const styles = {
  title: {
    textAlign: "center",
    fontWeight: "bold",
  },
  button: {
    width: "150px",
    margin: "0px auto",
    display: "block",
  },
  icon: {
    color: "#58738D",
  },
  formControl: {
    textAlign: "center",
    backgroundColor: "white",
  },
  field: {
    marginBottom: "30px",
    backgroundColor: "white",
    borderRadius: "20px",
    [`& fieldset`]: {
      borderRadius: "20px",
      border: "1px",
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    },
  },
  center: {
    display: "block",
    margin: "15px auto",
  },
  centeredIcon: {
    display: "block",
    margin: "0px auto",
  },
  error: {
    fontSize: "16px",
    color: "#8B0000",
  },
};
