import React from "react";
import { Link } from "react-router-dom";

import NavMenu from "../../components/navMenu/NavMenu.js";
import Footer from "../../components/footer/Footer.js";

import { Card, Typography, Grid } from "@mui/material";

const styles = {
  card: {
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    borderRadius: "50px",
    m: 3,
  },
  title: {
    fontWeight: "bold",
    fontSize: "30px",
    marginTop: "20px",
    textAlign: "center",
  },
  subTitle: {
    textAlign: "center",
    fontSize: "14px",
  },
  secondaryTitle: {
    fontWeight: "bold",
    fontSize: "25px",
    m: 3,
  },
  tertiaryTitle: {
    fontWeight: "bold",
    fontSize: "20px",
    color: "#58738D",
    m: 3,
  },
  commonText: {
    textAlign: "justify",
    m: 3,
  },
};

export default function Privacy() {
  return (
    <>
      <NavMenu />
      <Grid container spacing={0}>
        <Grid item xs={0} md={1} lg={2} />
        <Grid item xs={12} md={10} lg={8}>
          <Card sx={styles.card}>
            <Typography sx={styles.title}>
              Politique de confidentialité
            </Typography>
            <Typography sx={styles.subTitle}>
              Dernière mise à jour - 13/02/2023
            </Typography>
            <br />

            <Typography sx={styles.secondaryTitle}>Préambule</Typography>
            <Typography sx={styles.commonText}>
              Andromede, 864 Avenue de Provence, 83440 Montauroux, France (
              ci-après dénommée « OctopusQR ») développe, exploite et fournit
              divers services dans les domaines de la création, de la
              conception, de la gestion, de l’optimisation, numérisation et
              analyse des codes QR pour ses clients (ci-après appelés
              « Utilisateur » ou « Utilisateurs »). Ces services sont fournis
              sous le domaine OctopusQR.com. OctopusQR se réserve le droit
              d’ajouter d’autres domaines à l’avenir. Avec les présentes
              Conditions Générales, OctopusQR veut assurer une relation
              d’affaires transparente avec l’Utilisateur, mais aussi protéger
              OctopusQR contre une utilisation malveillante. En acceptant les
              conditions générales, vous acceptez également notre licence de
              téléchargement de contenu, notre politique de confidentialité et
              notre politique d'utilisation acceptable dans la version
              applicable au moment de la conclusion de l’entente. Que vous
              pouvez trouver ici :
            </Typography>
            <Link to="/terms" style={{ marginLeft: "25px" }} target="_blank">
              Conditions générales d'utilisation
            </Link>
            <br />
            <Link to="/privacy" style={{ marginLeft: "25px" }} target="_blank">
              Politique de confidentialité
            </Link>
            <br />
            <Link to="/legal" style={{ marginLeft: "25px" }} target="_blank">
              Mentions légales
            </Link>
            <br />

            <Typography sx={styles.secondaryTitle}>
              1. Définitions et mots clés
            </Typography>

            <Typography sx={styles.commonText}>
              Pour aider à expliquer les choses aussi clairement que possible
              dans cette politique de confidentialité, chaque fois que l’un de
              ces termes est mentionné, il est strictement définis comme :
            </Typography>
            <Typography sx={styles.commonText}>
              - <strong>Cookie : </strong>petite quantité de données générées
              par un site Web et enregistrées par votre navigateur Web. Il est
              utilisé pour identifier votre navigateur, fournir des analyses,
              mémoriser des informations vous concernant telles que vos
              informations de connexion.
            </Typography>
            <Typography sx={styles.commonText}>
              - <strong>Pays : </strong>où OctopusQR ou les
              propriétaires/fondateurs de OctopusQR sont basés, dans ce cas FR
              (France)
            </Typography>
            <Typography sx={styles.commonText}>
              - <strong>Client : </strong>désigne l’entreprise, l’organisation
              ou la personne qui s’engage à utiliser le service OctopusQR pour
              gérer les relations avec vos consommateurs ou utilisateurs de
              services.
            </Typography>
            <Typography sx={styles.commonText}>
              - <strong>Appareil : </strong>tout appareil connecté à Internet
              comme un téléphone, une tablette, un ordinateur ou tout autre
              appareil pouvant être utilisé pour visiter OctopusQR et utiliser
              les services.
            </Typography>
            <Typography sx={styles.commonText}>
              - <strong>Personnel : </strong>désigne les personnes qui sont
              employées par OctopusQR ou qui sont sous contrat pour exécuter un
              service au nom de l’une des parties. Données personnelles : toute
              information qui, directement, indirectement ou en relation avec
              d’autres renseignements, y compris un numéro d’identification
              personnel, permet l’identification ou l’identifiabilité d’une
              personne physique.
            </Typography>
            <Typography sx={styles.commonText}>
              - <strong>Service : </strong>désigne le service fourni par
              OctopusQR tel que décrit dans les termes relatifs (si disponible)
              et sur cette plateforme.
            </Typography>
            {/* <Typography sx={styles.commonText}>
              - <strong></strong>
            </Typography> */}

            <Typography sx={styles.secondaryTitle}>
              2. Quels renseignements recueillons-nous?
            </Typography>

            <Typography sx={styles.commonText}>
              Nous recueillons des renseignements auprès de vous lorsque vous
              vous inscrivez sur notre site,que vous vous abonnez à nos
              services, que vous répondez à un sondage ou que vous ajoutez une
              ressource.
            </Typography>

            <Typography sx={styles.commonText}>
              - Nom de l'entreprise / de l'entité
            </Typography>
            <Typography sx={styles.commonText}>
              - Numéro de téléphone
            </Typography>
            <Typography sx={styles.commonText}>- Adresse mail</Typography>
            <Typography sx={styles.commonText}>- Mot de Passe</Typography>
            <Typography sx={styles.commonText}>
              - Ressources (URL / Fichiers)
            </Typography>
            <Typography sx={styles.commonText}>
              - Surnom des ressources
            </Typography>

            <Typography sx={styles.secondaryTitle}>
              3. Comment utilisons-nous les renseignements que nous recueillons?
            </Typography>
            <Typography sx={styles.commonText}>
              Toute information que nous recueillons auprès de vous peut être
              utilisée de l’une des façons suivantes :
            </Typography>
            <Typography sx={styles.commonText}>
              - Pour afficher vos données. En effet, le but premier de
              recueillir vos données et de les afficher auprès de vos clients.
            </Typography>
            <Typography sx={styles.commonText}>
              - Pour traiter les transactions
            </Typography>
            <Typography sx={styles.commonText}>
              - Pour administrer un concours, une promotion, un sondage ou une
              autre fonction du site
            </Typography>
            <Typography sx={styles.commonText}>
              - Pour envoyer des courriels périodiques
            </Typography>
            <Typography sx={styles.commonText}>
              - Pour personnaliser votre expérience (vos informations nous
              aident à mieux répondre à vos besoins individuels)
            </Typography>

            <Typography sx={styles.secondaryTitle}>
              4. Où et quand les renseignements sont-ils recueillis auprès des
              clients et des utilisateurs finaux?
            </Typography>

            <Typography sx={styles.commonText}>
              OctopusQR recueillera les renseignements personnels que vous nous
              soumettez. Nous pouvons également recevoir des informations
              personnelles vous concernant de notre partenaire Stripe.
            </Typography>

            <Typography sx={styles.secondaryTitle}>
              5. Partageons-nous les renseignements que nous recueillons?
            </Typography>

            <Typography sx={styles.commonText}>
              OctopusQR s'engage à la non diffusion de vos informations. Toutes
              les informations collectés par OctopusQR seront exploités
              seulement par le personnel OctopusQR et ne sortiront jamais de
              l'enceinte du stockage OctopusQR.
            </Typography>

            <Typography sx={styles.secondaryTitle}>
              6. Comment utilisons-nous votre adresse courriel?
            </Typography>

            <Typography sx={styles.commonText}>
              En soumettant votre adresse e-mail sur ce site, vous acceptez de
              recevoir des e-mails de notre part.
            </Typography>
          </Card>
        </Grid>
        <Grid item xs={0} md={1} lg={2} />
      </Grid>
      <Footer />
    </>
  );
}
