import { useCallback, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import { unsubscribeCustomPageUser } from "../../../../api/qrCodes/customPage/customPageQrHandler";

import ErrorCardWithMenu from "../../../../components/error/ErrorCardWithMenu";
import LoadingWithMenu from "../../../../components/loading/LoadingWithMenu";
import NavMenu from "../../../../components/navMenu/NavMenu";
import Footer from "../../../../components/footer/Footer";

import { Box, Typography } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";

const UnsubscribeUser = () => {
  const { userId, qrCodeId, customPageId } = useParams();
  const [searchParams] = useSearchParams();
  const [hasApiError, setHasApiError] = useState(false);
  const [hasBeenTrigerred, setHasBeenTrigerred] = useState(false);

  const unsubscribeUser = useCallback(async () => {
    try {
      await unsubscribeCustomPageUser(userId, qrCodeId, customPageId, {
        mail: searchParams.get("mail"),
      });
    } catch {
      setHasApiError(true);
    }
    setHasBeenTrigerred(true);
  }, [customPageId, qrCodeId, searchParams, userId]);

  useEffect(() => {
    unsubscribeUser();
  }, [unsubscribeUser]);

  let content;

  if (!hasBeenTrigerred) {
    return <LoadingWithMenu />;
  } else {
    if (hasApiError) {
      content = <ErrorCardWithMenu />;
    } else {
      content = (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            p: 2,
            flexDirection: "column",
          }}
        >
          <DoneIcon sx={{ fontSize: "40px", my: 2 }} />
          <Typography>
            Votre désabonnement a bien été pris en compte. Vous ne receverez
            plus d'email.
          </Typography>
        </Box>
      );
    }
  }

  return (
    <>
      <div style={{ minHeight: "calc(100vh - 250px)" }}>
        <NavMenu />
        {content}
      </div>
      <Footer />
    </>
  );
};
export default UnsubscribeUser;
