import api from "../../axios";

export const getQrFolders = async (iUserId) => {
  const aResponse = await api.get(`/users/${iUserId}/folders`);
  return aResponse.data;
};

export const getQrFolder = async (iUserId, iFolderId) => {
  const aResponse = await api.get(`/users/${iUserId}/folders/${iFolderId}`);
  return aResponse.data;
};

export const postQrFolder = async (iUserId, iData) => {
  const aResponse = await api.post(`/users/${iUserId}/folders`, iData);
  return aResponse.data;
};

export const renameQrFolder = async (iUserId, iFolderId, iData) => {
  const aResponse = await api.patch(
    `/users/${iUserId}/folders/${iFolderId}`,
    iData
  );
  return aResponse.data;
};

export const deleteQrFolder = async (iUserId, iFolderId) => {
  const aResponse = await api.delete(`/users/${iUserId}/folders/${iFolderId}`);
  return aResponse.data;
};
