import { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useNavigate } from "react-router-dom";

import { Box, Card, Typography } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  CustomLink,
  OctopusQRButton,
  UploaderBox,
} from "../../../../../utils/styled/Styled";
import Loading from "../../../../../components/loading/Loading";
import { styles } from "../../QRCodeView.style";
import { putFileQr } from "../../../../../api/qrCodes/file/fileQrHandler";
import PreviewIcon from "@mui/icons-material/Preview";

export default function EditFileContent({
  user,
  qrCode,
  qrContent,
  setQrContent,
  setHasBeenSaved,
}) {
  const navigateTo = useNavigate();
  const initialErrorState = { hasError: false, errorText: "" };
  const fileTypes = ["JPG", "PNG", "JPEG", "PDF", "MP4"];
  const [qrFile, setQrFile] = useState(null);
  const [hasChanged, setHasChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(initialErrorState);
  const handleChange = (file) => {
    setHasChanged(true);
    setHasBeenSaved(false);
    setError(initialErrorState);
    if (file.size > 35000000) {
      setError({
        hasError: true,
        errorText: "Fichier trop volumineux, 35Mo maximum autorisé",
      });
      return;
    }
    setQrFile(file);
  };

  const saveChanges = async () => {
    setIsLoading(true);
    setError(initialErrorState);

    if (!qrFile) {
      setError({ hasError: true, errorText: "Vous devez importer un fichier" });
      setIsLoading(false);
      return;
    }

    try {
      const aNewQrFileData = new FormData();
      aNewQrFileData.append("file", qrFile);
      const aNewFile = await putFileQr(
        user._id,
        qrCode._id,
        qrContent._id,
        aNewQrFileData
      );
      setQrFile(null);
      setQrContent({ ...aNewFile });
      setHasChanged(false);
      setHasBeenSaved(true);
    } catch {
      setError({
        hasError: true,
        errorText: "Une erreur est survenue, veuillez réessayer",
      });
    }
    setIsLoading(false);
    if (qrCode.folderId) {
      navigateTo(`/qrcodes/folder/${qrCode.folderId}`);
    } else {
      navigateTo(`/qrcodes`);
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  const aUploadBox = (
    <UploaderBox style={styles.center}>
      <CloudUploadIcon sx={styles.icon} />
      <Typography sx={{ mt: 5, mr: 2, ml: 2 }}>
        <strong style={{ textDecoration: "underline" }}>Sélectionner</strong> ou
        glisser pour importer un nouveau fichier
      </Typography>
      <Typography sx={{ mt: 3, mr: 2, ml: 2, mb: 4 }}>
        Accepté : PNG, JPG, JPEG, PDF, MP4
      </Typography>
    </UploaderBox>
  );

  return (
    <>
      <Box
        sx={{
          ...styles.flexCenter,
          width: "100%",
          mt: 6,
        }}
      >
        <Card sx={styles.card}>
          <Typography variant="h4" sx={styles.titleContainer}>
            Modification du fichier
          </Typography>
          <Box sx={styles.uploader}>
            <FileUploader
              children={aUploadBox}
              handleChange={handleChange}
              name="file"
              types={fileTypes}
              label="Sélectionner ou glisser déposer un fichier."
              hoverTitle="Déposez ici"
            />
          </Box>
          <Typography
            variant="overline"
            sx={{
              m: 1,
            }}
          >
            {qrFile ? (
              <>Nouveau fichier : {qrFile.name} </>
            ) : (
              <>
                <CustomLink
                  href={qrContent.path}
                  target="#blank"
                  style={{
                    margin: "10px",
                  }}
                >
                  <PreviewIcon sx={{ ...styles.icon, fontSize: "30px" }} />
                </CustomLink>
                <br />
                Fichier actuel : {qrContent.path}
              </>
            )}
          </Typography>

          <br />
          <OctopusQRButton
            sx={styles.button}
            onClick={saveChanges}
            disabled={!hasChanged}
          >
            Terminer
          </OctopusQRButton>
          <br />
          {error.hasError ? (
            <Typography variant="overline" sx={styles.errorText}>
              {error.errorText}
            </Typography>
          ) : null}
        </Card>
      </Box>
    </>
  );
}
