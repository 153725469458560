import {
  DYNAMIC_QR_TYPE,
  QR_CODE_TYPE,
} from "../../../../utils/constants/Constants";
import NotFound from "../../../notFound/NotFound";
import EditCustomPageContent from "./customPage/EditCustomPageContent";
import EditFileContent from "./file/EditFileContent";
import EditFormContent from "./form/EditFormContent";
import EditLinkContent from "./link/EditLinkContent";
import EditSocialNetworkContent from "./socialNetwork/EditSocialNetworkContent";
import EditTextContent from "./text/EditTextContent";

export default function EditQRCodeContent({
  user,
  qrCode,
  qrContent,
  setQrContent,
  setHasBeenSaved,
}) {
  if (!DYNAMIC_QR_TYPE.includes(qrCode.type)) {
    return <NotFound />;
  }

  let content;
  switch (qrCode.type) {
    case QR_CODE_TYPE.CUSTOM_PAGE:
      content = (
        <EditCustomPageContent
          user={user}
          qrCode={qrCode}
          qrContent={qrContent}
          setQrContent={setQrContent}
          setHasBeenSaved={setHasBeenSaved}
        />
      );
      break;

    case QR_CODE_TYPE.FILE:
      content = (
        <EditFileContent
          user={user}
          qrCode={qrCode}
          qrContent={qrContent}
          setQrContent={setQrContent}
          setHasBeenSaved={setHasBeenSaved}
        />
      );
      break;
    case QR_CODE_TYPE.FORM:
      let formItems = [];
      for (const [key, value] of Object.entries(qrContent?.items)) {
        formItems.push({ id: key, position: value?.position });
      }
      content = (
        <EditFormContent
          user={user}
          qrCode={qrCode}
          qrContent={qrContent}
          formItems={formItems}
          setQrContent={setQrContent}
          setHasBeenSaved={setHasBeenSaved}
        />
      );
      break;
    case QR_CODE_TYPE.LINK:
      content = (
        <EditLinkContent
          user={user}
          qrCode={qrCode}
          qrContent={qrContent}
          setQrContent={setQrContent}
          setHasBeenSaved={setHasBeenSaved}
        />
      );
      break;
    case QR_CODE_TYPE.SOCIAL_NETWORK:
      content = (
        <EditSocialNetworkContent
          user={user}
          qrCode={qrCode}
          qrContent={qrContent}
          setQrContent={setQrContent}
          setHasBeenSaved={setHasBeenSaved}
        />
      );
      break;
    case QR_CODE_TYPE.TEXT:
      content = (
        <EditTextContent
          user={user}
          qrCode={qrCode}
          qrContent={qrContent}
          setQrContent={setQrContent}
          setHasBeenSaved={setHasBeenSaved}
        />
      );
      break;
    default:
      <NotFound />;
  }
  return <>{content}</>;
}
