import { Card, Box, Typography } from "@mui/material";

import maximize from "../../../assets/home/maximize.png";
const styles = {
  maximizeContainer: {
    backgroundImage: `url(${maximize})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    minHeight: "700px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    px: 2,
    py: 10,
  },
  card: {
    backdropFilter: "blur(200px)",
    backgroundColor: "rgba(255, 255, 255, 0.3)",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
    borderRadius: "30px",
    padding: "1rem",
    margin: "auto",
    width: "70%",
    display: "flex",
    flexDirection: "column",
    p: 3,
  },
  cardTitle: {
    color: "#58738D",
    fontWeight: "bold",
    fontSize: "26px",
    textAlign: "center",
  },
  cardPoint: {
    fontWeight: "bold",
    fontSize: "20px",
    textAlign: "center",
  },
  contentContainer: { maxWidth: "600px", margin: "auto", textAlign: "justify" },
};

export default function CustomPage() {
  return (
    <Box sx={styles.maximizeContainer}>
      <Card sx={styles.card}>
        <Box sx={styles.contentContainer}>
          <Typography variant="h3" sx={styles.cardTitle}>
            Maximisez Votre Portée avec OctopusQR
          </Typography>
          <br />
          <Typography>
            Augmentez la visibilité de votre marque avec des QR Codes conçus
            pour attirer et engager. OctopusQR est une solution complète qui
            vous permet de créer des QR Codes hautement personnalisables,
            adaptés à vos besoins marketing. Notre plateforme combine puissance,
            flexibilité et simplicité, offrant une gamme impressionnante de
            fonctionnalités.
          </Typography>
          <br />
          <Typography sx={styles.cardPoint}>
            ✓ Décuplez Votre Engagement
            <br />
            <br />
          </Typography>
          <Typography>
            Profitez des QR Codes dynamiques pour créer des expériences
            interactives, changer le contenu en temps réel et capter l'attention
            de votre audience.
            <br />
            <br />
          </Typography>
          <Typography sx={styles.cardPoint}>
            ✓ Analyse Profonde
            <br />
            <br />
          </Typography>
          <Typography>
            Mesurez l'impact de vos campagnes grâce à nos outils d'analyse
            avancés. Découvrez qui scanne vos codes, quand, et ajustez
            votre stratégie en conséquence.
            <br />
            <br />
          </Typography>
          <Typography sx={styles.cardPoint}>
            ✓ Conformité & Sécurité
            <br />
            <br />
          </Typography>
          <Typography>
            Assurez-vous que vos codes respectent les réglementations locales et
            internationales en matière de confidentialité et de sécurité.
            <br />
            <br />
          </Typography>
          <Typography>
            <strong>
              Maximisez votre portée, optimisez votre engagement et accélérez
              votre croissance avec OctopusQR, la solution QR Code tout-en-un.
            </strong>
          </Typography>
        </Box>
      </Card>
    </Box>
  );
}
