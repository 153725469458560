import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { DEVICE_SORTABLE_TYPE } from "../../../../../../utils/constants/Constants";
import DescriptionDeviceComponent from "./deviceComponents/DescriptionDeviceComponent";
import LogoDeviceComponent from "./deviceComponents/LogoDeviceComponent";
import LinksAndFilesDeviceComponent from "./deviceComponents/LinksAndFilesDeviceComponent";
import SocialNetworkDeviceComponent from "./deviceComponents/SocialNetworksDeviceComponent";
import { getVisibleDraggableColor } from "../../../../../../utils/Utils";
import { Box, TextField, Typography } from "@mui/material";
import { styles } from "../CustomPageContent.style";
import { BlackButton } from "../../../../../../utils/styled/Styled";

export default function DeviceSortable({
  items,
  setItems,
  hasPush,
  customization,
}) {
  const grid = 3;
  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: "transparent",
    borderRadius: "30px",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver) => ({
    borderRadius: "30px",
    background: isDraggingOver
      ? getVisibleDraggableColor(customization.backgroundColor)
      : customization.backgroundColor,
    padding: grid,
    margin: "auto",
    marginTop: "30px",
    width: "95%",
  });

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    result.forEach((aItem, aIndex) => {
      aItem.position = aIndex;
    });
    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    setItems(reorder(items, result.source.index, result.destination.index));
  };

  const buttonStyle = {
    color: customization.buttonFontColor,
    backgroundColor: customization.buttonColor,
    fontFamily: customization.buttonFontType,
    fontSize: customization.buttonFontSize,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "20px auto",
    width: "85%",
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              id="test"
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {items.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      {item.type === DEVICE_SORTABLE_TYPE.LOGO &&
                      item.content.picture ? (
                        <LogoDeviceComponent
                          content={item.content}
                          customization={customization}
                        />
                      ) : null}
                      {item.type === DEVICE_SORTABLE_TYPE.DESCRIPTION &&
                      item.content ? (
                        <DescriptionDeviceComponent
                          content={item.content}
                          customization={customization}
                        />
                      ) : null}
                      {item.type === DEVICE_SORTABLE_TYPE.LINKS_AND_FILES &&
                      item.content.length >= 1 ? (
                        <LinksAndFilesDeviceComponent
                          content={item.content}
                          buttonStyle={buttonStyle}
                          customization={customization}
                        />
                      ) : null}
                      {item.type === DEVICE_SORTABLE_TYPE.SOCIAL_NETWORKS &&
                      item.content.length >= 1 ? (
                        <SocialNetworkDeviceComponent
                          content={item.content}
                          customization={customization}
                        />
                      ) : null}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {hasPush && (
        <Box sx={{ my: 2, p: 1 }}>
          <Typography>
            Pour recevoir les actualités de la page, veuillez entrer votre
            adresse email
          </Typography>
          <TextField
            sx={{ ...styles.field, width: "90%", my: 1 }}
            label="Adresse mail"
          />
          <BlackButton disabled sx={{ ...buttonStyle, width: "150px", mt: 2 }}>
            S'abonner
          </BlackButton>
        </Box>
      )}
    </>
  );
}
