export const styles = {
  pageContainer: { minHeight: "calc(100vh - 250px)" },

  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    m: -1,
  },

  titleIcon: {
    color: "#58738D",
    fontSize: "70px",
    mt: 3,
    mb: 1,
  },

  flexColumnCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },

  card: {
    mt: 2,
    mr: 3,
    ml: 3,
    mb: 9,
    p: 2,
    width: { lg: "60%", md: "70%", xs: "95%" },
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    borderRadius: "20px",
    textAlign: "center",
  },

  imageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    mt: -1,
    mb: -1,
  },

  icon: {
    fontSize: "25px",
    mr: 1,
  },

  typeTypo: {
    fontSize: "15px",
    fontWeight: "bold",
  },

  field: {
    marginBottom: "50px",
    backgroundColor: "white",
    borderRadius: "20px",
    [`& fieldset`]: {
      borderRadius: "20px",
      border: "1px",
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    },
  },

  formControl: {
    width: { xs: "85%", md: "70%", lg: "65%" },
    backgroundColor: "white",
  },

  button: {
    width: "280px",
    height: "90px",
    color: "#3D4856",
    margin: "auto",
    marginBottom: "20px",
    marginTop: "20px",
  },

  backArrow: {
    display: "flex",
    justifyContent: "start",
    m: { lg: 2, md: 1, xs: 0 },
    fontSize: "230px",
    color: "#58738D",
  },

  titleContainer: {
    marginTop: { xs: 3, lg: -7, md: -5 },
    marginBottom: 3,
  },
};
