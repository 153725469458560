export const styles = {
  card: {
    mt: 18,
    mb: "30%",
    borderRadius: "20px",
    ml: 2,
    mr: 2,
    textAlign: "center",
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
  },
  button: {
    margin: "30px",
  },
  icon: { color: "#58738D", mb: 2, mt: 2, fontSize: "70px" },
};
