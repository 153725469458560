import React, { useCallback, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import NavMenu from "../../components/navMenu/NavMenu";
import Footer from "../../components/footer/Footer";
import allActions from "../../redux/actions/allActions";
import { OctopusQRButton } from "../../utils/styled/Styled";
import { store } from "../../index";
import { styles } from "./MailVerification.style";
import { patchMailVerification } from "../../api/user/mail/patchMailVerification";

import { Grid, Card, Typography } from "@mui/material";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import LoadingWithMenu from "../../components/loading/LoadingWithMenu";
import NotFoundWithMenu from "../notFound/NotFoundWithMenu";
import {
  STORAGE_ACCESS_TOKEN,
  STORAGE_USER_ID,
} from "../../utils/constants/Constants";

export default function MailVerification() {
  const { id } = useParams();
  const navigateTo = useNavigate();

  const [isMailVerified, setIsMailVerified] = useState();
  const [hasError, setHasError] = useState();
  const patchData = useCallback(async () => {
    try {
      const aResponse = await patchMailVerification(id);
      store.dispatch(
        allActions.userActions.login(aResponse.user, aResponse.accessToken)
      );
      localStorage.setItem(STORAGE_ACCESS_TOKEN, aResponse.accessToken);
      localStorage.setItem(STORAGE_USER_ID, aResponse.user._id);
      setIsMailVerified(true);
    } catch {
      setHasError(true);
    }
  }, [id]);

  useEffect(() => {
    patchData();
  }, [patchData]);

  if (hasError) {
    return <NotFoundWithMenu />;
  }

  if (!isMailVerified) {
    return <LoadingWithMenu />;
  }

  return (
    <>
      <div style={{ minHeight: "calc(100vh - 250px)" }}>
        <NavMenu />
        <Grid container spacing={0}>
          <Grid item sm={2} md={3} />
          <Grid item xs={12} sm={8} md={6}>
            <Card sx={styles.card}>
              <MarkEmailReadIcon sx={styles.icon} />
              <br />
              <Typography variant="h6">
                Votre mail a été correctement vérifié, vous pouvez désormais
                accéder aux services OctopusQR
              </Typography>
              <br />
              <OctopusQRButton
                onClick={() => {
                  navigateTo("/qrcodes");
                }}
                sx={styles.button}
              >
                Mes QRCodes
              </OctopusQRButton>
            </Card>
          </Grid>
          <Grid item sm={2} md={3} />
        </Grid>
      </div>
      <Footer />
    </>
  );
}
