import { Box, IconButton } from "@mui/material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { getVisibleColor } from "../../../../../../../utils/Utils";

import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import PinterestIcon from "@mui/icons-material/Pinterest";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import RedditIcon from "@mui/icons-material/Reddit";
import TwitterIcon from "@mui/icons-material/Twitter";
import GoogleIcon from "@mui/icons-material/Google";
import {
  TikTok,
  Snapchat,
  CustomLink,
  Tripadvisor,
} from "../../../../../../../utils/styled/Styled";

export default function SocialNetworkDeviceComponent({
  content,
  customization,
}) {
  const SOCIAL_NETWORK_ICON_MAP = {
    LINKEDIN: (
      <LinkedInIcon sx={{ fontSize: customization.socialNetworksSize }} />
    ),
    INSTAGRAM: (
      <InstagramIcon sx={{ fontSize: customization.socialNetworksSize }} />
    ),
    FACEBOOK: (
      <FacebookIcon sx={{ fontSize: customization.socialNetworksSize }} />
    ),
    YOUTUBE: (
      <YouTubeIcon sx={{ fontSize: customization.socialNetworksSize }} />
    ),
    TRIPADVISOR: (
      <Tripadvisor
        color={customization.socialNetworksColor}
        size={customization.socialNetworksSize}
      />
    ),
    GOOGLE: <GoogleIcon sx={{ fontSize: customization.socialNetworksSize }} />,
    REDDIT: <RedditIcon sx={{ fontSize: customization.socialNetworksSize }} />,
    TWITTER: (
      <TwitterIcon sx={{ fontSize: customization.socialNetworksSize }} />
    ),
    PINTEREST: (
      <PinterestIcon sx={{ fontSize: customization.socialNetworksSize }} />
    ),
    TIKTOK: (
      <TikTok
        color={customization.socialNetworksColor}
        size={customization.socialNetworksSize}
      />
    ),
    SNAPCHAT: (
      <Snapchat
        color={customization.socialNetworksColor}
        size={customization.socialNetworksSize}
      />
    ),
  };
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <DragIndicatorIcon
        sx={{ color: getVisibleColor(customization.backgroundColor) }}
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          justifyContent: "space-evenly",
        }}
      >
        {content.map((aSocialNetwork, index) => {
          return (
            <CustomLink href={aSocialNetwork.path} target="_blank" key={index}>
              <IconButton sx={{ color: customization.socialNetworksColor }}>
                {SOCIAL_NETWORK_ICON_MAP[aSocialNetwork.type]}
              </IconButton>
            </CustomLink>
          );
        })}
      </Box>
      <Box sx={{ width: "40px" }} />
    </Box>
  );
}
