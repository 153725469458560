export const styles = {
  card: {
    mt: 13,
    mr: 3,
    ml: 3,
    textAlign: "center",
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    borderRadius: "40px",
  },

  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  loading: {
    display: "block",
    margin: "10px auto",
  },

  firstText: {
    fontSize: "20px",
    fontWeight: "bold",
    margin: "20px",
    textAlign: "justify",
    textJustify: "inter-character",
    textAlignLast: "center",
  },

  secondText: {
    textAlign: "justify",
    margin: "20px",
    textJustify: "inter-character",
    textAlignLast: "center",
  },

  errorText: {
    color: "#8B0000",
    fontWeight: "bold",
    fontSize: 12,
  },
};
