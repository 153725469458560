import React from "react";
import { Link } from "react-router-dom";

import Footer from "../../components/footer/Footer.js";
import NavMenu from "../../components/navMenu/NavMenu.js";

import { Card, Typography, Grid } from "@mui/material";

const styles = {
  card: {
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    borderRadius: "50px",
    m: 3,
  },
  title: {
    fontWeight: "bold",
    fontSize: "30px",
    marginTop: "20px",
    textAlign: "center",
  },
  subTitle: {
    textAlign: "center",
    fontSize: "14px",
  },
  secondaryTitle: {
    fontWeight: "bold",
    fontSize: "25px",
    m: 3,
  },
  tertiaryTitle: {
    fontWeight: "bold",
    fontSize: "20px",
    color: "#58738D",
    m: 3,
  },
  commonText: {
    textAlign: "justify",
    m: 3,
  },
};

export default function Legal() {
  return (
    <>
      <NavMenu />
      <Grid container spacing={0}>
        <Grid item xs={0} md={1} lg={2} />
        <Grid item xs={12} md={10} lg={8}>
          <Card sx={styles.card}>
            <Typography sx={styles.title}>Mentions légales</Typography>
            <Typography sx={styles.subTitle}>
              Dernière mise à jour - 13/02/2023
            </Typography>
            <br />

            <Typography sx={styles.secondaryTitle}>Préambule</Typography>
            <Typography sx={styles.commonText}>
              Andromede, 864 Avenue de Provence, 83440 Montauroux, France (
              ci-après dénommée « OctopusQR ») développe, exploite et fournit
              divers services dans les domaines de la création, de la
              conception, de la gestion, de l’optimisation, numérisation et
              analyse des codes QR pour ses clients (ci-après appelés
              « Utilisateur » ou « Utilisateurs »). Ces services sont fournis
              sous le domaine OctopusQR.com. OctopusQR se réserve le droit
              d’ajouter d’autres domaines à l’avenir. Avec les présentes
              Conditions Générales, OctopusQR veut assurer une relation
              d’affaires transparente avec l’Utilisateur, mais aussi protéger
              OctopusQR contre une utilisation malveillante. En acceptant les
              conditions générales, vous acceptez également notre licence de
              téléchargement de contenu, notre politique de confidentialité et
              notre politique d'utilisation acceptable dans la version
              applicable au moment de la conclusion de l’entente. Que vous
              pouvez trouver ici :
            </Typography>
            <Link to="/terms" style={{ marginLeft: "25px" }} target="_blank">
              Conditions générales d'utilisation
            </Link>
            <br />
            <Link to="/privacy" style={{ marginLeft: "25px" }} target="_blank">
              Politique de confidentialité
            </Link>
            <br />
            <Link to="/legal" style={{ marginLeft: "25px" }} target="_blank">
              Mentions légales
            </Link>
            <br />
            <Typography sx={styles.secondaryTitle}>
              Contenu du site web
            </Typography>
            <Typography sx={styles.commonText}>
              L’auteur ne prend aucune garantie quant à la pertinence,
              l’exactitude, l’exhaustivité ou la qualité du contenu fourni. Les
              réclamations contre l’auteur concernant des dommages matériels ou
              intellectuels ou d’autres résultats préjudiciables résultant de
              l’utilisation ou de la non-utilisation de toute information
              incomplète ou incorrecte, sera donc rejetée à moins que l’auteur
              ne puisse prouver qu’il y a eu négligence délibérée ou grave.
              Toutes les offres sont sujettes à changement et ne sont pas
              contraignantes. L’auteur se réserve le droit exprès de modifier,
              d’ajouter ou de supprimer des sections du contenu en ligne sans
              préavis ou de mettre fin à tout ou partie du contenu en ligne, de
              façon permanente ou temporaire.
            </Typography>
            <Typography sx={styles.secondaryTitle}>
              Liens et fichiers
            </Typography>
            <Typography sx={styles.commonText}>
              L’auteur ne peut être tenu responsable des renvois directs ou
              indirects à d’autres sites Internet (liens) ou à des fichiers dont
              il n’est pas directement responsable si l’auteur connaît le
              contenu des sites en question et s’il est en mesure de : et a la
              capacité technique d’empêcher l’utilisation de contenus illégaux.
              L’auteur déclare qu’au moment où les liens ont été placés sur le
              site, aucun contenu illégal n’était apparent sur les sites liés.
              L’auteur n’a aucune influence sur la conception actuelle ou
              future, le contenu et la paternité des sites liés. L’auteur ne
              peut donc être tenu responsable des modifications apportées à des
              sites après que le lien a été placé sur le site. En outre,
              l’auteur ne peut être tenu responsable des liens ou des messages
              publiés par les utilisateurs de forums de discussion, de livres
              d’or, de listes de diffusion ou d’autres installations similaires
              fournies sur son propre site. Tout contenu illégal, incorrect ou
              incomplet, et tout dommage ou perte résultant de l’utilisation ou
              de la non-utilisation de ces informations, est de la
              responsabilité du fournisseur du site vers lequel un lien a été
              fait et non de la personne qui a simplement fait le lien vers la
              publication en question.
            </Typography>
            <Typography sx={styles.secondaryTitle}>
              Droit d’auteur et droit des marques
            </Typography>
            <Typography sx={styles.commonText}>
              Dans toutes les publications, l’auteur s’est efforcé de respecter
              les lois sur le droit d’auteur concernant tous les graphiques,
              documents audio, séquences vidéo et textes utilisés; d’utiliser,
              dans la mesure du possible, les graphiques, documents audio,
              séquences vidéo et textes de l’auteur; ou d’utiliser des
              graphiques, des documents audio, des séquences vidéo et des textes
              du domaine public. Toutes les marques de commerce et de marque qui
              figurent dans le contenu Internet de l’auteur et qui sont
              protégées par des tiers sont entièrement soumises aux dispositions
              de la loi sur le droit d’auteur et du droit de propriété
              applicables au propriétaire enregistré dans chaque cas. Il ne faut
              pas supposer que, simplement parce qu’une marque est nommée, les
              marques ne sont pas protégées par les droits de tiers! Le droit
              d’auteur du matériel publié par l’auteur demeure la propriété de
              l’auteur des pages. Toute reproduction ou utilisation de tels
              graphiques, documents audio, séquences vidéo et textes dans
              d’autres publications électroniques ou imprimées n’est pas
              autorisée sans l’autorisation expresse de l’auteur.
            </Typography>
            <Typography sx={styles.secondaryTitle}>
              Protection des données
            </Typography>
            <Typography sx={styles.commonText}>
              Consultez notre{" "}
              <Link to="/privacy" target="_blank">
                Politique de confidentialité
              </Link>
            </Typography>
            <Typography sx={styles.secondaryTitle}>
              Force juridique du présent déni de responsabilité
            </Typography>
            <Typography sx={styles.commonText}>
              Cette clause de non-responsabilité doit être considérée comme
              faisant partie de la publication Internet à partir de laquelle
              vous avez été renvoyé. Si des sections de ce texte, ou des
              formulations individuelles, ne sont pas ou ne sont plus
              entièrement conformes à la loi actuelle, le contenu ou la validité
              des autres parties de ce document restent inchangés par ce fait.
            </Typography>
          </Card>
        </Grid>
      </Grid>
      <Footer />
    </>
  );
}
