import { Box } from "@mui/material";
import {
  CustomLink,
  OctopusQRButton,
} from "../../../../../../utils/styled/Styled";
import { FILE_ICON_MAP } from "../../../../../../utils/constants/Constants";
import LinkIcon from "@mui/icons-material/Link";
import { putStatistic } from "../../../../../../api/qrCodes/statisticsHandler";

export default function LinksAndFilesDisplay({ linksAndFiles, customPage }) {
  const buttonStyle = {
    color: customPage?.buttonFontColor,
    backgroundColor: customPage?.buttonColor,
    fontFamily: customPage?.buttonFontType,
    fontSize: customPage?.buttonFontSize,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "10px auto",
    width: "100%",
  };

  const updateStatistic = async (iElemId) => {
    await putStatistic(iElemId);
  };

  return (
    <Box sx={{ width: "220px" }}>
      {linksAndFiles.map((aLinkAndFile, index) => {
        if (aLinkAndFile.isLink) {
          return (
            <CustomLink
              onClick={() => {
                updateStatistic(aLinkAndFile.id);
              }}
              key={index}
              href={aLinkAndFile.path}
              target="_blank"
            >
              <OctopusQRButton style={buttonStyle}>
                <LinkIcon sx={{ color: "white" }} />
                <Box sx={{ ml: 2 }} />
                {aLinkAndFile.name}
              </OctopusQRButton>
            </CustomLink>
          );
        } else {
          return (
            <CustomLink
              onClick={() => {
                updateStatistic(aLinkAndFile.id);
              }}
              href={aLinkAndFile.path}
              target="_blank"
              key={index}
            >
              <OctopusQRButton style={buttonStyle}>
                {FILE_ICON_MAP[aLinkAndFile.type]}
                <Box sx={{ ml: 2 }} />
                {aLinkAndFile.name}
              </OctopusQRButton>
            </CustomLink>
          );
        }
      })}
    </Box>
  );
}
