import { useSearchParams, useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";

import EiffageLogo from "../../../../../assets/private_customers/eiffage/eiffage_logo.png";

import { styles } from "./WorksiteControl.style";
import Loading from "../../../../../components/loading/Loading";
import { DEFAULT_ERROR_MESSAGE } from "../../../../../utils/constants/Constants";

import {
  Box,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  TextField,
  Divider,
} from "@mui/material";
import Escalib from "./escalib/Escalib";
import Scaffold from "./scaffold/Scaffold";
import { getWorksiteControlForm } from "./apiWorksiteHandler";

export default function ControlEchafEscalib() {
  const [searchParams] = useSearchParams();
  const { userId, formId } = useParams();
  const [form, setForm] = useState();
  const [formValues, setFormValues] = useState({
    lastName: "",
    firstName: "",
    recept: searchParams.get("name"),
  });
  const [isError, setIsError] = useState(false);
  const [controlOn, setControlOn] = useState();
  const name = searchParams.get("name");

  const getData = useCallback(async () => {
    try {
      const aForm = await getWorksiteControlForm(userId, formId);
      setForm(aForm);
    } catch {
      setIsError(true);
    }
  }, [userId, formId]);

  useEffect(() => {
    getData();
  }, [getData]);

  const handleFormChange = (iEvent) => {
    const { name, value } = iEvent.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const changeControlOn = (iEvent) => {
    if (iEvent.target.value === "echafaudage") {
      setFormValues({
        ...formValues,
        controlOn: iEvent.target.value,
        loc: "",
      });
    } else {
      setFormValues({
        ...formValues,
        controlOn: iEvent.target.value,
        loc: "",
        mont_num: "",
        mont_dat: "",
        comm: "",
      });
    }

    setControlOn(iEvent.target.value);
  };

  document.title = `Evaluation ${name}`;

  if (isError) {
    return (
      <Box sx={styles.container}>
        <Typography variant="overline" color="red" sx={{ mt: 3 }}>
          {DEFAULT_ERROR_MESSAGE}
        </Typography>
      </Box>
    );
  }

  if (!form) {
    return <Loading />;
  }

  return (
    <Box sx={styles.container}>
      <Box sx={styles.formContainer}>
        <img
          src={EiffageLogo}
          width="200"
          alt="eiffage logo"
          style={{ margin: "40px auto" }}
        />

        <FormControl sx={{ display: "flex" }}>
          <Typography variant="overline" fontSize={"18px"}>
            {form.name}
          </Typography>
          <Divider sx={styles.divider} />
          <Typography
            variant="overline"
            fontSize={"13px"}
            marginTop={2}
            marginBottom={1}
          >
            Contrôle réalisé par
          </Typography>
          <Box sx={{ display: "flex" }}>
            <TextField
              sx={styles.inputText}
              value={formValues.lastName}
              onChange={handleFormChange}
              name="lastName"
              placeholder="Nom"
            />
            <TextField
              sx={styles.inputText}
              value={formValues.firstName}
              onChange={handleFormChange}
              name="firstName"
              placeholder="Prénom"
            />
          </Box>
          <Typography variant="overline" fontSize={"13px"}>
            Contrôle réalisé sur
          </Typography>
          <RadioGroup
            row
            sx={styles.eiffageRadio}
            name="controlOn"
            onChange={changeControlOn}
          >
            <FormControlLabel
              sx={{ flexBasis: "70%" }}
              value="echafaudage"
              control={<Radio />}
              label="Echafaudage"
            />
            <FormControlLabel
              sx={{ flexBasis: "70%" }}
              value="escalib"
              control={<Radio />}
              label="Tour Escalier / Escalib"
            />
          </RadioGroup>
          {controlOn === "escalib" ? (
            <Escalib
              formId={formId}
              userId={userId}
              name={name}
              formValues={formValues}
              setFormValues={setFormValues}
            />
          ) : null}
          {controlOn === "echafaudage" ? (
            <Scaffold
              formId={formId}
              userId={userId}
              name={name}
              formValues={formValues}
              setFormValues={setFormValues}
            />
          ) : null}
        </FormControl>
      </Box>
    </Box>
  );
}
