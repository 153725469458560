import { useState } from "react";
import { styles } from "../CustomPageContent.style";

import { Box, Typography, Divider } from "@mui/material";
import CustomDescription from "./customDescription/CustomDescription";
import CustomLogo from "./customLogo/CustomLogo";
import { DEVICE_SORTABLE_INDEX } from "../../../../../../utils/constants/Constants";
import LogoAndDescriptionCustomization from "./logoAndDescriptionCustomization/LogoAndDescriptionCustomization";

export default function CustomLogoAndDescription({
  user,
  initialLogoPicture,
  logo,
  setLogo,
  description,
  setDescription,
  items,
  setItems,
  customization,
  setCustomization,
}) {
  const [expanded, setExpanded] = useState(false);

  const handleChangeExpanded = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const updateDescription = (e) => {
    const aNewDescription = e.target.value;
    setDescription(aNewDescription);
    const aItems = Array.from(items);
    aItems.find(
      (aItem) => aItem.id === DEVICE_SORTABLE_INDEX.DESCRIPTION
    ).content = aNewDescription;
  };

  return (
    <Box sx={{ mb: 4 }}>
      <Divider variant="middle" />
      <Typography variant="overline">Logo et description</Typography>
      <Divider variant="middle" sx={{ mb: 3 }} />

      <Box sx={styles.flexColumnCenter}>
        <CustomLogo
          logo={logo}
          initialLogoPicture={initialLogoPicture}
          setLogo={setLogo}
          items={items}
          setItems={setItems}
          user={user}
          expanded={expanded}
          handleChangeExpanded={handleChangeExpanded}
        />
        <CustomDescription
          description={description}
          updateDescription={updateDescription}
          expanded={expanded}
          handleChangeExpanded={handleChangeExpanded}
        />
        <LogoAndDescriptionCustomization
          customization={customization}
          setCustomization={setCustomization}
          expanded={expanded}
          handleChangeExpanded={handleChangeExpanded}
        />
      </Box>
    </Box>
  );
}
