import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import Loading from "../../assets/adr_loading.gif";

import { OctopusQRButton } from "../../utils/styled/Styled";
import NavMenu from "../../components/navMenu/NavMenu";
import Footer from "../../components/footer/Footer";
import { getToken } from "../../api/user/password/token/getToken";
import LoadingWithMenu from "../../components/loading/LoadingWithMenu";
import NotFoundWithMenu from "../notFound/NotFoundWithMenu";

import {
  resetPassword,
  verifyResetPasswordData,
} from "../../api/user/password/token/resetPassword";
import { styles } from "./ResetPassword.style";

import {
  Card,
  InputAdornment,
  TextField,
  FormControl,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

export default function ResetPassword() {
  const [formValues, setFormValues] = useState({
    password: "",
    confirmedPassword: "",
  });
  const [errorValues, setErrorValues] = useState({
    hasPasswordError: false,
    password: "",
    hasConfirmedPasswordError: false,
    confirmedPassword: "",
  });
  const [resetPasswordTokenData, setResetPasswordTokenData] = useState();
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasResetPasswordErrors, setHasResetPasswordErrors] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordInputType, setPasswordInputType] = useState("password");
  const [showConfirmedPassword, setShowConfirmedPassword] = useState(false);
  const [confirmedPasswordInputType, setConfirmedPasswordInputType] =
    useState("password");
  const navigate = useNavigate();

  const { tokenId } = useParams();

  useEffect(() => {
    getToken(tokenId)
      .then((aResponse) => {
        setResetPasswordTokenData(aResponse);
      })
      .catch(() => {
        setResetPasswordTokenData({});
        setHasError(true);
      });
  }, [tokenId]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
    showPassword
      ? setPasswordInputType("password")
      : setPasswordInputType("text");
  };

  const handleClickShowConfirmedPassword = () => {
    setShowConfirmedPassword(!showConfirmedPassword);
    showConfirmedPassword
      ? setConfirmedPasswordInputType("password")
      : setConfirmedPasswordInputType("text");
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleSubmit();
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    setHasResetPasswordErrors(false);
    const aErrorValues = verifyResetPasswordData(formValues);
    if (
      aErrorValues.hasPasswordError ||
      aErrorValues.hasConfirmedPasswordError
    ) {
      setErrorValues({ ...aErrorValues });
      setIsLoading(false);
      return;
    }

    resetPassword(tokenId, {
      password: formValues.password,
      ...resetPasswordTokenData,
    })
      .then(() => {
        setIsLoading(false);
        navigate("/login/action-success");
      })
      .catch(() => {
        setHasResetPasswordErrors(true);
        setIsLoading(false);
      });
  };

  if (!resetPasswordTokenData) {
    return <LoadingWithMenu />;
  }

  if (hasError) {
    return <NotFoundWithMenu />;
  }

  return (
    <>
      <div style={{ minHeight: "calc(100vh - 250px)" }}>
        <NavMenu />
        <Grid container spacing={0}>
          <Grid item sm={0} md={2} />
          <Grid item xs={12} sm={12} md={8}>
            <Card sx={styles.card}>
              <Typography style={styles.title}>
                Réinitialisation du mot de passe
              </Typography>
              <FormControl sx={{ m: 4 }} style={styles.formControl}>
                <TextField
                  id="password-input"
                  name="password"
                  type={passwordInputType}
                  label="Nouveau mot de passe"
                  required={true}
                  variant="outlined"
                  value={formValues.password}
                  error={errorValues.hasPasswordError}
                  helperText={errorValues.password}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                  sx={styles.field}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon style={styles.icon} />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                        >
                          {showPassword ? (
                            <VisibilityIcon sx={styles.icon} />
                          ) : (
                            <VisibilityOffIcon sx={styles.icon} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <TextField
                  id="confirmed-password-input"
                  name="confirmedPassword"
                  type={confirmedPasswordInputType}
                  label="Confirmation du mot de passe"
                  required={true}
                  variant="outlined"
                  value={formValues.confirmedPassword}
                  error={errorValues.hasConfirmedPasswordError}
                  helperText={errorValues.confirmedPassword}
                  sx={styles.field}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon style={styles.icon} />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmedPassword}
                        >
                          {showConfirmedPassword ? (
                            <VisibilityIcon sx={styles.icon} />
                          ) : (
                            <VisibilityOffIcon sx={styles.icon} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {isLoading ? (
                  <img
                    src={Loading}
                    style={styles.center}
                    height="40"
                    alt="Loading"
                  />
                ) : null}
                {hasResetPasswordErrors ? (
                  <p style={styles.error}>
                    Une erreur est survenue, veuillez réessayer
                  </p>
                ) : null}
              </FormControl>
              <OctopusQRButton style={styles.button} onClick={handleSubmit}>
                Confirmer
              </OctopusQRButton>
            </Card>
          </Grid>
          <Grid item sm={0} md={2} />
        </Grid>
      </div>
      <Footer />
    </>
  );
}
