import { useCallback, useEffect, useState } from "react";
import { getFormAnswers } from "../../../../../api/qrCodes/form/formQrHandler";
import Loading from "../../../../../components/loading/Loading";
import { Box, Typography, Card, IconButton } from "@mui/material";
import {
  FORM_INPUT_TYPE_FR,
  QR_CODE_TYPE,
} from "../../../../../utils/constants/Constants";
import { styles } from "../../../render/Render.style";
import { generateColorArray } from "../../../../../utils/Utils";
import { Doughnut } from "react-chartjs-2";
import TablePaginate from "./TablePaginate";
import * as XLSX from "xlsx";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import DownloadingIcon from "@mui/icons-material/Downloading";

export default function FormAnswers({ user, qrCode, qrContent }) {
  const initialErrorState = {
    hasError: false,
    errorText: "",
  };
  const [error, setError] = useState(initialErrorState);
  const [answers, setAnswers] = useState();
  const [isDownloading, setIsDownloading] = useState(false);

  const downloadAnswersAsExcel = async () => {
    setIsDownloading(true);

    const questions = Object.values(qrContent.items).map(
      (item) => item.question
    );
    const data = [questions];

    const transformedItems = Object.values(answers.items).map((item) => {
      return item.answers;
    });

    const mergedItems = transformedItems[0].map((_, i) => {
      return transformedItems.map((arr) => arr[i]);
    });

    mergedItems.forEach((aItem) => {
      data.push(aItem);
    });

    const maxNumAnswers = questions.reduce((max, question) => {
      const numAnswers = answers.length;
      return numAnswers > max ? numAnswers : max;
    }, 0);

    for (let i = 0; i < maxNumAnswers; i++) {
      const row = [];
      for (let j = 0; j < questions.length; j++) {
        const answer = i < answers.length ? answers[i] : "";
        row.push(answer);
      }
      data.push(row);
    }

    const ws = XLSX.utils.aoa_to_sheet(data);

    ws["!cols"] = questions.map((question) => {
      return { wch: question.length + 5 };
    });

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Statistiques");
    const aNow = new Date();
    XLSX.writeFile(
      wb,
      `${aNow.toLocaleDateString("fr")}_reponses_formulaire_${qrCode.name}.xlsx`
    );

    setIsDownloading(false);
  };

  const getData = useCallback(async () => {
    try {
      const aAnswers = await getFormAnswers(
        user._id,
        qrContent.qrCodeId,
        qrContent._id
      );
      setAnswers(aAnswers);
    } catch {
      setError({
        hasError: true,
        errorText: "Une erreur est survenue, veuillez réessayer.",
      });
    }
  }, [user, qrContent]);

  useEffect(() => {
    getData();
  }, [getData]);

  function BuildAnswerGraph({ iItemKey, iItemValue }) {
    let content;
    if (
      iItemValue.type === FORM_INPUT_TYPE_FR.SHORT_ANSWER ||
      iItemValue.type === FORM_INPUT_TYPE_FR.LONG_ANSWER
    ) {
      content = <TablePaginate rows={iItemValue.answers} />;
    } else {
      const polarData = {
        labels: iItemValue.labels,
        datasets: [
          {
            label: "Nombre de réponses",
            data: iItemValue.data,
            backgroundColor: generateColorArray(iItemValue.labels.length),
          },
        ],
      };
      content = (
        <Box
          sx={{ ...styles.flexCenter, height: "500px", width: "100%", mt: 4 }}
        >
          <Doughnut
            data={polarData}
            options={{
              responsive: true,
            }}
          />
        </Box>
      );
    }

    return (
      <Box
        sx={{
          width: { xs: "95%", md: "70%", lg: "45%" },
          mx: 2,
        }}
      >
        <Card sx={styles.answerCard}>
          <Typography
            variant="overline"
            sx={{ fontSize: "15px", fontWeight: "bold" }}
          >
            {iItemValue.question}
          </Typography>
          {content}
        </Card>
      </Box>
    );
  }

  if (qrCode?.type !== QR_CODE_TYPE.FORM || !qrContent?.items) {
    return (
      <Box sx={{ maxWidth: "300px", margin: "auto" }}>
        <Typography
          variant="h6"
          sx={{ textAlign: "justify", mt: 6, color: "#8B0000" }}
        >
          Vous devez importer du contenu d'un QR Code de type Formulaire avant
          d'accéder à cette page.
        </Typography>
      </Box>
    );
  }

  if (error.hasError) {
    return (
      <Box
        sx={{
          ...styles.flexColumnCenter,
          width: "330px",
          margin: "auto",
          mt: 6,
        }}
      >
        <Typography variant="overline" sx={styles.errorText}>
          {error.errorText}
        </Typography>
      </Box>
    );
  }
  if (!answers) {
    return <Loading />;
  }

  return (
    <>
      <IconButton sx={{ mt: 1 }} onClick={downloadAnswersAsExcel}>
        {isDownloading ? (
          <DownloadingIcon sx={{ m: 1, fontSize: "40px", color: "#58738D" }} />
        ) : (
          <DownloadForOfflineIcon
            sx={{ m: 1, fontSize: "40px", color: "#58738D" }}
          />
        )}
      </IconButton>
      <Box sx={styles.flexCenter}>
        <Typography variant="overline" sx={{ mx: 2, mb: 2, fontSize: "25px" }}>
          Vous avez obtenu {answers.totalCount} réponse(s)
        </Typography>
      </Box>
      <Box
        sx={{
          ...styles.flexCenter,
          flexWrap: "wrap",
          justifyContent: "space-evenly",
          mb: 4,
        }}
      >
        {Object.entries(answers.items).map(([key, value], index) => {
          return (
            <BuildAnswerGraph iItemKey={key} iItemValue={value} key={index} />
          );
        })}
      </Box>
    </>
  );
}
