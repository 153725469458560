import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";

import "./index.css";

import App from "./App.js";
import rootReducer from "./redux/reducers/rootReducer.js";

import {
  STORAGE_ACCESS_TOKEN,
  STORAGE_USER_ID,
} from "./utils/constants/Constants.js";
import allActions from "./redux/actions/allActions.js";
import { configureStore } from "@reduxjs/toolkit";
import { getUser } from "./api/user/getUser";

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== "production",
});

if (localStorage.getItem(STORAGE_ACCESS_TOKEN)) {
  getUser(localStorage.getItem(STORAGE_USER_ID))
    .then((aResponse) => {
      store.dispatch(allActions.userActions.initializeUserData(aResponse));
    })
    .catch(() => {
      localStorage.removeItem(STORAGE_ACCESS_TOKEN);
      localStorage.removeItem(STORAGE_USER_ID);
      store.dispatch(allActions.userActions.logout());
    });
}
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
