import React, { useState, useEffect } from "react";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { convertToHTML } from "draft-convert";

import { OctopusQRButton } from "../../../../utils/styled/Styled";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { notifyCustomPageSubscribers } from "../../../../api/qrCodes/customPage/customPageQrHandler";

import VerifyEmailModal from "./VerifyEmailModal";
import Loading from "../../../../components/loading/Loading";

import { Box, TextField, Typography } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";

const RichTextEditor = ({ user, qrCode, qrContent }) => {
  const styles = {
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      backgroundColor: "white",
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
      p: 3,
      borderRadius: "20px",
    },
    errorText: {
      color: "#8B0000",
      fontSize: "15px",
      my: 1,
    },
    field: {
      marginBottom: "50px",
      backgroundColor: "white",
      borderRadius: "20px",
      [`& fieldset`]: {
        borderRadius: "20px",
        border: "1px",
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
      },
      width: "90%",
    },
  };

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const [htmlContent, setHtmlContent] = useState(null);

  useEffect(() => {
    setHtmlContent(convertToHTML(editorState.getCurrentContent()));
  }, [editorState]);

  const defaultSubjectError = {
    errorText: "",
    hasError: false,
  };
  const [subject, setSubject] = useState("");
  const [errorText, setErrorText] = useState("");
  const [subjectError, setSubjectError] = useState(defaultSubjectError);
  const [isLoading, setIsLoading] = useState(false);
  const [isVerifyMailModalOpen, setIsVerifyMailModalOpen] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const closeVerifyMailModal = () => {
    setIsVerifyMailModalOpen(false);
  };

  const verifyContent = async () => {
    setIsLoading(true);
    setSubjectError(defaultSubjectError);
    setErrorText("");

    if (!subject) {
      setSubjectError({
        errorText: "L'objet du mail est obligatoire",
        hasError: true,
      });
      setIsLoading(false);
      return;
    }

    if (htmlContent === "<p></p>") {
      setErrorText("Vous ne pouvez pas envoyer un notification vide.");
      setTimeout(() => {
        setErrorText("");
      }, 3500);
      setIsLoading(false);
      return;
    }
    setIsLoading(false);
    setIsVerifyMailModalOpen(true);
  };

  const sendEmail = async () => {
    setIsVerifyMailModalOpen(false);
    setIsLoading(true);

    try {
      await notifyCustomPageSubscribers(
        user._id,
        qrCode._id,
        qrContent?.customPage?._id,
        { content: htmlContent, subject: subject }
      );
      setEditorState(EditorState.createEmpty());
      setSubject("");
      setEmailSent(true);
    } catch {
      setErrorText("Une erreur est survenue, veuillez réessayer");
    }

    setIsLoading(false);
  };

  return (
    <Box sx={styles.container}>
      {emailSent ? (
        <>
          <DoneIcon sx={{ fontSize: "30px", color: "#58738D" }} />
          <Typography>Notification envoyée avec succès</Typography>
        </>
      ) : (
        <>
          <TextField
            label="Objet du mail"
            sx={styles.field}
            value={subject}
            helperText={subjectError.errorText}
            error={subjectError.hasError}
            onChange={(e) => setSubject(e.target.value)}
          />
          <Editor
            editorState={editorState}
            onEditorStateChange={setEditorState}
          />
          <OctopusQRButton onClick={verifyContent} sx={{ width: "150px" }}>
            Envoyer
          </OctopusQRButton>
          <VerifyEmailModal
            open={isVerifyMailModalOpen}
            onClose={closeVerifyMailModal}
            sendEmail={sendEmail}
            htmlContent={htmlContent}
            subject={subject}
          />
          {errorText && (
            <Typography sx={styles.errorText}>{errorText}</Typography>
          )}
          {isLoading && <Loading />}
        </>
      )}
    </Box>
  );
};

export default RichTextEditor;
