export const processEscalibSumbit = (iValues, iIsPlanActive) => {
  if (iIsPlanActive && (!iValues.mont_num || !iValues.mont_dat)) {
    return false;
  }
  if (
    !iValues.lastName ||
    !iValues.firstName ||
    !iValues.controlOn ||
    !iValues.recept ||
    !iValues.controlType ||
    !iValues.mont ||
    !iValues.loc ||
    !iValues.floor ||
    !iValues.horiz ||
    !iValues.ent_sec ||
    !iValues.assembl ||
    !iValues.presence ||
    !iValues.sort_sec ||
    !iValues.verrou ||
    !iValues.amarr ||
    !iValues.absenc ||
    !iValues.autor ||
    !iValues.aff_pv
  ) {
    return false;
  }
  return true;
};

export const processScaffoldSubmit = (iValues) => {
  if (
    !iValues.lastName ||
    !iValues.firstName ||
    !iValues.controlOn ||
    !iValues.recept ||
    !iValues.echafType ||
    !iValues.controlType ||
    !iValues.prot_pli ||
    !iValues.prot_liss ||
    !iValues.prot_liss_plus ||
    !iValues.prot_gard ||
    !iValues.acc_plan ||
    !iValues.acc_ech ||
    !iValues.acc_trap ||
    !iValues.acc_abs ||
    !iValues.fix_lia_gar ||
    !iValues.fix_lia_con ||
    !iValues.fix_pat ||
    !iValues.fix_lia_pot ||
    !iValues.fix_lia_pie ||
    !iValues.fix_pres ||
    !iValues.ama_ancr ||
    !iValues.ama_stab ||
    !iValues.port_terr ||
    !iValues.ama_cal ||
    !iValues.ama_fre ||
    !iValues.mont ||
    !iValues.aspe ||
    !iValues.autor
  ) {
    return false;
  }
  return true;
};
