import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, Link, Outlet } from "react-router-dom";

import FrenchFlag from "../../assets/icons/french_flag.svg";

import { OctopusQRButton } from "../../utils/styled/Styled";
import { MAIL_ALREADY_EXISTS_ERROR } from "../../utils/constants/Constants";
import NavMenu from "../../components/navMenu/NavMenu";
import Footer from "../../components/footer/Footer";
import Loading from "../../components/loading/Loading";
import { styles } from "./Register.style";

import { store } from "../../index";
import allActions from "../../redux/actions/allActions";

import {
  STORAGE_ACCESS_TOKEN,
  STORAGE_USER_ID,
} from "../../utils/constants/Constants";
import { registerUser, verifyRegisterData } from "../../api/user/registerUser";

import {
  Card,
  InputAdornment,
  TextField,
  FormControl,
  Box,
  Grid,
  IconButton,
  Typography,
  Checkbox,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import AppRegistrationIcon from "@mui/icons-material/AppRegistration";

export default function Register() {
  const user = useSelector((state) => state.userReducer);

  const [formValues, setFormValues] = useState({
    name: "",
    phoneNumber: "",
    mail: "",
    password: "",
  });
  const [errorValues, setErrorValues] = useState({
    hasNameError: false,
    name: "",
    hasMailError: false,
    mail: "",
    hasPhoneNumberError: false,
    phoneNumber: "",
    hasPasswordError: false,
    password: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [hasUserAccepted, setHasUserAccepted] = useState(false);
  const [passwordVisibility, setPasswordInputType] = useState("password");
  const [errorMessage, setErrorMessage] = useState("");
  const navigateTo = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handlePhoneChange = (e) => {
    let target = e.target;
    let position = target.selectionEnd;
    let length = target.value.length;

    if (length >= 15) {
      return;
    }

    target.value = target.value
      .replace(/[^\d]/g, "")
      .replace(/(.{2})/g, "$1 ")
      .trim();
    target.selectionEnd = position +=
      target.value.charAt(position - 1) === " " &&
      target.value.charAt(length - 1) === " " &&
      length !== target.value.length
        ? 1
        : 0;
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13 && hasUserAccepted) {
      handleSubmit();
    }
  };

  const handleCheckboxChange = () => {
    setHasUserAccepted(!hasUserAccepted);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    setErrorMessage("");

    const aErrorValues = verifyRegisterData(formValues);
    setErrorValues({
      ...aErrorValues,
    });
    if (
      aErrorValues.hasNameError ||
      aErrorValues.hasPhoneNumberError ||
      aErrorValues.hasPasswordError ||
      aErrorValues.hasMailError
    ) {
      setIsLoading(false);
      return;
    }

    try {
      const aResponse = await registerUser(formValues);
      store.dispatch(allActions.userActions.login(aResponse.user));
      localStorage.setItem(STORAGE_ACCESS_TOKEN, aResponse.accessToken);
      localStorage.setItem(STORAGE_USER_ID, aResponse.user._id);
      navigateTo("/welcome");
    } catch (aError) {
      if (aError?.message === MAIL_ALREADY_EXISTS_ERROR) {
        setErrorValues({
          hasMailError: true,
          mail: MAIL_ALREADY_EXISTS_ERROR,
        });
      } else {
        setErrorMessage("Une erreur est survenue, veuillez réessayer");
      }
      setIsLoading(false);
      return;
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
    showPassword
      ? setPasswordInputType("password")
      : setPasswordInputType("text");
  };

  if (user.loggedIn && !isLoading) {
    navigateTo("/profile");
  }

  return (
    <>
      <div style={{ minHeight: "calc(100vh - 250px)" }}>
        <NavMenu />
        <Grid container spacing={0}>
          <Grid item sm={2} md={3} />
          <Grid item xs={12} sm={8} md={6}>
            <Box
              component="form"
              sx={{ mb: 10, ml: 2, mr: 2, mt: 5 }}
              noValidate
              autoComplete="off"
            >
              <Card sx={styles.card}>
                <FormControl sx={styles.formControl}>
                  <AppRegistrationIcon sx={styles.titleIcon} />
                  <TextField
                    id="name-input"
                    name="name"
                    label="Nom d'utilisateur"
                    required={true}
                    variant="outlined"
                    value={formValues.name}
                    error={errorValues.hasNameError}
                    helperText={errorValues.name}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    sx={styles.field}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonIcon style={styles.icon} />
                        </InputAdornment>
                      ),
                    }}
                  />

                  <TextField
                    id="phone-input"
                    name="phoneNumber"
                    type="tel"
                    label="Numéro de  téléphone"
                    required={true}
                    variant="outlined"
                    placeholder="01 23 45 67 89"
                    value={formValues.phoneNumber}
                    error={errorValues.hasPhoneNumberError}
                    helperText={errorValues.phoneNumber}
                    onChange={handlePhoneChange}
                    onKeyDown={handleKeyDown}
                    sx={styles.field}
                    InputProps={{
                      startAdornment: (
                        <img
                          src={FrenchFlag}
                          style={styles.imgAdornment}
                          width="23"
                          alt="french-flag"
                        />
                      ),
                    }}
                  />

                  <TextField
                    id="mail-input"
                    name="mail"
                    type="email"
                    label="Adresse Mail"
                    required={true}
                    variant="outlined"
                    value={formValues.mail}
                    error={errorValues.hasMailError}
                    helperText={errorValues.mail}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    sx={styles.field}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <EmailIcon style={styles.icon} />
                        </InputAdornment>
                      ),
                    }}
                  />

                  <TextField
                    id="password-input"
                    name="password"
                    type={passwordVisibility}
                    label="Mot de passe"
                    required={true}
                    variant="outlined"
                    value={formValues.password}
                    error={errorValues.hasPasswordError}
                    helperText={errorValues.password}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    autoComplete="on"
                    sx={styles.field}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LockIcon style={styles.icon} />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                          >
                            {showPassword ? (
                              <VisibilityIcon sx={styles.icon} />
                            ) : (
                              <VisibilityOffIcon sx={styles.icon} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <div style={styles.formFooter}>
                    <Link style={styles.forgottenPassword} to="/login">
                      <Typography sx={styles.information}>
                        Déjà inscrit ?
                      </Typography>
                    </Link>
                    <Typography sx={styles.information}>
                      * Champ obligatoire
                    </Typography>
                  </div>
                  <div style={styles.boxContainer}>
                    <Checkbox
                      onChange={handleCheckboxChange}
                      sx={{ mr: 1, ml: -1 }}
                    />{" "}
                    <Typography sx={{ textAlign: "justify", fontSize: "16px" }}>
                      J'ai lu et j'accepte les{" "}
                      <Link to="/terms" target="_blank">
                        CGU
                      </Link>{" "}
                      et la{" "}
                      <Link to="/privacy" target="_blank">
                        politique de confidentialité
                      </Link>{" "}
                      OctopusQR
                    </Typography>
                  </div>
                  {isLoading ? <Loading /> : null}
                  {errorMessage ? (
                    <Typography sx={styles.error}>{errorMessage}</Typography>
                  ) : null}
                </FormControl>
                {hasUserAccepted ? (
                  <OctopusQRButton style={styles.button} onClick={handleSubmit}>
                    S'inscrire
                  </OctopusQRButton>
                ) : (
                  <OctopusQRButton style={styles.button} disabled>
                    S'inscrire
                  </OctopusQRButton>
                )}
              </Card>
            </Box>
          </Grid>
          <Grid item sm={2} md={3} />
        </Grid>
      </div>
      <Footer />
      <Outlet />
    </>
  );
}
