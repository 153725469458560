import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  ADD_QR_ACTIONS,
  CUSTOMIZATION_POSITION,
  CUSTOM_PAGE_LOGO_OPTIONS,
  DEVICE_SORTABLE_INDEX,
  DEVICE_SORTABLE_TYPE,
  STORAGE_USER_ID,
} from "../../../../../utils/constants/Constants";
import { styles } from "./CustomPageContent.style";
import DeviceWrapper from "../deviceWrapper/DeviceWrapper";

import {
  deleteQrCode,
  postQrCode,
  postQrLogo,
} from "../../../../../api/qrCodes/qrCodesHandler";

import {
  Box,
  Card,
  Typography,
  IconButton,
  Grid,
  Checkbox,
} from "@mui/material";
import WestIcon from "@mui/icons-material/West";
import CustomLogoAndDescription from "./logoAndDescription/CustomLogoAndDescription";
import CustomLinksAndFiles from "./linksAndFiles/CustomLinksAndFiles";
import CustomSocialNetworks from "./socialNetworks/CustomSocialNetworks";

import DeviceSortable from "./deviceSortable/DeviceSortable";
import { OctopusQRButton } from "../../../../../utils/styled/Styled";
import Loading from "../../../../../components/loading/Loading";
import {
  postCustomPage,
  postCustomPageFile,
  postCustomPageLink,
  postCustomPageLogo,
  postCustomPageSocialNetwork,
} from "../../../../../api/qrCodes/customPage/customPageQrHandler";

export default function CustomPageContent({
  currentAction,
  QRCode,
  file,
  putQRCode,
}) {
  const user = useSelector((state) => state.userReducer);
  const navigateTo = useNavigate();
  const initialError = {
    hasError: false,
    errorText: "",
  };

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(initialError);

  //Push notification part
  const [hasPush, setHasPush] = useState(false);

  //LogoAndDescription
  const logoInitalState = {
    logoOptionChosen: CUSTOM_PAGE_LOGO_OPTIONS.NONE,
    file: null,
    picture: "",
  };
  const [descritpion, setDescription] = useState("");
  const [logo, setLogo] = useState(logoInitalState);

  //linksAndFiles

  const [linksAndFiles, setLinksAndFiles] = useState([]);

  //socialNetworks
  const [socialNetworks, setSocialNetworks] = useState([]);
  const [items, setItems] = useState([
    {
      id: "item_1",
      type: DEVICE_SORTABLE_TYPE.LOGO,
      content: { ...logoInitalState },
      position: 0,
    },
    {
      id: "item_2",
      type: DEVICE_SORTABLE_TYPE.DESCRIPTION,
      content: "",
      position: 1,
    },
    {
      id: "item_3",
      type: DEVICE_SORTABLE_TYPE.LINKS_AND_FILES,
      content: [],
      position: 2,
    },
    {
      id: "item_4",
      type: DEVICE_SORTABLE_TYPE.SOCIAL_NETWORKS,
      content: [],
      position: 3,
    },
  ]);

  //Customization
  const [customization, setCustomization] = useState({
    descriptionFontColor: "#000000",
    descriptionFontType: "'Roboto', sans-serif",
    descriptionFontSize: 15,
    position: CUSTOMIZATION_POSITION.TOP,
    backgroundColor: "#ffffff",
    socialNetworksColor: "#757575",
    socialNetworksSize: 30,
    buttonColor: "#404040",
    buttonFontColor: "#ffffff",
    buttonFontType: "'Roboto', sans-serif",
    buttonFontSize: 15,
  });

  const handleSubmit = async () => {
    setIsLoading(true);
    setError(initialError);

    const aUserId = localStorage.getItem(STORAGE_USER_ID);
    if (!aUserId) {
      setError({
        hasError: true,
        errorText: "Vous n'êtes pas autorisé à faire cela",
      });
      setIsLoading(false);
      return;
    }

    let aQRCodeId;

    let aCustomPageId;

    let aItems = Array.from(items);

    let aLogoObject = aItems.find(
      (aItem) => aItem.id === DEVICE_SORTABLE_INDEX.LOGO
    );

    let aLogo = aLogoObject.content;
    const aCustomPagePicture =
      aLogo.logoOptionChosen === CUSTOM_PAGE_LOGO_OPTIONS.USER_LOGO
        ? aLogo.picture
        : "";

    const aLinksAndFiles = aItems.find(
      (aItem) => aItem.id === DEVICE_SORTABLE_INDEX.LINKS_AND_FILES
    );
    const aLinks = aLinksAndFiles.content.filter((aLink) => aLink.isLink);
    const aFiles = aLinksAndFiles.content.filter((aLink) => !aLink.isLink);

    const aDescription = aItems.find(
      (aItem) => aItem.id === DEVICE_SORTABLE_INDEX.DESCRIPTION
    );

    const aSocialNetworks = aItems.find(
      (aItem) => aItem.id === DEVICE_SORTABLE_INDEX.SOCIAL_NETWORKS
    );

    try {
      if (
        aLogo.logoOptionChosen === CUSTOM_PAGE_LOGO_OPTIONS.PERSONNAL &&
        aLogo.file === null
      ) {
        setError({
          hasError: true,
          errorText:
            "Si vous utilisez votre propre logo, il est obligatoire de l'importer - Section 'Ajouter un Logo'",
        });
        setIsLoading(false);
        return;
      }

      if (!QRCode?._id) {
        const aQRCode = await postQrCode(aUserId, QRCode);
        aQRCodeId = aQRCode._id;
        if (file !== null) {
          const aQrLogoData = new FormData();
          aQrLogoData.append("file", file);
          await postQrLogo(aUserId, aQRCodeId, aQrLogoData);
        }
      } else {
        await putQRCode();
        aQRCodeId = QRCode._id;
      }
      const aCustomPage = await postCustomPage(aUserId, aQRCodeId, {
        picture: aCustomPagePicture,
        description: aDescription.content,
        customization: customization,
        order: {
          logo: aLogoObject.position,
          description: aDescription.position,
          linksAndFiles: aLinksAndFiles.position,
          socialNetworks: aSocialNetworks.position,
        },
        hasPush: hasPush,
      });
      aCustomPageId = aCustomPage._id;

      if (aLogo.logoOptionChosen === CUSTOM_PAGE_LOGO_OPTIONS.PERSONNAL) {
        const aCustomPageLogoData = new FormData();
        aCustomPageLogoData.append("file", aLogo.file);
        await postCustomPageLogo(
          aUserId,
          aQRCodeId,
          aCustomPageId,
          aCustomPageLogoData
        );
      }

      for (const aLink of aLinks) {
        await postCustomPageLink(aUserId, aQRCodeId, aCustomPageId, aLink);
      }

      for (const aFile of aFiles) {
        const aFileData = new FormData();
        aFileData.append("file", aFile.file);
        aFileData.append("position", aFile.position);
        aFileData.append("name", aFile.name);
        await postCustomPageFile(aUserId, aQRCodeId, aCustomPageId, aFileData);
      }

      for (const aSocialNetwork of aSocialNetworks.content) {
        await postCustomPageSocialNetwork(
          aUserId,
          aQRCodeId,
          aCustomPageId,
          aSocialNetwork
        );
      }
      navigateTo("/qrcodes");
    } catch {
      if (!QRCode?._id) {
        await deleteQrCode(aUserId, aQRCodeId);
      }
      setError({
        hasError: true,
        errorText: "Une erreur est survenue, merci de réessayer.",
      });
      setIsLoading(false);
      return;
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  const boxSx =
    customization.position === CUSTOMIZATION_POSITION.CENTER
      ? {
          position: { xs: "block", lg: "absolute" },
          top: { xs: "", lg: "50%" },
          transform: { xs: "", lg: "translate(0%, -50%)" },
          width: "100%",
        }
      : {};

  const deviceContent = (
    <Box sx={boxSx}>
      <DeviceSortable
        items={items}
        setItems={setItems}
        hasPush={hasPush}
        customization={customization}
      />
    </Box>
  );

  return (
    <Box
      sx={{
        ...styles.flexCenter,
        width: { xs: "100%", md: "90%", lg: "80%" },
      }}
    >
      <Card sx={styles.card}>
        <IconButton
          sx={styles.backArrow}
          onClick={() => {
            currentAction(ADD_QR_ACTIONS.CUSOMIZATION);
          }}
        >
          <WestIcon sx={styles.backArrowIcon} />
        </IconButton>
        <Typography variant="h4" sx={styles.titleContainer}>
          Création de la page personnalisée
        </Typography>
        <Grid container spacing={0} sx={styles.flexCenter}>
          <Grid item md={12} lg={6} sx={{ margin: "auto" }}>
            <CustomLogoAndDescription
              user={user}
              logo={logo}
              setLogo={setLogo}
              description={descritpion}
              setDescription={setDescription}
              items={items}
              setItems={setItems}
              customization={customization}
              setCustomization={setCustomization}
            />

            <CustomLinksAndFiles
              linksAndFiles={linksAndFiles}
              setLinksAndFiles={setLinksAndFiles}
              items={items}
              setItems={setItems}
              customization={customization}
              setCustomization={setCustomization}
            />

            <CustomSocialNetworks
              socialNetworks={socialNetworks}
              setSocialNetworks={setSocialNetworks}
              items={items}
              setItems={setItems}
              customization={customization}
              setCustomization={setCustomization}
            />

            {user.hasPush && (
              <Box sx={styles.flexCenter}>
                <Checkbox
                  checked={hasPush}
                  onChange={() => setHasPush(!hasPush)}
                />
                <Typography>
                  Activer les notifications push sur cette page
                </Typography>
              </Box>
            )}

            <OctopusQRButton sx={styles.button} onClick={handleSubmit}>
              Terminer
            </OctopusQRButton>
            <br />
            {error.hasError ? (
              <Typography variant="overline" sx={styles.errorText}>
                {error.errorText}
              </Typography>
            ) : null}
          </Grid>

          <Grid item md={12} lg={6} sx={{ margin: "auto" }}>
            <Box sx={{ display: { xs: "none", md: "block" } }}>
              <DeviceWrapper
                content={deviceContent}
                backgroundColor={customization.backgroundColor}
              />
            </Box>
            <Box sx={{ display: { xs: "block", md: "none" } }}>
              <Typography variant="overline" sx={{ my: 2 }}>
                Rendu
              </Typography>
              <Card
                sx={{
                  ...styles.card,
                  width: "300px",
                  background: customization.backgroundColor,
                }}
              >
                {deviceContent}
              </Card>
            </Box>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
}
