import api from "../../axios";

export const postLinkQr = async (iUserId, iQRCodeId, iData) => {
  const aResponse = await api.post(
    `/users/${iUserId}/qrcodes/${iQRCodeId}/links`,
    iData
  );
  return aResponse.data;
};

export const putLinkQr = async (iUserId, iQRCodeId, iLinkId, iData) => {
  const aResponse = await api.put(
    `/users/${iUserId}/qrcodes/${iQRCodeId}/links/${iLinkId}`,
    iData
  );
  return aResponse.data;
};

export const verifyLinkData = (iLink) => {
  const aErrorValues = {
    hasLinkError: false,
    link: "",
  };

  if (!iLink) {
    aErrorValues.hasLinkError = true;
    aErrorValues.link = "Champs requis";
    return aErrorValues;
  }

  const aRegex = new RegExp(
    /^(https?:\/\/)[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
    "i"
  );
  if (!aRegex.test(iLink)) {
    aErrorValues.hasLinkError = true;
    aErrorValues.link =
      "Le lien n'est pas valide, il doit démarrer par http:// ou https://";
  }

  return aErrorValues;
};
