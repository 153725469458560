import api from "../axios";

export const getStatistic = async (iUserId, iElementId) => {
  const aResponse = await api.get(`/users/${iUserId}/statistics/${iElementId}`);
  return aResponse.data;
};

export const putStatistic = async (iElementId) => {
  const aResponse = await api.put(`/statistics/${iElementId}`, {});
  return aResponse.data;
};
