import LoadingImg from "../../assets/adr_loading.gif";
import NavMenu from "../navMenu/NavMenu";
import Footer from "../footer/Footer";

export default function LoadingWithMenu() {
  return (
    <>
      <div style={{ minHeight: "calc(100vh - 250px)" }}>
        <NavMenu />
        <img
          src={LoadingImg}
          alt="loading"
          style={{ display: "block", margin: "50px auto" }}
          width="140px"
        />
      </div>
      <Footer />
    </>
  );
}
