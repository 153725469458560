import { useNavigate } from "react-router-dom";
import { useState } from "react";

import { ADD_QR_ACTIONS } from "../../../../../utils/constants/Constants";
import { styles } from "./LinkContent.style";

import {
  deleteQrCode,
  postQrLogo,
  postQrCode,
} from "../../../../../api/qrCodes/qrCodesHandler";
import Loading from "../../../../../components/loading/Loading";
import { STORAGE_USER_ID } from "../../../../../utils/constants/Constants";
import { OctopusQRButton } from "../../../../../utils/styled/Styled";

import {
  Box,
  Card,
  Typography,
  FormControl,
  IconButton,
  TextField,
  InputAdornment,
} from "@mui/material";
import WestIcon from "@mui/icons-material/West";
import LinkIcon from "@mui/icons-material/Link";
import {
  postLinkQr,
  verifyLinkData,
} from "../../../../../api/qrCodes/link/linkQrHandler";

export default function LinkContent({
  currentAction,
  QRCode,
  file,
  putQRCode,
}) {
  const [formValues, setFormValues] = useState({ link: "" });
  const [errorValues, setErrorValues] = useState({
    link: "",
    hasLinkError: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const isQrExisting = QRCode?._id;
  const navigateTo = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const aErrorValues = verifyLinkData(formValues.link);
    setErrorValues({ ...aErrorValues });
    if (aErrorValues.hasLinkError) {
      setIsLoading(false);
      return;
    }

    const aUserId = localStorage.getItem(STORAGE_USER_ID);
    if (!aUserId) {
      setHasError(true);
      setIsLoading(false);
      return;
    }

    let aQRCodeId;
    try {
      if (!isQrExisting) {
        const aQRCode = await postQrCode(aUserId, QRCode);
        aQRCodeId = aQRCode._id;
        if (file !== null) {
          const aQrLogoData = new FormData();
          aQrLogoData.append("file", file);
          await postQrLogo(aUserId, aQRCodeId, aQrLogoData);
        }
      } else {
        aQRCodeId = QRCode._id;
      }
      await postLinkQr(aUserId, aQRCodeId, {
        qrCodeId: aQRCodeId,
        path: formValues.link,
      });
      if (isQrExisting) {
        await putQRCode();
      }
      navigateTo("/qrcodes");
    } catch {
      if (!isQrExisting) {
        //Delete the QR In case error happens
        await deleteQrCode(aUserId, aQRCodeId);
      }
      setHasError(true);
      setIsLoading(false);
      return;
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Box
        sx={{
          ...styles.flexCenter,
          width: "100%",
        }}
      >
        <Card sx={styles.card}>
          <IconButton
            sx={styles.backArrow}
            onClick={() => {
              currentAction(ADD_QR_ACTIONS.CUSOMIZATION);
            }}
          >
            <WestIcon />
          </IconButton>
          <Typography variant="h4" sx={styles.titleContainer}>
            Saisie du lien
          </Typography>
          <FormControl sx={styles.formControl}>
            <TextField
              id="link-input"
              name="link"
              label="Lien"
              required={true}
              variant="outlined"
              value={formValues.link}
              error={errorValues.hasLinkError}
              helperText={errorValues.link}
              onChange={handleInputChange}
              sx={{
                ...styles.field,
                width: { xs: "266px", md: "350px" },
                margin: "auto",
              }}
              placeholder="https://octopusqr.com"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LinkIcon style={styles.icon} />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
          <br />
          <br />
          <OctopusQRButton sx={styles.button} onClick={handleSubmit}>
            Terminer
          </OctopusQRButton>
          <br />
          {hasError ? (
            <Typography variant="overline" sx={styles.errorText}>
              Une erreur est survenue, veuillez réessayer
            </Typography>
          ) : null}
        </Card>
      </Box>
    </>
  );
}
