export const styles = {
  card: {
    mt: 13,
    textAlign: "center",
    ml: "10%",
    mr: "10%",
    borderRadius: "40px",
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
  },
  title: {
    textAlign: "center",
    marginBottom: "10px",
    marginTop: "30px",
    fontSize: "20px",
    fontWeight: "bold",
  },
  button: {
    margin: "auto",
    display: "block",
    marginTop: "-30px",
    marginBottom: "40px",
  },
  formControl: {
    width: "70%",
    textAlign: "center",
    backgroundColor: "white",
  },
  field: {
    marginBottom: "60px",
    backgroundColor: "white",
    borderRadius: "20px",
    [`& fieldset`]: {
      borderRadius: "20px",
      border: "1px",
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    },
  },
  center: {
    display: "block",
    margin: "15px auto",
  },
  error: {
    fontSize: "16px",
    color: "#8B0000",
  },
  icon: {
    color: "#58738D",
  },
};
