import { Box, Typography } from "@mui/material";

export default function DescriptionDisplay({ customPage }) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        whiteSpace: "pre-line",
      }}
    >
      <Typography
        sx={{
          fontSize: customPage?.descriptionFontSize,
          fontFamily: customPage?.descriptionFontType,
          color: customPage?.descriptionFontColor,
          textAlign: "justify",
          textAlignLast: "center",
        }}
      >
        {customPage?.description}
      </Typography>
    </Box>
  );
}
