import { Grid, Card, Typography, Box } from "@mui/material";
import { motion } from "framer-motion";
import features from "../../../assets/home/features.png";

const styles = {
  featuresContainer: {
    backgroundImage: `url(${features})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    minHeight: "700px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    p: 2,
  },
  card: {
    backdropFilter: "blur(130px)",
    backgroundColor: "rgba(255, 255, 255, 0.3)",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
    borderRadius: "30px",
    padding: "1rem",
    margin: "auto",
    height: { xs: "320px", md: "550px" },
    width: { xs: "300px", md: "340px" },
    display: "flex",
    overflow: "hidden",
    py: 3,
    pl: 3,
  },
  scrollContainer: {
    width: "100%",
    height: "100%",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "6px",
    },
    "&::-webkit-scrollbar-track": {
      background: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "rgba(88, 115, 141, 0.3)",
      borderRadius: "20px",
    },
  },
  contentContainer: {
    px: 2,
    margin: "auto",
    textAlign: "justify",
  },
  cardTitle: {
    color: "#58738D",
    fontWeight: "bold",
    fontSize: "26px",
    textAlign: "center",
  },
  cardSubTitle: {
    fontSize: "13px",
    textAlign: "center",
    py: 1,
  },
  cardPoint: {
    fontWeight: "bold",
    fontSize: "20px",
  },
};

export default function Features() {
  return (
    <motion.div
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1, ease: "easeInOut" }}
    >
      <Box sx={styles.featuresContainer}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={4}>
            <Card sx={styles.card}>
              <Box sx={styles.scrollContainer}>
                <Typography variant="h2" sx={styles.cardTitle}>
                  QR Code Dynamique
                </Typography>
                <Typography variant="h3" sx={styles.cardSubTitle}>
                  La Clé du Marketing Agile
                  <br />
                  <br />
                </Typography>
                <Box sx={styles.contentContainer}>
                  <Typography>
                    Un QR Code dynamique est bien plus qu'un simple code à
                    scanner. C'est un outil de marketing numérique à part
                    entière qui booste l'engagement, facilite la conversion et
                    optimise vos campagnes de manière inégalée.
                    <br />
                    <br />
                  </Typography>
                  <Typography sx={styles.cardPoint}>
                    ✓ Flexible et Évolutif
                  </Typography>
                  <br />
                  <Typography>
                    Modifiez l'URL de destination à tout moment sans changer le
                    QR Code physique. Testez différents contenus, adaptez-vous
                    aux tendances et réagissez en temps réel !<br /> <br />
                  </Typography>
                  <Typography sx={styles.cardPoint}>
                    ✓ Riche en Analytiques
                  </Typography>
                  <br />
                  <Typography>
                    Suivez qui et quand scannent vos codes. Bénéficiez d'un
                    aperçu précis de l'efficacité de vos actions pour une
                    stratégie affinée. <br /> <br />
                  </Typography>
                  <Typography sx={styles.cardPoint}>
                    ✓ Personnalisable à souhait
                  </Typography>
                  <br />
                  <Typography>
                    Ajoutez votre logo, vos couleurs, et adaptez l'apparence du
                    code à votre marque. Transformez chaque scan en expérience
                    unique.
                  </Typography>
                  <br />
                  L'avenir du marketing est agile et data-driven. Soyez prêt.
                  Choisissez le QR Code dynamique pour une stratégie marketing
                  optimisée.
                </Box>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} md={12} lg={4}>
            <Card sx={styles.card}>
              <Box sx={styles.scrollContainer}>
                <Typography variant="h2" sx={styles.cardTitle}>
                  QR Code Statique
                </Typography>
                <Typography variant="h3" sx={styles.cardSubTitle}>
                  L'Outil Fiable pour une Connexion Directe
                  <br />
                  <br />
                </Typography>
                <Box sx={styles.contentContainer}>
                  <Typography>
                    Un QR Code statique est le pont numérique direct entre vous
                    et votre audience. Simple, fiable et sans complications, il
                    donne un accès instantané à l'information que vous souhaitez
                    partager.
                    <br />
                    <br />
                  </Typography>
                  <Typography sx={styles.cardPoint}>
                    ✓ Robuste et Indépendant
                  </Typography>
                  <br />
                  <Typography>
                    Une fois créé, son contenu est gravé dans le marbre. Aucun
                    serveur ou service en ligne n'est nécessaire pour le lire,
                    assurant un accès continu et sans faille.
                    <br /> <br />
                  </Typography>
                  <Typography sx={styles.cardPoint}>
                    ✓ Instantané et Direct
                  </Typography>
                  <br />
                  <Typography>
                    Lien vers une URL, un texte, un numéro de téléphone, une
                    localisation GPS, ou tout autre donnée statique. Transmettez
                    des informations directement et sans intermédiaire. <br />{" "}
                    <br />
                  </Typography>
                  <Typography sx={styles.cardPoint}>
                    ✓ Personnalisable
                  </Typography>
                  <br />
                  <Typography>
                    Comme son homologue dynamique, le QR Code statique peut être
                    personnalisé à votre marque pour une expérience utilisateur
                    cohérente.
                  </Typography>
                  <br />
                  Sûr, simple et efficace. Pour une connexion directe et sans
                  tracas avec votre audience, optez pour le QR Code statique.
                </Box>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} md={12} lg={4}>
            <Card sx={styles.card}>
              <Box sx={styles.scrollContainer}>
                <Typography variant="h2" sx={styles.cardTitle}>
                  Ce que permet OctopusQR
                </Typography>
                <Typography variant="h3" sx={styles.cardSubTitle}>
                  Votre Plateforme de Création de QR Codes Ultra-Puissante
                  <br />
                  <br />
                </Typography>
                <Box sx={styles.contentContainer}>
                  <Typography>
                    OctopusQR transforme la création de QR Codes en une
                    expérience intuitive et puissante, mettant à votre portée
                    des outils marketing numériques exceptionnels.
                    <br />
                    <br />
                  </Typography>
                  <Typography sx={styles.cardPoint}>
                    ✓ Flexibilité et Puissance
                  </Typography>
                  <br />
                  <Typography>
                    Générez des QR Codes dynamiques et statiques en un clin
                    d'œil. Adaptez-vous en temps réel ou assurez une connexion
                    directe avec votre audience. Vos options sont sans limites.
                    <br /> <br />
                  </Typography>
                  <Typography sx={styles.cardPoint}>
                    ✓ Personnalisation à l'Extrême
                  </Typography>
                  <br />
                  <Typography>
                    Incrustez votre logo, ajustez les couleurs et le design pour
                    faire écho à votre marque. Chaque QR Code devient un
                    ambassadeur visuel de votre identité. <br /> <br />
                  </Typography>
                  <Typography sx={styles.cardPoint}>
                    ✓ Analytiques Avancées
                  </Typography>
                  <br />
                  <Typography>
                    Qui a scanné votre code? Quand ? Avec quel appareil?
                    OctopusQR offre une richesse de données pour affiner vos
                    campagnes marketing et maximiser votre impact.
                    <br /> <br />
                  </Typography>
                  <Typography sx={styles.cardPoint}>
                    ✓ Assistance premium
                  </Typography>
                  <br />
                  <Typography>
                    Notre équipe d'experts en QR Code est à votre disposition,
                    prête à vous guider vers le succès de vos campagnes.
                  </Typography>
                  <br />
                  Engagez votre audience, optimisez vos campagnes, et propulsez
                  votre marketing au niveau supérieur. Avec OctopusQR, la
                  création de QR Codes devient un jeu d'enfant.
                </Box>
              </Box>
            </Card>
          </Grid>
          {/* Additional cards go here */}
        </Grid>
      </Box>
    </motion.div>
  );
}
