import api from "../axios";
import { verifyMail } from "../../utils/forms/FormsVerification";

export const logUser = async (iFormValues) => {
  const aResponse = await api.post(`/users/login`, iFormValues);
  return aResponse.data;
};

export const verifyLoginData = (iFormValues) => {
  const aErrorValues = {
    hasMailError: false,
    mail: "",
    hasPasswordError: false,
    password: "",
  };

  verifyMail(iFormValues.mail, aErrorValues);
  if (!iFormValues.password) {
    aErrorValues.hasPasswordError = true;
    aErrorValues.password = "Champ obligatoire";
  }

  return aErrorValues;
};
