import { Card, Typography, Box, Avatar } from "@mui/material";
import { motion } from "framer-motion";
import julieImage from "../../assets/contact/julie.jpg";
import corentinImage from "../../assets/contact/corentin.jpeg";
import contact from "../../assets/contact/contact.png";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneIcon from "@mui/icons-material/Phone";
import PersonIcon from "@mui/icons-material/Person";
import NavMenu from "../../components/navMenu/NavMenu";
import Footer from "../../components/footer/Footer";
import { CustomFooterLink } from "../../utils/styled/Styled";

const styles = {
  contactContainer: {
    backgroundImage: `url(${contact})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    minHeight: "700px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    p: 2,
  },
  pageContainer: { minHeight: "calc(100vh - 250px)" },
  contactCard: {
    backdropFilter: "blur(130px)",
    backgroundColor: "rgba(255, 255, 255, 0.3)",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
    borderRadius: "30px",
    p: 3,
    margin: "auto",
  },
  titleContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    py: 3,
    background: "#282828",
    minHeight: "100px",
    width: "100%",
  },
  profileContainer: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    flexDirection: { xs: "column", lg: "row" },
    p: 3,
  },
  avatar: {
    width: 160,
    height: 160,
    mb: 5,
    mx: "auto",
  },
};

const avatarVariants = {
  hidden: { opacity: 0, scale: 0.9 },
  visible: { opacity: 1, scale: 1, transition: { duration: 0.4 } },
};

export default function Contact() {
  return (
    <>
      <Box sx={styles.pageContainer}>
        <NavMenu />
        <Box sx={styles.titleContainer}>
          <Typography variant="h2" sx={{ fontSize: "34px", color: "white" }}>
            Contactez-nous
          </Typography>
        </Box>
        <Box sx={styles.contactContainer}>
          <Card sx={styles.contactCard}>
            <Box sx={styles.profileContainer}>
              <motion.div
                variants={avatarVariants}
                initial="hidden"
                animate="visible"
              >
                <Box
                  sx={{
                    minWidth: "330px",
                    maxWidth: "500px",
                    justifyContent: "center",
                    py: 3,
                  }}
                >
                  <Avatar
                    alt="Julie Lorrain"
                    src={julieImage}
                    sx={styles.avatar}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <PersonIcon />
                    <Typography variant="h5" sx={{ ml: 1 }}>
                      Julie LORRAIN
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                    <MailOutlineIcon />
                    <Typography variant="subtitle1" sx={{ ml: 1 }}>
                      <CustomFooterLink
                        href="mailto:julie.lorrain@octopusqr.com"
                        sx={{ color: "black" }}
                      >
                        julie.lorrain@octopusqr.com
                      </CustomFooterLink>
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                    <PhoneIcon />
                    <Typography variant="subtitle1" sx={{ ml: 1 }}>
                      <CustomFooterLink
                        href="tel:0664805427"
                        sx={{ color: "black" }}
                      >
                        06.64.80.54.27
                      </CustomFooterLink>
                    </Typography>
                  </Box>
                  <Typography sx={{ textAlign: "justify", mt: 2 }}>
                    Forte d'une formation en administration des affaires de
                    l'ESSEC Business School et d'une spécialisation en
                    Management des Systèmes d'Information de Télécom Paris,
                    Julie apporte son expertise commerciale et marketing à
                    Octopus QR. Sa passion pour le secteur de l'IT, combinée à
                    des expériences professionnelles variées, lui permet de
                    piloter efficacement le développement commercial de
                    l'entreprise.
                  </Typography>
                </Box>
                <Box
                  sx={{
                    minWidth: "330px",
                    maxWidth: "500px",
                    pt: 4,
                  }}
                >
                  <Avatar
                    alt="Corentin Lizy"
                    src={corentinImage}
                    sx={styles.avatar}
                  />
                  <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                    <PersonIcon />
                    <Typography variant="h5" sx={{ ml: 1 }}>
                      Corentin LIZY
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                    <MailOutlineIcon />
                    <Typography variant="subtitle1" sx={{ ml: 1 }}>
                      <CustomFooterLink
                        href="mailto:corentin.lizy@octopusqr.com"
                        sx={{ color: "black" }}
                      >
                        corentin.lizy@octopusqr.com
                      </CustomFooterLink>
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                    <PhoneIcon />
                    <Typography variant="subtitle1" sx={{ ml: 1 }}>
                      <CustomFooterLink
                        href="tel:0762749946"
                        sx={{ color: "black" }}
                      >
                        07.62.74.99.46
                      </CustomFooterLink>
                    </Typography>
                  </Box>
                  <Typography sx={{ textAlign: "justify", mt: 2 }}>
                    Titulaire d'un master MIAGE de l'Université Côte d'Azur,
                    Corentin est le cerveau technique derrière Octopus QR. Il
                    est fasciné par l'évolution rapide du domaine de
                    l'informatique et met à profit ses compétences de
                    développement pour faire avancer le site web et le
                    générateur de QR Codes de l'entreprise. Son expérience en
                    apprentissage chez Amadeus et son travail avec la Junior
                    Entreprise soulignent son expertise technique.
                  </Typography>
                </Box>
              </motion.div>
            </Box>
          </Card>
        </Box>
      </Box>
      <Footer />
    </>
  );
}
