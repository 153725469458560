import api from "../../axios";

export const postFileQr = async (iUserId, iQRCodeId, iData) => {
  const aResponse = await api.post(
    `/users/${iUserId}/qrcodes/${iQRCodeId}/files`,
    iData,
    { timeout: 10 * 60 * 1000 }
  );
  return aResponse.data;
};

export const putFileQr = async (iUserId, iQRCodeId, iFileId, iData) => {
  const aResponse = await api.put(
    `/users/${iUserId}/qrcodes/${iQRCodeId}/files/${iFileId}`,
    iData,
    { timeout: 10 * 60 * 1000 }
  );
  return aResponse.data;
};
