import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useNavigate } from "react-router-dom";

import Loading from "../../../assets/adr_loading.gif";

import { store } from "../../../index";
import allActions from "../../../redux/actions/allActions";
import { OctopusQRButton, UploaderBox } from "../../../utils/styled/Styled";
import { styles } from "./UploadLogo.style";
import { STORAGE_USER_ID } from "../../../utils/constants/Constants";
import { uploadLogo } from "../../../api/user/logo/uploadLogo";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

export default function UploadLogo() {
  const fileTypes = ["JPG", "PNG", "JPEG"];
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [hasError, setHasError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    navigate("/profile");
  };

  const resetErrors = () => {
    setHasError(false);
    setErrorText("");
  };

  const handleChange = (file) => {
    resetErrors();
    if (file.size > 2000000) {
      setHasError(true);
      setErrorText("Fichier trop volumineux, 2Mo maximum autorisé");
      return;
    }
    setFile(file);
  };

  const handleLogoUpload = async () => {
    setIsLoading(true);
    resetErrors();

    const aLogoData = new FormData();
    aLogoData.append("file", file);

    try {
      const aResponse = await uploadLogo(
        localStorage.getItem(STORAGE_USER_ID),
        aLogoData
      );
      store.dispatch(allActions.userActions.updateLogo(aResponse.logoPath));
      navigate("/profile");
    } catch {
      setHasError(true);
      setErrorText("Une erreur est survenue, veuillez réessayer");
      setIsLoading(false);
    }
  };

  const aUploadBox = (
    <UploaderBox style={styles.center}>
      <CloudUploadIcon style={styles.centeredIcon} />
      <Typography sx={{ mt: 5, mr: 2, ml: 2 }}>
        <strong style={{ textDecoration: "underline" }}>Sélectionner</strong> ou
        glisser pour importer un fichier
      </Typography>
      <Typography sx={{ mt: 3, mr: 2, ml: 2, mb: 4 }}>
        Accepté : PNG, JPG, JPEG
      </Typography>
    </UploaderBox>
  );
  return (
    <Dialog open={true} onClose={handleClose}>
      <DialogTitle>
        <IconButton
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 1,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <Typography style={styles.title}>Importer un logo</Typography>
      </DialogTitle>
      <DialogContent>
        <FileUploader
          style={styles.uploader}
          children={aUploadBox}
          handleChange={handleChange}
          name="file"
          types={fileTypes}
          label="Sélectionner ou glisser déposer un fichier."
          hoverTitle="Déposez ici"
        />
        {file ? (
          <>
            <p style={styles.text}>Fichier importé : {file.name}</p>
            <OctopusQRButton style={styles.center} onClick={handleLogoUpload}>
              Ajouter
            </OctopusQRButton>
          </>
        ) : (
          <p style={styles.text}>Aucun fichier pour le moment</p>
        )}
        {isLoading ? (
          <img src={Loading} style={styles.center} height="40" alt="Loading" />
        ) : null}
        {hasError ? <p style={styles.error}>{errorText}</p> : null}
      </DialogContent>
    </Dialog>
  );
}
