import { useState } from "react";

import { styles } from "../QRCodes.style";

import {
  QR_CODE_TYPE,
  DYNAMIC_QR_TYPE,
  SITE_BASE_URL,
  USER_ROLE,
} from "../../../../utils/constants/Constants";
import { Box, IconButton, Popover, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import DownloadingIcon from "@mui/icons-material/Downloading";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import { useNavigate } from "react-router-dom";
import DoneIcon from "@mui/icons-material/Done";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import DownloadQRCode from "../DownloadQRCode";

export default function QRCodeAction({ qrCode, user }) {
  const [isCopied, setIsCopied] = useState(false);
  // Popover content
  // Download popover
  const [anchorDownload, setAnchorDownload] = useState(null);
  const navigateTo = useNavigate();
  const openDonwload = Boolean(anchorDownload);

  const handleDownloadPopoverOpen = (iEvent) => {
    setAnchorDownload(iEvent.currentTarget);
  };

  const handleDownloadPopoverClose = () => {
    setAnchorDownload(null);
  };

  // Edit Popover
  const [anchorEdit, setAnchorEdit] = useState(null);
  const openEdit = Boolean(anchorEdit);

  const handleEditPopoverOpen = (iEvent) => {
    setAnchorEdit(iEvent.currentTarget);
  };

  const handleEditPopoverClose = () => {
    setAnchorEdit(null);
  };

  // Stats Popover
  const [anchorStats, setAnchorStats] = useState(null);
  const openStats = Boolean(anchorStats);

  const handleStatsPopoverOpen = (iEvent) => {
    setAnchorStats(iEvent.currentTarget);
  };

  const handleStatsPopoverClose = () => {
    setAnchorStats(null);
  };

  // Answers Popover
  const [anchorAnswers, setAnchorAnswers] = useState(null);
  const openAnswers = Boolean(anchorAnswers);

  const handleAnswersPopoverOpen = (iEvent) => {
    setAnchorAnswers(iEvent.currentTarget);
  };

  const handleAnswersPopoverClose = () => {
    setAnchorAnswers(null);
  };

  // Copy Popover
  const [anchorCopy, setAnchorCopy] = useState(null);
  const openCopy = Boolean(anchorCopy);

  const handleCopyPopoverOpen = (iEvent) => {
    setAnchorCopy(iEvent.currentTarget);
  };

  const handleCopyPopoverClose = () => {
    setAnchorCopy(null);
  };

  // QR Code content
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);

  const closeDownloadModal = () => {
    setIsDownloadModalOpen(false);
  };

  if (!user.isSubscribed && user.role !== USER_ROLE.PRIVATE) {
    return;
  }

  return (
    <Box sx={styles.downIconContainer}>
      {/* DOWNLOAD PART */}
      <IconButton
        onClick={() => setIsDownloadModalOpen(true)}
        sx={styles.iconButton}
        onMouseEnter={handleDownloadPopoverOpen}
        onMouseLeave={handleDownloadPopoverClose}
      >
        <DownloadingIcon sx={styles.icon} />
      </IconButton>
      <Popover
        sx={styles.popOver}
        open={openDonwload}
        anchorEl={anchorDownload}
        anchorOrigin={styles.popOverAnchor}
        transformOrigin={styles.popOverTransform}
        onClose={handleDownloadPopoverClose}
        disableRestoreFocus
      >
        <Typography variant="overline" sx={{ m: 2 }}>
          Télécharger
        </Typography>
      </Popover>
      <DownloadQRCode
        open={isDownloadModalOpen}
        onClose={closeDownloadModal}
        qrCode={qrCode}
      />

      {!DYNAMIC_QR_TYPE.includes(qrCode.type) && (
        <>
          {/* CUSTOMIZE PART */}
          <IconButton
            onMouseEnter={handleEditPopoverOpen}
            onMouseLeave={handleEditPopoverClose}
            sx={styles.iconButton}
            onClick={() => {
              navigateTo(`/qrcodes/${qrCode._id}?viewType=customize`);
            }}
          >
            <QrCode2Icon sx={styles.icon} />
          </IconButton>
          <Popover
            sx={styles.popOver}
            open={openEdit}
            anchorEl={anchorEdit}
            anchorOrigin={styles.popOverAnchor}
            transformOrigin={styles.popOverTransform}
            onClose={handleEditPopoverClose}
            disableRestoreFocus
          >
            <Typography variant="overline" sx={{ m: 2 }}>
              Personnaliser le QR Code
            </Typography>
          </Popover>
        </>
      )}

      {DYNAMIC_QR_TYPE.includes(qrCode.type) && (
        <>
          {/* EDIT PART */}
          <IconButton
            onMouseEnter={handleEditPopoverOpen}
            onMouseLeave={handleEditPopoverClose}
            sx={styles.iconButton}
            onClick={() => {
              navigateTo(`/qrcodes/${qrCode._id}?viewType=edit`);
            }}
          >
            <EditIcon sx={styles.icon} />
          </IconButton>
          <Popover
            sx={styles.popOver}
            open={openEdit}
            anchorEl={anchorEdit}
            anchorOrigin={styles.popOverAnchor}
            transformOrigin={styles.popOverTransform}
            onClose={handleEditPopoverClose}
            disableRestoreFocus
          >
            <Typography variant="overline" sx={{ m: 2 }}>
              Éditer / Accéder au contenu
            </Typography>
          </Popover>

          {/* Stats PART  */}
          <IconButton
            onMouseEnter={handleStatsPopoverOpen}
            onMouseLeave={handleStatsPopoverClose}
            sx={styles.iconButton}
            onClick={() => {
              navigateTo(`/qrcodes/${qrCode._id}?viewType=statistics`);
            }}
          >
            <QueryStatsIcon sx={styles.icon} />
          </IconButton>
          <Popover
            sx={styles.popOver}
            open={openStats}
            anchorEl={anchorStats}
            anchorOrigin={styles.popOverAnchor}
            transformOrigin={styles.popOverTransform}
            onClose={handleStatsPopoverClose}
            disableRestoreFocus
          >
            <Typography variant="overline" sx={{ m: 2 }}>
              Statistiques
            </Typography>
          </Popover>
        </>
      )}

      {/* ANSWER PART  */}
      {qrCode.type === QR_CODE_TYPE.FORM && (
        <>
          <IconButton
            onMouseEnter={handleAnswersPopoverOpen}
            onMouseLeave={handleAnswersPopoverClose}
            sx={styles.iconButton}
            onClick={() => {
              navigateTo(`/qrcodes/${qrCode._id}?viewType=answers`);
            }}
          >
            <QuestionAnswerIcon sx={styles.icon} />
          </IconButton>
          <Popover
            sx={styles.popOver}
            open={openAnswers}
            anchorEl={anchorAnswers}
            anchorOrigin={styles.popOverAnchor}
            transformOrigin={styles.popOverTransform}
            onClose={handleAnswersPopoverClose}
            disableRestoreFocus
          >
            <Typography variant="overline" sx={{ m: 2 }}>
              Réponses
            </Typography>
          </Popover>
        </>
      )}

      {/* COPY PART */}
      {qrCode.type !== QR_CODE_TYPE.WIFI && (
        <>
          <IconButton
            onMouseEnter={handleCopyPopoverOpen}
            onMouseLeave={handleCopyPopoverClose}
            sx={styles.iconButton}
            onClick={() => {
              navigator.clipboard.writeText(
                `${SITE_BASE_URL}render/${qrCode._id}`
              );
              setIsCopied(true);
              setTimeout(() => {
                setIsCopied(false);
              }, 1500);
            }}
          >
            {isCopied ? (
              <DoneIcon sx={styles.icon} />
            ) : (
              <ContentCopyIcon sx={styles.icon} />
            )}
          </IconButton>
          <Popover
            sx={styles.popOver}
            open={openCopy}
            anchorEl={anchorCopy}
            anchorOrigin={styles.popOverAnchor}
            transformOrigin={styles.popOverTransform}
            onClose={handleCopyPopoverClose}
            disableRestoreFocus
          >
            <Typography variant="overline" sx={{ m: 2 }}>
              Copier le lien
            </Typography>
          </Popover>
        </>
      )}
    </Box>
  );
}
