import { useEffect, useState } from "react";
import { sortArrayByPosition } from "../../../../../utils/Utils";
import { Box, Typography, TextField } from "@mui/material";
import { styles } from "../../Render.style";
import Loading from "../../../../../components/loading/Loading";
import DescriptionDisplay from "./description/DescriptionDisplay";
import LogoDisplay from "./logo/LogoDisplay";
import LinksAndFilesDisplay from "./linksAndFiles/LinksAndFilesDisplay";
import SocialNetworksDisplay from "./socialNetworks/SocialNetworksDisplay";
import { BlackButton } from "../../../../../utils/styled/Styled";
import { patchCustomPageMail } from "../../../../../api/qrCodes/customPage/customPageQrHandler";
import DoneIcon from "@mui/icons-material/Done";

const buildLinksAndFilesArray = (iLinks, iFiles) => {
  let aLinksAndFilesArray = [];

  iLinks.forEach((aLink) => {
    aLinksAndFilesArray.push({
      id: aLink._id,
      isLink: true,
      name: aLink.name,
      path: aLink.path,
      position: aLink.position,
    });
  });

  iFiles.forEach((aFile) => {
    aLinksAndFilesArray.push({
      id: aFile._id,
      isLink: false,
      name: aFile.name,
      path: aFile.path,
      type: aFile.type,
      position: aFile.position,
    });
  });

  sortArrayByPosition(aLinksAndFilesArray);

  return aLinksAndFilesArray;
};

export default function CustomPageDisplay({ user, qrCode, qrContent }) {
  const [customPageContent, setCustomPageContent] = useState();
  const [customPage] = useState(qrContent?.customPage);
  const [email, setEmail] = useState("");
  const defaultMailError = {
    errorText: "",
    hasError: false,
  };
  const [mailError, setMailError] = useState(defaultMailError);
  const [hasBeenAdded, setHasBeenAdded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const aLinksAndFiles = buildLinksAndFilesArray(
      qrContent.links,
      qrContent.files
    );
    setCustomPageContent(
      sortArrayByPosition([
        {
          content: <LogoDisplay picture={customPage?.picture} />,
          position: customPage?.order?.logo ? customPage?.order?.logo : 0,
        },
        {
          content: <DescriptionDisplay customPage={customPage} />,
          position: customPage?.order?.description
            ? customPage?.order?.description
            : 0,
        },
        {
          content: (
            <LinksAndFilesDisplay
              linksAndFiles={aLinksAndFiles}
              customPage={customPage}
            />
          ),
          position: customPage?.order?.linksAndFiles
            ? customPage?.order?.linksAndFiles
            : 0,
        },
        {
          content: (
            <SocialNetworksDisplay
              socialNetworks={qrContent?.socialNetworks}
              customPage={customPage}
            />
          ),
          position: customPage?.order?.socialNetworks
            ? customPage?.order?.socialNetworks
            : 0,
        },
      ])
    );
  }, [qrContent, customPage]);

  const buttonStyle = {
    color: customPage?.buttonFontColor,
    backgroundColor: customPage?.buttonColor,
    fontFamily: customPage?.buttonFontType,
    fontSize: customPage?.buttonFontSize,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "10px auto",
    width: "100%",
  };

  if (!customPageContent) {
    return <Loading />;
  }

  const subscribeUser = async () => {
    setMailError(defaultMailError);
    setIsLoading(true);
    const anEmailRegex = /\S+@\S+\.\S+/;
    if (!anEmailRegex.test(email)) {
      setMailError({
        errorText: "Adresse mail incorrect",
        hasError: true,
      });
      setIsLoading(false);
      return;
    }
    try {
      await patchCustomPageMail(user._id, qrCode._id, customPage._id, {
        mail: email,
      });
      setHasBeenAdded(true);
    } catch (iError) {
      if (iError.status === 400) {
        setMailError({
          errorText: "Cette adresse mail est déjà abonnée à cette page.",
          hasError: true,
        });
      } else {
        setMailError({
          errorText: "Une erreur est survenue, veuillez réessayer.",
          hasError: true,
        });
      }
    }
    setIsLoading(false);
  };

  return (
    <Box
      sx={{
        backgroundColor: customPage.backgroundColor,
        height: "100vh",
      }}
    >
      <Box sx={{ ...styles.flexColumnCenter, width: "350px", margin: "auto" }}>
        {customPageContent.map((aElement, index) => {
          return (
            <Box key={index} sx={{ my: "10px" }}>
              {aElement.content}
            </Box>
          );
        })}
        {customPage.hasPush && !hasBeenAdded && (
          <Box sx={{ my: 2, p: 1 }}>
            <Typography>
              Pour recevoir les actualités de la page, veuillez entrer votre
              adresse email
            </Typography>
            <TextField
              sx={{ ...styles.field, width: "100%", my: 1 }}
              label="Adresse mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              helperText={mailError.errorText}
              error={mailError.hasError}
            />
            <BlackButton
              disabled={email.length === 0}
              sx={{ ...buttonStyle, width: "150px", mt: 2 }}
              onClick={subscribeUser}
            >
              S'abonner
            </BlackButton>
          </Box>
        )}
        {hasBeenAdded && (
          <Box sx={styles.flexColumnCenter}>
            <DoneIcon />
            <Typography variant="overline" sx={{ mt: 1 }}>
              Vous êtes abonné aux actualités de cette page
            </Typography>
          </Box>
        )}
        {isLoading && <Loading />}
      </Box>
    </Box>
  );
}
