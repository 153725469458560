export const styles = {
  field: {
    background: "white",
    [`& fieldset`]: {
      borderRadius: "20px",
      border: "1px",
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    },
  },

  uploader: {
    marginTop: "40px",
    width: "80%",
  },

  accordionStyle: {
    p: "4px",
    width: "88%",
    boxShadow: "rgba(0, 0, 0, 0.24) 1px 2px 5px",
  },

  flexColumnCenter: {
    mb: 3,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },

  card: {
    mt: 2,
    mb: 6,
    p: 2,
    width: { lg: "80%", md: "70%", xs: "95%" },
    minHeight: { lg: "950px", md: "auto" },
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    borderRadius: "20px",
    textAlign: "center",
  },

  menuProps: {
    PaperProps: {
      style: {
        maxHeight: 48 * 4.5 + 10,
        width: 250,
      },
    },
  },

  formControl: {
    mt: 5,
  },

  textCard: {
    width: "250px",
    margin: "auto",
    minHeight: "300px",
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    borderRadius: "20px",
    textAlign: "center",
  },

  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  accordionTitle: {
    fontSize: "15px",
  },

  accordionSubTitle: {
    margin: "auto",
    fontStyle: "italic",
    mb: 2,
  },

  colorPicker: {
    margin: "auto",
    height: "120px",
    marginBottom: "10px",
  },

  button: {
    mt: 3,
    mb: 3,
    width: "200px",
  },

  deviceBox: {
    mx: 1,
    mt: 5,
    userSelect: "none",
    overflow: "auto",
    maxWidth: "100%",
  },

  backArrow: {
    display: "flex",
    justifyContent: "start",
    m: { lg: 2, md: 1, xs: 0 },
  },

  backArrowIcon: {
    color: "#58738D",
    fontSize: "30px",
  },

  titleContainer: {
    marginTop: { xs: 3, lg: -7, md: -3 },
    marginBottom: 4,
  },

  errorText: {
    color: "#8B0000",
    fontWeight: "bold",
    fontSize: 12,
  },
};
