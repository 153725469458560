import { HexColorInput, HexColorPicker } from "react-colorful";
import { styles } from "../CustomPageContent.style";
import { capitalizeFirstLetter, RGBToHex } from "../../../../../../utils/Utils";
import { useState } from "react";

import {
  SOCIAL_NETWORK_ICON_MAP,
  SOCIAL_NETWORK_LINK_MAP,
  SOCIAL_NETWORK_ARRAY,
  DEVICE_SORTABLE_INDEX,
  FONT_SIZE,
} from "../../../../../../utils/constants/Constants";
import { OctopusQRButton } from "../../../../../../utils/styled/Styled";

import {
  Box,
  Typography,
  AccordionSummary,
  Divider,
  Accordion,
  AccordionDetails,
  Select,
  MenuItem,
  FormControl,
  TextField,
  IconButton,
  InputLabel,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";

export default function CustomSocialNetworks({
  socialNetworks,
  setSocialNetworks,
  items,
  setItems,
  customization,
  setCustomization,
}) {
  const [expanded, setExpanded] = useState(false);

  const handleChangeExpanded = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [pseudonym, setPseudonym] = useState("");
  const [socialNetworkSelected, setSocialNetworkSelected] =
    useState("INSTAGRAM");

  const updateSocialNetworkSelected = (iEvent) => {
    setSocialNetworkSelected(iEvent.target.value);
  };

  const updatePseudoym = (iEvent) => {
    setPseudonym(iEvent.target.value);
  };

  const updateFontSize = (iEvent) => {
    setCustomization({
      ...customization,
      socialNetworksSize: iEvent.target.value,
    });
  };

  const updateSocialNetworksColor = (iEvent) => {
    const aRgb = iEvent.target.querySelector("div").style.backgroundColor;
    const aHex = RGBToHex(aRgb);
    setCustomization({ ...customization, socialNetworksColor: aHex });
  };

  const updateSocialNetworksColorFromInput = (iEvent) => {
    setCustomization({ ...customization, socialNetworksColor: iEvent });
  };

  const handleAddSocialNetwork = () => {
    const aSocialNetworks = Array.from(socialNetworks);
    aSocialNetworks.push({
      path: SOCIAL_NETWORK_LINK_MAP[socialNetworkSelected] + pseudonym,
      type: socialNetworkSelected,
    });

    const aItems = Array.from(items);
    aItems.find(
      (aItem) => aItem.id === DEVICE_SORTABLE_INDEX.SOCIAL_NETWORKS
    ).content = aSocialNetworks;
    setItems(aItems);
    setSocialNetworks(aSocialNetworks);
    setPseudonym("");
    setSocialNetworkSelected("INSTAGRAM");
  };

  const deleteSocialNetwork = (iIndex) => {
    const aSocialNetworks = Array.from(socialNetworks);
    aSocialNetworks.splice(iIndex, 1);
    const aItems = Array.from(items);
    aItems.find(
      (aItem) => aItem.id === DEVICE_SORTABLE_INDEX.SOCIAL_NETWORKS
    ).content = aSocialNetworks;
    setItems(aItems);
    setSocialNetworks(aSocialNetworks);
  };

  return (
    <>
      <Divider variant="middle" />
      <Typography variant="overline">Réseaux sociaux</Typography>
      <Divider variant="middle" sx={{ mb: 3 }} />
      <Box sx={styles.flexColumnCenter}>
        <Accordion
          sx={styles.accordionStyle}
          expanded={expanded === "panel1"}
          onChange={handleChangeExpanded("panel1")}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography sx={styles.accordionTitle}>
              Ajouter un réseau social
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Select
              labelId="font-label"
              value={socialNetworkSelected}
              fullWidth
              sx={styles.field}
              MenuProps={styles.menuProps}
              onChange={updateSocialNetworkSelected}
              label="Votre choix"
            >
              {SOCIAL_NETWORK_ARRAY.map((aSocialNetwork) => {
                return (
                  <MenuItem value={aSocialNetwork} key={aSocialNetwork}>
                    <Box sx={styles.flexCenter}>
                      {SOCIAL_NETWORK_ICON_MAP[aSocialNetwork]}

                      <Typography sx={{ ml: 2 }}>
                        {capitalizeFirstLetter(aSocialNetwork)}
                      </Typography>
                    </Box>
                  </MenuItem>
                );
              })}
            </Select>
            <Box
              sx={{
                mt: 3,
                display: { xs: "block", md: "flex" },
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="overline">
                {SOCIAL_NETWORK_LINK_MAP[socialNetworkSelected]}
              </Typography>
              <FormControl sx={styles.pseudonymForm}>
                <TextField
                  label="Pseudonyme"
                  variant="outlined"
                  value={pseudonym}
                  onChange={updatePseudoym}
                  sx={styles.field}
                />
              </FormControl>
            </Box>
          </AccordionDetails>
          <OctopusQRButton sx={styles.button} onClick={handleAddSocialNetwork}>
            Ajouter
          </OctopusQRButton>
          <Box sx={{ mx: 3, my: 1 }}>
            {socialNetworks.map((aSocialNetwork, index) => {
              return (
                <Box sx={{ my: 1 }} key={index}>
                  <Box sx={styles.flexCenter}>
                    {SOCIAL_NETWORK_ICON_MAP[aSocialNetwork.type]}
                    <Divider orientation="vertical" sx={{ mx: 1 }} />
                    <TextField
                      value={aSocialNetwork.path}
                      fullWidth
                      sx={styles.field}
                      disabled
                    ></TextField>
                    <IconButton
                      onClick={() => {
                        deleteSocialNetwork(index);
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Accordion>
        <Accordion
          sx={styles.accordionStyle}
          expanded={expanded === "panel2"}
          onChange={handleChangeExpanded("panel2")}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography sx={styles.accordionTitle}>Personnalisation</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl sx={{ width: "88%", mt: 3 }}>
              <InputLabel id="font-label">Taille</InputLabel>
              <Select
                labelId="font-label"
                sx={styles.field}
                MenuProps={styles.menuProps}
                value={customization.socialNetworksSize}
                onChange={updateFontSize}
                label="Votre choix"
              >
                {FONT_SIZE.map((aFontSize) => {
                  return (
                    <MenuItem value={aFontSize} key={aFontSize}>
                      <Typography>{aFontSize}</Typography>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <Box sx={{ mt: 3 }}>
              <Typography variant="overline">Couleur texte</Typography>
              <HexColorPicker
                color={customization.socialNetworksColor}
                onMouseUp={updateSocialNetworksColor}
                style={styles.colorPicker}
              />
              <HexColorInput
                color={customization.socialNetworksColor}
                onChange={updateSocialNetworksColorFromInput}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
}
