import React from "react";
import { useNavigate } from "react-router-dom";

// import { OctopusQRButton, CustomLink } from "../../../utils/styled/Styled.js";

import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import WarningIcon from "@mui/icons-material/Warning";

const styles = {
  mainBox: {
    textAlign: "justify",
  },
  title: {
    textAlign: "center",
    marginBottom: "10px",
    marginTop: "20px",
    fontSize: "25px",
    fontWeight: "bold",
  },
  center: {
    display: "block",
    margin: "20px auto",
  },
};

export default function Welcome() {
  const navigate = useNavigate();

  const handleClose = () => {
    navigate("/");
  };

  return (
    <Dialog open={true} onClose={handleClose} style={styles.mainBox}>
      <DialogTitle>
        <IconButton
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 1,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <Typography style={styles.title}>Bienvenue !</Typography>
      </DialogTitle>
      <DialogContent sx={{ mb: 3 }}>
        <Typography sx={{ display: "flex", alignItems: "center" }}>
          <WarningIcon sx={{ mr: 4, color: "#58738D" }} />{" "}
          <strong>
            Un lien de vérification vous a été envoyé par mail. Il est
            nécessaire de vérifier votre email afin d'accéder aux services.
            Vérifiez bien vos spams.{" "}
          </strong>
        </Typography>
        <Typography>
          <br />
          <br />
          Merci pour votre inscription sur OctopusQR, vous bénéficiez d'
          <strong>une semaine offerte</strong> sans besoin de renseigner un
          moyen de paiement.
          <br />
          <br />
          Un abonnement mensuel sera nécessaire par la suite afin d'accéder aux
          services.
          <br />
          <br />
          Vous pouvez naviguer librement sur le site afin de créer vos QRCodes
          personnalisés.
        </Typography>
      </DialogContent>
      {/* <CustomLink
        style={styles.center}
        href="https://andromedefiles.blob.core.windows.net/files/Tutoriel_OctopusQR.pdf"
        target="_blank"
      >
        <OctopusQRButton style={styles.button}>
          Accéder au tutoriel
        </OctopusQRButton>
      </CustomLink> */}
    </Dialog>
  );
}
