import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Box, TextField, Divider, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styles } from "../../CustomPageContent.style";
import {
  DEVICE_SORTABLE_INDEX,
  FILE_ICON_MAP,
} from "../../../../../../../utils/constants/Constants";
import LinkIcon from "@mui/icons-material/Link";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

export default function LinksAndFilesSortable({
  items,
  setItems,
  deviceItems,
  setDeviceItems,
}) {
  const grid = 2;
  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: "transparent",
    borderRadius: "30px",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver) => ({
    borderRadius: "30px",
    background: isDraggingOver ? "#F9F9F9" : "white",
    padding: grid,
    margin: "auto",
    width: "95%",
  });

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    result.forEach((aItem, aIndex) => {
      aItem.position = aIndex;
    });
    return result;
  };

  const deleteItem = (iIndex) => {
    let aItems = Array.from(items);
    aItems.splice(iIndex, 1);
    const aDeviceItems = Array.from(deviceItems);
    aDeviceItems.find(
      (aItem) => aItem.id === DEVICE_SORTABLE_INDEX.LINKS_AND_FILES
    ).content = aItems;
    setDeviceItems(aDeviceItems);
    setItems(aItems);
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const aNewItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );
    const aDeviceItems = Array.from(deviceItems);
    aDeviceItems.find(
      (aItem) => aItem.id === DEVICE_SORTABLE_INDEX.LINKS_AND_FILES
    ).content = aNewItems;
    setDeviceItems(aDeviceItems);
    setItems(aNewItems);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {items.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                  >
                    <>
                      {item.isLink ? (
                        <Box key={index}>
                          <Box sx={styles.flexCenter}>
                            <DragIndicatorIcon />
                            <Divider orientation="vertical" sx={{ mr: 1 }} />
                            <LinkIcon />
                            <Divider orientation="vertical" sx={{ mr: 1 }} />
                            <TextField
                              value={`${item.name} | ${item.path}`}
                              fullWidth
                              sx={styles.field}
                              disabled
                            ></TextField>
                            <IconButton
                              onClick={() => {
                                deleteItem(index);
                              }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </Box>
                        </Box>
                      ) : (
                        <Box sx={styles.flexCenter} key={index}>
                          <DragIndicatorIcon />
                          <Divider orientation="vertical" sx={{ mr: 1 }} />
                          {FILE_ICON_MAP[item.type]}
                          <Divider orientation="vertical" sx={{ mr: 1 }} />
                          <TextField
                            value={`${item.name} | ${item.fileName}`}
                            fullWidth
                            sx={styles.field}
                            disabled
                          ></TextField>
                          <IconButton
                            onClick={() => {
                              deleteItem(index);
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Box>
                      )}
                    </>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
