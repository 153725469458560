import React from "react";
import { motion } from "framer-motion";

import logo from "../../../assets/home/logo.png";
import premium from "../../../assets/home/premium.png";
import unique from "../../../assets/home/unique.png";

import { Grid, Typography, Box, Card } from "@mui/material";

const styles = {
  titleBlueBold: {
    fontSize: "34px",
    color: "#58738D",
    fontWeight: "bold",
    textAlign: "center",
    ml: 1,
  },
  titleContainer: {
    py: 3,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: { xs: "column", md: "row" },
  },
  title: {
    fontSize: "34px",
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  blueBoldText: {
    color: "#58738D",
    fontWeight: "bold",
    fontSize: "24px",
    pb: 2,
  },
  card: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    width: { xs: "300px", md: "340px" },
    height: { xs: "320px", md: "350px" },
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
    borderRadius: "30px",
    p: 3,
  },
};

const cardVariant = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { delay: 0.5, duration: 0.8 } },
};

const logoVariant = {
  hidden: { opacity: 0, scale: 0.5, y: 0 },
  visible: {
    opacity: 1,
    scale: 0.95,
    y: [-5, 5, -5, 5, -5],
    transition: {
      y: {
        repeat: Infinity,
        repeatType: "reverse",
        duration: 5,
        ease: "easeInOut",
      },
      delay: 0.3,
      duration: 1,
    },
  },
};

export default function HomeHeader() {
  return (
    <motion.div
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1, ease: "easeInOut" }}
    >
      <Box sx={styles.titleContainer}>
        <Typography variant="h1" sx={styles.title}>
          OctopusQR -{" "}
        </Typography>
        <Typography variant="h1" sx={styles.titleBlueBold}>
          Créez, Scannez, Connectez
        </Typography>
      </Box>
      <Grid
        container
        sx={{
          py: 3,
          display: "flex",
          alignItems: "center",
          gap: { xs: 3, md: 0 },
        }}
      >
        <Grid item xs={12} md={12} lg={4}>
          <Box sx={styles.flexCenter}>
            <motion.div
              variants={cardVariant}
              initial="hidden"
              animate="visible"
            >
              <Card sx={styles.card}>
                <img src={unique} alt="unique" width="120" />
                <Box sx={{ textAlign: "center" }}>
                  <Typography sx={styles.blueBoldText} variant="h2">
                    Créez des QR Codes Exceptionnels{" "}
                  </Typography>
                  <Typography sx={{ textAlign: "justify" }}>
                    Avec des formulaires ingénieusement intégrés, une
                    personnalisation de haut niveau, des analyses perspicaces et
                    des pages tout-en-un pour une efficacité optimale !
                  </Typography>
                </Box>
              </Card>
            </motion.div>
          </Box>
        </Grid>
        <Grid
          item
          xs={0}
          md={12}
          lg={4}
          sx={{ display: { xs: "none", md: "block" } }}
          gap={2}
        >
          <Box sx={styles.flexCenter}>
            <motion.img
              src={logo}
              alt="logo"
              width="230"
              variants={logoVariant}
              initial="hidden"
              animate="visible"
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={12} lg={4}>
          <Box sx={styles.flexCenter}>
            <motion.div
              variants={cardVariant}
              initial="hidden"
              animate="visible"
            >
              <Card sx={styles.card}>
                <img src={premium} alt="premium" width="120" />
                <Box sx={{ textAlign: "center" }}>
                  <Typography sx={styles.blueBoldText} variant="h2">
                    Service Client Élite{" "}
                  </Typography>
                  <Typography sx={{ textAlign: "justify" }}>
                    Bénéficiez d’une assistance experte spécialement dédiée à
                    catalyser votre succès. Pour OctopusQR, le créateur de QR
                    Codes Premium par excellence.
                  </Typography>
                </Box>
              </Card>
            </motion.div>
          </Box>
        </Grid>
      </Grid>
    </motion.div>
  );
}
