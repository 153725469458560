import { useState } from "react";

import { styles } from "../CustomPageContent.style";

import CustomLinks from "./customLinks/CustomLinks";
import CustomFiles from "./customFiles/CustomFiles";
import LinksAndFilesCustomization from "./linksAndFilesCustomization/LinksAndFilesCustomization";

import {
  Box,
  Typography,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LinksAndFilesSortable from "./linksAndFilesSortable/LinksAndFilesSortable";

export default function CustomLinksAndFiles({
  linksAndFiles,
  setLinksAndFiles,
  items,
  setItems,
  customization,
  setCustomization,
}) {
  const [expanded, setExpanded] = useState(false);

  const handleChangeExpanded = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [addLink, setAddLink] = useState(true);

  const changeAddLink = () => {
    setAddLink(!addLink);
  };

  return (
    <>
      <Divider variant="middle" />
      <Typography variant="overline">Liens et fichiers</Typography>
      <Divider variant="middle" sx={{ mb: 3 }} />

      <Box sx={styles.flexColumnCenter}>
        <Accordion
          sx={styles.accordionStyle}
          expanded={expanded === "panel1"}
          onChange={handleChangeExpanded("panel1")}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography sx={styles.accordionTitle}>
              Ajouter un lien ou un fichier
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <RadioGroup
              row
              sx={{ justifyContent: "space-evenly", mb: 2 }}
              onChange={changeAddLink}
              value={addLink}
            >
              <FormControlLabel
                value={true}
                control={<Radio />}
                label="Ajouter un lien"
              />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label="Ajouter un fichier"
              />
            </RadioGroup>
            {addLink ? (
              <CustomLinks
                linksAndFiles={linksAndFiles}
                setLinksAndFiles={setLinksAndFiles}
                items={items}
                setItems={setItems}
              />
            ) : (
              <CustomFiles
                linksAndFiles={linksAndFiles}
                setLinksAndFiles={setLinksAndFiles}
                items={items}
                setItems={setItems}
              />
            )}
            <LinksAndFilesSortable
              items={linksAndFiles}
              setItems={setLinksAndFiles}
              deviceItems={items}
              setDeviceItems={setItems}
            />
          </AccordionDetails>
        </Accordion>
        <LinksAndFilesCustomization
          expanded={expanded}
          handleChangeExpanded={handleChangeExpanded}
          customization={customization}
          setCustomization={setCustomization}
        />
      </Box>
    </>
  );
}
