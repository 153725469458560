import { Box, Typography } from "@mui/material";

export default function TextDisplay({ qrContent }) {
  return (
    <Box
      sx={{
        display: "flex",
        margin: "auto",
        justifyContent: "center",
        width: "100%",
        height: "100vh",
        backgroundColor: qrContent?.backgroundColor,
      }}
    >
      <Box sx={{ width: "330px", whiteSpace: "pre-line" }}>
        <Typography
          sx={{
            textAlign: "center",
            color: qrContent.color,
            fontSize: qrContent.fontSize,
            fontFamily: qrContent.fontType,
          }}
        >
          {qrContent.content}
        </Typography>
      </Box>
    </Box>
  );
}
