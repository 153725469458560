import { useState } from "react";

import Loading from "../../assets/adr_loading.gif";

import { postMailVerification } from "../../api/user/mail/postMailVerification";
import { OctopusQRButton } from "../../utils/styled/Styled.js";
import { styles } from "./AskToVerifyEmailCard.style";
import Footer from "../footer/Footer";
import NavMenu from "../navMenu/NavMenu";

import { Grid, Card, Typography, Box } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import CancelIcon from "@mui/icons-material/Cancel";

export default function AskToVerifyEmailCard({ user }) {
  const errorInitialState = {
    hasError: false,
    errorText: "",
  };
  const [isLoading, setIsLoading] = useState();
  const [hasBeenSent, setHasBeenSent] = useState(false);
  const [error, setError] = useState(errorInitialState);

  const handleResendVerificationMail = async () => {
    setError(errorInitialState);
    setHasBeenSent(false);
    setIsLoading(true);
    try {
      await postMailVerification(user._id);
      setIsLoading(false);
      setHasBeenSent(true);
    } catch {
      setError({
        hasError: true,
        errorText: "Une erreur est survenue, veuillez réessayer",
      });
      setIsLoading(false);
    }
  };

  return (
    <>
      <div style={{ minHeight: "calc(100vh - 250px)" }}>
        <NavMenu />
        <Grid container spacing={1}>
          <Grid item sm={2} md={2} />
          <Grid item xs={12} sm={8} md={8}>
            <Card sx={styles.card}>
              <CancelIcon sx={{ fontSize: "80px", color: "#58738D", mt: 2 }} />
              <Typography sx={styles.firstText}>
                La vérification de votre adresse mail est nécessaire afin
                d'accéder à nos services et vous ne l'avez pas encore fait.
              </Typography>

              <Typography sx={styles.secondText}>
                Si vous n'avez toujours pas reçu de mails, vérifiez bien vos
                spams ou renvoyez un mail en cliquant sur le bouton ci dessous.
              </Typography>
              <OctopusQRButton
                onClick={handleResendVerificationMail}
                sx={{ margin: "40px" }}
              >
                Renvoyer le mail
              </OctopusQRButton>
              {isLoading ? <Loading /> : null}
              {hasBeenSent ? (
                <Box sx={styles.flexCenter}>
                  <DoneIcon />
                  <Typography variant="overline">
                    Un email a été renvoyé
                  </Typography>
                </Box>
              ) : null}
              {error.hasError ? (
                <Typography variant="overline" sx={styles.errorText}>
                  {error.errorText}
                </Typography>
              ) : null}
            </Card>
          </Grid>
          <Grid item sm={2} md={2} />
        </Grid>
      </div>
      <Footer />
    </>
  );
}
