import { useEffect, useRef, useState } from "react";
import { styles } from "./QRCodeCustomizeView.style";
import QRCodeStyling from "qr-code-styling";
import { HexColorPicker, HexColorInput } from "react-colorful";
import { FileUploader } from "react-drag-drop-files";

import {
  QR_CODE_LOGO_OPTIONS,
  SITE_BASE_URL,
} from "../../../../utils/constants/Constants";
import { BlackButton, UploaderBox } from "../../../../utils/styled/Styled";

import QrCodeClassy from "../../../../assets/qrcode/classy.png";
import QrCodeClassyRounded from "../../../../assets/qrcode/classy_rounded.png";
import QrCodeDots from "../../../../assets/qrcode/dots.png";
import QrCodeExtraRounded from "../../../../assets/qrcode/extra_rounded.png";
import QrCodeRounded from "../../../../assets/qrcode/rounded.png";
import QrCodeSquare from "../../../../assets/qrcode/square.png";
import { RGBToHex } from "../../../../utils/Utils";

import BorderSquare from "../../../../assets/qrcode/border_square.png";
import BorderDot from "../../../../assets/qrcode/border_dot.png";
import BorderExtraRounded from "../../../../assets/qrcode/border_extra_rounded.png";

import Cancel from "../../../../assets/unauthorized.png";
import BorderInSquare from "../../../../assets/qrcode/border_in_square.png";
import BorderInDot from "../../../../assets/qrcode/border_in_dot.png";

import {
  Box,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  IconButton,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import WarningIcon from "@mui/icons-material/Warning";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

export default function QRCodeCustomizeView({
  user,
  qrCode,
  initialCustomization,
  customizationValues,
  setCustomizationValues,
}) {
  //QR Code Setup
  const qrCodeRef = useRef();
  const aQrCodeOption = {
    width: 280,
    height: 280,
    type: "svg",
    data: qrCode ? `${SITE_BASE_URL}render/${qrCode._id}` : `${SITE_BASE_URL}`,
    image: customizationValues.picture,
    dotsOptions: {
      type: customizationValues.dotsType,
      color: customizationValues.dotsColor,
    },
    cornersDotOptions: {
      type: customizationValues.cornerDotType,
      color: customizationValues.cornerDotColor,
    },
    cornersSquareOptions: {
      type: customizationValues.cornerSquaresType,
      color: customizationValues.cornerSquaresColor,
    },
    backgroundOptions: {
      color: customizationValues.backgroundColor,
    },
    imageOptions: {
      crossOrigin: "anonymous",
      imageSize: 0.3,
      margin: 5,
    },
  };

  const userQrCode = new QRCodeStyling(aQrCodeOption);

  const reinitQRCode = () => {
    if (qrCode) {
      const aBackgroundOption =
        qrCode.backgroundColor === "transparent" ? "default" : "custom";
      setCustomizationValues({
        ...customizationValues,
        dotsType: qrCode.dotsType,
        dotsColor: qrCode.dotsColor,
        cornerSquaresType: qrCode.cornerSquaresType,
        cornerSquaresColor: qrCode.cornerSquaresColor,
        cornerDotType: qrCode.cornerDotType,
        cornerDotColor: qrCode.cornerDotColor,
        backgroundColor: qrCode.backgroundColor,
        backgroundOptionChosen: aBackgroundOption,
      });
      setCustomBackground(aBackgroundOption === "custom");
    } else {
      setCustomizationValues({
        ...customizationValues,
        dotsType: initialCustomization.dotsType,
        dotsColor: initialCustomization.dotsColor,
        cornerSquaresType: initialCustomization.cornerSquaresType,
        cornerSquaresColor: initialCustomization.cornerSquaresColor,
        cornerDotType: initialCustomization.cornerDotType,
        cornerDotColor: initialCustomization.cornerDotColor,
        backgroundColor: initialCustomization.backgroundColor,
        backgroundOptionChosen: initialCustomization.backgroundOptionChosen,
      });
    }
  };

  //Customization part
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    userQrCode.append(qrCodeRef.current);
    requestAnimationFrame(() => {
      userQrCode.update(aQrCodeOption);
    });
  });

  const toClassy = () => {
    setCustomizationValues({ ...customizationValues, dotsType: "classy" });
  };

  const toClassyRounded = () => {
    setCustomizationValues({
      ...customizationValues,
      dotsType: "classy-rounded",
    });
  };

  const toRounded = () => {
    setCustomizationValues({ ...customizationValues, dotsType: "rounded" });
  };

  const toExtraRounded = () => {
    setCustomizationValues({
      ...customizationValues,
      dotsType: "extra-rounded",
    });
  };

  const toDots = () => {
    setCustomizationValues({ ...customizationValues, dotsType: "dots" });
  };

  const toSquare = () => {
    setCustomizationValues({ ...customizationValues, dotsType: "square" });
  };

  const updateDotsColor = (iEvent) => {
    const aRgb = iEvent.target.querySelector("div").style.backgroundColor;
    const aHex = RGBToHex(aRgb);
    setCustomizationValues({ ...customizationValues, dotsColor: aHex });
  };

  const updateDotsColorFromInput = (iEvent) => {
    setCustomizationValues({ ...customizationValues, dotsColor: iEvent });
  };

  const cornerSquareToCancel = () => {
    setCustomizationValues({ ...customizationValues, cornerSquaresType: "" });
  };

  const cornerSquareToSquare = () => {
    setCustomizationValues({
      ...customizationValues,
      cornerSquaresType: "square",
    });
  };

  const cornerSquareToDot = () => {
    setCustomizationValues({
      ...customizationValues,
      cornerSquaresType: "dot",
    });
  };

  const cornerSquareToExtraRounded = () => {
    setCustomizationValues({
      ...customizationValues,
      cornerSquaresType: "extra-rounded",
    });
  };

  const updateCornerSquaresColor = (iEvent) => {
    const aRgb = iEvent.target.querySelector("div").style.backgroundColor;
    const aHex = RGBToHex(aRgb);
    setCustomizationValues({
      ...customizationValues,
      cornerSquaresColor: aHex,
    });
  };

  const updateCornerSquaresColorFromInput = (iEvent) => {
    setCustomizationValues({
      ...customizationValues,
      cornerSquaresColor: iEvent,
    });
  };

  const cornerDotToCancel = () => {
    setCustomizationValues({
      ...customizationValues,
      cornerDotType: "",
    });
  };

  const cornerDotToSquare = () => {
    setCustomizationValues({
      ...customizationValues,
      cornerDotType: "square",
    });
  };

  const cornerDotToDot = () => {
    setCustomizationValues({
      ...customizationValues,
      cornerDotType: "dot",
    });
  };

  const updateCornerDotColor = (iEvent) => {
    const aRgb = iEvent.target.querySelector("div").style.backgroundColor;
    const aHex = RGBToHex(aRgb);
    setCustomizationValues({
      ...customizationValues,
      cornerDotColor: aHex,
    });
  };

  const updateCornerDotColorFromInput = (iEvent) => {
    setCustomizationValues({
      ...customizationValues,
      cornerDotColor: iEvent,
    });
  };

  const updateBackgroundColor = (iEvent) => {
    const aRgb = iEvent.target.querySelector("div").style.backgroundColor;
    const aHex = RGBToHex(aRgb);
    setCustomizationValues({
      ...customizationValues,
      backgroundColor: aHex,
    });
  };

  const updateBackgroundColorFromInput = (iEvent) => {
    setCustomizationValues({
      ...customizationValues,
      backgroundColor: iEvent,
    });
  };

  //Logo part
  const [uploadLogo, setUploadLogo] = useState(
    customizationValues.logoOptionChosen === QR_CODE_LOGO_OPTIONS.PERSONNAL
  );
  const [logoError, setLogoError] = useState(false);
  const [logoErrorText, setLogoErrorText] = useState("");
  const changeLogoSource = (iEvent) => {
    if (iEvent.target.value === QR_CODE_LOGO_OPTIONS.USER_LOGO) {
      setCustomizationValues({
        ...customizationValues,
        picture: user.logoPath,
        logoOptionChosen: iEvent.target.value,
      });
      setUploadLogo(false);
    } else if (iEvent.target.value === QR_CODE_LOGO_OPTIONS.PERSONNAL) {
      if (file) {
        let reader = new FileReader();
        reader.onload = function (event) {
          setCustomizationValues({
            ...customizationValues,
            picture: event.target.result,
            logoOptionChosen: iEvent.target.value,
          });
        };
        reader.readAsDataURL(file);
      } else if (qrCode) {
        setCustomizationValues({
          ...customizationValues,
          picture: qrCode.picture,
          logoOptionChosen: iEvent.target.value,
        });
      } else {
        setCustomizationValues({
          ...customizationValues,
          picture: "",
          logoOptionChosen: iEvent.target.value,
        });
      }
      setUploadLogo(true);
    } else if (iEvent.target.value === QR_CODE_LOGO_OPTIONS.NONE) {
      setCustomizationValues({
        ...customizationValues,
        picture: "",
        logoOptionChosen: iEvent.target.value,
      });
      setUploadLogo(false);
    }
  };

  const aUploadBox = (
    <UploaderBox style={styles.center}>
      <CloudUploadIcon style={styles.centeredIcon} />
      <Typography sx={{ mt: 5, mr: 2, ml: 2 }}>
        <strong style={{ textDecoration: "underline" }}>Sélectionner</strong> ou
        glisser pour importer un fichier
      </Typography>
      <Typography sx={{ mt: 3, mr: 2, ml: 2, mb: 4 }}>
        Accepté : PNG, JPG, JPEG
      </Typography>
    </UploaderBox>
  );

  const [file, setFile] = useState(customizationValues.file);
  const fileTypes = ["JPG", "PNG", "JPEG"];
  const handleLogoAdded = (file) => {
    setLogoError(false);
    setLogoErrorText("");

    if (file.size > 2000000) {
      setLogoError(true);
      setLogoErrorText("Fichier trop volumineux, 2Mo maximum autorisé");
      return;
    }

    let reader = new FileReader();
    reader.onload = function (event) {
      setCustomizationValues({
        ...customizationValues,
        picture: event.target.result,
        file: file,
      });
    };

    reader.readAsDataURL(file);

    setFile(file);
  };

  //Background part
  const [customBackground, setCustomBackground] = useState(
    customizationValues.backgroundOptionChosen === "custom"
  );
  const [backgroundColorSave, setBackgroundColorSave] = useState(
    customizationValues.backgroundColor
  );
  const handleBackgroundOptionChange = (iEvent) => {
    if (iEvent.target.value === "default") {
      if (customizationValues.backgroundColor !== "transparent") {
        setBackgroundColorSave(customizationValues.backgroundColor);
      }
      setCustomizationValues({
        ...customizationValues,
        backgroundColor: "transparent",
        backgroundOptionChosen: iEvent.target.value,
      });
      setCustomBackground(false);
    } else {
      setCustomizationValues({
        ...customizationValues,
        backgroundColor: backgroundColorSave,
        backgroundOptionChosen: iEvent.target.value,
      });
      setCustomBackground(true);
    }
  };
  return (
    <>
      <Grid container spacing={0} margin={0}>
        <Grid item xs={12} md={12} lg={6}>
          <Box sx={styles.flexColumnCenter}>
            <Accordion
              sx={styles.accordionStyle}
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography sx={styles.title}>Logo</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <RadioGroup
                  row
                  sx={{ justifyContent: "space-evenly" }}
                  value={customizationValues.logoOptionChosen}
                  onChange={changeLogoSource}
                >
                  <FormControlLabel
                    value={QR_CODE_LOGO_OPTIONS.NONE}
                    control={<Radio />}
                    label="Aucun logo"
                  />
                  <FormControlLabel
                    value={QR_CODE_LOGO_OPTIONS.PERSONNAL}
                    control={<Radio />}
                    label="Ajouter un logo"
                  />
                  {user.logoPath ? (
                    <FormControlLabel
                      value={QR_CODE_LOGO_OPTIONS.USER_LOGO}
                      control={<Radio />}
                      label="Utiliser mon logo"
                    />
                  ) : null}
                </RadioGroup>
                {uploadLogo ? (
                  <Box sx={styles.uploaderContainer}>
                    <FileUploader
                      style={styles.uploader}
                      children={aUploadBox}
                      handleChange={handleLogoAdded}
                      name="file"
                      types={fileTypes}
                      label="Sélectionner ou glisser déposer un fichier."
                      hoverTitle="Déposez ici"
                    />
                    {file ? (
                      <Typography variant="overline" sx={styles.text}>
                        Fichier importé : {file.name}
                      </Typography>
                    ) : (
                      <Typography variant="overline" sx={styles.text}>
                        Aucun fichier pour le moment
                      </Typography>
                    )}
                    {logoError ? (
                      <>
                        <br />
                        <Typography variant="overline" sx={styles.errorText}>
                          {logoErrorText}
                        </Typography>
                      </>
                    ) : null}
                  </Box>
                ) : null}
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={styles.accordionStyle}
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography sx={styles.title}>Corps</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography sx={styles.subTitle}>Forme</Typography>
                <Grid container spacing={0}>
                  <Grid item xs={4} md={4} lg={4}>
                    <IconButton
                      onClick={() => {
                        toClassy();
                      }}
                    >
                      <img src={QrCodeClassy} width="56px" alt="QrCodeClassy" />
                    </IconButton>
                  </Grid>
                  <Grid item xs={4} md={4} lg={4}>
                    <IconButton
                      onClick={() => {
                        toClassyRounded();
                      }}
                    >
                      <img
                        src={QrCodeClassyRounded}
                        width="56px"
                        alt="QrCodeClassyRounded"
                      />
                    </IconButton>
                  </Grid>
                  <Grid item xs={4} md={4} lg={4}>
                    <IconButton
                      onClick={() => {
                        toDots();
                      }}
                    >
                      <img src={QrCodeDots} width="56px" alt="QrCodeDots" />
                    </IconButton>
                  </Grid>
                  <Grid item xs={4} md={4} lg={4}>
                    <IconButton
                      onClick={() => {
                        toExtraRounded();
                      }}
                    >
                      <img
                        src={QrCodeExtraRounded}
                        width="56px"
                        alt="QrCodeExtraRounded"
                      />
                    </IconButton>
                  </Grid>
                  <Grid item xs={4} md={4} lg={4}>
                    <IconButton
                      onClick={() => {
                        toRounded();
                      }}
                    >
                      <img
                        src={QrCodeRounded}
                        width="56px"
                        alt="QrCodeRounded"
                      />
                    </IconButton>
                  </Grid>
                  <Grid item xs={4} md={4} lg={4}>
                    <IconButton
                      onClick={() => {
                        toSquare();
                      }}
                    >
                      <img src={QrCodeSquare} width="56px" alt="QrCodeSquare" />
                    </IconButton>
                  </Grid>
                </Grid>

                <Typography sx={styles.subTitle}>Couleur</Typography>
                <HexColorPicker
                  color={customizationValues.dotsColor}
                  onMouseUp={updateDotsColor}
                  style={styles.colorPicker}
                />
                <HexColorInput
                  color={customizationValues.dotsColor}
                  onChange={updateDotsColorFromInput}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={styles.accordionStyle}
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography sx={styles.title}>
                  Extérieur des extrémités
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography sx={styles.subTitle}>Forme</Typography>
                <Grid container spacing={0}>
                  <Grid item xs={3} md={3} lg={3}>
                    <IconButton
                      onClick={() => {
                        cornerSquareToCancel();
                      }}
                    >
                      <img src={Cancel} width="58px" alt="BorderInCancel" />
                    </IconButton>
                  </Grid>
                  <Grid item xs={3} md={3} lg={3}>
                    <IconButton
                      onClick={() => {
                        cornerSquareToSquare();
                      }}
                    >
                      <img src={BorderSquare} width="52px" alt="BorderSquare" />
                    </IconButton>
                  </Grid>
                  <Grid item xs={3} md={3} lg={3}>
                    <IconButton
                      onClick={() => {
                        cornerSquareToDot();
                      }}
                    >
                      <img src={BorderDot} width="56px" alt="BorderDot" />
                    </IconButton>
                  </Grid>
                  <Grid item xs={3} md={3} lg={3}>
                    <IconButton
                      onClick={() => {
                        cornerSquareToExtraRounded();
                      }}
                    >
                      <img
                        src={BorderExtraRounded}
                        width="56px"
                        alt="BorderExtraRounded"
                      />
                    </IconButton>
                  </Grid>
                </Grid>
                <Typography sx={styles.subTitle}>Couleur</Typography>
                <HexColorPicker
                  color={customizationValues.cornerSquaresColor}
                  onMouseUp={updateCornerSquaresColor}
                  style={styles.colorPicker}
                />
                <HexColorInput
                  color={customizationValues.cornerSquaresColor}
                  onChange={updateCornerSquaresColorFromInput}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={styles.accordionStyle}
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography sx={styles.title}>
                  Intérieur des extrémités
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography sx={styles.subTitle}>Forme</Typography>
                <Grid container spacing={0}>
                  <Grid item xs={4} md={4} lg={4}>
                    <IconButton
                      onClick={() => {
                        cornerDotToCancel();
                      }}
                    >
                      <img src={Cancel} width="48px" alt="BorderInCancel" />
                    </IconButton>
                  </Grid>
                  <Grid item xs={4} md={4} lg={4}>
                    <IconButton
                      onClick={() => {
                        cornerDotToSquare();
                      }}
                    >
                      <img src={BorderInSquare} width="48px" alt="BorderDot" />
                    </IconButton>
                  </Grid>
                  <Grid item xs={4} md={4} lg={4}>
                    <IconButton
                      onClick={() => {
                        cornerDotToDot();
                      }}
                    >
                      <img src={BorderInDot} width="48px" alt="BorderDot" />
                    </IconButton>
                  </Grid>
                </Grid>
                <Typography sx={styles.subTitle}>Couleur</Typography>
                <HexColorPicker
                  color={customizationValues.cornerDotColor}
                  onMouseUp={updateCornerDotColor}
                  style={styles.colorPicker}
                />
                <HexColorInput
                  color={customizationValues.cornerDotColor}
                  onChange={updateCornerDotColorFromInput}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={styles.accordionStyle}
              expanded={expanded === "panel5"}
              onChange={handleChange("panel5")}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography sx={styles.title}>Arrière plan</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <RadioGroup
                  row
                  sx={{ justifyContent: "space-evenly" }}
                  value={customizationValues.backgroundOptionChosen}
                  onChange={handleBackgroundOptionChange}
                >
                  <FormControlLabel
                    value="default"
                    control={<Radio />}
                    label="Transparent"
                  />
                  <FormControlLabel
                    value="custom"
                    control={<Radio />}
                    label="Personnalisé"
                  />
                </RadioGroup>
                {customBackground ? (
                  <Box sx={{ mt: 2 }}>
                    <HexColorPicker
                      color={customizationValues.backgroundColor}
                      onMouseUp={updateBackgroundColor}
                      style={styles.colorPicker}
                    />
                    <HexColorInput
                      color={customizationValues.backgroundColor}
                      onChange={updateBackgroundColorFromInput}
                    />
                  </Box>
                ) : null}
              </AccordionDetails>
            </Accordion>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          lg={6}
          sx={{
            mt: 2,
            display: "flex",
            alignItems: "center",
            margin: "auto",
          }}
        >
          <div style={{ margin: "auto", marginTop: "30px" }}>
            <Box sx={{ display: { xs: "none", md: "block" } }}>
              <WarningIcon sx={styles.icon} />
              <Typography sx={{ mb: 5 }}>
                Essayez de scanner votre QR Code avant de l'enregister.
              </Typography>
            </Box>
            <div ref={qrCodeRef} />
            <Box>
              <BlackButton onClick={reinitQRCode} sx={{ mt: 1 }}>
                Réinitialiser
              </BlackButton>
            </Box>
          </div>
        </Grid>
      </Grid>
    </>
  );
}
