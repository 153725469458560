import profile from "../../assets/about/profile.jpeg";
import dda from "../../assets/about/dda.png";
import aboutBackground from "../../assets/about/aboutBackground.png";
import logo from "../../assets/press/logo.png";

import { Box, Typography, Card } from "@mui/material";
import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import NavMenu from "../../components/navMenu/NavMenu";
import Footer from "../../components/footer/Footer";
import "./about.css";

const About = () => {
  const styles = {
    chrono: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      margin: "auto",
      width: { xs: "98%", sm: "98%", md: "80%", lg: "80%", xl: "80%" },
    },
    titleContainer: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
      maxWidth: "800px",
      color: "white",
      margin: "30px auto",
      textAlign: "justify",
      textJustify: "inter-character",
      textAlignLast: "center",
    },
    imageContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      my: 2,
    },
    timelineContainer: {
      backgroundImage: `url(${aboutBackground})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center center",
      minHeight: "700px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      px: 2,
      py: 10,
    },
    timelineWrapper: {
      backdropFilter: "blur(130px)",
      backgroundColor: "rgba(255, 255, 255, 0.3)",
      boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
      borderRadius: "30px",
      p: 3,
      margin: "auto",
    },
    contentStyle: {
      textAlign: { xs: "center", md: "justify" },
      background: "rgba(255,255,255,0.5)",
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
      borderRadius: "30px",
    },
  };

  return (
    <>
      <Box sx={{ minHeight: "calc(100vh - 250px)" }}>
        <NavMenu />
        <Box sx={{ p: 4, background: "#282828" }}>
          <Box sx={styles.titleContainer}>
            <Typography variant="h3" sx={{ fontSize: "34px" }}>
              C'est le moment des présentations !
            </Typography>
            <br />
            <Typography variant="body1" fontSize="1rem">
              <br />
              Nous, c'est Julie et Corentin et on est ravie de faire votre
              connaissance. Sur cette page, vous découvrirez les événements clés
              qui ont marqué le développement d'Octopus QR depuis sa création.
            </Typography>
          </Box>
        </Box>
        <Box sx={styles.timelineContainer}>
          <Card sx={styles.timelineWrapper}>
            <VerticalTimeline lineColor={"white"}>
              <VerticalTimelineElement
                contentArrowStyle={{ borderRight: "7px solid  #58738D" }}
                date="Sept. 2021"
                iconStyle={{
                  background: "#58738D",
                }}
                contentStyle={styles.contentStyle}
              >
                <Typography
                  variant="h3"
                  sx={{ color: "#58738D", fontSize: "34px" }}
                >
                  Création d'Octopus QR
                </Typography>
                <Typography variant="h3" sx={{ fontSize: "20px" }}>
                  Par Corentin Lizy
                </Typography>
                <Typography>
                  Corentin a fondé Octopus QR via le statut d'auto-entrepreneur
                  le 11/09/2021. À l’origine, l’application était destinée aux
                  restaurants pour simplifier la gestion de leurs cartes.
                </Typography>
                <Box sx={styles.imageContainer}>
                  <img
                    src={logo}
                    style={{
                      maxHeight: "150px",
                      maxWidth: "150px",
                    }}
                    alt="Logo"
                  />
                </Box>
              </VerticalTimelineElement>

              <VerticalTimelineElement
                contentStyle={styles.contentStyle}
                iconStyle={{ background: "#58738D" }}
                contentArrowStyle={{ borderRight: "7px solid  #58738D" }}
                date="2022"
              >
                <Typography
                  variant="h3"
                  sx={{ color: "#58738D", fontSize: "28px" }}
                >
                  Rencontre de Julie et Corentin
                </Typography>
                <Typography variant="h3" sx={{ fontSize: "18px" }}>
                  Lors d'un hackathon organisé par le label “French tech”
                </Typography>

                <Typography>
                  Ils se sont retrouvés au sein de la même équipe et ont
                  collaboré pendant 48h sur le développement d’une application
                  mobile, “Space it”, qui a remporté la victoire.
                </Typography>
                <Box sx={styles.imageContainer}>
                  <img
                    src={dda}
                    style={{
                      maxHeight: "400px",
                      maxWidth: "220px",
                      borderRadius: "20px",
                    }}
                    alt="dda"
                  />
                </Box>
              </VerticalTimelineElement>

              <VerticalTimelineElement
                contentStyle={styles.contentStyle}
                iconStyle={{ background: "#58738D" }}
                contentArrowStyle={{ borderRight: "7px solid  #58738D" }}
                date="2023"
              >
                <Typography
                  variant="h3"
                  sx={{ color: "#58738D", fontSize: "28px" }}
                >
                  Julie rejoint l'équipe
                </Typography>

                <Typography>
                  Julie Lorrain a officiellement rejoint l’entreprise le
                  01/05/2023 en tant qu’associée. Cette association a mis en
                  lumière la complémentarité de leurs profils.
                </Typography>

                <Box sx={styles.imageContainer}>
                  <img
                    src={profile}
                    style={{
                      maxHeight: "450px",
                      maxWidth: "220px",
                      borderRadius: "20px",
                    }}
                    alt="profile"
                  />
                </Box>
              </VerticalTimelineElement>

              <VerticalTimelineElement
                contentStyle={styles.contentStyle}
                iconStyle={{ background: "#58738D" }}
                contentArrowStyle={{ borderRight: "7px solid  #58738D" }}
                date="Juin 2023"
              >
                <Typography
                  variant="h3"
                  sx={{ color: "#58738D", fontSize: "28px" }}
                >
                  Octopus QR
                </Typography>

                <Typography>
                  Avec le temps, l’application s’est modernisée et offre
                  aujourd'hui beaucoup plus de solutions et de types de QR Codes
                  en s'adaptant constamment aux besoins de ses utilisateurs.
                </Typography>
              </VerticalTimelineElement>
            </VerticalTimeline>
          </Card>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default About;
