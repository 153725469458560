import { QR_CODE_TYPE } from "../../../../../utils/constants/Constants";
import NotFound from "../../../../notFound/NotFound";
import CustomPageContent from "../../../add/content/customPage/CustomPageContent";
import FileContent from "../../../add/content/file/FileContent";
import FormContent from "../../../add/content/form/FormContent";
import LinkContent from "../../../add/content/link/LinkContent";
import SocialNetworkContent from "../../../add/content/socialNetwork/SocialNetworkContent";
import TextContent from "../../../add/content/text/TextContent";
import VirtualCardContent from "../../../add/content/virtualCard/VirtualCardContent";

export default function AddContent({
  qrType,
  QRCode,
  setCurrentAction,
  putQRCode,
}) {
  let content;
  switch (qrType) {
    case QR_CODE_TYPE.CUSTOM_PAGE:
      content = (
        <CustomPageContent
          currentAction={setCurrentAction}
          QRCode={QRCode}
          file={null}
          putQRCode={putQRCode}
        />
      );
      break;

    case QR_CODE_TYPE.VIRTUAL_CARD:
      content = (
        <VirtualCardContent
          currentAction={setCurrentAction}
          QRCode={QRCode}
          file={null}
          putQRCode={putQRCode}
        />
      );
      break;

    case QR_CODE_TYPE.FILE:
      content = (
        <FileContent
          currentAction={setCurrentAction}
          QRCode={QRCode}
          file={null}
          putQRCode={putQRCode}
        />
      );
      break;
    case QR_CODE_TYPE.FORM:
      content = (
        <FormContent
          currentAction={setCurrentAction}
          QRCode={QRCode}
          file={null}
          putQRCode={putQRCode}
        />
      );
      break;
    case QR_CODE_TYPE.LINK:
      content = (
        <LinkContent
          currentAction={setCurrentAction}
          QRCode={QRCode}
          file={null}
          putQRCode={putQRCode}
        />
      );
      break;
    case QR_CODE_TYPE.SOCIAL_NETWORK:
      content = (
        <SocialNetworkContent
          currentAction={setCurrentAction}
          QRCode={QRCode}
          file={null}
          putQRCode={putQRCode}
        />
      );
      break;
    case QR_CODE_TYPE.TEXT:
      content = (
        <TextContent
          currentAction={setCurrentAction}
          QRCode={QRCode}
          file={null}
          putQRCode={putQRCode}
        />
      );
      break;
    default:
      <NotFound />;
      break;
  }
  return <>{content}</>;
}
