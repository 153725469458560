export const styles = {
  pageContainer: { minHeight: "calc(100vh - 250px)" },

  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  pseudonymForm: {
    ml: 1,
    width: "200px",
  },

  flexColumnCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },

  radioContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    py: 2,
  },

  typeTypo: {
    fontSize: "13px",
    fontWeight: "bold",
    color: "#58738D",
  },

  typeIcon: {
    mr: 1,
    color: "#58738D",
  },

  typeCard: {
    width: "250px",
    py: 1,
    borderRadius: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#58738D",
    color: "white",
  },

  card: {
    mt: 2,
    mr: 3,
    ml: 3,
    mb: 9,
    py: 3,
    px: 2,
    width: { lg: "60%", md: "70%", xs: "95%" },
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    borderRadius: "20px",
    textAlign: "center",
  },

  radioType: {
    mx: 2,
  },

  icon: {
    color: "#58738D",
    mt: 2,
  },

  menuContainer: {
    display: "flex",
    justifyContent: { xs: "space-between", lg: "center" },
    alignItems: "center",
    flexWrap: "wrap",
    background: "#58738D",
    color: "white",
    py: { xs: 1, md: 2 },

    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
  },

  menuItem: { borderRadius: "50px", mx: { xs: 1, md: 2 } },

  menuTypo: {
    fontSize: { xs: "15px", md: "14px" },
    fontWeight: { xs: "light", md: "bold" },
  },

  paperMenuProps: {
    elevation: 0,
    sx: {
      overflow: "visible",
      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
      mt: 1.5,
      "& .MuiAvatar-root": {
        width: 32,
        height: 32,
        ml: -0.5,
        mr: 1,
      },
      "&:before": {
        content: '""',
        display: "block",
        position: "absolute",
        top: 0,
        right: 14,
        width: 10,
        height: 10,
        bgcolor: "background.paper",
        transform: "translateY(-50%) rotate(45deg)",
        zIndex: 0,
      },
    },
  },

  button: {
    mt: 3,
    mb: 3,
    width: "200px",
  },

  errorText: {
    color: "#8B0000",
    fontWeight: "bold",
    fontSize: 12,
  },

  savedText: {
    color: "green",
    fontWeight: "bold",
    fontSize: 12,
  },

  warningText: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    textAlign: "justify",
    color: "#8B0000",
    fontSize: "13px",
  },

  field: {
    marginBottom: "50px",
    backgroundColor: "white",
    borderRadius: "20px",
    [`& fieldset`]: {
      borderRadius: "20px",
      border: "1px",
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    },
  },

  formField: {
    backgroundColor: "white",
    borderRadius: "20px",
    [`& fieldset`]: {
      borderRadius: "20px",
      border: "1px",
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    },
  },

  formControl: {
    width: { xs: "85%", md: "70%", lg: "65%" },
    backgroundColor: "white",
  },

  uploader: {
    mt: 4,
    mb: 2,
    mx: { xs: "5%", md: "15%", lg: "25%" },
  },
};
