import { useState } from "react";

import { styles } from "../FormContent.style";

import {
  Card,
  Box,
  Typography,
  FormControl,
  Grid,
  Select,
  MenuItem,
  TextField,
  IconButton,
  Checkbox,
} from "@mui/material";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import ShortTextIcon from "@mui/icons-material/ShortText";
import NotesIcon from "@mui/icons-material/Notes";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import CloseIcon from "@mui/icons-material/Close";
import RadioChoice from "./RadioChoice";
import CheckBoxChoice from "./CheckBoxChoice";
import SelectChoice from "./SelectChoice";
import { FORM_INPUT_TYPE_FR } from "../../../../../../utils/constants/Constants";
import { removeObjectWithId } from "../../../../../../utils/Utils";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

const FORM_INPUT_ARRAY = [
  "Réponse courte",
  "Paragraphe",
  "Choix multiples",
  "Cases à cocher",
  "Liste déroulante",
];

const FORM_ICONS = [
  <ShortTextIcon />,
  <NotesIcon />,
  <RadioButtonCheckedIcon />,
  <CheckBoxIcon />,
  <ExpandCircleDownIcon />,
];

export default function FormElement({
  formData,
  updateFormData,
  items,
  setItems,
  elementId,
}) {
  const [inputSelected, setInputSelected] = useState(
    formData?.items[elementId]?.type
      ? formData.items[elementId].type
      : "Réponse courte"
  );

  const updateInputSelected = (iEvent) => {
    setInputSelected(iEvent.target.value);
    let aEntryValue = {};
    switch (iEvent.target.value) {
      case FORM_INPUT_TYPE_FR.RADIO:
        aEntryValue = { radio_1: "Option 1" };
        break;
      case FORM_INPUT_TYPE_FR.CHECKBOX:
        aEntryValue = { checkbox_1: "Option 1" };
        break;
      case FORM_INPUT_TYPE_FR.SELECT:
        aEntryValue = { select_1: "Option 1" };
        break;
      default:
        break;
    }

    updateFormData({
      items: {
        ...formData.items,
        [elementId]: {
          ...formData.items[elementId],
          type: iEvent.target.value,
          entries: aEntryValue,
        },
      },
    });
  };

  const handleInputChange = (e) => {
    updateFormData({
      items: {
        ...formData.items,
        [elementId]: {
          ...formData.items[elementId],
          question: e.target.value,
        },
      },
    });
  };

  const handleIsRequiredChange = (e) => {
    updateFormData({
      items: {
        ...formData.items,
        [elementId]: {
          ...formData.items[elementId],
          isRequired: !formData.items[elementId].isRequired,
        },
      },
    });
  };

  const deleteItem = () => {
    let aFormData = { ...formData };
    delete aFormData.items[elementId];
    let aItems = Array.from(items);
    removeObjectWithId(aItems, elementId);
    setItems([...aItems]);
    updateFormData({
      ...aFormData,
    });
  };

  let itemsIds = [];
  if (formData?.items[elementId]?.entries) {
    for (const aEntry of Object.entries(formData.items[elementId].entries)) {
      itemsIds.push({ id: aEntry[0] });
    }
  }

  const FORM_ENTRY_MAP = {
    "Choix multiples": (
      <RadioChoice
        formData={formData}
        updateFormData={updateFormData}
        elementId={elementId}
        itemsIds={itemsIds}
      />
    ),
    "Cases à cocher": (
      <CheckBoxChoice
        formData={formData}
        updateFormData={updateFormData}
        elementId={elementId}
        itemsIds={itemsIds}
      />
    ),
    "Liste déroulante": (
      <SelectChoice
        formData={formData}
        updateFormData={updateFormData}
        elementId={elementId}
        itemsIds={itemsIds}
      />
    ),
  };

  return (
    <Card sx={styles.elementCard}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {items.length > 1 ? <Box sx={{ width: "40px" }} /> : <Box />}
        <DragIndicatorIcon sx={{ transform: "rotate(90deg)", ml: 1 }} />
        {items.length > 1 ? (
          <IconButton onClick={deleteItem} sx={{ mr: 1 }}>
            <CloseIcon sx={styles.deleteCross} />
          </IconButton>
        ) : (
          <Box />
        )}
      </Box>

      <Grid container spacing={0}>
        <Grid item xs={12} md={6} lg={6}>
          <FormControl sx={{ mx: 2, mt: { xs: 3, md: 2 }, width: "90%" }}>
            <TextField
              id={elementId}
              name={elementId}
              required={true}
              variant="outlined"
              value={formData.items[elementId].question}
              onChange={handleInputChange}
              sx={styles.field}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <FormControl
            sx={{
              mx: 2,
              mt: 2,
              mb: { xs: 3, md: 2 },
              width: "90%",
            }}
          >
            <Select
              value={inputSelected}
              sx={styles.field}
              MenuProps={styles.menuProps}
              onChange={updateInputSelected}
            >
              {FORM_INPUT_ARRAY.map((aFormInput, aIndex) => {
                return (
                  <MenuItem value={aFormInput} key={aFormInput}>
                    <Box sx={styles.flexCenter}>
                      {FORM_ICONS[aIndex]}

                      <Typography sx={{ ml: 2 }}>{aFormInput}</Typography>
                    </Box>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Box>{FORM_ENTRY_MAP[inputSelected]}</Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          px: "30px",
        }}
      >
        <Box onClick={handleIsRequiredChange}>
          <Checkbox checked={formData.items[elementId].isRequired} />
          <Typography variant="overline">Réponse obligatoire</Typography>
        </Box>
      </Box>
    </Card>
  );
}
