import React, { useState } from "react";
import {
  Box,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Footer from "../../components/footer/Footer";
import NavMenu from "../../components/navMenu/NavMenu";

const faqData = [
  {
    question: "Qu'est-ce qu'un QR Code et comment ça fonctionne ?",
    subQuestion: [
      {
        question: "Qu'est ce qu'un QR Code ?",
        answer:
          "Un QR Code est un type de code-barres bidimensionnel qui peut être lu en utilisant des smartphones et des applications de scanner QR dédiées.",
      },
      {
        question: "Comment scanner un QR Code?",
        answer:
          "Pour scanner un QR Code, vous aurez besoin d’un smartphone équipé d’un appareil photo ou d’une application de scanner QR.",
      },
    ],
  },
  {
    question: "Les fonctionnalités disponibles",
    subQuestion: [
      {
        question: "Puis-je personnaliser mes QR Codes?",
        answer:
          "Oui, notre application vous permet de personnaliser l'apparence de vos QR Codes pour qu'ils s'alignent avec votre marque.",
      },
      {
        question:
          "Quelle est la différence entre un QR code statique et un QR code dynamique ?",
        answer:
          "Un QR code statique contient une information fixe encodée directement dans le QR code. Cela signifie qu'une fois que le QR code a été créé, l'information qu'il contient ne peut pas être modifiée. Il est souvent utilisé pour des informations qui ne changent pas, comme l'URL d'un site web ou une carte de visite numérique.\n\nA contrario, un QR code dynamique contient une URL courte qui redirige vers une page web. Cette redirection peut être modifiée à tout moment, ce qui signifie que le contenu accessible via le QR code peut être mis à jour sans avoir à créer un nouveau code. C'est idéal pour les informations qui peuvent changer dans le temps, comme les menus de restaurant, les promotions en cours, etc. De plus, les QR codes dynamiques permettent également le suivi des scans, ce qui peut être utile pour analyser l'engagement des utilisateurs.",
      },
      {
        question: "Qu'est-ce que le rapport statistique complet?",
        answer:
          "Le rapport statistique complet vous donne des informations détaillées sur l'utilisation de vos QR Codes, comme le nombre de scans, l'évolution des courbes, la tendance ...",
      },
      {
        question: "Est-ce que mes QR Codes expirent?",
        answer:
          "Non, les QR Codes que vous créez avec notre service n'expirent pas et continueront à fonctionner tant que votre abonnement est actif.",
      },
    ],
  },
  {
    question: "Essai gratuit et gestion de mon abonnement",
    subQuestion: [
      {
        question: "Comment obtenir l'essai gratuit de 7 jours ?",
        answer:
          "L'essai gratuit vous permet de tester l'ensemble des fonctionnalités d'Octopus QR pendant 7 jours.\n Le petit plus : pas besoin de renseigner vos coordonnées bancaires pour en profiter ! Une fois la période d'essai passée, les QR Codes crées ne seront plus accessibles, vous recevrez une notification mail vous indiquant la fin de votre essai gratuit et vous invitera à vous abonner afin d'accéder de nouveau à botre compte.\n Pour débuter votre essai gratuit, ",
        link: "https://octopusqr.com/register",
        linkText: "c'est par ici que ça se passe",
      },
      {
        question: "Y a-t-il une politique de remboursement?",
        answer:
          "Oui, nous offrons une garantie de satisfaction ou remboursement. Si vous n'êtes pas satisfait de notre service, nous vous rembourserons.",
      },
      {
        question: "Comment fonctionnent les abonnements?",
        answer:
          "Nous proposons différents abonnements en fonction du nombre de QR Codes dont vous avez besoin. Les plans varient de 1, 3, 8, 15 à un nombre illimité de QR Codes, avec des prix allant de 4.99€ par mois à 34.99€/mois.",
      },
      {
        question: "Puis-je changer de forfait d'abonnement à tout moment?",
        answer:
          "Oui, nous avons à coeur de vous proposer une solution qui répond à vos besoins, vous pouvez donc changer d'abonnement à tout moment en nous contactant (Cf. Page Contact).",
      },
      {
        question: "Comment puis-je annuler mon abonnement?",
        answer:
          "Vous pouvez annuler votre abonnement à tout moment en nous contactant (Cf. Page Contact).",
      },
    ],
  },

  {
    question: "Application des QR Codes",
    subQuestion: [
      {
        question: "Puis-je utiliser vos QR Codes pour mon entreprise?",
        answer:
          "Absolument! Nos QR Codes sont parfaits pour une utilisation professionnelle. Ils peuvent aider à rendre votre entreprise plus accessible et à augmenter l'engagement du client.",
      },
    ],
  },
];

const styles = {
  questionContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    maxWidth: "450px",
    textAlign: "justify",
    margin: "auto",
  },
  question: {
    fontSize: "1.1rem",
    fontWeight: "bold",
    color: "#58738D",
    marginBottom: "10px",
  },
  answer: {
    fontSize: "0.9rem",
    marginBottom: "10px",
  },
};

const Faq = () => {
  const [expanded, setExpanded] = useState("panel0");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      <Box sx={{ minHeight: "calc(100vh - 250px)" }}>
        <NavMenu />
        <Box
          sx={{
            mt: 2,
            width: { xs: "90%", md: "70%", lg: "50%" },
            margin: "70px auto",
          }}
        >
          {faqData.map((faq, index) => (
            <Accordion
              key={index}
              expanded={expanded === `panel${index}`}
              onChange={handleChange(`panel${index}`)}
              sx={{ boxShadow: "none" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}a-content`}
                id={`panel${index}a-header`}
              >
                <Typography
                  sx={{ fontWeight: "bold", my: 2, fontSize: "1.2rem" }}
                >
                  {faq.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {faq.subQuestion.map((subQuestion, index) => (
                  <Box sx={styles.questionContainer} key={index}>
                    <Typography sx={styles.question}>
                      {subQuestion.question}
                    </Typography>
                    <br />
                    <Typography sx={styles.answer}>
                      {subQuestion.answer}
                      {subQuestion.link && (
                        <a href={subQuestion.link}>{subQuestion.linkText}</a>
                      )}
                    </Typography>
                    <br />
                  </Box>
                ))}
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default Faq;
