import { useCallback, useEffect, useState } from "react";
import { getUser } from "../../api/user/getUser";
import ErrorCardWithMenu from "../../components/error/ErrorCardWithMenu";
import LoadingWithMenu from "../../components/loading/LoadingWithMenu";
import { STORAGE_USER_ID, USER_ROLE } from "../../utils/constants/Constants";
import { styles } from "./Admin.style";
import { Box } from "@mui/material";
import NavMenu from "../../components/navMenu/NavMenu";
import Footer from "../../components/footer/Footer";
import NotFoundWithMenu from "../notFound/NotFoundWithMenu";
import AdminMenu from "./AdminMenu";
import { useSearchParams } from "react-router-dom";
import RegisterUser from "./viewTypes/register/RegisterUser";
import Quotation from "./viewTypes/quotation/Quotation";
import Billing from "./viewTypes/billing/Billing";
import ManageUsers from "./viewTypes/manageUsers/ManageUsers";

const Admin = () => {
  const [user, setUser] = useState();
  const [hasApiError, setHasApiError] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const getData = useCallback(async () => {
    try {
      const aUser = await getUser(localStorage.getItem(STORAGE_USER_ID));
      setUser(aUser);
    } catch {
      setHasApiError(true);
    }
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  if (hasApiError) {
    return <ErrorCardWithMenu />;
  }

  if (!user) {
    return <LoadingWithMenu />;
  }

  if (user.role !== USER_ROLE.ADMIN) {
    return <NotFoundWithMenu />;
  }

  let content;
  switch (searchParams.get("viewType")) {
    case "register":
      content = <RegisterUser user={user} />;
      break;
    case "quotation":
      content = <Quotation />;
      break;
    case "billing":
      content = <Billing />;
      break;
    case "management":
      content = <ManageUsers user={user} />;
      break;
    default:
      content = <RegisterUser user={user} />;
      break;
  }

  return (
    <>
      <Box sx={styles.pageContainer}>
        <NavMenu />
        <AdminMenu user={user} setSearchParams={setSearchParams} />
        {content}
      </Box>
      <Footer />
    </>
  );
};

export default Admin;
