import React, { useState } from "react";
import { useNavigate, Link, Outlet } from "react-router-dom";

import {
  STORAGE_ACCESS_TOKEN,
  STORAGE_USER_ID,
} from "../../utils/constants/Constants.js";
import { OctopusQRButton } from "../../utils/styled/Styled.js";
import NavMenu from "../../components/navMenu/NavMenu.js";
import Footer from "../../components/footer/Footer.js";
import { styles } from "./Login.style";
import { store } from "../../index";
import allActions from "../../redux/actions/allActions";
import Loading from "../../components/loading/Loading";
import { logUser, verifyLoginData } from "../../api/user/logUser";

import {
  Card,
  InputAdornment,
  TextField,
  FormControl,
  Grid,
  IconButton,
  Typography,
  Box,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import LoginIcon from "@mui/icons-material/Login";

export default function Login() {
  const initialErrorState = {
    hasError: false,
    errorText: "",
  };
  const [isLoading, setIsLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    mail: "",
    password: "",
  });
  const [errorValues, setErrorValues] = useState({
    hasMailError: false,
    mail: "",
    hasPasswordError: false,
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [passwordVisibility, setPasswordInputType] = useState("password");
  const [error, setError] = useState(initialErrorState);

  const navigateTo = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    setError(initialErrorState);

    const aErrorValues = verifyLoginData(formValues);
    setErrorValues({
      ...aErrorValues,
    });
    if (aErrorValues.hasMailError || aErrorValues.hasPasswordError) {
      setIsLoading(false);
      return;
    }
    try {
      const aResponse = await logUser(formValues);
      setIsLoading(false);
      store.dispatch(
        allActions.userActions.login(aResponse.user, aResponse.accessToken)
      );
      localStorage.setItem(STORAGE_ACCESS_TOKEN, aResponse.accessToken);
      localStorage.setItem(STORAGE_USER_ID, aResponse.user._id);
      navigateTo("/");
    } catch (aError) {
      if (aError?.status === 400) {
        setError({
          hasError: true,
          errorText: "Adresse mail ou mot de passe incorrect.",
        });
        setErrorValues({
          hasMailError: true,
          hasPasswordError: true,
        });
      } else {
        setError({
          hasError: true,
          errorText: "Une erreur est survenue, veuillez réessayer.",
        });
      }
      setIsLoading(false);
      return;
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
    showPassword
      ? setPasswordInputType("password")
      : setPasswordInputType("text");
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleSubmit();
    }
  };

  return (
    <>
      <div style={{ minHeight: "calc(100vh - 250px)" }}>
        <NavMenu />
        <Grid container spacing={0}>
          <Grid item sm={2} md={3} />
          <Grid item xs={12} sm={8} md={6}>
            <Card sx={styles.card}>
              <form>
                <FormControl sx={styles.formControl}>
                  <LoginIcon sx={styles.titleIcon} />
                  <TextField
                    id="mail-input"
                    name="mail"
                    type="email"
                    label="Adresse Mail"
                    required={true}
                    variant="outlined"
                    value={formValues.mail}
                    error={errorValues.hasMailError}
                    helperText={errorValues.mail}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    sx={styles.field}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <EmailIcon style={styles.icon} />
                        </InputAdornment>
                      ),
                    }}
                  />

                  <TextField
                    id="password-input"
                    name="password"
                    type={passwordVisibility}
                    label="Mot de passe"
                    required={true}
                    variant="outlined"
                    autoComplete="on"
                    value={formValues.password}
                    error={errorValues.hasPasswordError}
                    helperText={errorValues.password}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    sx={styles.field}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LockIcon style={styles.icon} />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                          >
                            {showPassword ? (
                              <VisibilityIcon sx={styles.icon} />
                            ) : (
                              <VisibilityOffIcon sx={styles.icon} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <div style={styles.formFooter}>
                    <div style={{ marginTop: "-40px" }}>
                      <Link
                        style={styles.forgottenPassword}
                        to="/login/generate-reset-password-token"
                      >
                        <Typography sx={styles.information}>
                          Mot de passe oublié ?
                        </Typography>
                      </Link>
                      <Link to="/register">
                        <Typography sx={{ fontSize: "13px", marginTop: "5px" }}>
                          Pas encore inscrit ?
                        </Typography>
                      </Link>
                    </div>

                    <Typography sx={styles.information}>
                      * Champ obligatoire
                    </Typography>
                  </div>
                </FormControl>
              </form>
              <OctopusQRButton style={styles.button} onClick={handleSubmit}>
                Se connecter
              </OctopusQRButton>
              <Box sx={{ my: 1 }}>
                {isLoading ? <Loading /> : null}
                {error.hasError ? (
                  <Typography sx={styles.error}>{error.errorText}</Typography>
                ) : null}
              </Box>
            </Card>
          </Grid>
          <Grid item sm={2} md={3} />{" "}
        </Grid>
      </div>
      <Footer />
      <Outlet />
    </>
  );
}
