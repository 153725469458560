import api from "../../axios";
import { verifyPassword } from "../../../utils/forms/FormsVerification";

export const updatePassword = async (iUserId, iData) => {
  const aResponse = await api.patch(`/users/${iUserId}/password`, iData);
  return aResponse.data;
};

export const verifyUpdatePasswordData = (iFormValues) => {
  const aErrorValues = {
    hasOldPasswordError: false,
    oldPassword: "",
    hasPasswordError: false,
    password: "",
    hasConfirmedPasswordError: false,
    confirmedPassword: "",
  };

  if (iFormValues.password !== iFormValues.confirmedPassword) {
    aErrorValues.hasPasswordError = true;
    aErrorValues.hasConfirmedPasswordError = true;
    aErrorValues.confirmedPassword = "Les mots de passe ne correspondent pas";
  }

  if (iFormValues.password === iFormValues.oldPassword) {
    aErrorValues.hasPasswordError = true;
    aErrorValues.hasConfirmedPasswordError = true;
    aErrorValues.password = "Mot de passe identique au précédent";
  }

  if (!iFormValues.oldPassword) {
    aErrorValues.hasOldPasswordError = true;
    aErrorValues.oldPassword = "Champ obligatoire";
  }
  if (!iFormValues.confirmedPassword) {
    aErrorValues.hasConfirmedPasswordError = true;
    aErrorValues.confirmedPassword = "Champ obligatoire";
  }
  verifyPassword(iFormValues.password, aErrorValues);

  return aErrorValues;
};
