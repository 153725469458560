import {
  Box,
  Typography,
  TextField,
  RadioGroup,
  Radio,
  FormControl,
  Checkbox,
  Select,
  MenuItem,
  Card,
} from "@mui/material";
import { useEffect, useState } from "react";
import { styles } from "../../Render.style";
import { FORM_INPUT_TYPE_FR } from "../../../../../utils/constants/Constants";
import { capitalizeFirstLetter } from "../../../../../utils/Utils";
import { BlackButton } from "../../../../../utils/styled/Styled";
import Loading from "../../../../../components/loading/Loading";
import { postFormAnswer } from "../../../../../api/qrCodes/form/formQrHandler";
import { useNavigate } from "react-router-dom";

export default function FormDisplay({ qrContent }) {
  const initialErrorState = {
    hasError: false,
    errorText: "",
  };
  const [formState, setFormState] = useState();
  const [loading, setIsLoading] = useState(false);
  const [error, setError] = useState(initialErrorState);
  const navigateTo = useNavigate();

  useEffect(() => {
    let initialFormState = {};
    for (const [key, value] of Object.entries(qrContent?.items)) {
      initialFormState[key] = { answer: "", isRequired: value.isRequired };
    }
    setFormState(initialFormState);
  }, [qrContent]);

  if (!formState) {
    return <Loading />;
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState({
      ...formState,
      [name]: { isRequired: formState[name].isRequired, answer: value },
    });
  };

  const handleCheckboxChange = (event) => {
    const { name, value, checked } = event.target;
    setFormState((prevState) => {
      return {
        ...prevState,
        [name]: {
          isRequired: formState[name].isRequired,
          answer: checked
            ? [...prevState[name].answer, value]
            : prevState[name].answer.filter((val) => val !== value),
        },
      };
    });
  };

  const handleSubmit = async () => {
    setError(initialErrorState);
    setIsLoading(true);
    for (const item of Object.entries(formState)) {
      if (
        item[1].isRequired &&
        (item[1].answer === undefined ||
          item[1].answer === "" ||
          item[1].answer === null ||
          item[1].answer === [])
      ) {
        setError({
          hasError: true,
          errorText:
            "Vous n'avez pas compléter l'ensemble des réponses obligatoires.",
        });
        setIsLoading(false);
        return;
      }
    }

    let aFormAnswerBody = {};
    for (const [key, item] of Object.entries(formState)) {
      aFormAnswerBody[key] = item.answer;
    }

    try {
      await postFormAnswer(
        qrContent?.qrCodeId,
        qrContent?._id,
        aFormAnswerBody
      );
      navigateTo(`/answer-sent`);
    } catch {
      setIsLoading(false);
      setError({
        hasError: true,
        errorText: "Une erreur est survenue, veuillez réessayer.",
      });
    }
  };

  let fields = [];

  for (const [itemKey, itemValue] of Object.entries(qrContent?.items)) {
    switch (itemValue.type) {
      case FORM_INPUT_TYPE_FR.RADIO:
        fields.push(
          <>
            <Typography variant="h5">{itemValue.question}</Typography>
            {itemValue.isRequired ? (
              <Typography variant="overline" sx={{ fontSize: "10px" }}>
                * Réponse obligatoire
              </Typography>
            ) : null}

            <Box sx={{ ...styles.flexCenter, mt: 2 }}>
              <RadioGroup name={itemKey} value={formState[itemKey].answer}>
                {Object.entries(itemValue?.entries).map(
                  ([key, value], index) => {
                    return (
                      <Box
                        sx={{
                          ...styles.flexCenter,
                          minWidth: { xs: "300px", md: "500px", lg: "740px" },
                        }}
                        key={index}
                        onClick={() => {
                          handleChange({
                            target: {
                              name: itemKey,
                              value: value,
                            },
                          });
                        }}
                      >
                        <Radio value={value} />
                        <Box sx={{ flexBasis: "100%" }}>
                          <Typography variant="overline">{value}</Typography>
                        </Box>
                      </Box>
                    );
                  }
                )}
              </RadioGroup>
            </Box>
          </>
        );
        break;
      case FORM_INPUT_TYPE_FR.CHECKBOX:
        fields.push(
          <>
            <Typography variant="h5">{itemValue.question}</Typography>
            {itemValue.isRequired ? (
              <Typography variant="overline" sx={{ fontSize: "10px" }}>
                * Réponse obligatoire
              </Typography>
            ) : null}
            <Box sx={{ ...styles.flexColumnCenter, mt: 2 }}>
              {Object.entries(itemValue?.entries).map(([key, value], index) => {
                return (
                  <Box
                    sx={{
                      ...styles.flexCenter,
                      minWidth: { xs: "300px", md: "500px", lg: "740px" },
                    }}
                    key={index}
                  >
                    <Checkbox
                      name={itemKey}
                      value={value}
                      onChange={handleCheckboxChange}
                    />
                    <Box sx={{ flexBasis: "100%" }}>
                      <Typography variant="overline">{value}</Typography>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </>
        );
        break;
      case FORM_INPUT_TYPE_FR.SHORT_ANSWER:
        fields.push(
          <>
            <Typography variant="h5">{itemValue.question}</Typography>
            {itemValue.isRequired ? (
              <Typography variant="overline" sx={{ fontSize: "10px" }}>
                * Réponse obligatoire
              </Typography>
            ) : null}
            <FormControl sx={{ width: "100%", mt: 2 }}>
              <TextField
                name={itemKey}
                label={itemValue.type}
                onChange={handleChange}
                required={itemValue.isRequired}
                value={formState[itemKey].answer}
                variant="outlined"
                sx={styles.formField}
              />
            </FormControl>
          </>
        );
        break;
      case FORM_INPUT_TYPE_FR.LONG_ANSWER:
        fields.push(
          <>
            <Typography variant="h5">{itemValue.question}</Typography>
            {itemValue.isRequired ? (
              <Typography variant="overline" sx={{ fontSize: "10px" }}>
                * Réponse obligatoire
              </Typography>
            ) : null}
            <FormControl sx={{ width: "100%", mt: 2 }}>
              <TextField
                name={itemKey}
                label={itemValue.type}
                onChange={handleChange}
                required={itemValue.isRequired}
                variant="outlined"
                value={formState[itemKey].answer}
                sx={styles.formField}
                multiline={true}
                rows={3}
              />
            </FormControl>
          </>
        );
        break;
      case FORM_INPUT_TYPE_FR.SELECT:
        fields.push(
          <>
            <Typography variant="h5">{itemValue.question}</Typography>
            {itemValue.isRequired ? (
              <Typography variant="overline" sx={{ fontSize: "10px" }}>
                * Réponse obligatoire
              </Typography>
            ) : null}
            <Select
              sx={{ ...styles.formField, width: "100%", mt: 2 }}
              MenuProps={styles.menuProps}
              name={itemKey}
              onChange={handleChange}
              defaultValue={""}
              value={formState[itemKey].answer}
              displayEmpty
            >
              <MenuItem value={""} disabled>
                Sélectionnez votre réponse
              </MenuItem>
              {Object.entries(itemValue?.entries).map(([key, value], index) => {
                return (
                  <MenuItem value={value} key={index}>
                    <Box sx={styles.flexCenter}>
                      <Typography>{capitalizeFirstLetter(value)}</Typography>
                    </Box>
                  </MenuItem>
                );
              })}
            </Select>
          </>
        );
        break;
      default:
        break;
    }
  }

  return (
    <Box
      sx={{
        ...styles.flexColumnCenter,
        width: { xs: "350px", md: "550px", lg: "800px" },
        margin: "auto",
      }}
    >
      <Typography variant="h4" sx={{ mt: 2, textAlign: "center" }}>
        {qrContent.name}
      </Typography>
      {fields.map((aField, index) => {
        return (
          <Box key={index} sx={{ width: "98%", my: 3 }}>
            <Card sx={{ borderRadius: "20px", px: 4, py: 3 }}>{aField}</Card>
          </Box>
        );
      })}
      {error.hasError ? (
        <Typography sx={styles.errorText} variant="overline">
          {error.errorText}
        </Typography>
      ) : null}
      <BlackButton onClick={handleSubmit} sx={{ my: 3, width: "150px" }}>
        Envoyer
      </BlackButton>
      {loading ? <Loading /> : null}
    </Box>
  );
}
