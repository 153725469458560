import NavMenu from "../../components/navMenu/NavMenu";
import NotFound from "./NotFound";
import Footer from "../../components/footer/Footer";

export default function NotFoundWithMenu() {
  return (
    <>
      <div style={{ minHeight: "calc(100vh - 250px)" }}>
        <NavMenu />
        <NotFound />
      </div>
      <Footer />
    </>
  );
}
