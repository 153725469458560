import React, { useCallback, useEffect, useState } from "react";
import QRCodeStyling from "qr-code-styling";

import Loading from "../../../components/loading/Loading";

import { downloadQr } from "../../../api/qrCodes/qrCodesHandler";

import { retrieveQrCodeOptions } from "../../../utils/Utils";

import { OctopusQRButton } from "../../../utils/styled/Styled";
import { styles } from "../main/QRCodes.style";
import { saveAs } from "file-saver";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Select,
  DialogActions,
  MenuItem,
} from "@mui/material";

const DownloadQRCode = ({ open, onClose, qrCode }) => {
  const [extension, setExtension] = useState("png");
  const [isDownloading, setIsDownloading] = useState(false);
  const [qrCodeOption, setQrCodeOption] = useState();

  const fetchData = useCallback(async () => {
    const aQrCodeOption = await retrieveQrCodeOptions(qrCode);
    setQrCodeOption(aQrCodeOption);
  }, [qrCode]);
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const downloadQR = async () => {
    setIsDownloading(true);
    const HighResolutionQR = new QRCodeStyling({
      ...qrCodeOption,
      width: 1024,
      height: 1024,
    });

    if (extension === "pdf") {
      try {
        const response = await downloadQr(qrCode, extension);
        const blob = new Blob([response], {
          type: "application/pdf",
        });
        saveAs(blob, `${qrCode.name}.${extension}`);
        setIsDownloading(false);
        setExtension("png");
        onClose();
      } catch {}
    } else {
      HighResolutionQR.download({
        name: `${qrCode.name}`,
        extension: extension,
      });
      setIsDownloading(false);
      setExtension("png");
      onClose();
    }
  };

  if (!qrCodeOption) {
    return (
      <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
        <Loading />
      </Dialog>
    );
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>Choisir le format de téléchargement</DialogTitle>
      <DialogContent>
        <Select
          sx={{
            ...styles.searchBar,
            backgroundColor: "white",
            minWidth: "250px",
            width: "100%",
          }}
          label="Format"
          value={extension}
          onChange={(e) => setExtension(e.target.value)}
          fullWidth
          autoFocus
        >
          <MenuItem value={"png"}>PNG</MenuItem>
          <MenuItem value={"svg"}>SVG</MenuItem>
          <MenuItem value={"jpeg"}>JPEG</MenuItem>
          <MenuItem value={"webp"}>WEBP</MenuItem>
          <MenuItem value={"pdf"}>PDF</MenuItem>
        </Select>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <OctopusQRButton onClick={onClose} sx={{ width: "150px" }}>
          Annuler
        </OctopusQRButton>
        <OctopusQRButton onClick={downloadQR} sx={{ width: "150px" }}>
          Télécharger
        </OctopusQRButton>
      </DialogActions>
      {isDownloading && <Loading />}
    </Dialog>
  );
};

export default DownloadQRCode;
