import { Box, FormControl, TextField, IconButton } from "@mui/material";
import { useState } from "react";

import { styles } from "../FormContent.style";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { removeObjectWithId } from "../../../../../../utils/Utils";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function SelectContent({
  formValues,
  setFormValues,
  itemId,
  elementId,
  formData,
  updateFormData,
}) {
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
    updateFormData({
      items: {
        ...formData.items,
        [elementId]: {
          ...formData.items[elementId],
          entries: {
            ...formData.items[elementId].entries,
            [itemId]: value,
          },
        },
      },
    });
  };

  return (
    <FormControl sx={styles.choiceFormControl}>
      <TextField
        id={itemId}
        name={itemId}
        required={true}
        variant="outlined"
        value={formValues[itemId]}
        onChange={handleInputChange}
        sx={styles.field}
      />
    </FormControl>
  );
}

export default function SelectChoice({
  formData,
  updateFormData,
  elementId,
  itemsIds,
}) {
  const [numberOfItemsCreated, setNumberOfItemsCreated] = useState(
    formData?.items[elementId]?.entries
      ? Object.keys(formData.items[elementId].entries).length
      : 1
  );
  const [formValues, setFormValues] = useState(
    formData?.items[elementId]?.entries
      ? { ...formData.items[elementId].entries }
      : {
          select_1: "Option 1",
        }
  );

  const [items, setItems] = useState(itemsIds);

  const deleteCurrentItem = (iItemId) => {
    let aItems = [...items];
    removeObjectWithId(aItems, iItemId);
    let aFormData = { ...formData };
    delete aFormData.items[elementId].entries[iItemId];
    updateFormData(formData);
    setItems([...aItems]);
  };

  const addItem = () => {
    const aNewItemId = `select_${numberOfItemsCreated + 1}`;
    setNumberOfItemsCreated(numberOfItemsCreated + 1);
    updateFormData({
      items: {
        ...formData.items,
        [elementId]: {
          ...formData.items[elementId],
          entries: {
            ...formData.items[elementId].entries,
            [aNewItemId]: `Option ${items.length + 1}`,
          },
        },
      },
    });
    setFormValues({
      ...formValues,
      [aNewItemId]: `Option ${items.length + 1}`,
    });
    setItems([...items, { id: aNewItemId }]);
  };

  const content = items.map((item) => {
    return (
      <Box key={item.id} sx={{ width: "90%", margin: "auto" }}>
        <Box sx={styles.flexCenter}>
          <ExpandMoreIcon />
          <SelectContent
            formValues={formValues}
            setFormValues={setFormValues}
            itemId={item.id}
            elementId={elementId}
            formData={formData}
            updateFormData={updateFormData}
          />
          {items.length > 1 ? (
            <IconButton
              onClick={() => {
                deleteCurrentItem(item.id);
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </Box>
      </Box>
    );
  });

  return (
    <Box>
      {content}
      <br />
      <IconButton onClick={addItem}>
        <AddIcon />
      </IconButton>
    </Box>
  );
}
