import api from "../axios";

export const getQrCode = async (iQrCodeId) => {
  const aResponse = await api.get(`/qrcodes/${iQrCodeId}`);
  return aResponse.data;
};

export const getQrCodes = async (iUserId, pageNumber, search) => {
  const aResponse = await api.get(
    `/users/${iUserId}/qrcodes?search=${search}&pageNumber=${pageNumber}`
  );
  return aResponse.data;
};

export const getNumberOfQrCodes = async (iUserId) => {
  const aResponse = await api.get(`/users/${iUserId}/qrcodes/number`);
  return aResponse.data;
};

export const postQrCode = async (iUserId, iData) => {
  const aResponse = await api.post(`/users/${iUserId}/qrcodes`, iData);
  return aResponse.data;
};

export const putQrCode = async (iUserId, iQrCodeId, iData) => {
  const aResponse = await api.put(
    `/users/${iUserId}/qrcodes/${iQrCodeId}`,
    iData
  );
  return aResponse.data;
};

export const patchQrCodeName = async (iUserId, iQrCodeId, iData) => {
  const aResponse = await api.patch(
    `/users/${iUserId}/qrcodes/${iQrCodeId}/name`,
    iData
  );
  return aResponse.data;
};

export const patchQrCodeFolder = async (iUserId, iQrCodeId, iData) => {
  const aResponse = await api.patch(
    `/users/${iUserId}/qrcodes/${iQrCodeId}/folder`,
    iData
  );
  return aResponse.data;
};

export const deleteQrCode = async (iUserId, iQrCodeId) => {
  const aResponse = await api.delete(`/users/${iUserId}/qrcodes/${iQrCodeId}`);
  return aResponse.data;
};

export const postQrLogo = async (iUserId, iQRCodeId, iData) => {
  const aResponse = await api.post(
    `/users/${iUserId}/qrcodes/${iQRCodeId}/logo`,
    iData,
    { timeout: 10 * 60 * 1000 }
  );
  return aResponse.data;
};

export const deleteQrLogo = async (iUserId, iQRCodeId) => {
  const aResponse = await api.delete(
    `/users/${iUserId}/qrcodes/${iQRCodeId}/logo`
  );
  return aResponse.data;
};

export const downloadQr = async (qrCode, downloadFormat) => {
  const aResponse = await api.post(
    `/qrcodes/download`,
    {
      qrCodeId: qrCode._id,
      qrCodeName: qrCode.name,
      downloadFormat,
    },
    { responseType: "arraybuffer" }
  );
  return aResponse.data;
};
