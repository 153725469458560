export const styles = {
  card: {
    marginTop: "40px",
    textAlign: "center",
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    borderRadius: "20px",

    p: 2,
  },

  titleIcon: {
    color: "#58738D",
    fontSize: "40px",
    margin: "auto",
    mb: 5,
    mt: 3,
  },

  formControl: {
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },

  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  field: {
    marginBottom: "30px",
    backgroundColor: "white",
    borderRadius: "20px",
    width: "300px",
    [`& fieldset`]: {
      borderRadius: "20px",
      border: "1px",
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    },
  },

  button: {
    width: "150px",
    display: "block",
    margin: "20px auto",
  },

  formFooter: {
    display: "flex",
    justifyContent: "space-between",
  },

  information: {
    fontSize: "13px",
  },

  error: {
    fontSize: "16px",
    color: "#8B0000",
  },

  icon: {
    color: "#58738D",
  },

  imgAdornment: {
    marginRight: "10px",
    borderRadius: "10%",
  },

  boxContainer: {
    marginTop: "20px",
    display: "flex",
    alignItems: "center",
  },
};
