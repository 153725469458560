import React, { useState } from "react";
import { styles } from "./TextContent.style";
import { useNavigate } from "react-router-dom";
import { HexColorInput, HexColorPicker } from "react-colorful";

import { OctopusQRButton } from "../../../../../utils/styled/Styled";
import { RGBToHex } from "../../../../../utils/Utils";
import {
  ADD_QR_ACTIONS,
  FONT_TYPE,
  FONT_SIZE,
  STORAGE_USER_ID,
} from "../../../../../utils/constants/Constants";
import DeviceWrapper from "../deviceWrapper/DeviceWrapper";

import { postTextQr } from "../../../../../api/qrCodes/text/textQrHandler";
import {
  deleteQrCode,
  postQrLogo,
  postQrCode,
} from "../../../../../api/qrCodes/qrCodesHandler";

import Loading from "../../../../../components/loading/Loading";

import {
  Box,
  Card,
  Typography,
  FormControl,
  TextField,
  InputAdornment,
  MenuItem,
  InputLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Select,
  Grid,
  IconButton,
} from "@mui/material";
import AbcIcon from "@mui/icons-material/Abc";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import WestIcon from "@mui/icons-material/West";

export default function TextContent({
  currentAction,
  QRCode,
  file,
  putQRCode,
}) {
  const initialErrorState = {
    hasContentError: false,
    content: "",
  };
  const [formValues, setFormValues] = useState({
    content: "",
  });
  const [errorValues, setErrorValues] = useState(initialErrorState);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  //Customization part
  const [customizationValues, setCustomizationValues] = useState({
    backgroundColor: "#FFFFFF",
    color: "#000000",
    fontType: "'Roboto', sans-serif",
    fontSize: 15,
  });
  const [expanded, setExpanded] = useState(false);

  const handleChangeExpanded = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const updateFontType = (iEvent) => {
    setCustomizationValues({
      ...customizationValues,
      fontType: iEvent.target.value,
    });
  };

  const updateFontSize = (iEvent) => {
    setCustomizationValues({
      ...customizationValues,
      fontSize: iEvent.target.value,
    });
  };

  const updateTextColor = (iEvent) => {
    const aRgb = iEvent.target.querySelector("div").style.backgroundColor;
    const aHex = RGBToHex(aRgb);
    setCustomizationValues({ ...customizationValues, color: aHex });
  };

  const updateTextColorFromInput = (iEvent) => {
    setCustomizationValues({ ...customizationValues, color: iEvent });
  };

  const updateBackgroundColor = (iEvent) => {
    const aRgb = iEvent.target.querySelector("div").style.backgroundColor;
    const aHex = RGBToHex(aRgb);
    setCustomizationValues({ ...customizationValues, backgroundColor: aHex });
  };

  const updateBackgroundColorFromInput = (iEvent) => {
    setCustomizationValues({ ...customizationValues, backgroundColor: iEvent });
  };

  //Submit part
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigateTo = useNavigate();
  const handleSubmit = async () => {
    setErrorValues(initialErrorState);
    setIsLoading(true);
    if (!formValues.content) {
      setErrorValues({
        content: "Champs requis",
        hasContentError: true,
      });
      setIsLoading(false);
      return;
    }

    const aUserId = localStorage.getItem(STORAGE_USER_ID);
    if (!aUserId) {
      setHasError(true);
      setIsLoading(false);
      return;
    }

    let aQrCodeId;
    try {
      if (!QRCode?._id) {
        const aQRCode = await postQrCode(aUserId, QRCode);
        aQrCodeId = aQRCode._id;
        if (file !== null) {
          const aQrLogoData = new FormData();
          aQrLogoData.append("file", file);
          await postQrLogo(aUserId, aQrCodeId, aQrLogoData);
        }
      } else {
        await putQRCode();
        aQrCodeId = QRCode._id;
      }
      await postTextQr(aUserId, aQrCodeId, {
        qrCodeId: aQrCodeId,
        ...formValues,
        ...customizationValues,
      });
      navigateTo("/qrcodes");
    } catch {
      if (!QRCode?._id) {
        await deleteQrCode(aUserId, aQrCodeId);
      }
      setHasError(true);
      setIsLoading(false);
      return;
    }
  };

  const content = (
    <Box
      sx={{ ...styles.deviceBox, whiteSpace: "pre-line" }}
      style={{ backgroundColor: customizationValues.backgroundColor }}
    >
      <Typography
        sx={{
          fontFamily: customizationValues.fontType,
          fontSize: customizationValues.fontSize,
          color: customizationValues.color,
        }}
      >
        {formValues.content}
      </Typography>
    </Box>
  );

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Box
        sx={{
          ...styles.flexCenter,
          width: { xs: "100%", md: "95%", lg: "90%" },
        }}
      >
        <Card sx={styles.card}>
          <IconButton
            sx={styles.backArrow}
            onClick={() => {
              currentAction(ADD_QR_ACTIONS.CUSOMIZATION);
            }}
          >
            <WestIcon />
          </IconButton>
          <Typography variant="h4" sx={styles.titleContainer}>
            Intégration du texte
          </Typography>
          <Box>
            <Grid container spacing={0} sx={styles.flexCenter}>
              <Grid item md={12} lg={6} sx={{ margin: "auto" }}>
                <Box
                  sx={{
                    width: { lg: "90%", md: "90%", xs: "275px" },
                    m: "40px auto",
                  }}
                >
                  <FormControl sx={styles.formControl}>
                    <TextField
                      id="content-input"
                      name="content"
                      label="Texte"
                      required={true}
                      variant="outlined"
                      value={formValues.content}
                      error={errorValues.hasContentError}
                      helperText={errorValues.content}
                      onChange={handleInputChange}
                      sx={styles.field}
                      multiline={true}
                      rows={3}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AbcIcon style={styles.icon} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>

                  <Accordion
                    expanded={expanded === "panel1"}
                    onChange={handleChangeExpanded("panel1")}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography sx={styles.accordionTitle}>Police</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <FormControl sx={styles.formControl}>
                        <InputLabel id="font-label">Votre choix</InputLabel>
                        <Select
                          labelId="font-label"
                          sx={styles.field}
                          MenuProps={styles.menuProps}
                          value={customizationValues.fontType}
                          onChange={updateFontType}
                          label="Votre choix"
                        >
                          {FONT_TYPE.map((aFontType) => {
                            return (
                              <MenuItem value={aFontType} key={aFontType}>
                                <Typography sx={{ fontFamily: aFontType }}>
                                  {aFontType}
                                </Typography>
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expanded === "panel2"}
                    onChange={handleChangeExpanded("panel2")}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography sx={styles.accordionTitle}>Taille</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <FormControl sx={styles.formControl}>
                        <InputLabel id="font-label">Votre choix</InputLabel>
                        <Select
                          labelId="font-label"
                          sx={styles.field}
                          MenuProps={styles.menuProps}
                          value={customizationValues.fontSize}
                          onChange={updateFontSize}
                          label="Votre choix"
                        >
                          {FONT_SIZE.map((aFontSize) => {
                            return (
                              <MenuItem value={aFontSize} key={aFontSize}>
                                <Typography>{aFontSize}</Typography>
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expanded === "panel3"}
                    onChange={handleChangeExpanded("panel3")}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography sx={styles.accordionTitle}>
                        Couleur
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <HexColorPicker
                        color={customizationValues.color}
                        onMouseUp={updateTextColor}
                        style={styles.colorPicker}
                      />
                      <HexColorInput
                        color={customizationValues.color}
                        onChange={updateTextColorFromInput}
                      />
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expanded === "panel4"}
                    onChange={handleChangeExpanded("panel4")}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography sx={styles.accordionTitle}>
                        Couleur de fond
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <HexColorPicker
                        color={customizationValues.backgroundColor}
                        onMouseUp={updateBackgroundColor}
                        style={styles.colorPicker}
                      />
                      <HexColorInput
                        color={customizationValues.backgroundColor}
                        onChange={updateBackgroundColorFromInput}
                      />
                    </AccordionDetails>
                  </Accordion>
                  <OctopusQRButton
                    style={{ ...styles.button, marginTop: "40px" }}
                    onClick={handleSubmit}
                  >
                    Terminer
                  </OctopusQRButton>
                </Box>
              </Grid>
              <Grid item md={12} lg={6} sx={{ margin: "auto" }}>
                <Box sx={{ display: { lg: "block", xs: "none" } }}>
                  <DeviceWrapper
                    content={content}
                    backgroundColor={customizationValues.backgroundColor}
                  />
                </Box>
                <Box
                  sx={{
                    display: { lg: "none", md: "block", xs: "block" },
                    m: 5,
                  }}
                >
                  <Typography
                    variant="overline"
                    sx={{ fontWeight: "bold", m: 2 }}
                  >
                    Rendu
                  </Typography>
                  <Card
                    sx={styles.textCard}
                    style={{
                      backgroundColor: customizationValues.backgroundColor,
                    }}
                  >
                    {content}
                  </Card>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <br />
          {hasError ? (
            <Typography variant="overline" sx={styles.errorText}>
              Une erreur est survenue, veuillez réessayer
            </Typography>
          ) : null}
        </Card>
      </Box>
    </>
  );
}
