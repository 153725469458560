import React from "react";
import { useNavigate } from "react-router-dom";

// import { OctopusQRButton } from "../../utils/styled/Styled.js";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";

const styles = {
  center: {
    display: "block",
    margin: "auto",
    marginTop: "15px",
  },
  centeredIcon: {
    margin: "0px auto",
    display: "block",
    color: "#58738D",
  },
};
export default function ActionSuccess({ iFrom, iMessage, iHasPreviewButton }) {
  const navigate = useNavigate();

  const handleClose = () => {
    navigate(iFrom);
  };

  return (
    <Dialog open={true} onClose={handleClose}>
      <DialogTitle>
        <DoneIcon style={styles.centeredIcon} />
        <IconButton
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 1,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography>{iMessage}</Typography>
        {/* {iHasPreviewButton ? (
          <OctopusQRButton
            style={styles.center}
            onClick={() => {
              navigate("/customer-view");
            }}
          >
            Ma page
          </OctopusQRButton>
        ) : null} */}
      </DialogContent>
    </Dialog>
  );
}
