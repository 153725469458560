import { Typography } from "@mui/material";

const Quotation = () => {
  return (
    <Typography variant="h3" textAlign={"center"} sx={{ mt: 5 }}>
      En attente de MEG
    </Typography>
  );
};
export default Quotation;
