import {
  ADD_QR_ACTIONS,
  QR_CODE_TYPE,
} from "../../../../utils/constants/Constants";
import NotFound from "../../../notFound/NotFound";
import CustomPageContent from "./customPage/CustomPageContent";
import FileContent from "./file/FileContent";
import FormContent from "./form/FormContent";
import LinkContent from "./link/LinkContent";
import SocialNetworkContent from "./socialNetwork/SocialNetworkContent";
import TextContent from "./text/TextContent";
import VirtualCardContent from "./virtualCard/VirtualCardContent";
import WifiContent from "./wifi/WifiContent";

export default function QRCodeContent({
  currentAction,
  QRCode,
  file,
  qrCodeName,
}) {
  if (!qrCodeName) {
    currentAction(ADD_QR_ACTIONS.NAME);
  }

  let content;
  switch (QRCode.type) {
    case QR_CODE_TYPE.CUSTOM_PAGE:
      content = (
        <CustomPageContent
          currentAction={currentAction}
          QRCode={QRCode}
          file={file}
        />
      );
      break;
    case QR_CODE_TYPE.VIRTUAL_CARD:
      content = (
        <VirtualCardContent
          currentAction={currentAction}
          QRCode={QRCode}
          file={file}
        />
      );
      break;
    case QR_CODE_TYPE.WIFI:
      content = (
        <WifiContent
          currentAction={currentAction}
          QRCode={QRCode}
          file={file}
        />
      );
      break;
    case QR_CODE_TYPE.FILE:
      content = (
        <FileContent
          currentAction={currentAction}
          QRCode={QRCode}
          file={file}
        />
      );
      break;
    case QR_CODE_TYPE.FORM:
      content = (
        <FormContent
          currentAction={currentAction}
          QRCode={QRCode}
          file={file}
        />
      );
      break;
    case QR_CODE_TYPE.LINK:
      content = (
        <LinkContent
          currentAction={currentAction}
          QRCode={QRCode}
          file={file}
        />
      );
      break;
    case QR_CODE_TYPE.SOCIAL_NETWORK:
      content = (
        <SocialNetworkContent
          currentAction={currentAction}
          QRCode={QRCode}
          file={file}
        />
      );
      break;
    case QR_CODE_TYPE.TEXT:
      content = (
        <TextContent
          currentAction={currentAction}
          QRCode={QRCode}
          file={file}
        />
      );
      break;
    default:
      <NotFound />;
      break;
  }
  return <>{content}</>;
}
