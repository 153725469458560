export const styles = {
  title: { marginTop: { xs: 3, lg: -7, md: -5 }, marginBottom: 3 },

  card: {
    mt: 2,
    mx: 2,
    mb: 6,
    width: { lg: "55%", md: "70%", xs: "95%" },
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    borderRadius: "20px",
    textAlign: "center",
  },

  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  flexColumn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },

  flexEvenly: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    flexWrap: "wrap",
  },

  backArrow: {
    display: "flex",
    justifyContent: "start",
    m: { lg: 2, md: 1, xs: 0 },
    fontSize: "230px",
    color: "#58738D",
  },

  field: {
    my: 1,
    width: "300px",
    backgroundColor: "white",
    borderRadius: "20px",
    [`& fieldset`]: {
      borderRadius: "20px",
      border: "1px",
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    },
  },

  button: {
    width: "150px",
    my: 3,
  },

  errorText: {
    color: "#8B0000",
    fontWeight: "bold",
    fontSize: 12,
  },
};
