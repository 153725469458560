import { useCallback, useEffect, useRef, useState } from "react";
import QRCodeStyling from "qr-code-styling";
import { Box, Typography } from "@mui/material";
import { retrieveQrCodeOptions, isLightColor } from "../../../../utils/Utils";
import Loading from "../../../../components/loading/Loading";
import WarningIcon from "@mui/icons-material/Warning";

export default function QRCodeDisplay({ qrCode }) {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [imageId, setImageId] = useState("");
  const [displayWarning, setDisplayWarning] = useState(false);
  const imageSrc = useRef();

  const convertQrToRawData = useCallback(async () => {
    setIsLoading(true);
    try {
      const aQrCodeOption = await retrieveQrCodeOptions(qrCode);
      const isLightDotsColor = isLightColor(qrCode.dotsColor);
      const isLightSquaresColor = isLightColor(qrCode.cornerSquaresColor);
      const isLightDotColor = isLightColor(qrCode.cornerDotColor);
      setDisplayWarning(
        (isLightDotsColor || isLightSquaresColor || isLightDotColor) &&
          qrCode.backgroundColor === "transparent"
      );
      let userQrCode = new QRCodeStyling({ ...aQrCodeOption });
      const aQrRawData = await userQrCode.getRawData();

      let urlCreator = window.URL || window.webkitURL;
      imageSrc.current = urlCreator.createObjectURL(aQrRawData);
    } catch {
      setHasError(true);
    }

    setIsLoading(false);
  }, [qrCode]);

  useEffect(() => {
    convertQrToRawData();
    setImageId(qrCode.name.toLowerCase().replace(" ", "_") + qrCode._id);
  }, [convertQrToRawData, qrCode]);

  if (hasError) {
    return (
      <Typography
        variant="overline"
        sx={{ color: "#8B0000", fontWeight: "bold", fontSize: 12 }}
      >
        Une erreur est survenue, veuillez réessayer
      </Typography>
    );
  }
  if (!imageId || isLoading) {
    return (
      <Box
        sx={{
          height: "235px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Loading />
      </Box>
    );
  }

  return (
    <Box>
      <Box sx={{ mt: 2 }}>
        {displayWarning && (
          <Box
            sx={{
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <WarningIcon sx={{ mr: 1, color: "#8B0000" }} />
            <Typography variant="overline" sx={{ fontSize: "10px" }}>
              QR Code blanc sur fond transparent
            </Typography>
          </Box>
        )}
        <img
          id={imageId}
          src={imageSrc.current}
          alt={`QR Code ${qrCode.name} ${qrCode._id}`}
        />
      </Box>
      <br />
    </Box>
  );
}
