import React from "react";

import { styles } from "../QRCodes.style";

import { QR_CODE_TYPE } from "../../../../utils/constants/Constants";
import QRCodeAction from "./QRCodeAction";
import QRCodeDisplay from "./QRCodeDisplay";

import { Box, Card, Typography, Divider, Checkbox } from "@mui/material";
import PublicIcon from "@mui/icons-material/Public";
import AbcIcon from "@mui/icons-material/Abc";
import LinkIcon from "@mui/icons-material/Link";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import ListAltIcon from "@mui/icons-material/ListAlt";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import WifiIcon from "@mui/icons-material/Wifi";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import { dragImage } from "./DragImage";

import { DragPreviewImage, useDrag } from "react-dnd";

const QRCodeCard = ({
  qrCode,
  user,
  index,
  selectedQrs,
  setSelectedQrs,
  qrCodes,
}) => {
  let aImgDisplay;
  switch (qrCode.type) {
    case QR_CODE_TYPE.CUSTOM_PAGE:
      aImgDisplay = (
        <Box sx={styles.imageContainer}>
          <GroupWorkIcon sx={styles.icon} />
          <Typography variant="overline"> - Page personnalisée</Typography>
        </Box>
      );
      break;
    case QR_CODE_TYPE.VIRTUAL_CARD:
      aImgDisplay = (
        <Box sx={styles.imageContainer}>
          <AssignmentIndIcon sx={styles.icon} />
          <Typography variant="overline"> - Fiche contact</Typography>
        </Box>
      );
      break;
    case QR_CODE_TYPE.WIFI:
      aImgDisplay = (
        <Box sx={styles.imageContainer}>
          <WifiIcon sx={styles.icon} />
          <Typography variant="overline"> - Wifi</Typography>
        </Box>
      );
      break;
    case QR_CODE_TYPE.FILE:
      aImgDisplay = (
        <Box sx={styles.imageContainer}>
          <AttachFileIcon sx={styles.icon} />
          <Typography variant="overline"> - Fichier</Typography>
        </Box>
      );
      break;
    case QR_CODE_TYPE.FORM:
      aImgDisplay = (
        <Box sx={styles.imageContainer}>
          <ListAltIcon sx={styles.icon} />
          <Typography variant="overline"> - Formulaire</Typography>
        </Box>
      );
      break;
    case QR_CODE_TYPE.LINK:
      aImgDisplay = (
        <Box sx={styles.imageContainer}>
          <LinkIcon sx={styles.icon} />
          <Typography variant="overline"> - Lien</Typography>
        </Box>
      );
      break;
    case QR_CODE_TYPE.TEXT:
      aImgDisplay = (
        <Box sx={styles.imageContainer}>
          <AbcIcon sx={styles.icon} />
          <Typography variant="overline"> - Texte</Typography>
        </Box>
      );
      break;
    case QR_CODE_TYPE.SOCIAL_NETWORK:
      aImgDisplay = (
        <Box sx={styles.imageContainer}>
          <PublicIcon sx={styles.icon} />
          <Typography variant="overline"> - Réseau social</Typography>
        </Box>
      );
      break;
    default:
      break;
  }

  return (
    <>
      <Box sx={{ display: { xs: "none", md: "flex" } }}>
        <WideScreenQRCodeCard
          qrCode={qrCode}
          user={user}
          index={index}
          imgDisplay={aImgDisplay}
          selectedQrs={selectedQrs}
          setSelectedQrs={setSelectedQrs}
          qrCodes={qrCodes}
        />
      </Box>
      <Box sx={{ display: { xs: "flex", md: "none" } }}>
        <PhoneQRCodeCard qrCode={qrCode} user={user} imgDisplay={aImgDisplay} />
      </Box>
    </>
  );
};

let lastChecked = null;
const WideScreenQRCodeCard = ({
  qrCode,
  user,
  index,
  imgDisplay,
  selectedQrs,
  setSelectedQrs,
  qrCodes,
}) => {
  const handleCheckboxChange = (event, qrCode, index) => {
    if (event.shiftKey && lastChecked !== null) {
      const start = Math.min(index, lastChecked);
      const end = Math.max(index, lastChecked) + 1;
      const toBeChecked = qrCodes.slice(start, end);
      const toBeCheckedFiltered = toBeChecked.filter(
        (item) => !selectedQrs.includes(item)
      );

      setSelectedQrs([...selectedQrs, ...toBeCheckedFiltered]);
    } else {
      if (event.target.checked) {
        setSelectedQrs([...selectedQrs, qrCode]);
      } else {
        setSelectedQrs(
          selectedQrs.filter((selectedQr) => selectedQr !== qrCode)
        );
      }
    }
    lastChecked = index;
  };

  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: "card",
      item: { id: qrCode._id, index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [qrCode._id, index]
  );

  return (
    <>
      <DragPreviewImage connect={preview} src={dragImage} />

      <Card
        sx={{
          ...styles.card,
          position: "relative",
        }}
        style={{
          opacity: isDragging ? 0.3 : 1,
        }}
      >
        <Checkbox
          sx={{
            position: "absolute",
            left: 4,
            top: 4,
          }}
          onClick={(event) => handleCheckboxChange(event, qrCode, index)}
          checked={selectedQrs.includes(qrCode)}
        />
        <Box sx={{ width: "100%" }}>
          <div
            ref={drag}
            style={{
              cursor: "grab",
            }}
          >
            <DragHandleIcon
              sx={{
                fontSize: "30px",
              }}
            />
            <Typography
              sx={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {qrCode.name}
            </Typography>
          </div>
        </Box>
        {imgDisplay}

        <Divider sx={styles.divider} />

        <QRCodeDisplay qrCode={qrCode} />
        <QRCodeAction qrCode={qrCode} user={user} />
      </Card>
    </>
  );
};

const PhoneQRCodeCard = ({ qrCode, user, imgDisplay }) => {
  return (
    <Card sx={styles.card}>
      <Typography
        variant="h4"
        sx={{
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
      >
        {qrCode.name}
      </Typography>
      {imgDisplay}
      <Divider sx={styles.divider} />

      <QRCodeDisplay qrCode={qrCode} />
      <QRCodeAction qrCode={qrCode} user={user} />
    </Card>
  );
};

export default QRCodeCard;
