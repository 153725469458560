import { useState } from "react";
import { FileUploader } from "react-drag-drop-files";

import { styles } from "../../CustomPageContent.style";
import {
  OctopusQRButton,
  UploaderBox,
} from "../../../../../../../utils/styled/Styled";

import {
  Box,
  Typography,
  FormControl,
  TextField,
  InputAdornment,
} from "@mui/material";
import AbcIcon from "@mui/icons-material/Abc";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  DEVICE_SORTABLE_INDEX,
  FILE_EXTENSIONS_MAP,
} from "../../../../../../../utils/constants/Constants";

export default function CustomFiles({
  linksAndFiles,
  setLinksAndFiles,
  items,
  setItems,
}) {
  const initialErrorState = {
    value: "",
    hasError: false,
  };
  const fileTypes = ["JPG", "PNG", "JPEG", "PDF", "MP4"];
  const [fileName, setFileName] = useState("");
  const [fileNameError, setFileNameError] = useState(initialErrorState);
  const updateFileName = (iEvent) => {
    setFileName(iEvent.target.value);
  };
  const [file, setFile] = useState(null);
  const [hasFileError, setHasFileError] = useState(false);
  const [fileErrorText, setFileErrorText] = useState("");

  const resetErrors = () => {
    setFile(null);
    setHasFileError(false);
    setFileErrorText("");
  };

  const handleFileAdded = (file) => {
    resetErrors();
    if (file.size > 35000000) {
      setHasFileError(true);
      setFileErrorText("Fichier trop volumineux, 35Mo maximum autorisé");
      return;
    }
    setFile(file);
  };

  const addFile = () => {
    setFileNameError(initialErrorState);
    setHasFileError(false);
    setFileErrorText("");

    if (!fileName) {
      setFileNameError({
        value: "Champs requis",
        hasError: true,
      });
      return;
    }
    if (!file) {
      setHasFileError(true);
      setFileErrorText("Vous devez importer un fichier");
      return;
    }
    const aFiles = Array.from(linksAndFiles);
    const aFileExtension = file.name.split(".").pop().toUpperCase();
    aFiles.push({
      isLink: false,
      file: file,
      fileName: file.name,
      name: fileName,
      type: FILE_EXTENSIONS_MAP[aFileExtension],
      id: `link_${aFiles.length + 1}`,
      position: aFiles.length + 1,
    });

    const aItems = Array.from(items);

    aItems.find(
      (aItem) => aItem.id === DEVICE_SORTABLE_INDEX.LINKS_AND_FILES
    ).content = aFiles;
    setItems(aItems);
    setLinksAndFiles(aFiles);
    setFile(null);
    setFileName("");
  };

  const aUploadBox = (
    <UploaderBox style={styles.uploader}>
      <CloudUploadIcon style={styles.centeredIcon} />
      <Typography sx={{ mt: 5, mr: 2, ml: 2 }}>
        <strong style={{ textDecoration: "underline" }}>Sélectionner</strong> ou
        glisser pour importer un fichier
      </Typography>
      <Typography sx={{ mt: 3, mr: 2, ml: 2, mb: 4 }}>
        Accepté : PNG, JPG, JPEG, PDF, MP4
      </Typography>
    </UploaderBox>
  );

  return (
    <Box sx={styles.uploaderContainer}>
      <FormControl sx={{ width: "88%" }}>
        <TextField
          label="Nom du fichier"
          variant="outlined"
          value={fileName}
          onChange={updateFileName}
          error={fileNameError.hasError}
          helperText={fileNameError.value}
          sx={styles.field}
          placeholder="Locaux de l'entreprise"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AbcIcon style={styles.icon} />
              </InputAdornment>
            ),
          }}
        />
      </FormControl>
      <Box sx={{ my: 3 }} />
      <FileUploader
        sx={styles.uploader}
        children={aUploadBox}
        handleChange={handleFileAdded}
        name="file"
        types={fileTypes}
        label="Sélectionner ou glisser déposer un fichier."
        hoverTitle="Déposez ici"
      />
      {hasFileError ? (
        <Typography variant="overline" sx={styles.errorText}>
          {fileErrorText}
        </Typography>
      ) : (
        <>
          {file ? (
            <Typography variant="overline" sx={styles.text}>
              Fichier importé : {file.name}
            </Typography>
          ) : (
            <Typography variant="overline" sx={styles.text}>
              Aucun fichier pour le moment
            </Typography>
          )}
        </>
      )}
      <br />
      <OctopusQRButton sx={{ ...styles.button, mb: 2 }} onClick={addFile}>
        Ajouter
      </OctopusQRButton>
    </Box>
  );
}
