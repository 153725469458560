import { styles } from "../QRCodeView.style";
import { useState } from "react";

import { Box } from "@mui/material";

import SelectType from "./selectType/SelectType";
import {
  DYNAMIC_QR_TYPE,
  EDIT_TYPE_ACTION,
} from "../../../../utils/constants/Constants";
import AddContent from "./addContent/AddContent";
import NotFound from "../../../notFound/NotFound";

export default function EditQRCodeType({
  qrCode,
  qrType,
  setQrType,
  setQrContent,
  putQRCode,
  defaultType,
  setHasBeenSaved,
}) {
  const [hasChange, setHasChange] = useState(false);

  const [currentAction, setCurrentAction] = useState(EDIT_TYPE_ACTION.TYPE);

  if (!DYNAMIC_QR_TYPE.includes(qrType)) {
    return <NotFound />;
  }

  const handleChangeType = (iEvent) => {
    setHasBeenSaved(false);
    const aType = iEvent.target.value;
    setHasChange(true);
    if (aType === defaultType) {
      setHasChange(false);
    }
    setQrType(aType);
  };

  const content =
    currentAction === EDIT_TYPE_ACTION.TYPE ? (
      <SelectType
        qrType={qrType}
        hasChange={hasChange}
        handleChangeType={handleChangeType}
        setCurrentAction={setCurrentAction}
      />
    ) : (
      <AddContent
        qrType={qrType}
        QRCode={qrCode}
        setCurrentAction={setCurrentAction}
        putQRCode={putQRCode}
      />
    );

  return (
    <>
      <Box sx={styles.flexCenter}>
        <Box
          sx={{
            ...styles.flexColumnCenter,
            width: "85%",
            mt: 6,
          }}
        >
          {content}
        </Box>
      </Box>
    </>
  );
}
