export const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  formContainer: {
    minWidth: "335px",
    maxWidth: "342px",
    textAlign: "center",
  },
  planContainer: {
    paddingTop: 3,
    display: "flex",
    flexWrap: "wrap",
    paddingLeft: 2,
    paddingRight: 2,
    justifyContent: "center",
    alignItems: "center",
  },
  inputText: {
    mx: 2,
    mb: "20px",
    backgroundColor: "white",
    borderRadius: "20px",
    [`& fieldset`]: {
      borderRadius: "20px",
      border: "1px",
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    },
  },
  eiffageRadio: {
    display: "flex",
    flexWrap: "wrap",
    paddingLeft: 2,
    paddingRight: 2,
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",
    "& .MuiRadio-root.Mui-checked": {
      color: "#E4010B",
    },
    "& .MuiRadio-root": {
      color: "#E4010B",
    },
  },
  radioContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  flexContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  radioTypo: {
    mt: 4,
    mb: 1,
    textDecoration: "underline",
    fontWeight: "bold",
    p: 1,
  },
  divider: {
    my: 2,
  },
  submitButton: { width: "250px", margin: "40px auto" },
  subTitle: { fontSize: "25px", fontWeight: "bold" },
};

// const escalibMapping = {
//   lastName: "Nom",
//   firstName: "Prénom",
//   controlOn: "Contrôle réalisé sur",
//   recept: "PV de réception Escalier & ESCALIB N°",
//   controlType: "Type de contrôle",
//   loc: "Localisation",
//   floor: "Assise au sol / Réglage des pieds.",
//   horiz: "Horizontalité / verticalité de l’Escalib.",
//   orient:
//     "Orientation de l’Escalib suivant la dalle à desservir. Sortie entièrement sécurisée.",
//   access:
//     "Accessibilité au pied de l’Escalib par au moins 1 des 2 faces d’entrée (face principale A ou secondaire B).",
//   assembl:
//     "Assemblage des modules et de l’embase : Présence des 4 vis M24, correctement serrées.",
//   presence:
//     "Présence indispensable et bon positionnement du garde-corps de fermeture et de son boulon.",
//   module:
//     "Sur le module de tête, lisse de montage en position verticale si la dalle à desservir se situe face B ou en position horizontale pour les autres faces.",
//   verrou:
//     "Hormis la face desservant la dalle, verrouillage correct de toutes les lisses garde-corps.",
//   amarr: "Amarrage de l’Escalib conforme à la notice technique et/ou au plan.",
//   absenc:
//     "Absence de déformation permanente ou de corrosion significative des éléments de la structure.",
//   comm: "Commentaires",
//   autor: "Autorisation d'utilisation",
// };

// const echafMapping = {
//   lastName: "Nom",
//   firstName: "Prénom",
//   controlOn: "Contrôle réalisé sur",
//   recept: "PV de réception Escalier & ESCALIB N°",
//   echafType: "Type d'échafaudage",
//   controlType: "Type de contrôle",
//   loc: "Localisation",
//   prot_pli: "Protections collectives - plinthes",
//   prot_liss: "Protections collectives - lisses / sous-lisses",
//   prot_liss_plus:
//     "Protections collectives - lisses supplémentaires sur la travée d'accès",
//   prot_gard:
//     "Protections collectives - Garde-corps si échafaudage à + de 20cm de l’ouvrage",
//   acc_plan: "Planchers / paliers correctement montés, continus, non encombrés",
//   acc_ech: "Echelles / escaliers",
//   acc_trap: "Trappes",
//   acc_abs: "Absence de charges dépassants les limites admisibles",
//   fix_lia_gar: "Liaisons garde-corps / poteaux",
//   fix_lia_con: "Liaisons contreventements / poteaux",
//   fix_pat: "Patte anti-soulèvement des plateaux",
//   fix_lia_pot: "Liaisons poteaux / poteaux",
//   fix_lia_pie: "Liaisons pieds / poteaux",
//   fix_pres: "Si présence de filets ou bâches  : bon état et fixtion",
//   ama_ancr: "Amarrage sur ancrage à l’ouvrage",
//   ama_stab: "Stabilisateurs",
//   ama_stab_terr: "Stabilité du terrain",
//   ama_cal: "Calage des pieds",
//   ama_fre: "Freins en place et en bon état sur chaques roues",
//   mont: "Montage & matériel conforme à la notice du fabricant",
//   aspe: "Aspect visuel, état du matériel, corrosion",
//   comm: "Commentaires",
//   autor: "Autorisation d'utilisation",
// };
