import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { styles } from "../../../add/content/form/FormContent.style";
import FormSortable from "../../../add/content/form/formSortable/FormSortable";

import Loading from "../../../../../components/loading/Loading";
import { OctopusQRButton } from "../../../../../utils/styled/Styled";
import {
  putFormQr,
  verifyFormData,
} from "../../../../../api/qrCodes/form/formQrHandler";
import {
  Box,
  Card,
  Typography,
  IconButton,
  FormControl,
  TextField,
  InputAdornment,
} from "@mui/material";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AbcIcon from "@mui/icons-material/Abc";
import WarningIcon from "@mui/icons-material/Warning";

export default function EditFormContent({
  user,
  qrCode,
  qrContent,
  formItems,
  setQrContent,
  setHasBeenSaved,
}) {
  const navigateTo = useNavigate();
  const [formData, setFormData] = useState({
    formTitle: qrContent?.name,
    items: qrContent?.items,
  });

  const updateFormData = (iValue) => {
    setFormData({ ...formData, ...iValue });
  };

  const [errorValues, setErrorValues] = useState({
    formTitle: "",
    hasFormTitleError: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const initialApiErrorState = {
    hasError: false,
    errorText: "",
  };
  const [apiError, setApiError] = useState(initialApiErrorState);

  const [numberOfItemsCreated, setNumberOfItemsCreated] = useState(
    formItems.length
  );
  const [items, setItems] = useState(formItems);

  const handleInputChange = (iEvent) => {
    setFormData({ ...formData, formTitle: iEvent.target.value });
  };

  const saveChanges = async () => {
    setIsLoading(true);
    setHasError(false);
    setApiError(initialApiErrorState);
    setErrorText("");

    const aErrorValues = verifyFormData(formData);

    setErrorValues({ ...aErrorValues });
    if (aErrorValues.hasFormTitleError) {
      setHasError(true);
      setErrorText("Vous devez saisir un titre");
      setIsLoading(false);
      return;
    }

    const aItems = Array.from(items);
    aItems.forEach((aItem) => {
      formData.items[aItem.id].position = aItem.position;
    });

    try {
      const aNewForm = await putFormQr(
        user._id,
        qrCode._id,
        qrContent._id,
        formData
      );
      setQrContent({ ...aNewForm });
      setHasBeenSaved(true);
      setIsLoading(false);
      if (qrCode.folderId) {
        navigateTo(`/qrcodes/folder/${qrCode.folderId}`);
      } else {
        navigateTo(`/qrcodes`);
      }
    } catch {
      setApiError({
        hasError: true,
        errorText: "Une erreur est survenue, veuillez réessayer",
      });
      setIsLoading(false);
      return;
    }
  };

  const addItem = async () => {
    const aNewId = `item_${numberOfItemsCreated + 1}`;
    setFormData({
      ...formData,
      items: {
        ...formData.items,
        [aNewId]: {
          question: "Question",
          type: "Réponse courte",
          entries: {},
          isRequired: true,
        },
      },
    });
    setItems([
      ...items,
      {
        id: aNewId,
        position: items.length + 1,
      },
    ]);
    setNumberOfItemsCreated(numberOfItemsCreated + 1);
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Box
        sx={{
          ...styles.flexCenter,
          width: "100%",
        }}
      >
        <Card sx={styles.card}>
          {apiError.hasError ? (
            <Typography variant="overline" sx={{ ...styles.errorText, m: 3 }}>
              {apiError.errorText}
            </Typography>
          ) : null}
          <WarningIcon sx={{ color: "#8B0000", margin: "auto", mt: 2 }} />
          <Box
            sx={{
              ...styles.flexColumnCenter,
              width: "80%",
              margin: "auto",
              mt: -1,
              mb: 2,
              textAlign: "justify",
            }}
          >
            <br />
            <Typography
              variant="overline"
              color={"red"}
              sx={{ textAlign: "justify" }}
            >
              La modification du Formulaire entraine la perte des précédentes
              réponses. Soyez sûr de les avoir enregistrer sur la page
              "Réponses" avant de finaliser ces modifications.
            </Typography>
          </Box>
          <Typography variant="h4" sx={{ mb: 3 }}>
            Modification du formulaire
          </Typography>
          <br />
          <FormControl sx={styles.formControl}>
            <TextField
              id="form-title-input"
              name="formTitle"
              label="Titre"
              required={true}
              variant="outlined"
              value={formData.formTitle}
              error={errorValues.hasFormTitleError}
              helperText={errorValues.formTitle}
              onChange={handleInputChange}
              sx={styles.field}
              placeholder="Questionnaire"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AbcIcon />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
          <br />
          <FormSortable
            items={items}
            setItems={setItems}
            formData={formData}
            updateFormData={updateFormData}
          />

          <br />
          <IconButton onClick={addItem}>
            <AddCircleOutlineIcon />
          </IconButton>
          <br />
          <OctopusQRButton sx={styles.button} onClick={saveChanges}>
            Sauvegarder
          </OctopusQRButton>
          <br />
          {hasError ? (
            <Typography variant="overline" sx={styles.errorText}>
              {errorText}
            </Typography>
          ) : null}
        </Card>
      </Box>
    </>
  );
}
