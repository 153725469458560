import { styles } from "../AddQRCode.style";
import {
  ADD_QR_ACTIONS,
  QR_CODE_TYPE,
  QR_CODE_TYPE_ARRAY,
} from "../../../../utils/constants/Constants";

import { Box, Card, Typography, IconButton, Button } from "@mui/material";
import PublicIcon from "@mui/icons-material/Public";
import AbcIcon from "@mui/icons-material/Abc";
import LinkIcon from "@mui/icons-material/Link";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import ListAltIcon from "@mui/icons-material/ListAlt";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import WestIcon from "@mui/icons-material/West";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import WifiIcon from "@mui/icons-material/Wifi";

export default function QRCodeType({ currentAction, qrCodeType, qrCodeName }) {
  if (!qrCodeName) {
    currentAction(ADD_QR_ACTIONS.NAME);
  }

  let qrTypeContent;

  qrTypeContent = QR_CODE_TYPE_ARRAY.map((aQrCodeType, index) => {
    switch (aQrCodeType) {
      case QR_CODE_TYPE.CUSTOM_PAGE:
        return (
          <Button
            variant="outlined"
            sx={styles.button}
            onClick={() => {
              qrCodeType(aQrCodeType);
              currentAction(ADD_QR_ACTIONS.CUSOMIZATION);
            }}
            key={index}
          >
            <Box sx={styles.imageContainer}>
              <GroupWorkIcon sx={styles.icon} />
              <Typography variant="overline" sx={styles.typeTypo}>
                {" "}
                - Page personnalisée
              </Typography>
            </Box>
          </Button>
        );
      case QR_CODE_TYPE.VIRTUAL_CARD:
        return (
          <Button
            variant="outlined"
            sx={styles.button}
            onClick={() => {
              qrCodeType(aQrCodeType);
              currentAction(ADD_QR_ACTIONS.CUSOMIZATION);
            }}
            key={index}
          >
            <Box sx={styles.imageContainer}>
              <AssignmentIndIcon sx={styles.icon} />
              <Typography variant="overline" sx={styles.typeTypo}>
                {" "}
                - Fiche contact <strong>*</strong>
              </Typography>
            </Box>
          </Button>
        );

      case QR_CODE_TYPE.WIFI:
        return (
          <Button
            variant="outlined"
            sx={styles.button}
            onClick={() => {
              qrCodeType(aQrCodeType);
              currentAction(ADD_QR_ACTIONS.CUSOMIZATION);
            }}
            key={index}
          >
            <Box sx={styles.imageContainer}>
              <WifiIcon sx={styles.icon} />
              <Typography variant="overline" sx={styles.typeTypo}>
                {" "}
                - Wifi <strong>*</strong>
              </Typography>
            </Box>
          </Button>
        );
      case QR_CODE_TYPE.FILE:
        return (
          <Button
            variant="outlined"
            sx={styles.button}
            onClick={() => {
              qrCodeType(aQrCodeType);
              currentAction(ADD_QR_ACTIONS.CUSOMIZATION);
            }}
            key={index}
          >
            <Box sx={styles.imageContainer}>
              <AttachFileIcon sx={styles.icon} />
              <Typography variant="overline" sx={styles.typeTypo}>
                {" "}
                - Fichier
              </Typography>
            </Box>
          </Button>
        );
      case QR_CODE_TYPE.FORM:
        return (
          <Button
            variant="outlined"
            sx={styles.button}
            onClick={() => {
              qrCodeType(aQrCodeType);
              currentAction(ADD_QR_ACTIONS.CUSOMIZATION);
            }}
            key={index}
          >
            <Box sx={styles.imageContainer}>
              <ListAltIcon sx={styles.icon} />
              <Typography variant="overline" sx={styles.typeTypo}>
                {" "}
                - Formulaire
              </Typography>
            </Box>
          </Button>
        );
      case QR_CODE_TYPE.LINK:
        return (
          <Button
            variant="outlined"
            sx={styles.button}
            onClick={() => {
              qrCodeType(aQrCodeType);
              currentAction(ADD_QR_ACTIONS.CUSOMIZATION);
            }}
            key={index}
          >
            <Box sx={styles.imageContainer}>
              <LinkIcon sx={styles.icon} />
              <Typography variant="overline" sx={styles.typeTypo}>
                {" "}
                - Lien
              </Typography>
            </Box>
          </Button>
        );
      case QR_CODE_TYPE.TEXT:
        return (
          <Button
            variant="outlined"
            sx={styles.button}
            onClick={() => {
              qrCodeType(aQrCodeType);
              currentAction(ADD_QR_ACTIONS.CUSOMIZATION);
            }}
            key={index}
          >
            <Box sx={styles.imageContainer}>
              <AbcIcon sx={styles.icon} />
              <Typography variant="overline" sx={styles.typeTypo}>
                {" "}
                - Texte
              </Typography>
            </Box>
          </Button>
        );
      case QR_CODE_TYPE.SOCIAL_NETWORK:
        return (
          <Button
            variant="outlined"
            sx={styles.button}
            onClick={() => {
              qrCodeType(aQrCodeType);
              currentAction(ADD_QR_ACTIONS.CUSOMIZATION);
            }}
            key={index}
          >
            <Box sx={styles.imageContainer}>
              <PublicIcon sx={styles.icon} />
              <Typography variant="overline" sx={styles.typeTypo}>
                {" "}
                - Réseau social
              </Typography>
            </Box>
          </Button>
        );
      default:
        break;
    }
    return <Box key={index} />;
  });
  return (
    <>
      <Box
        sx={{
          ...styles.flexCenter,
          width: { xs: "100%", md: "95%", lg: "90%" },
        }}
      >
        <Card sx={styles.card}>
          <IconButton
            sx={styles.backArrow}
            onClick={() => {
              currentAction(ADD_QR_ACTIONS.NAME);
            }}
          >
            <WestIcon />
          </IconButton>
          <Typography variant="h4" sx={styles.titleContainer}>
            Type de QRCode
          </Typography>
          <Box sx={{ ...styles.flexCenter, flexWrap: "wrap", p: 2 }}>
            {qrTypeContent}
          </Box>
          <Typography variant="overline">
            <strong>*</strong> Les types de QR Codes ayant ce symbole ne
            possèdent pas de statistiques.
          </Typography>
        </Card>
      </Box>
    </>
  );
}
