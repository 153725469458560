import { Typography, Box } from "@mui/material";

import experience from "../../../assets/home/experience.png";
import amplification from "../../../assets/home/amplification.png";

const styles = {
  howToUseContainer: {
    p: 3,
    display: "flex",
    justifyContent: "space-around",
    flexDirection: { xs: "column", md: "row" },
    gap: "20px",
    minHeight: "800px",
    backgroundColor: "#282828",
  },
  contentContainer: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    flexDirection: "column",
  },
  title: {
    color: "#58738D",
    fontWeight: "bold",
    fontSize: "23px",
    textAlign: "center",
    py: 3,
  },
  content: {
    color: "white",
    textAlign: "justify",
  },
};

export default function HowToUse() {
  return (
    <Box sx={styles.howToUseContainer}>
      <Box sx={styles.contentContainer}>
        <img src={experience} alt="service" width="300" />
        <Box sx={{ maxWidth: "600px" }}>
          <Typography variant="h2" sx={styles.title}>
            Expérience Utilisateur Incomparable avec OctopusQR
          </Typography>
          <Typography sx={styles.content}>
            OctopusQR, c'est l'expérience utilisateur de haute qualité incarnée.
            Nous avons conçu notre plateforme pour rendre la création et la
            gestion de QR Codes aussi simple et efficace que possible. Notre
            interface conviviale est spécialement conçue pour répondre aux
            besoins des professionnels du marketing, qu'ils soient débutants ou
            expérimentés. Vous n'avez pas besoin d'être un technicien aguerri
            pour utiliser OctopusQR. Notre plateforme est intuitive et facile à
            prendre en main, vous permettant de commencer à créer vos QR Codes
            en un rien de temps. Malgré sa simplicité, OctopusQR n'en demeure
            pas moins un outil puissant. Vous pouvez explorer une gamme de
            fonctionnalités avancées pour personnaliser, analyser et optimiser
            vos QR Codes. Et n'oubliez pas, notre service client est toujours
            prêt à vous aider. Que vous ayez besoin d'aide pour démarrer ou
            d'assistance pour une campagne spécifique, nous sommes là pour vous.
          </Typography>
        </Box>
      </Box>
      <Box sx={styles.contentContainer}>
        <img src={amplification} alt="plateform" width="300" />

        <Box sx={{ maxWidth: "600px" }}>
          <Typography variant="h2" sx={styles.title}>
            Amplifiez Vos Campagnes Marketing avec OctopusQR
          </Typography>
          <Typography sx={styles.content}>
            Donnez une nouvelle dimension à vos campagnes marketing avec
            OctopusQR. Nos QR Codes sont des outils de communication puissants
            qui permettent d'établir une connexion directe entre votre marque et
            votre public. Quel que soit le type de contenu vers lequel vous
            souhaitez diriger votre audience - site web, vidéo, formulaire
            d'inscription ou autre - nos QR Codes sont la solution. Et avec
            OctopusQR, ne laissez plus votre marketing au hasard. Grâce à nos
            outils d'analyse avancés, vous pouvez mesurer précisément
            l'efficacité de vos campagnes et faire des ajustements basés sur des
            données réelles. Nos QR Codes personnalisables vous permettent de
            les rendre aussi attrayants et engageants que possible, augmentant
            ainsi vos chances de conversion. Découvrez comment OctopusQR peut
            amplifier l'impact de vos campagnes marketing et choisissez la
            qualité, l'efficacité et le professionnalisme pour vos QR Codes.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
