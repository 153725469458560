import { useState } from "react";
import { styles } from "./QRCodeCustomization.style";
import { useSelector } from "react-redux";

import {
  ADD_QR_ACTIONS,
  QR_CODE_LOGO_OPTIONS,
} from "../../../../utils/constants/Constants";
import { OctopusQRButton } from "../../../../utils/styled/Styled";

import { Box, Card, IconButton, Typography } from "@mui/material";
import WestIcon from "@mui/icons-material/West";
import QRCodeCustomizeView from "../../main/customization/QRCodeCustomizeView";

export default function QRCodeCustomization({
  currentAction,
  qrCodeCustomization,
  setQrCodeCustomization,
  qrCodeName,
}) {
  if (!qrCodeName) {
    currentAction(ADD_QR_ACTIONS.NAME);
  }

  const user = useSelector((state) => state.userReducer);
  const [logoError, setLogoError] = useState({
    hasError: false,
    errorText: "",
  });
  const [customizationValues, setCustomizationValues] = useState({
    logoOptionChosen: qrCodeCustomization.logoOptionChosen,
    file: qrCodeCustomization.file,
    picture: qrCodeCustomization.picture,
    dotsType: qrCodeCustomization.dotsType,
    dotsColor: qrCodeCustomization.dotsColor,
    cornerSquaresType: qrCodeCustomization.cornerSquaresType,
    cornerSquaresColor: qrCodeCustomization.cornerSquaresColor,
    cornerDotType: qrCodeCustomization.cornerDotType,
    cornerDotColor: qrCodeCustomization.cornerDotColor,
    backgroundColor: qrCodeCustomization.backgroundColor,
    backgroundOptionChosen: qrCodeCustomization.backgroundOptionChosen,
  });

  const navigateToNextPage = () => {
    if (
      customizationValues.logoOptionChosen === QR_CODE_LOGO_OPTIONS.PERSONNAL &&
      !customizationValues.file
    ) {
      setLogoError({
        hasError: true,
        errorText:
          "Si vous ajoutez un logo, il est nécessaire de l'importer. Section 'Logo'",
      });
      return;
    }
    currentAction(ADD_QR_ACTIONS.CONTENT);
    setQrCodeCustomization(customizationValues);
  };

  return (
    <>
      <Box
        sx={{
          ...styles.flexCenter,
          width: { xs: "100%", md: "90%", lg: "80%" },
        }}
      >
        <Card sx={styles.card}>
          <IconButton
            sx={styles.backArrow}
            onClick={() => {
              currentAction(ADD_QR_ACTIONS.TYPE);
              setQrCodeCustomization(customizationValues);
            }}
          >
            <WestIcon />
          </IconButton>
          <Typography variant="h4" sx={styles.titleContainer}>
            Personnalisation
          </Typography>
          <QRCodeCustomizeView
            user={user}
            initialCustomization={qrCodeCustomization}
            customizationValues={customizationValues}
            setCustomizationValues={setCustomizationValues}
          />
          <OctopusQRButton
            sx={{ my: 2, width: "150px" }}
            onClick={navigateToNextPage}
          >
            Suivant
          </OctopusQRButton>
          <br />
          {logoError.hasError ? (
            <Typography variant="overline" sx={styles.errorText}>
              {logoError.errorText}
            </Typography>
          ) : null}
        </Card>
      </Box>
    </>
  );
}
