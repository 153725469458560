export const styles = {
  submitButton: {
    mt: 2,
    width: "200px",
  },
  icon: {
    color: "#58738D",
    mb: 2,
  },
  field: {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
    backgroundColor: "white",
    borderRadius: "20px",
    width: "350px",
    [`& fieldset`]: {
      borderRadius: "20px",
      border: "1px",
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    },
  },

  imgAdornment: {
    marginRight: "10px",
    borderRadius: "10%",
  },

  flexSpace: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
  },

  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    py: 3,
    px: 7,
  },

  errorText: {
    fontSize: "12px",
    color: "#C34D60",
    mt: 2,
  },
};
