import { styles } from "../../QRCodeView.style";

import {
  EDIT_TYPE_ACTION,
  QR_CODE_TYPE,
  QR_CODE_TYPE_ARRAY,
} from "../../../../../utils/constants/Constants";

import {
  FormControl,
  Box,
  Card,
  Typography,
  Radio,
  RadioGroup,
} from "@mui/material";
import PublicIcon from "@mui/icons-material/Public";
import AbcIcon from "@mui/icons-material/Abc";
import LinkIcon from "@mui/icons-material/Link";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import ListAltIcon from "@mui/icons-material/ListAlt";
import WarningIcon from "@mui/icons-material/Warning";
import GroupWorkIcon from "@mui/icons-material/GroupWork";

import { OctopusQRButton } from "../../../../../utils/styled/Styled";

export default function SelectType({
  qrType,
  hasChange,
  handleChangeType,
  setCurrentAction,
}) {
  let qrTypeContent;
  qrTypeContent = QR_CODE_TYPE_ARRAY.map((aQrCodeType) => {
    switch (aQrCodeType) {
      case QR_CODE_TYPE.CUSTOM_PAGE:
        return (
          <FormControl sx={styles.radioContainer} key={aQrCodeType}>
            <Radio value={QR_CODE_TYPE.CUSTOM_PAGE} sx={styles.radioType} />
            <Box sx={styles.flexCenter}>
              <GroupWorkIcon sx={styles.typeIcon} />
              <Typography variant="overline" sx={styles.typeTypo}>
                {"  "}- Page personnalisée
              </Typography>
            </Box>
          </FormControl>
        );
      case QR_CODE_TYPE.FILE:
        return (
          <FormControl sx={styles.radioContainer} key={aQrCodeType}>
            <Radio value={QR_CODE_TYPE.FILE} sx={styles.radioType} />
            <Box sx={styles.flexCenter}>
              <AttachFileIcon sx={styles.typeIcon} />
              <Typography variant="overline" sx={styles.typeTypo}>
                {" "}
                - Fichier
              </Typography>
            </Box>
          </FormControl>
        );
      case QR_CODE_TYPE.FORM:
        return (
          <FormControl sx={styles.radioContainer} key={aQrCodeType}>
            <Radio value={QR_CODE_TYPE.FORM} sx={styles.radioType} />
            <Box sx={styles.flexCenter}>
              <ListAltIcon sx={styles.typeIcon} />
              <Typography variant="overline" sx={styles.typeTypo}>
                {" "}
                - Formulaire
              </Typography>
            </Box>
          </FormControl>
        );
      case QR_CODE_TYPE.LINK:
        return (
          <FormControl sx={styles.radioContainer} key={aQrCodeType}>
            <Radio value={QR_CODE_TYPE.LINK} sx={styles.radioType} />
            <Box sx={styles.flexCenter}>
              <LinkIcon sx={styles.typeIcon} />
              <Typography variant="overline" sx={styles.typeTypo}>
                {" "}
                - Lien
              </Typography>
            </Box>
          </FormControl>
        );
      case QR_CODE_TYPE.TEXT:
        return (
          <FormControl sx={styles.radioContainer} key={aQrCodeType}>
            <Radio value={QR_CODE_TYPE.TEXT} sx={styles.radioType} />
            <Box sx={styles.flexCenter}>
              <AbcIcon sx={styles.typeIcon} />
              <Typography variant="overline" sx={styles.typeTypo}>
                {" "}
                - Texte
              </Typography>
            </Box>
          </FormControl>
        );
      case QR_CODE_TYPE.SOCIAL_NETWORK:
        return (
          <FormControl sx={styles.radioContainer} key={aQrCodeType}>
            <Radio value={QR_CODE_TYPE.SOCIAL_NETWORK} sx={styles.radioType} />
            <Box sx={styles.flexCenter}>
              <PublicIcon sx={styles.typeIcon} />
              <Typography variant="overline" sx={styles.typeTypo}>
                {" "}
                - Réseau social
              </Typography>
            </Box>
          </FormControl>
        );
      default:
        return null;
    }
  });

  return (
    <>
      {hasChange ? (
        <Box
          sx={{
            width: { xs: "300px", md: "600px" },
            m: 2,
            textAlign: "center",
          }}
        >
          <Typography variant="overline" sx={styles.warningText}>
            <WarningIcon />
            Si vous décidez de changer le type de votre QR Code, vous perdrez
            l'ensemble des données liées à l'ancien QR Code. Cependant, Le QR
            Code ne changera pas et les statistiques de scan seront conservées.
          </Typography>
        </Box>
      ) : null}
      <Card sx={styles.card}>
        <Box sx={styles.flexCenter}>
          <RadioGroup
            onChange={handleChangeType}
            value={qrType}
            sx={{ ...styles.flexColumnCenter, alignItems: "start" }}
          >
            {qrTypeContent}
          </RadioGroup>
        </Box>
        <br />
        <OctopusQRButton
          sx={styles.button}
          onClick={() => {
            setCurrentAction(EDIT_TYPE_ACTION.CONTENT);
          }}
          disabled={!hasChange}
        >
          Suivant
        </OctopusQRButton>
      </Card>
    </>
  );
}
