import { useParams } from "react-router-dom";
import { useState, useCallback, useEffect } from "react";
import Loading from "../../../components/loading/Loading";
import { Box, Typography } from "@mui/material";
import { getQrCode } from "../../../api/qrCodes/qrCodesHandler";
import { putStatistic } from "../../../api/qrCodes/statisticsHandler";
import { styles } from "./Render.style";
import { QR_CODE_TYPE } from "../../../utils/constants/Constants";
import { getRenderUser } from "../../../api/user/getUser";
import TextDisplay from "./display/text/TextDisplay";
import FormDisplay from "./display/form/FormDisplay";
import CustomPageDisplay from "./display/customPage/CustomPageDisplay";

export default function Render() {
  const [qrContent, setQrContent] = useState();
  const [qrCode, setQrCode] = useState();
  const [user, setUser] = useState();
  const [hasApiError, setHasApiError] = useState(false);
  const { qrCodeId } = useParams();

  const getData = useCallback(async () => {
    try {
      const aGetQrResponse = await getQrCode(qrCodeId);
      setQrContent(aGetQrResponse.content);
      setQrCode(aGetQrResponse.qrCode);
      await putStatistic(qrCodeId);
      const aUser = await getRenderUser(aGetQrResponse.qrCode.userId);
      setUser(aUser);
    } catch {
      setHasApiError(true);
    }
  }, [qrCodeId]);

  useEffect(() => {
    getData();
  }, [getData]);

  if (hasApiError) {
    return (
      <Box sx={styles.boxError}>
        <Typography variant="overline" sx={{ color: "#8B0000" }}>
          Une erreur est survenue, nous sommes désolé.
        </Typography>
      </Box>
    );
  }

  if (!qrContent || !qrCode || !user) {
    return <Loading />;
  }

  if (!user.isSubscribed) {
    return (
      <Box sx={{ ...styles.flexCenter, width: "290px", p: 3, margin: "auto" }}>
        <Typography
          variant="overline"
          sx={{ textAlign: "justify", fontSize: "15px" }}
        >
          Ce QR Code est temporairement désactivé, vous ne pouvez donc pas
          accéder à son contenu.
        </Typography>
      </Box>
    );
  }

  if (
    (qrCode?.type === QR_CODE_TYPE.FILE ||
      qrCode?.type === QR_CODE_TYPE.LINK ||
      qrCode?.type === QR_CODE_TYPE.SOCIAL_NETWORK) &&
    qrContent?.path
  ) {
    const link = document.createElement("a");
    link.href = qrContent.path;
    document.body.appendChild(link);
    link.click();
    return;
  }

  if (qrCode?.type === QR_CODE_TYPE.VIRTUAL_CARD) {
    const vCardString = `BEGIN:VCARD\r\nVERSION:3.0\r\nN:${qrContent.firstName} ${qrContent.lastName}\r\nFN:${qrContent.fullName}\r\nORG:${qrContent.organization}\r\nTITLE:${qrContent.title}\r\nTEL;TYPE=WORK,VOICE:${qrContent.phoneNumber.work}\r\nTEL;TYPE=CELL:${qrContent.phoneNumber.cell}\r\nTEL;TYPE=HOME,VOICE:${qrContent.phoneNumber.home}\r\nEMAIL:${qrContent.email}\r\nEND:VCARD\r\n`;
    const vCardBlob = new Blob([vCardString], { type: "text/vcard" });
    const downloadURL = URL.createObjectURL(vCardBlob);
    const link = document.createElement("a");
    link.href = downloadURL;
    document.body.appendChild(link);
    link.click();
    return (
      <Box
        sx={{
          display: "flex",
          p: 2,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="overline">
          Un fichier s'est téléchargé. Ouvrez le afin d'ajouter le contact.
        </Typography>
      </Box>
    );
  }

  if (qrCode?.type === QR_CODE_TYPE.TEXT) {
    return <TextDisplay qrContent={qrContent} />;
  } else if (qrCode?.type === QR_CODE_TYPE.FORM) {
    return <FormDisplay qrContent={qrContent} />;
  } else {
    return (
      <CustomPageDisplay user={user} qrCode={qrCode} qrContent={qrContent} />
    );
  }
}
