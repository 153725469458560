import { Types } from "../actions/actionTypes.js";

const initialUserState = {
  _id: "",
  name: "",
  phoneNumber: "",
  mail: "",
  isSubscribed: false,
  role: "",
  subscriptionPlan: "",
  isOnTrial: false,
  isMailVerified: false,
  logoPath: "",
  loggedIn: false,
};

const userReducer = (state = initialUserState, action) => {
  switch (action.type) {
    case Types.LOGIN:
      return {
        ...state,
        ...action.payload,
        loggedIn: true,
      };
    case Types.LOGOUT:
      return {
        ...state,
        ...initialUserState,
      };
    case Types.INITIALIZE_USER_DATA:
      return {
        ...state,
        ...action.payload,
        loggedIn: true,
      };
    case Types.UPDATE_LOGO:
      return {
        ...state,
        logoPath: action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
