import * as React from "react";
import { useNavigate } from "react-router-dom";

import MenuIcon from "@mui/icons-material/Menu";
import {
  Menu,
  MenuItem,
  Typography,
  Box,
  Tooltip,
  IconButton,
  Divider,
} from "@mui/material";
import { CustomRouterLink } from "../../../utils/styled/Styled.js";
import LoginIcon from "@mui/icons-material/Login";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import CottageIcon from "@mui/icons-material/Cottage";
import HelpIcon from "@mui/icons-material/Help";
import InfoIcon from "@mui/icons-material/Info";
import ContactPageIcon from "@mui/icons-material/ContactPage";

const styles = {
  icon: { mr: "12px", color: "#58738D" },
  menuItem: { borderRadius: "50px", mr: 1 },
  typo: { display: "flex", alignItems: "center" },
};

export default function NotLoggedMenu() {
  const navigate = useNavigate();

  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const navigateTo = (destination) => {
    navigate(destination);
    handleCloseUserMenu();
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <>
      <Box
        sx={{ flexGrow: 0, display: { xs: "flex", md: "flex", lg: "none" } }}
      >
        <Tooltip title="Menu">
          <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
            <MenuIcon sx={{ color: "#58738D" }} />
          </IconButton>
        </Tooltip>
        <Menu
          sx={{ mt: "45px" }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          <MenuItem
            key="Home"
            onClick={() => {
              navigateTo("/");
            }}
          >
            <Typography sx={styles.typo} textAlign="center">
              <CottageIcon sx={styles.icon} />
              Accueil
            </Typography>
          </MenuItem>
          <MenuItem key="Connexion" onClick={() => navigateTo("/login")}>
            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              textAlign="center"
            >
              <LoginIcon sx={styles.icon} />
              Connexion
            </Typography>
          </MenuItem>
          <MenuItem key="Register" onClick={() => navigateTo("/register")}>
            <Typography sx={styles.typo} textAlign="center">
              <AppRegistrationIcon sx={styles.icon} />
              Inscription
            </Typography>
          </MenuItem>

          <Divider sx={{ my: 2, mx: 1 }} />

          <MenuItem key="Contact" onClick={() => navigateTo("/contact")}>
            <Typography sx={styles.typo} textAlign="center">
              <ContactPageIcon sx={styles.icon} />
              Contact
            </Typography>
          </MenuItem>

          <MenuItem key="About" onClick={() => navigateTo("/about")}>
            <Typography sx={styles.typo} textAlign="center">
              <InfoIcon sx={styles.icon} />À propos
            </Typography>
          </MenuItem>

          <MenuItem key="FAQ" onClick={() => navigateTo("/faq")}>
            <Typography sx={styles.typo} textAlign="center">
              <HelpIcon sx={styles.icon} />
              FAQ
            </Typography>
          </MenuItem>
        </Menu>
      </Box>
      <Box
        sx={{ flexGrow: 0, display: { xs: "none", md: "none", lg: "flex" } }}
      >
        <CustomRouterLink to="/">
          <MenuItem key="Home" sx={styles.menuItem}>
            <Typography sx={styles.typo} textAlign="center">
              <CottageIcon sx={styles.icon} />
              Accueil
            </Typography>
          </MenuItem>
        </CustomRouterLink>
        <CustomRouterLink to="/contact">
          <MenuItem key="Contact" sx={styles.menuItem}>
            <Typography sx={styles.typo} textAlign="center">
              <ContactPageIcon sx={styles.icon} />
              Contact
            </Typography>
          </MenuItem>
        </CustomRouterLink>

        <CustomRouterLink to="/about">
          <MenuItem key="About" sx={styles.menuItem}>
            <Typography sx={styles.typo} textAlign="center">
              <InfoIcon sx={styles.icon} />À propos
            </Typography>
          </MenuItem>
        </CustomRouterLink>

        <CustomRouterLink to="/faq">
          <MenuItem key="FAQ" sx={styles.menuItem}>
            <Typography sx={styles.typo} textAlign="center">
              <HelpIcon sx={styles.icon} />
              FAQ
            </Typography>
          </MenuItem>
        </CustomRouterLink>

        <CustomRouterLink to="/login">
          <MenuItem key="Login" sx={styles.menuItem}>
            <Typography sx={styles.typo} textAlign="center">
              <LoginIcon sx={styles.icon} />
              Connexion
            </Typography>
          </MenuItem>
        </CustomRouterLink>
        <CustomRouterLink to="/register">
          <MenuItem key="Register" sx={styles.menuItem}>
            <Typography sx={styles.typo} textAlign="center">
              <AppRegistrationIcon sx={styles.icon} />
              Inscription
            </Typography>
          </MenuItem>
        </CustomRouterLink>
      </Box>
    </>
  );
}
