import api from "../../../axios";
import { verifyMail } from "../../../../utils/forms/FormsVerification";

export const postToken = async (iData) => {
  const aResponse = await api.post(`/users/password/tokens`, iData);
  return aResponse.data;
};

export const verifyPostTokenData = (iFormValues) => {
  const aErrorValues = {
    hasMailError: false,
    mail: "",
  };

  verifyMail(iFormValues.mail, aErrorValues);

  return aErrorValues;
};
