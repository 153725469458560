import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import PinterestIcon from "@mui/icons-material/Pinterest";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import RedditIcon from "@mui/icons-material/Reddit";
import TwitterIcon from "@mui/icons-material/Twitter";
import { TikTok, Snapchat, Tripadvisor } from "../styled/Styled";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ImageIcon from "@mui/icons-material/Image";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import GoogleIcon from "@mui/icons-material/Google";

export const STORAGE_ACCESS_TOKEN = "accessToken";
export const STORAGE_USER_ID = "userId";

export const MAIL_ALREADY_EXISTS_ERROR =
  "Un compte avec cette adresse mail existe déjà";

export const DEFAULT_ERROR_MESSAGE =
  "Oups ... Une erreur est survenue, veuillez réessayer.";

export const LINK_EXTENSIONS = {
  JPG: "jpg",
  PNG: "png",
  PDF: "pdf",
  JPEG: "jpeg",
};

export const QR_CODE_TYPE = {
  SOCIAL_NETWORK: "social-network",
  FORM: "form",
  FILE: "file",
  LINK: "link",
  TEXT: "text",
  CUSTOM_PAGE: "custom-page",
  VIRTUAL_CARD: "vcard",
  WIFI: "wifi",
};

export const EDIT_TYPE_ACTION = {
  TYPE: "customization",
  CONTENT: "content",
};

export const QR_CODE_TYPE_ARRAY = [
  "custom-page",
  "form",
  "file",
  "link",
  "text",
  "social-network",
  "vcard",
  "wifi",
];

export const DYNAMIC_QR_TYPE = [
  "custom-page",
  "form",
  "file",
  "link",
  "text",
  "social-network",
];

export const QR_CODES_VIEW = {
  CUSTOMIZE: "customize",
  EDIT: "edit",
  TYPE: "type",
  STATISTIC: "statistics",
  ANSWERS: "answers",
  DELETE: "delete",
  PREVIEW: "preview",
  NOTIFY: "notify",
};

export const SOCIAL_NETWORK_TYPE = {
  LINKEDIN: "LINKEDIN",
  INSTAGRAM: "INSTAGRAM",
  FACEBOOK: "FACEBOOK",
  YOUTUBE: "YOUTUBE",
  TRIPADVISOR: "TRIPADVISOR",
  GOOGLE: "GOOGLE",
  REDDIT: "REDDIT",
  TWITTER: "TWITTER",
  PINTEREST: "PINTEREST",
  TIKTOK: "TIKTOK",
  SNAPCHAT: "SNAPCHAT",
};

export const SOCIAL_NETWORK_ARRAY = [
  "LINKEDIN",
  "INSTAGRAM",
  "FACEBOOK",
  "YOUTUBE",
  "TRIPADVISOR",
  "GOOGLE",
  "REDDIT",
  "TWITTER",
  "PINTEREST",
  "TIKTOK",
  "SNAPCHAT",
];

export const SOCIAL_NETWORK_ICON_MAP = {
  LINKEDIN: <LinkedInIcon />,
  INSTAGRAM: <InstagramIcon />,
  FACEBOOK: <FacebookIcon />,
  TRIPADVISOR: <Tripadvisor />,
  GOOGLE: <GoogleIcon />,
  YOUTUBE: <YouTubeIcon />,
  REDDIT: <RedditIcon />,
  TWITTER: <TwitterIcon />,
  PINTEREST: <PinterestIcon />,
  TIKTOK: <TikTok />,
  SNAPCHAT: <Snapchat />,
};

export const SOCIAL_NETWORK_LINK_MAP = {
  LINKEDIN: "https://linkedin.com/company/",
  INSTAGRAM: "https://instagram.com/",
  FACEBOOK: "https://facebook.com/",
  YOUTUBE: "https://youtube.com/",
  TRIPADVISOR: "https://www.tripadvisor.fr/",
  GOOGLE: "https://www.google.com/",
  REDDIT: "https://reddit.com/user/",
  TWITTER: "https://twitter.com/",
  PINTEREST: "https://pinterest.com/",
  TIKTOK: "https://tiktok.com/",
  SNAPCHAT: "https://t.snapchat.com/",
};

export const FORM_INPUT_TYPE = {
  RADIO: "radio",
  CHECKBOX: "checkbox",
  SHORT_ANSWER: "short_answer",
  LONG_ANSWER: "long_answer",
  SELECT: "select",
};

export const FORM_INPUT_TYPE_FR = {
  RADIO: "Choix multiples",
  CHECKBOX: "Cases à cocher",
  SHORT_ANSWER: "Réponse courte",
  LONG_ANSWER: "Paragraphe",
  SELECT: "Liste déroulante",
};

export const FILE_EXTENSIONS_MAP = {
  PNG: "IMAGE",
  JPG: "IMAGE",
  JPEG: "IMAGE",
  MP4: "VIDEO",
  PDF: "PDF",
};

export const FILE_ICON_MAP = {
  IMAGE: <ImageIcon />,
  VIDEO: <PlayCircleIcon />,
  PDF: <PictureAsPdfIcon />,
};

export const FONT_TYPE = [
  "'Roboto', sans-serif",
  "'Anek Malayalam', sans-serif",
  "'Fascinate', cursive",
  "'Joan', serif",
  "'Kdam Thmor Pro', sans-serif",
  "'Lato', sans-serif",
  "'League Gothic', sans-serif",
  "'Montserrat', sans-serif",
  "'Open Sans', sans-serif",
  "'Poppins', sans-serif",
  "'Ubuntu', sans-serif",
];

export const FONT_SIZE = [
  10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28,
  29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47,
  48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66,
  67, 68, 69, 70,
];

export const TRANSLATE_MONTH = {
  january: "Janvier",
  february: "Février",
  march: "Mars",
  april: "Avril",
  may: "Mai",
  june: "Juin",
  july: "Juillet",
  august: "Août",
  september: "Septembre",
  october: "Octobre",
  november: "Novembre",
  december: "Décembre",
};

export const ADD_QR_ACTIONS = {
  NAME: "name",
  TYPE: "type",
  CUSOMIZATION: "customization",
  CONTENT: "content",
  SAVE: "save",
};

export const QR_CODE_LOGO_OPTIONS = {
  PERSONNAL: "personnal",
  USER_LOGO: "userLogo",
  NONE: "none",
};

export const CUSTOM_PAGE_LOGO_OPTIONS = {
  PERSONNAL: "personnal",
  USER_LOGO: "userLogo",
  NONE: "none",
};

export const DEVICE_SORTABLE_TYPE = {
  LOGO: "Logo",
  DESCRIPTION: "Description",
  LINKS_AND_FILES: "LinksAndFiles",
  SOCIAL_NETWORKS: "SocialNetworks",
};

export const DEVICE_SORTABLE_INDEX = {
  LOGO: "item_1",
  DESCRIPTION: "item_2",
  LINKS_AND_FILES: "item_3",
  SOCIAL_NETWORKS: "item_4",
};

export const CUSTOMIZATION_POSITION = {
  TOP: "top",
  CENTER: "center",
};

export const API_BASE_URL =
  process.env.NODE_ENV === "development" ? "http://localhost:5001/" : "/api/";

export const SITE_BASE_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:80/"
    : "https://octopusqr.com/";

export const SUBSCRIPTION_LIST_MAP =
  process.env.NODE_ENV === "development"
    ? {
        price_1MZFA6HfZZD0tXqOEa6A28p6: {
          name: "1 QR Code ",
          price: "3.99€ / Mois",
          content: [
            " 1 QR Code",
            " Modifications de contenu infinies",
            " Modifications de type infinies",
          ],
          color: "#58738D",
          subscriptionPlan: "ONE_SS",
        },

        price_1MZFA6HfZZD0tXqO1gwRglMT: {
          name: "3 QR Codes ",
          price: "6.99€ / Mois",
          content: [
            " 3 QR Codes",
            " Modifications de contenu infinies",
            " Modifications de type infinies",
          ],
          color: "#008A5C",
          subscriptionPlan: "THREE_SS",
        },

        price_1MZFA6HfZZD0tXqOFIdjPDaR: {
          name: "8 QR Codes ",
          price: "11.99€ / Mois",
          content: [
            " 8 QR Codes",
            " Modifications de contenu infinies",
            " Modifications de type infinies",
          ],
          color: "#826D54",
          subscriptionPlan: "HEIGHT_SS",
        },

        price_1MZFA6HfZZD0tXqO91DAU2Ma: {
          name: "15 QR Codes ",
          price: "15.99€ / Mois",
          content: [
            " 15 QR Codes",
            " Modifications de contenu infinies",
            " Modifications de type infinies",
          ],
          color: "#9B6C95",
          subscriptionPlan: "FIFTEEN_SS",
        },

        price_1MZFA7HfZZD0tXqOOsHa5NaY: {
          name: "Illimités ",
          price: "29.99€ / Mois",
          content: [
            " QR Codes illimités",
            " Modifications de contenu infinies",
            " Modifications de type infinies",
          ],
          color: "#E49D23",
          subscriptionPlan: "UNLI_SS",
        },
      }
    : {
        price_1MaJe9HfZZD0tXqOE1kMQcVL: {
          name: "1 QR Code ",
          price: "3.99€ / Mois",
          content: [
            " 1 QR Code",
            " Modifications de contenu infinies",
            " Modifications de type infinies",
          ],
          color: "#58738D",
          subscriptionPlan: "ONE_SS",
        },

        price_1MaJe9HfZZD0tXqOn0FJkjwq: {
          name: "3 QR Codes ",
          price: "6.99€ / Mois",
          content: [
            " 3 QR Codes",
            " Modifications de contenu infinies",
            " Modifications de type infinies",
          ],
          color: "#008A5C",
          subscriptionPlan: "THREE_SS",
        },

        price_1MaJe9HfZZD0tXqOVHIoDPQ6: {
          name: "8 QR Codes ",
          price: "11.99€ / Mois",
          content: [
            " 8 QR Codes",
            " Modifications de contenu infinies",
            " Modifications de type infinies",
          ],
          color: "#826D54",
          subscriptionPlan: "HEIGHT_SS",
        },

        price_1MaJe9HfZZD0tXqO3X8eyESy: {
          name: "15 QR Codes ",
          price: "15.99€ / Mois",
          content: [
            " 15 QR Codes",
            " Modifications de contenu infinies",
            " Modifications de type infinies",
          ],
          color: "#9B6C95",
          subscriptionPlan: "FIFTEEN_SS",
        },

        price_1MaJe9HfZZD0tXqO5qL6fgHY: {
          name: "Illimités ",
          price: "29.99€ / Mois",
          content: [
            " QR Codes illimités",
            " Modifications de contenu infinies",
            " Modifications de type infinies",
          ],
          color: "#E49D23",
          subscriptionPlan: "UNLI_SS",
        },
      };

export const SUBSCRIPTION_STATISTICS_LIST_MAP =
  process.env.NODE_ENV === "development"
    ? {
        price_1KyzB7HfZZD0tXqOzNrPwOEk: {
          name: "1 QR Code ",
          price: "4.99€ / Mois",
          content: [
            " 1 QR Code",
            " Modifications de contenu infinies",
            " Modifications de type infinies",
            " Rapport statistique complet",
          ],
          color: "#58738D",
          subscriptionPlan: "ONE_AS",
        },
        price_1MZFA6HfZZD0tXqOv978j78W: {
          name: "3 QR Codes ",
          price: "8.99€ / Mois",
          content: [
            " 3 QR Codes",
            " Modifications de contenu infinies",
            " Modifications de type infinies",
            " Rapport statistique complet",
          ],
          color: "#008A5C",
          subscriptionPlan: "THREE_AS",
        },
        price_1MZFA6HfZZD0tXqOSGwTy70R: {
          name: "8 QR Codes ",
          price: "14.99€ / Mois",
          content: [
            " 8 QR Codes",
            " Modifications de contenu infinies",
            " Modifications de type infinies",
            " Rapport statistique complet",
          ],
          color: "#826D54",
          subscriptionPlan: "HEIGHT_AS",
        },
        price_1MZFA6HfZZD0tXqOAQG4zAzQ: {
          name: "15 QR Codes ",
          price: "19.99€ / Mois",
          content: [
            " 15 QR Codes",
            " Modifications de contenu infinies",
            " Modifications de type infinies",
            " Rapport statistique complet",
          ],
          color: "#9B6C95",
          subscriptionPlan: "FIFTEEN_AS",
        },
        price_1MZFA7HfZZD0tXqO4Gz8wRoF: {
          name: "Illimités ",
          price: "34.99€ / Mois",
          content: [
            " QR Codes illimités",
            " Modifications de contenu infinies",
            " Modifications de type infinies",
            " Rapport statistique complet",
          ],
          color: "#E49D23",
          subscriptionPlan: "UNLI_AS",
        },
      }
    : {
        price_1L5VdIHfZZD0tXqOaXTx3uBQ: {
          name: "1 QR Code ",
          price: "4.99€ / Mois",
          content: [
            " 1 QR Code",
            " Modifications de contenu infinies",
            " Modifications de type infinies",
            " Rapport statistique complet",
          ],
          color: "#58738D",
          subscriptionPlan: "ONE_AS",
        },
        price_1MaJe9HfZZD0tXqOe7c4NmbU: {
          name: "3 QR Codes ",
          price: "8.99€ / Mois",
          content: [
            " 3 QR Codes",
            " Modifications de contenu infinies",
            " Modifications de type infinies",
            " Rapport statistique complet",
          ],
          color: "#008A5C",
          subscriptionPlan: "THREE_AS",
        },
        price_1MaJe9HfZZD0tXqO9Q4wyQjR: {
          name: "8 QR Codes ",
          price: "14.99€ / Mois",
          content: [
            " 8 QR Codes",
            " Modifications de contenu infinies",
            " Modifications de type infinies",
            " Rapport statistique complet",
          ],
          color: "#826D54",
          subscriptionPlan: "HEIGHT_AS",
        },
        price_1MaJe9HfZZD0tXqOGlBv12hs: {
          name: "15 QR Codes ",
          price: "19.99€ / Mois",
          content: [
            " 15 QR Codes",
            " Modifications de contenu infinies",
            " Modifications de type infinies",
            " Rapport statistique complet",
          ],
          color: "#9B6C95",
          subscriptionPlan: "FIFTEEN_AS",
        },
        price_1MaJe9HfZZD0tXqOO8CC8qsN: {
          name: "Illimités ",
          price: "34.99€ / Mois",
          content: [
            " QR Codes illimités",
            " Modifications de contenu infinies",
            " Modifications de type infinies",
            " Rapport statistique complet",
          ],
          color: "#E49D23",
          subscriptionPlan: "UNLI_AS",
        },
      };

export const PLAN_VALUE_MAP = {
  ONE_AS: 499,
  THREE_AS: 899,
  HEIGHT_AS: 1499,
  FIFTEEN_AS: 1999,
  UNLI_AS: 3499,
};

export const PLAN_NUMBER_OF_QR_MAP = {
  ONE_SS: 1,
  THREE_SS: 3,
  HEIGHT_SS: 8,
  FIFTEEN_SS: 15,
  UNLI_SS: 999,
  ONE_AS: 1,
  THREE_AS: 3,
  HEIGHT_AS: 8,
  FIFTEEN_AS: 15,
  UNLI_AS: 999,
};

export const SUBSCRIPTION_PLANS = {
  ONE_SS: "ONE_SS",
  THREE_SS: "THREE_SS",
  HEIGHT_SS: "HEIGHT_SS",
  FIFTEEN_SS: "FIFTEEN_SS",
  UNLI_SS: "UNLI_SS",
  ONE_AS: "ONE_AS",
  THREE_AS: "THREE_AS",
  HEIGHT_AS: "HEIGHT_AS",
  FIFTEEN_AS: "FIFTEEN_AS",
  UNLI_AS: "UNLI_AS",
};

export const SUBSCRIPTION_PLANS_ARRAY = [
  { value: SUBSCRIPTION_PLANS.ONE_AS, label: "1 QR Code" },
  { value: SUBSCRIPTION_PLANS.THREE_AS, label: "3 QR Codes" },
  { value: SUBSCRIPTION_PLANS.HEIGHT_AS, label: "8 QR Codes" },
  { value: SUBSCRIPTION_PLANS.FIFTEEN_AS, label: "15 QR Codes" },
  { value: SUBSCRIPTION_PLANS.UNLI_AS, label: "Illimités" },
];

export const USER_ROLE = {
  ADMIN: "admin",
  USER: "user",
  PRIVATE: "private",
};

export const USER_ROLE_ARRAY = [
  { value: USER_ROLE.ADMIN, label: "Administrateur" },
  { value: USER_ROLE.USER, label: "Utilisateur" },
  { value: USER_ROLE.PRIVATE, label: "Privé" },
];
