export const styles = {
  title: {
    margin: "25px",
    fontSize: "15px",
  },
  card: {
    marginTop: "20%",
    textAlign: "center",
    borderRadius: "40px",
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    p: 2,
  },

  button: {
    margin: "20px",
    width: "150px",
  },
  icon: {
    color: "#58738D",
    mb: 2,
    mt: 2,
    fontSize: "50px",
  },
};
