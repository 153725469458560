import api from "../axios";
import {
    verifyMail,
    verifyPhoneNumber,
    verifyName,
  } from "../../utils/forms/FormsVerification.js";

export const patchUser = async (iUserId, iData) => {
  const aResponse = await api.patch(`/users/${iUserId}`, iData);
  return aResponse.data;
};

export const verifyPatchUserData = (iFormValues) => {
  let aErrorValues = {
    hasNameError: false,
    name: "",
    hasMailError: false,
    mail: "",
    hasPhoneNumberError: false,
    phoneNumber: "",
  };
  verifyName(iFormValues.name, aErrorValues);
  verifyPhoneNumber(iFormValues.phoneNumber, aErrorValues);
  verifyMail(iFormValues.mail, aErrorValues);

  return aErrorValues;
};
