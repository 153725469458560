import { getVisibleColor } from "../../../../../../../utils/Utils";
import { Typography, Box } from "@mui/material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
export default function DescriptionDeviceComponent({ content, customization }) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        whiteSpace: "pre-line",
      }}
    >
      <DragIndicatorIcon
        sx={{ color: getVisibleColor(customization.backgroundColor) }}
      />
      <Typography
        sx={{
          fontSize: customization.descriptionFontSize,
          fontFamily: customization.descriptionFontType,
          color: customization.descriptionFontColor,
          textAlign: "justify",
          textAlignLast: "center",
        }}
      >
        {content}
      </Typography>
      <Box sx={{ width: "40px" }} />
    </Box>
  );
}
