import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { OctopusQRButton } from "../../../utils/styled/Styled.js";
import { styles } from "./UpdatePassword.style";
import {
  updatePassword,
  verifyUpdatePasswordData,
} from "../../../api/user/password/updatePassword";
import Loading from "../../../components/loading/Loading.js";
import { STORAGE_USER_ID } from "../../../utils/constants/Constants.js";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  FormControl,
  TextField,
  InputAdornment,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import LockIcon from "@mui/icons-material/Lock";
import LockClockIcon from "@mui/icons-material/LockClock";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import DoneIcon from "@mui/icons-material/Done";

export default function UpdatePassword() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [hasUpdatePasswordErrors, setHasUpdatePasswordErrors] = useState(false);
  const [isPasswordModified, setIsPasswordModified] = useState(false);
  const [formValues, setFormValues] = useState({
    oldPassword: "",
    password: "",
    confirmedPassword: "",
  });
  const [errorValues, setErrorValues] = useState({
    hasOldPasswordError: false,
    oldPassword: "",
    hasPasswordError: false,
    password: "",
    hasConfirmedPasswordError: false,
    confirmedPassword: "",
  });
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [oldPasswordInputType, setOldPasswordInputType] = useState("password");
  const [showPassword, setShowPassword] = useState(false);
  const [passwordInputType, setPasswordInputType] = useState("password");
  const [showConfirmedPassword, setShowConfirmedPassword] = useState(false);
  const [confirmedPasswordInputType, setConfirmedPasswordInputType] =
    useState("password");

  const handleClose = () => {
    navigate("/profile");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleClickShowOldPassword = () => {
    setShowOldPassword(!showOldPassword);
    showOldPassword
      ? setOldPasswordInputType("password")
      : setOldPasswordInputType("text");
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
    showPassword
      ? setPasswordInputType("password")
      : setPasswordInputType("text");
  };

  const handleClickShowConfirmedPassword = () => {
    setShowConfirmedPassword(!showConfirmedPassword);
    showConfirmedPassword
      ? setConfirmedPasswordInputType("password")
      : setConfirmedPasswordInputType("text");
  };

  const handleSubmit = () => {
    setIsLoading(true);
    setHasUpdatePasswordErrors(false);
    const aErrorValues = verifyUpdatePasswordData(formValues);

    if (
      aErrorValues.hasOldPasswordError ||
      aErrorValues.hasPasswordError ||
      aErrorValues.hasConfirmedPasswordError
    ) {
      setIsLoading(false);
      setErrorValues({ ...aErrorValues });
      return;
    }
    updatePassword(localStorage.getItem(STORAGE_USER_ID), {
      oldPassword: formValues.oldPassword,
      password: formValues.password,
    })
      .then(() => {
        setIsLoading(false);
        setIsPasswordModified(true);
      })
      .catch((iError) => {
        if (iError.status === 400) {
          setErrorValues({
            hasOldPasswordError: true,
            oldPassword: "Mot de passe incorrect",
          });
        } else {
          setHasUpdatePasswordErrors(true);
        }
        setIsLoading(false);
      });
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleSubmit();
    }
  };

  return (
    <Dialog open={true} onClose={handleClose}>
      {isPasswordModified ? (
        <>
          <DialogTitle>
            <DoneIcon style={styles.centeredIcon} />
            <IconButton
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 1,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Typography>Mot de passe correctement modifié</Typography>
          </DialogContent>
        </>
      ) : (
        <>
          <DialogTitle>
            <IconButton
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 1,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>

            <Typography sx={styles.title}>
              Modification du mot de passe
            </Typography>
          </DialogTitle>
          <DialogContent>
            <FormControl sx={{ m: 4 }} style={styles.formControl}>
              <form>
                <TextField
                  id="old-password-input"
                  name="oldPassword"
                  type={oldPasswordInputType}
                  label="Ancien mot de passe"
                  required={true}
                  variant="outlined"
                  autoComplete="on"
                  value={formValues.oldPassword}
                  error={errorValues.hasOldPasswordError}
                  helperText={errorValues.oldPassword}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                  sx={styles.field}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockClockIcon style={styles.icon} />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowOldPassword}
                        >
                          {showOldPassword ? (
                            <VisibilityIcon sx={styles.icon} />
                          ) : (
                            <VisibilityOffIcon sx={styles.icon} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </form>
              <form>
                <TextField
                  id="password-input"
                  name="password"
                  autoComplete="on"
                  type={passwordInputType}
                  label="Nouveau mot de passe"
                  required={true}
                  variant="outlined"
                  value={formValues.password}
                  error={errorValues.hasPasswordError}
                  helperText={errorValues.password}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                  sx={styles.field}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon style={styles.icon} />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                        >
                          {showPassword ? (
                            <VisibilityIcon sx={styles.icon} />
                          ) : (
                            <VisibilityOffIcon sx={styles.icon} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </form>
              <form>
                <TextField
                  id="confirmed-password-input"
                  name="confirmedPassword"
                  type={confirmedPasswordInputType}
                  label="Confirmation du mot de passe"
                  required={true}
                  autoComplete="on"
                  variant="outlined"
                  value={formValues.confirmedPassword}
                  error={errorValues.hasConfirmedPasswordError}
                  helperText={errorValues.confirmedPassword}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                  sx={styles.field}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon style={styles.icon} />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmedPassword}
                        >
                          {showConfirmedPassword ? (
                            <VisibilityIcon sx={styles.icon} />
                          ) : (
                            <VisibilityOffIcon sx={styles.icon} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </form>
              {hasUpdatePasswordErrors ? (
                <p style={styles.error}>
                  Une erreur est survenue, veuillez réessayer
                </p>
              ) : null}
            </FormControl>
            <OctopusQRButton style={styles.button} onClick={handleSubmit}>
              Confirmer
            </OctopusQRButton>
            {isLoading ? <Loading /> : null}
          </DialogContent>
        </>
      )}
    </Dialog>
  );
}
