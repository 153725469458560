import React from "react";
import { Link } from "react-router-dom";

import NavMenu from "../../components/navMenu/NavMenu.js";
import Footer from "../../components/footer/Footer.js";

import { Card, Typography, Grid } from "@mui/material";

const styles = {
  card: {
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    borderRadius: "50px",
    m: 3,
  },
  title: {
    fontWeight: "bold",
    fontSize: "30px",
    marginTop: "20px",
    textAlign: "center",
  },
  subTitle: {
    textAlign: "center",
    fontSize: "14px",
  },
  secondaryTitle: {
    fontWeight: "bold",
    fontSize: "25px",
    m: 3,
  },
  tertiaryTitle: {
    fontWeight: "bold",
    fontSize: "20px",
    color: "#58738D",
    m: 3,
  },
  commonText: {
    textAlign: "justify",
    m: 3,
  },
};

export default function Terms() {
  return (
    <>
      <NavMenu />
      <Grid container spacing={0}>
        <Grid item xs={0} md={1} lg={2} />
        <Grid item xs={12} md={10} lg={8}>
          <Card sx={styles.card}>
            <Typography sx={styles.title}>
              Conditions générales d'utilisation
            </Typography>
            <Typography sx={styles.subTitle}>
              Dernière mise à jour - 13/02/2023
            </Typography>
            <br />

            <Typography sx={styles.secondaryTitle}>Préambule</Typography>
            <Typography sx={styles.commonText}>
              Andromede, 864 Avenue de Provence, 83440 Montauroux, France (
              ci-après dénommée « OctopusQR ») développe, exploite et fournit
              divers services dans les domaines de la création, de la
              conception, de la gestion, de l’optimisation, numérisation et
              analyse des codes QR pour ses clients (ci-après appelés
              « Utilisateur » ou « Utilisateurs »). Ces services sont fournis
              sous le domaine OctopusQR.com. OctopusQR se réserve le droit
              d’ajouter d’autres domaines à l’avenir. Avec les présentes
              Conditions Générales, OctopusQR veut assurer une relation
              d’affaires transparente avec l’Utilisateur, mais aussi protéger
              OctopusQR contre une utilisation malveillante. En acceptant les
              conditions générales, vous acceptez également notre licence de
              téléchargement de contenu, notre politique de confidentialité et
              notre politique d'utilisation acceptable dans la version
              applicable au moment de la conclusion de l’entente. Que vous
              pouvez trouver ici :
            </Typography>
            <Link to="/terms" style={{ marginLeft: "25px" }} target="_blank">
              Conditions générales d'utilisation
            </Link>
            <br />
            <Link to="/privacy" style={{ marginLeft: "25px" }} target="_blank">
              Politique de confidentialité
            </Link>
            <br />
            <Link to="/legal" style={{ marginLeft: "25px" }} target="_blank">
              Mentions légales
            </Link>
            <br />
            <Typography sx={styles.secondaryTitle}>
              1. Champ d’application du présent accord
            </Typography>
            <Typography sx={styles.commonText}>
              Le champ d’application du présent accord est l’utilisation du
              service spécifiquement convenu, tel que décrit dans les sections
              de service concrètes respectives fournies par OctopusQR à
              l’utilisateur contre paiement ou gratuitement.
            </Typography>
            <Typography sx={styles.commonText}>
              Les présentes conditions générales régissent les relations
              contractuelles entre OctopusQR et l’utilisateur (ci-après
              dénommées « contrat »). Ceci s’applique indépendamment du fait que
              la déclaration, la connexion, l’enregistrement, la conclusion du
              contrat, le service ou la réception des services aient lieu en
              France ou à l’étranger.
            </Typography>

            <Typography sx={styles.secondaryTitle}>
              2. Conclusion de l’accord
            </Typography>
            <Typography sx={styles.commonText}>
              La présentation des services sur les sites Web de OctopusQR ne
              constitue pas une offre juridiquement contraignante de la part de
              OctopusQR, mais une invitation non contraignante pour
              l’utilisateur à soumettre une offre correspondante. Les processus
              et les étapes techniques qui mènent à une offre contraignante de
              la part de l’utilisateur seront expliqués dans les sections de
              services concrètes respectives..
            </Typography>
            <Typography sx={styles.commonText}>
              Avant la soumission de l’offre juridiquement contraignante
              respective par l’utilisateur, une confirmation explicite de
              l’utilisateur que l’utilisateur a lu et accepté les Conditions
              Générales ainsi que - dans le cas d’un utilisateur consommateur
              (c’est-à-dire privé, non commercial) - les instructions de
              révocation en cliquant sur la case à cocher respective est
              requise. Avant de soumettre une offre ferme ou de s’inscrire en
              cliquant sur le bouton correspondant, l’utilisateur peut vérifier
              et corriger les données, si nécessaire.
            </Typography>
            <Typography sx={styles.commonText}>
              Après l’enregistrement de l’utilisateur, OctopusQR envoie sans
              délai un e-mail à l’adresse e-mail fournie par l’utilisateur. Cet
              e-mail est une confirmation de l'enregistrement, mais ne constitue
              pas l’acceptation de celui-ci, sauf si OctopusQR le déclare
              explicitement. L’utilisateur doit s’assurer que l’adresse e-mail
              fournie par l’utilisateur est correcte et que les e-mails envoyés
              par OctopusQR seront reçus et ne seront pas bloqués ou similaires.
            </Typography>
            <Typography sx={styles.commonText}>
              Si l'utilisateur soumet à une offre OctopusQR. Toute la gestion de
              cette offre sera faite via notre partenaire Stripe. Bien que
              OctopusQR propose des services optimisé et le plus fonctionnel.
              OctopusQR ne s'engage en aucun cas dans les problèmes de
              traitement lié à la soumission de cette offre puisqu'il en découle
              de Stripe.
            </Typography>

            <Typography sx={styles.secondaryTitle}>3. Services</Typography>
            <Typography sx={styles.tertiaryTitle}>3.1 Généralités</Typography>

            <Typography sx={styles.commonText}>
              Les services de OctopusQR peuvent généralement être utilisés à des
              fins privées et commerciales, sauf s’il est explicitement indiqué
              dans le service concerné qu’un usage privé ou commercial est
              autorisé exclusivement. L’objectif de OctopusQR est de fournir aux
              Utilisateurs une disponibilité continue et une fonctionnalité sans
              erreur des services fournis. Néanmoins, OctopusQR peut restreindre
              ou arrêter les services et/ou les systèmes dans une mesure
              raisonnable en dehors des heures de pointe pour la maintenance ou
              l’optimisation des performances. En outre, OctopusQR est en droit
              d’interrompre complètement ses services après avis préalable.
            </Typography>

            <Typography sx={styles.tertiaryTitle}>3.2 Compte</Typography>
            <Typography sx={styles.commonText}>
              En tant qu’Utilisateur enregistré, vous pouvez créer et utiliser
              un compte conformément à la Politique d’utilisation acceptable.
              L’utilisation de faux noms d'entreprise ou d’enregistrements
              multiples pour une même entité est interdite, sauf autorisation
              écrite de OctopusQR. Le processus d’enregistrement est décrit dans
              la politique de confidentialité de OctopusQR.
            </Typography>
            <Typography sx={styles.commonText}>
              L’utilisateur s’engage à fournir des informations exactes et
              complètes lors de son inscription et au cours de la relation, en
              maintenant ces informations toujours à jour.
            </Typography>
            <Typography sx={styles.commonText}>
              L’utilisateur ne doit pas divulguer les informations relatives à
              son compte à un tiers, y compris le mot de passe. L’utilisateur
              est seul responsable du maintien de la confidentialité et de la
              sécurité du compte et de toutes les activités qui se produisent
              par le biais de celui-ci ou en relation avec celui-ci. En cas de
              suspicion de violation de la sécurité ou d’utilisation abusive du
              compte, l’utilisateur en informe immédiatement OctopusQR par
              écrit.
            </Typography>
            <Typography sx={styles.commonText}>
              OctopusQR n’est pas responsable des pertes ou dommages causés par
              une utilisation non autorisée du compte. Cela concerne
              explicitement les informations stockées dans le compte ou une
              éventuelle utilisation abusive du compte compromis, par exemple en
              échangeant des liens vers des codes spécifiques ou des activités
              similaires.
            </Typography>

            <Typography sx={styles.tertiaryTitle}>3.3 QR Code</Typography>

            <Typography sx={styles.commonText}>
              OctopusQR offre des services dans le domaine de la création, de la
              conception, de la gestion, de l’optimisation, de la numérisation
              et de l’analyse des codes QR.
            </Typography>
            <Typography sx={styles.commonText}>
              Les services contractuels concrets de OctopusQR résultent de leur
              présentation concrète respective dans les sections de service
              individuelles. Il en va de même pour l’étendue des services, la
              période de service, les obligations de rémunération de
              l’utilisateur et d’autres conditions de service concrètes.
            </Typography>
            <Typography sx={styles.commonText}>
              Les services fournis par OctopusQR dépendent dans une large mesure
              de la disponibilité de tiers, en particulier aussi de la
              disponibilité et de l’accessibilité, par exemple, de
              l’infrastructure mondiale d’Internet et de communications mobiles,
              des centres de données, des lignes téléphoniques, les réseaux de
              communication mobile, les domaines, les applications et ainsi de
              suite. L’Utilisateur est conscient que ces facteurs sont au-delà
              de l’influence et de la responsabilité de OctopusQR et reconnaît
              que pour cette raison, les services OctopusQR peuvent être
              totalement ou partiellement (par ex. des services uniques ou
              uniquement pour des Utilisateurs individuels), de façon permanente
              ou temporaire, abandonnées, restreintes, bloquées ou modifiées,
              autant que nécessaire.
            </Typography>
            <Typography sx={styles.commonText}>
              Certains services OctopusQR permettent à l’Utilisateur de
              télécharger, soumettre, stocker, envoyer, recevoir ou partager du
              contenu de son choix. Il n’y a aucune obligation de fournir du
              contenu pour le faire. Si l’Utilisateur choisit de télécharger ou
              de partager du contenu, il s’assure d’avoir les droits nécessaires
              et que le contenu respectif est légal. Une description détaillée
              de ce qui est explicitement interdit à cet égard est listée dans
              notre politique d'utilisation acceptable. OctopusQR se réserve le
              droit d’étendre cette réglementation à tout moment.
            </Typography>

            <Typography sx={styles.tertiaryTitle}>
              3.4 Outils ou Fonctionnalités en phase de test
            </Typography>
            <Typography sx={styles.commonText}>
              OctopusQR peut publier des outils, des fonctionnalités ou des
              éléments similaires (ci-après dénommés « Services de test ») qui
              sont encore en phase de test ou d’évaluation. Ces services d’essai
              seront identifiables par l’étiquetage avec « test », « évaluation
              », « alpha », « bêta », « aperçu » ou d’autres expressions
              similaires. Ces services de test peuvent ne pas être aussi fiables
              que les autres services de OctopusQR. OctopusQR met ces Services
              de Test à la disposition des Utilisateurs pour collecter des
              informations sur la convivialité et les fonctionnalités générales
              et sur cette base pour améliorer les Services. La convivialité
              peut être évaluée sous forme d’évaluations statistiques, dans tous
              les cas sans aucune vérification de la personne physique. Les
              services bêta sont confidentiels jusqu’au lancement officiel.
            </Typography>

            <Typography sx={styles.tertiaryTitle}>
              3.5 Autres services
            </Typography>
            <Typography sx={styles.commonText}>
              OctopusQR développe constamment de nouveaux services, qui peuvent
              être ajoutés aux sections correspondantes. L’utilisateur peut être
              informé de ces services, par exemple par le biais d’une fenêtre
              contextuelle dans le compte de l’utilisateur. Pour obtenir ces
              services, les règles susmentionnées dans la section 2 de ces CG
              concernant la conclusion d’un accord s’appliquent. Le consentement
              de l’utilisateur sera recueilli à cet égard, dans la mesure où la
              loi l’exige.
            </Typography>

            <Typography sx={styles.tertiaryTitle}>
              3.6 Refus de garantie
            </Typography>

            <Typography sx={styles.commonText}>
              OctopusQR s’efforce de fournir ses services, mais il y a des
              aspects que nous ne pouvons pas garantir. Dans toute la mesure
              permise par la loi, OctopusQR ne donne aucune garantie, expresse
              ou implicite, concernant les services. Les services sont fournis «
              en l’état » ; nous déclinons également toute garantie de qualité
              marchande, d’adéquation à un usage particulier et de
              non-violation. Certaines juridictions n’autorisent pas les clauses
              de non-responsabilité de ce paragraphe, qui peuvent donc ne pas
              s’appliquer à vous.
            </Typography>

            <Typography sx={styles.secondaryTitle}>4. Âge requis</Typography>

            <Typography sx={styles.commonText}>
              Dans le cas où un Utilisateur est une personne physique, les
              services de OctopusQR sont en principe destinés aux Utilisateurs
              majeurs. Si un Utilisateur n’a pas l’âge requis pour obtenir les
              services de OctopusQR, l’Utilisateur doit avoir la permission de
              son parent ou de son tuteur légal pour le faire, dans la mesure
              permise par la loi, et le parent ou le tuteur légal doit avoir lu
              les présentes conditions.
            </Typography>

            <Typography sx={styles.secondaryTitle}>5. Rémunération</Typography>
            <Typography sx={styles.commonText}>
              <strong>5.1 - </strong>Tous les prix comprennent la taxe sur la
              valeur ajoutée (« TVA ») légale respectivement applicable, sauf si
              la TVA est indiquée séparément.
            </Typography>
            <Typography sx={styles.commonText}>
              <strong>5.2 - </strong>Sauf si le service correspondant de
              OctopusQR est gratuit, les compensations et les frais à payer par
              l’utilisateur et valables au moment de la conclusion du contrat
              sont présentés dans les sections de service respectives, y compris
              tous les éléments de prix, la base de calcul, les processus de
              paiement et les conditions de paiement. La facture contractuelle
              correspondante sera envoyé par mail par nos partenaires Stripe.
            </Typography>
            <Typography sx={styles.commonText}>
              <strong>5.3 - </strong>Les frais sont dus immédiatement à la
              conclusion du contrat. Si la date d’échéance du paiement est
              déterminée par le calendrier, l’utilisateur est déjà en défaut en
              manquant l’échéance. Dans ce cas, l’utilisateur se verra privé des
              services OctopusQR et devra régler son abonnement auprès de nos
              partenaires Stripe. L’obligation de l’utilisateur de payer son
              abonnement auprès de Stripe n’empêche pas OctopusQR de faire
              valoir d’autres dommages causés par le retard.
            </Typography>
            <Typography sx={styles.commonText}>
              <strong>5.4 - </strong>Si l’utilisateur ne respecte pas les
              obligations de paiement contractuelles respectives, OctopusQR peut
              fixer un délai de grâce approprié assorti d’une menace de
              résiliation, après l’expiration duquel OctopusQR peut résilier de
              manière extraordinaire le contrat avec l’utilisateur et supprimer
              immédiatement toutes les données sans impliquer une obligation
              contractuelle distincte de suppression immédiate des données. Les
              dispositions relatives à la résiliation figurant à l’article 9 des
              présentes CGU ne sont pas affectées.
            </Typography>
            <Typography sx={styles.commonText}>
              <strong>5.5 - </strong> OctopusQR n’est pas tenu de fournir ou de
              débloquer les services contractuels avant la réception du
              paiement, lorsque celui-ci est dû via notre partenaire Stripe.
            </Typography>
            <Typography sx={styles.commonText}>
              <strong>5.6 - </strong> Si l’utilisateur ne fait pas appel aux
              services contractuels, cela ne le libère pas de ses obligations de
              paiement.
            </Typography>
            <Typography sx={styles.commonText}>
              <strong>5.7 - </strong>La compensation avec des contre-prétentions
              de l’utilisateur ou la retenue de paiements dus à de telles
              prétentions n’est autorisée que si les contre-prétentions sont
              incontestées ou ont été légalement établies ou résultent de la
              même commande en vertu de laquelle la livraison concernée a été
              effectuée.
            </Typography>

            <Typography sx={styles.secondaryTitle}>
              6. Droits et obligations de l’utilisateur
            </Typography>
            <Typography sx={styles.commonText}>
              L’utilisateur utilise les services fournis par OctopusQR en
              conformité avec les dispositions légales et réglementaires
              applicables. Cela signifie notamment, mais non exhaustivement :
            </Typography>
            <Typography sx={styles.commonText}>
              - L’Utilisateur n’utilise aucun contenu illégal ou lien vers un
              tel contenu qui viole les lois, les réglementations officielles ou
              les droits et libertés de tiers lors de l’utilisation des services
              OctopusQR, en particulier ne place pas ce contenu ou lien sur les
              services OctopusQR fournis, comme un espace de stockage. En cas de
              telles violations, contenus illégaux ou abus présumé, OctopusQR
              peut supprimer, bloquer ou restreindre les services contractuels,
              le contenu, les liens ou les codes correspondants sans préavis.
            </Typography>
            <Typography sx={styles.commonText}>
              - L’utilisateur empêche l’accès non autorisé de tiers au compte en
              prenant les précautions appropriées selon l’état actuel de la
              technique.
            </Typography>
            <Typography sx={styles.commonText}>
              - L’utilisateur ne transfère pas les services ou les droits
              contractuels à des tiers et ne les fournit pas à des tiers pour
              utilisation.
            </Typography>
            <Typography sx={styles.commonText}>
              - L’utilisateur vérifie les données et les informations à la
              recherche de virus ou d’autres composants nuisibles avant de les
              entrer dans l’espace de stockage OctopusQR.
            </Typography>
            <Typography sx={styles.commonText}>
              - Les informations stockées par l’utilisateur sur l’espace de
              stockage fourni par OctopusQR peuvent être protégées par le droit
              d’auteur et la loi sur la protection des données. L’utilisateur
              accorde par la présente à OctopusQR le droit de rendre les
              contenus stockés, de les reproduire, de les transférer pour les
              besoins de l’utilisateur et de reproduire le contenu à des fins de
              sauvegarde des données conformément à la licence de téléchargement
              de contenu.
            </Typography>
            <Typography sx={styles.commonText}>
              - L’utilisateur s’abstient de toute action qui pourrait affecter
              la fonctionnalité des services fournis ou organisés par OctopusQR.
              Cela comprend entre autres l’utilisation de virus, de logiciels
              malveillants ou de logiciels qui génèrent automatiquement des
              demandes d’utilisateurs via Internet ou d’une autre manière au
              détriment de OctopusQR. L’utilisateur ne modifiera pas non plus,
              ne manipulera pas, n’écrasera pas, ne copiera pas et ne
              distribuera pas les zones de site Web ou les zones de site Web
              d’autres utilisateurs fournies par OctopusQR ou ses fournisseurs
              de services.
            </Typography>
            <Typography sx={styles.commonText}>
              - L’Utilisateur est responsable des appareils techniques, des
              moyens de communication, du matériel et des logiciels qu’il
              utilise en relation avec les services OctopusQR. Cela s’applique
              également à leur pertinence et à leur sécurité.
            </Typography>
            <Typography sx={styles.commonText}>
              L’utilisateur est tenu de s’en assurer et de le vérifier
              continuellement et correctement sous sa propre responsabilité.
            </Typography>

            <Typography sx={styles.secondaryTitle}>
              7. Licence pour OctopusQR
            </Typography>

            <Typography sx={styles.commonText}>
              Si l’Utilisateur est une entreprise, l’Utilisateur accorde à
              OctopusQR un droit non exclusif, non transférable, mondial, libre
              de droits, limité d’utiliser le nom de l’Utilisateur, les marques
              déposées et les logos aux fins de référencement de l’utilisateur
              en tant que client de OctopusQR sur les sites Web de OctopusQR et
              dans les documents de marketing de OctopusQR. L’Utilisateur
              garantit à OctopusQR que l’Utilisateur a le droit, le titre et/ou
              l’autorité d’accorder une telle licence à OctopusQR. Si vous avez
              déjà refusé cette clause envers OctopusQR, cette clause ne
              s’appliquera toujours pas à vous. Si vous avez accepté une version
              antérieure de ces conditions générales qui vous a permis de vous
              retirer de cette clause, votre choix antérieur de vous retirer ou
              de ne pas vous retirer demeure obligatoire. Si vous avez convenu
              individuellement de certains détails avec OctopusQR à une date
              antérieure, qui s’écartent de cette clause, l’accord individuel a
              préséance sur cette clause pour vous.
            </Typography>

            <Typography sx={styles.commonText}>
              Pour vous désinscrire de cette clause et donc ne pas accorder une
              telle licence à OctopusQR, veuillez envoyer un e-mail à{" "}
              <a href="mailto:contact@octopusqr.com">contact@octopusqr.com</a>{" "}
              en indiquant votre numéro de client unique et votre société.
            </Typography>

            <Typography sx={styles.commonText}>
              Pour plus d'informations, voir la licence de téléchargement de
              contenu OctopusQR.
            </Typography>

            <Typography sx={styles.secondaryTitle}>
              8. Révocation / Retrait
            </Typography>

            <Typography sx={styles.commonText}>
              <strong>8.1 - </strong>Si vous êtes un consommateur (c’est-à-dire
              un particulier, non commercial), vous disposez d’un droit de
              révocation conformément aux dispositions légales. Le consommateur
              sera informé de ce droit séparément, le cas échéant.
            </Typography>

            <Typography sx={styles.commonText}>
              <strong>8.2 - </strong>OctopusQR accorde à l’utilisateur,
              indépendamment du droit de révocation, un droit de retrait
              volontaire de la convention. L’utilisateur dispose de ce droit de
              rétractation pendant 14 jours après la conclusion de la convention
              et exige une notification justifiée à OctopusQR (au moins sous
              forme de texte).
            </Typography>

            <Typography sx={styles.secondaryTitle}>
              9. Responsabilité
            </Typography>

            <Typography sx={styles.commonText}>
              <strong>9.1 -</strong> OctopusQR est responsable, sans limitation,
              en cas d’intention ou de négligence grave, des atteintes à la vie,
              au corps ou à la santé, conformément aux dispositions de la loi
              allemande sur la responsabilité du fait des produits et dans la
              mesure d’une garantie assumée par OctopusQR.
            </Typography>

            <Typography sx={styles.commonText}>
              <strong>9.2 - </strong>En cas de violation par négligence légère
              d’une obligation essentielle à la réalisation du but du contrat
              (obligation cardinale), la responsabilité de OctopusQR est limitée
              au montant du dommage prévisible et typique selon la nature de la
              relation contractuelle respective.
            </Typography>

            <Typography sx={styles.commonText}>
              <strong>9.3 - </strong>Toute autre responsabilité de OctopusQR est
              exclue.
            </Typography>

            <Typography sx={styles.commonText}>
              <strong>9.4 - </strong>La limitation de responsabilité ci-dessus
              s’applique également à la responsabilité personnelle des
              travailleurs, des représentants et des organes de OctopusQR.
            </Typography>

            <Typography sx={styles.secondaryTitle}>
              10. Indemnisation
            </Typography>
            <Typography sx={styles.commonText}>
              L’utilisateur accepte d’indemniser et de dégager de toute
              responsabilité OctopusQR, ses sociétés affiliées, ses filiales,
              ses dirigeants, ses agents, ses partenaires, ses employés et ses
              concédants de licence pour toute réclamation ou demande, y compris
              les frais d’avocat raisonnables, formulée par un tiers à
              l’encontre de l’une des personnes morales ou physiques
              susmentionnées, en raison de ou découlant d’une violation coupable
              des présentes CGU, de la licence de téléchargement de contenu, de
              la politique de confidentialité ou de la politique d’utilisation
              acceptable, par l’utilisateur.
            </Typography>

            <Typography sx={styles.secondaryTitle}>
              11. Confidentialité
            </Typography>

            <Typography sx={styles.commonText}>
              <strong>11.1 - </strong>Par « informations confidentielles », on
              entend toutes les informations et tous les documents de l’autre
              partie qui sont marqués comme confidentiels ou qui doivent être
              considérés comme confidentiels en raison des circonstances, en
              particulier les informations sur les processus opérationnels, les
              relations commerciales et le savoir-faire.
            </Typography>
            <Typography sx={styles.commonText}>
              <strong>11.2 - </strong>Les parties conviennent de garder le
              secret sur les informations confidentielles.
            </Typography>
            <Typography sx={styles.commonText}>
              <strong>11.3 - </strong>Sont exclues de cette obligation les
              informations confidentielles,
            </Typography>
            <Typography sx={styles.commonText}>
              <strong>a. - </strong>qui étaient manifestement déjà connus du
              bénéficiaire au moment de la conclusion de l’accord ou qui sont
              devenus ultérieurement connus du bénéficiaire par un tiers sans
              que cela n’enfreigne un accord de confidentialité, des
              dispositions légales ou des ordres officiels ;
            </Typography>
            <Typography sx={styles.commonText}>
              <strong>b. - </strong>qui sont publiquement connues au moment de
              la conclusion de l’accord ou qui deviennent publiquement connues
              par la suite, à moins que cela ne soit dû à une violation de
              l’accord ;
            </Typography>
            <Typography sx={styles.commonText}>
              <strong>c. - </strong>qui doivent être divulguées en raison
              d’obligations légales ou sur ordre d’un tribunal ou d’une
              autorité. Dans la mesure où cela est permis et possible, le
              destinataire soumis à une obligation de divulgation en informe
              préalablement l’autre partie et lui donne la possibilité de
              prendre des mesures contre la divulgation.
            </Typography>
            <Typography sx={styles.commonText}>
              <strong>11.4 - </strong>Les parties n’accordent l’accès aux
              informations confidentielles qu’aux personnes qui sont tenues au
              secret professionnel ou qui ont été préalablement soumises à des
              obligations de confidentialité équivalentes à celles du présent
              accord.
            </Typography>

            <Typography sx={styles.secondaryTitle}>
              12. Protection des données
            </Typography>

            <Typography sx={styles.commonText}>
              Merci de bien vouloir vous référer à la{" "}
              <Link to="/privacy" target="_blank">
                politique de confidentialité
              </Link>{" "}
              OctopusQR.
            </Typography>

            <Typography sx={styles.secondaryTitle}>
              13. Propriété intellectuelle
            </Typography>

            <Typography sx={styles.commonText}>
              OctopusQR veille à ce que l’utilisation contractuelle des services
              par l’utilisateur n’entre pas en conflit avec les droits de tiers.
              En cas de défaut de titre, OctopusQR offre à l’utilisateur une
              possibilité juridiquement irréprochable d’utiliser les services ou
              un équivalent à sa discrétion. S’il existe des indices d’une
              possible violation des droits de tiers, OctopusQR vérifiera la
              plausibilité de ces indices et, en cas de soupçon justifié de
              violation, prendra des mesures raisonnables.
            </Typography>

            <Typography sx={styles.commonText}>
              Sauf disposition expresse dans l’accord, ni OctopusQR ni ses
              sous-traitants ne font de déclaration ou de garantie, expresse ou
              implicite, légale ou autre, concernant toute question, y compris
              la qualité marchande, la pertinence, l’originalité ou l’adéquation
              à un usage ou à un but particulier, la non-violation ou les
              résultats à tirer de l’utilisation ou de l’intégration de tout
              produit ou service fourni dans le cadre de l’accord, ou que le
              fonctionnement de tout produit ou service sera sûr, ininterrompu
              ou sans erreur. L’utilisateur convient qu’il ne se fonde pas sur
              la livraison de fonctionnalités futures, les commentaires publics
              ou la publicité de OctopusQR ou les feuilles de route des produits
              pour obtenir des abonnements à un quelconque service.
            </Typography>

            <Typography sx={styles.secondaryTitle}>
              14. Durée / Résiliation
            </Typography>
            <Typography sx={styles.commonText}>
              Le Contrat est conclu pour une durée indéterminée, sauf convention
              contraire, entre OctopusQR et l’Utilisateur conformément aux
              délais stipulés dans les sections de service concrètes. La
              relation commence avec l’inscription et la connexion de
              l’Utilisateur et peut être résiliée par l’une ou l’autre partie
              avec la suppression du compte ou en cas de services convenus avec
              un préavis de chaque partie au moins sous forme de texte avec un
              préavis d’un (1) mois jusqu’à la fin de la période contractuelle
              convenue. Les prestations contractuelles d’une certaine durée
              seront automatiquement prolongées pour la même durée et au même
              prix. La résiliation doit être déclarée à OctopusQR en indiquant
              votre nom de société (Andromede, 864 Avenue de Provence, 83440
              Montauroux; mail{" "}
              <a href="mailto:support@octopusqr.com">support@octopusqr.com</a>).
            </Typography>

            <Typography sx={styles.commonText}>
              La suppression d’un compte n’a aucun effet sur les éventuelles
              obligations de paiement si le compte est supprimé pendant une
              période contractuelle convenue.
            </Typography>

            <Typography sx={styles.commonText}>
              Le droit de chaque partie de résilier le Contrat sans préavis
              valable (résiliation extraordinaire) demeure inchangé. OctopusQR
              est notamment habilité à résilier le Contrat sans préavis si
              l’Utilisateur viole des obligations contractuelles ou légales
              fondamentales, affecte la sécurité des systèmes de OctopusQR,
              autres utilisateurs ou tiers ou défaut de paiement malgré un
              rappel et la fixation d’un délai de grâce approprié ou si
              l’Utilisateur viole les dispositions contractuelles relatives à
              l’utilisation des services OctopusQR.
            </Typography>

            <Typography sx={styles.secondaryTitle}>
              15. Dispositions finales
            </Typography>

            <Typography sx={styles.commonText}>
              <strong>16.1 - </strong>L’invalidité de certaines dispositions des
              présentes conditions générales n’affecte pas la validité des
              autres dispositions. Les parties au contrat s’efforceront de
              trouver une disposition valide à la place de la disposition
              invalide, qui se rapproche le plus possible du sens économique de
              la disposition invalide.
            </Typography>

            <Typography sx={styles.commonText}>
              <strong>15.2 - </strong>L’omission par OctopusQR de faire
              appliquer une disposition ne constitue pas une renonciation à son
              droit de le faire ultérieurement.
            </Typography>

            <Typography sx={styles.commonText}>
              <strong>15.3 - </strong>L’utilisateur ne peut céder à des tiers
              ses droits à l’encontre de OctopusQR qu’avec l’accord écrit de
              OctopusQR.
            </Typography>

            <Typography sx={styles.commonText}>
              <strong>15.4 -</strong> Les présentes conditions générales
              s’appliquent exclusivement. Les conditions générales divergentes,
              contradictoires ou complémentaires des utilisateurs qui sont des «
              personnes physiques ou morales ou des sociétés de personnes dotées
              de la capacité juridique qui, lors de la conclusion d’un acte
              juridique, agissent dans l’exercice de leur activité
              professionnelle commerciale ou indépendante » ne font partie
              intégrante du contrat que si et dans la mesure où OctopusQR
              accepte expressément leur validité. Cette exigence de consentement
              s’applique dans tous les cas, par exemple même si OctopusQR
              effectue la fourniture des services à l’utilisateur en
              connaissance des conditions générales de l’utilisateur.
            </Typography>

            <Typography sx={styles.title}>
              Politique d’utilisation acceptable
            </Typography>

            <Typography sx={styles.commonText}>
              Chez OctopusQR, nous considérons les codes QR comme l’un des
              outils technologiques les plus importants à l’interface entre le
              monde réel et le monde numérique. Nous nous sentons responsables
              de l’utilisation de cette technologie, que nous souhaitons
              positive et constructive, au bénéfice des personnes et de notre
              société dans son ensemble.
            </Typography>

            <Typography sx={styles.commonText}>
              C’est pourquoi nous avons établi des lignes directrices générales
              concernant la manière dont nos solutions et services NE doivent
              PAS être utilisés, que vous acceptez lorsque vous utilisez nos
              services. Cela s’applique même si vous ne faites qu’aider ou
              faciliter ces utilisations.
            </Typography>

            <Typography sx={styles.commonText}>
              Voici une liste non exhaustive des utilisations prohibées :
            </Typography>

            <Typography sx={styles.commonText}>
              - analyser ou tester la vulnérabilité de nos systèmes et/ou de
              notre réseau de quelque manière que ce soit.
            </Typography>
            <Typography sx={styles.commonText}>
              - violer toute mesure d’authentification ou de sécurité.
            </Typography>
            <Typography sx={styles.commonText}>
              - accéder, modifier ou utiliser de manière illégale les zones
              partagées des services qui nécessitent une autorisation ou des
              zones non publiques du service.
            </Typography>
            <Typography sx={styles.commonText}>
              - entraver ou perturber le réseau, l’hôte ou l’utilisateur du
              service (par la surcharge, le pollupostage de toute partie du
              service, ou toute autre activité similaire) ou désobéir à toute
              exigence, procédure, politique ou réglementation des réseaux
              connectés aux sites web de OctopusQR.
            </Typography>
            <Typography sx={styles.commonText}>
              - utiliser de manière abusive ou créer des comptes à des fins
              autres que l’utilisation prévue de nos interfaces publiques, par
              exemple créer des comptes en grand nombre.
            </Typography>
            <Typography sx={styles.commonText}>
              - perturber les utilisateurs du service en envoyant des
              communications, des publicités ou des spams non sollicités.
            </Typography>
            <Typography sx={styles.commonText}>
              - envoyer des promotions ou des publicités sans autorisation
              appropriée pour des produits ou des services autres que les
              vôtres.
            </Typography>
            <Typography sx={styles.commonText}>
              - manipuler des identifiants par la falsification d’en-têtes de
              courrier électronique et/ou envoyer des informations trompeuses ou
              altérées dans le but de déguiser l’origine de tout contenu
              utilisateur transmis par le biais de nos sites web, y compris le
              "phishing" ou le "spoofing".
            </Typography>
            <Typography sx={styles.commonText}>
              - usurper l’identité d’une personne ou d’une entité ou représenter
              ou déclarer faussement votre lien avec une personne ou une entité.
            </Typography>
            <Typography sx={styles.commonText}>
              - collecter ou stocker toute donnée ou information personnelle
              d’autres utilisateurs du service.
            </Typography>
            <Typography sx={styles.commonText}>
              - exploiter les solutions, les fonctionnalités ou la bonne volonté
              de OctopusQR, telles que les balayages « illimités », bien au-delà
              du comportement « normal ».
            </Typography>
            <Typography sx={styles.commonText}>
              - publier, partager ou télécharger des fichiers publiés par un
              autre utilisateur qui sont illégaux et ne devraient pas être
              distribués légalement de cette manière (par exemple, des fichiers
              qui enfreignent les droits de propriété intellectuelle de toute
              partie, contiennent un virus, sont répréhensibles d’un point de
              vue éthique ou autre, ou contiennent des actes de violence extrême
              ou des activités terroristes, y compris la propagande terroriste).
            </Typography>
            <Typography sx={styles.commonText}>
              - harceler, menacer, diffamer, abuser ou violer les droits légaux
              du personnel ou des représentants de OctopusQR ainsi que des
              utilisateurs du service.
            </Typography>
            <Typography sx={styles.commonText}>
              - promouvoir la haine ou le sectarisme à l’égard d’une personne ou
              d’un groupe de personnes en raison de leur race, de leur origine
              ethnique, de leur religion, de leur sexe, de leur identité
              sexuelle, de leur orientation sexuelle, de leur handicap ou de
              leur déficience.
            </Typography>
            <Typography sx={styles.commonText}>
              - violer toute loi locale, étatique, nationale ou internationale
              applicable, ainsi que la vie privée ou les droits d’autrui.
            </Typography>

            <Typography sx={styles.title}>
              Licence pour le téléchargement de contenu
            </Typography>

            <Typography sx={styles.secondaryTitle}>1. Licence</Typography>

            <Typography sx={styles.commonText}>
              Le contenu que vous fournissez reste le vôtre. Vous conservez donc
              tous les droits de propriété intellectuelle que vous avez sur
              votre contenu, comme le droit de propriété intellectuelle sur le
              contenu créatif que vous réalisez en rédigeant des critiques ou le
              droit de partager le contenu créatif de quelqu’un d’autre s’il l’a
              autorisé. Dans le cas où notre utilisation de votre contenu est
              limitée par vos droits de propriété intellectuelle, nous avons
              besoin de votre autorisation. Cette licence fournit à OctopusQR
              cette autorisation.
            </Typography>
            <Typography sx={styles.commonText}>
              Cette licence est non exclusive, libre de droits et prend effet
              dans le monde entier. Cela signifie qu’elle prend effet partout
              dans le monde, qu’il n’existe aucune restriction concernant
              l’octroi de licences pour votre contenu à d’autres personnes et
              que cette licence est gratuite.
            </Typography>
            <Typography sx={styles.commonText}>
              La licence couvre votre contenu, à condition qu’il soit protégé
              par des droits de propriété intellectuelle. Elle ne concerne que
              vos droits de propriété intellectuelle ; vos droits de protection
              des données ne sont pas affectés. Les types de contenu suivants ne
              sont pas couverts par cette licence :
            </Typography>
            <Typography sx={styles.commonText}>
              - les informations factuelles que vous avez fournies et qui sont
              accessibles au public, par exemple les corrections apportées à
              l’adresse d’un commerce local. Ce type d’information est considéré
              comme une connaissance commune et peut être utilisé librement par
              tout le monde et ne nécessite donc pas de licence.
            </Typography>
            <Typography sx={styles.commonText}>
              - vos commentaires (par exemple, des suggestions pour améliorer
              nos services).
            </Typography>

            <Typography sx={styles.secondaryTitle}>2. Objectif</Typography>
            <Typography sx={styles.commonText}>
              L’objet de cette licence est limité à l’exploitation des services,
              permettant ainsi aux services de créer de nouvelles
              caractéristiques et fonctionnalités et de fonctionner comme prévu
              lors de leur conception. Cela inclut l’utilisation de systèmes
              automatisés et d’algorithmes pour analyser votre contenu.
              L’analyse a lieu lorsque le contenu est envoyé, reçu et lorsqu’il
              est stocké et utilisé :
            </Typography>
            <Typography sx={styles.commonText}>
              - pour rechercher des contenus illégaux, des spams ou des
              logiciels malveillants ;
            </Typography>
            <Typography sx={styles.commonText}>
              - pour reconnaître les modèles de données qui sont utilisés dans
              des fonctionnalités telles que l’optimisation du stockage.
            </Typography>

            <Typography sx={styles.secondaryTitle}>3. Droits</Typography>

            <Typography sx={styles.commonText}>
              Dans le cadre des objectifs limités mentionnés ci-dessus, les
              droits suivants sont accordés à OctopusQR par cette licence :
            </Typography>
            <Typography sx={styles.commonText}>
              - utiliser votre contenu exclusivement à des fins techniques,
              telles que le stockage de votre contenu sur nos systèmes, afin de
              vous le rendre accessible de n’importe où ou d’assurer la
              compatibilité de votre contenu avec nos services en reformatant
              votre contenu ;
            </Typography>
            <Typography sx={styles.commonText}>
              - assurer la disponibilité publique de votre contenu, à condition
              que vous l’ayez rendu visible pour les autres ;
            </Typography>
            <Typography sx={styles.commonText}>
              - accorder une sous-licence pour ces droits :
            </Typography>
            <Typography sx={styles.commonText}>
              - d’autres utilisateurs afin de garantir que les services
              fonctionnent comme prévu, par exemple pour vous permettre de
              partager des photos avec d’autres personnes
            </Typography>
            <Typography sx={styles.commonText}>
              - nos sous-traitants dans le cadre d’un accord conforme à ces
              conditions, dans le cadre des objectifs limités décrits dans la
              section Objectif ci-dessus.
            </Typography>

            <Typography sx={styles.secondaryTitle}>4. Durée</Typography>
            <Typography sx={styles.commonText}>
              Cette licence est valable pour la durée pendant laquelle votre
              contenu est protégé par des droits de propriété intellectuelle.
              Cela n’inclut pas le cas où vous retirez votre contenu plus tôt de
              nos services.
            </Typography>
            <Typography sx={styles.commonText}>
              Le retrait de votre contenu de nos services, qui est couvert par
              cette licence, aura pour conséquence que nos systèmes cesseront de
              rendre ce contenu accessible au public dans un délai raisonnable.
              L'exception suivante est applicable :
            </Typography>
            <Typography sx={styles.commonText}>
              - vous mettez votre contenu à disposition par l’intermédiaire des
              services d’autres entreprises, il est possible que les moteurs de
              recherche ou les archives web, par exemple, continuent de trouver
              et d’afficher votre contenu dans le cadre de leurs résultats de
              recherche.
            </Typography>

            <Typography sx={styles.title}>Politique d’annulation</Typography>
            <Typography sx={styles.secondaryTitle}>
              Droit de rétractation
            </Typography>
            <Typography sx={styles.commonText}>
              Vous avez le droit d’annuler le présent contrat dans un délai de
              quatorze jours sans fournir de motif. Le délai de réflexion est de
              quatorze jours à compter du jour de la conclusion du contrat.
            </Typography>

            <Typography sx={styles.commonText}>
              Pour exercer votre droit de rétractation, il suffit de supprimer
              votre compte via votre profile. Etant donné que OctopusQR offre
              une période d'essai d'une semaine, vous n'aurez aucun paiement à
              nous faire parvenir. La supression de votre compte engendrera
              l'annulation du présent contrat.
            </Typography>
            <Typography
              sx={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "30px",
                m: 5,
              }}
            >
              - Fin de la politique d’annulation -{" "}
            </Typography>
          </Card>
        </Grid>
        <Grid item xs={0} md={1} lg={2} />
      </Grid>
      <Footer />
    </>
  );
}
