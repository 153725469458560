import api from "../../axios";

export const postFormQr = async (iUserId, iQRCodeId, iData) => {
  const aResponse = await api.post(
    `/users/${iUserId}/qrcodes/${iQRCodeId}/forms`,
    iData
  );
  return aResponse.data;
};

export const putFormQr = async (iUserId, iQRCodeId, iFormId, iData) => {
  const aResponse = await api.put(
    `/users/${iUserId}/qrcodes/${iQRCodeId}/forms/${iFormId}`,
    iData
  );
  return aResponse.data;
};

export const postFormAnswer = async (iQRCodeId, iFormId, iData) => {
  const aResponse = await api.post(
    `/qrcodes/${iQRCodeId}/forms/${iFormId}/form-answers`,
    iData
  );
  return aResponse.data;
};

export const getFormAnswers = async (iUserId, iQrCodeId, iFormId) => {
  const aResponse = await api.get(
    `/users/${iUserId}/qrcodes/${iQrCodeId}/forms/${iFormId}/form-answers`
  );
  return aResponse.data;
};

export const verifyFormData = (iFormValues) => {
  let aErrorValues = {
    formTitle: "",
    hasFormTitleError: false,
  };

  if (!iFormValues.formTitle) {
    aErrorValues.formTitle = "Champs requis";
    aErrorValues.hasFormTitleError = true;
  }

  return aErrorValues;
};
