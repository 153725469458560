import { useCallback, useEffect, useState } from "react";

import { getUsers } from "../../../../api/admin/adminApi";

import { styles } from "./ManageUsers.style";

import Loading from "../../../../components/loading/Loading";
import UserRow from "./UserRow";

import {
  Box,
  TextField,
  InputAdornment,
  Table,
  TableCell,
  TableHead,
  TableContainer,
  Paper,
  TableRow,
  FormControl,
  TableBody,
  Pagination,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ErrorCard from "../../../../components/error/ErrorCard";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

const ManageUsers = ({ user }) => {
  const [searchResult, setSearchResult] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  const [users, setUsers] = useState();

  const [hasApiError, setHasApiError] = useState(false);

  const handleSearchChange = (iEvent) => {
    setSearchValue(iEvent.target.value);
    if (iEvent.target.value.length >= 2) {
      setPageNumber(1);
      setSearchResult(iEvent.target.value);
    } else {
      setSearchResult("");
    }
  };

  const handlePaginationChange = (iEvent, iValue) => {
    setPageNumber(iValue);
  };

  const fetchData = useCallback(async () => {
    try {
      const aResponse = await getUsers(user._id, pageNumber, 30, searchResult);
      setUsers(aResponse.users);
      setPageCount(aResponse.count);
      setTotalCount(aResponse.totalCount);
    } catch {
      setHasApiError(true);
    }
  }, [pageNumber, searchResult, user._id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (hasApiError) {
    return <ErrorCard />;
  }

  if (!users) {
    return <Loading />;
  }

  const userRows = users.map((aUser) => {
    return (
      <UserRow
        key={aUser._id}
        adminUser={user}
        user={aUser}
        setUsers={setUsers}
      />
    );
  });

  return (
    <Box>
      <ManageAccountsIcon sx={styles.icon} />
      <Box sx={{ mx: "4%" }}>
        <TableContainer component={Paper} sx={styles.tableContainer}>
          <Table sx={styles.headTable}>
            <TableHead size="medium" sx={styles.headTableHead}>
              <TableRow>
                <TableCell sx={{ border: "none" }}>
                  <FormControl>
                    <TextField
                      id="search-input"
                      name="search"
                      placeholder="Rechercher un utilisateur..."
                      type="text"
                      variant="outlined"
                      value={searchValue}
                      onChange={handleSearchChange}
                      sx={styles.searchBar}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon sx={{ color: "#027FA2" }} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableHead>
              <TableRow>
                <TableCell sx={{ border: "none" }}>
                  <Pagination
                    count={pageCount}
                    page={pageNumber}
                    onChange={handlePaginationChange}
                    sx={styles.pagination}
                  />
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
          <Table sx={{ borderTop: "1px solid rgba(99, 99, 99, 0.2)" }}>
            <TableHead>
              <TableRow>
                <TableCell component="th" align="center">
                  <strong>Identifiant</strong>
                </TableCell>
                <TableCell component="th" align="center">
                  <strong>Nom</strong>
                </TableCell>
                <TableCell component="th" align="center">
                  <strong>Adresse mail</strong>
                </TableCell>
                <TableCell component="th" align="center">
                  <strong>Numéro de tél</strong>
                </TableCell>
                <TableCell component="th" align="center">
                  <strong>Nombre de QR Codes</strong>
                </TableCell>
                <TableCell component="th" align="center">
                  <strong>Utilisateur privé ?</strong>
                </TableCell>
                <TableCell component="th" align="center">
                  <strong>Abonné ?</strong>
                </TableCell>
                <TableCell component="th" align="center" />
              </TableRow>
            </TableHead>
            <TableBody>{userRows}</TableBody>
          </Table>
          <Table>
            <TableHead sx={{ display: "flex", justifyContent: "start" }}>
              <TableRow>
                <TableCell sx={{ border: "none" }}>
                  <Typography>
                    <strong>Total d'utilisateurs :</strong> {totalCount}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableHead sx={{ display: "flex", justifyContent: "end" }}>
              <TableRow>
                <TableCell sx={{ border: "none" }}>
                  <Pagination
                    count={pageCount}
                    page={pageNumber}
                    onChange={handlePaginationChange}
                    sx={styles.pagination}
                  />
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default ManageUsers;
