import { Box, Card, Typography, IconButton, TextField } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { styles } from "./VirtualCardContent.style";
import {
  deleteQrCode,
  postQrCode,
  postQrLogo,
} from "../../../../../api/qrCodes/qrCodesHandler";
import {
  postVirtualCard,
  verifyVirtualCardData,
} from "../../../../../api/qrCodes/virtualCard/virtualCardQrHandler";
import {
  ADD_QR_ACTIONS,
  STORAGE_USER_ID,
} from "../../../../../utils/constants/Constants";
import Loading from "../../../../../components/loading/Loading";
import WestIcon from "@mui/icons-material/West";
import { OctopusQRButton } from "../../../../../utils/styled/Styled";

const defaultErrorValues = {
  lastNameText: "",
  hasLastNameError: false,
  firstNameText: "",
  hasFirstNameError: false,
  fullNameText: "",
  hasFullNameError: false,
  workText: "",
  hasWorkError: false,
  cellText: "",
  hasCellError: false,
  homeText: "",
  hasHomeError: false,
};
const defaultFormValues = {
  lastName: "",
  firstName: "",
  fullName: "",
  email: "",
  organization: "",
  title: "",
  work: "",
  cell: "",
  home: "",
};

const VirtualCardContent = ({ currentAction, QRCode, file, putQRCode }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorValues, setErrorValues] = useState(defaultErrorValues);
  const [formValues, setFormValues] = useState(defaultFormValues);
  const [hasError, setHasError] = useState(false);
  const navigateTo = useNavigate();

  const handleSubmit = async () => {
    setIsLoading(true);
    setHasError(false);
    const { aErrorValues, hasFormErrors } = verifyVirtualCardData(formValues);
    setErrorValues({ ...aErrorValues });
    if (hasFormErrors) {
      setIsLoading(false);
      return;
    }

    const aUserId = localStorage.getItem(STORAGE_USER_ID);
    if (!aUserId) {
      setHasError(true);
      setIsLoading(false);
      return;
    }

    let aQRCodeId;
    const isQrExisting = QRCode?._id;
    try {
      if (!isQrExisting) {
        const aQRCode = await postQrCode(aUserId, QRCode);
        aQRCodeId = aQRCode._id;
        if (file !== null) {
          const aQrLogoData = new FormData();
          aQrLogoData.append("file", file);
          await postQrLogo(aUserId, aQRCodeId, aQrLogoData);
        }
      } else {
        aQRCodeId = QRCode._id;
      }

      const aVirtualCard = {
        lastName: formValues.lastName,
        firstName: formValues.firstName,
        fullName: formValues.fullName,
        email: formValues.email,
        organization: formValues.organization,
        title: formValues.title,
        phoneNumber: {
          work: formValues.work,
          cell: formValues.cell,
          home: formValues.home,
        },
      };
      await postVirtualCard(aUserId, aQRCodeId, aVirtualCard);

      if (isQrExisting) {
        await putQRCode();
      }

      navigateTo("/qrcodes");
    } catch {
      setIsLoading(false);
      if (!isQrExisting) {
        //Delete the QR In case error happens
        await deleteQrCode(aUserId, aQRCodeId);
      }
      setHasError(true);
      return;
    }
    setIsLoading(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  if (isLoading) {
    return <Loading />;
  }
  return (
    <>
      <Box
        sx={{
          ...styles.flexCenter,
          width: "100%",
        }}
      >
        <Card sx={styles.card}>
          <IconButton
            sx={styles.backArrow}
            onClick={() => {
              currentAction(ADD_QR_ACTIONS.CUSOMIZATION);
            }}
          >
            <WestIcon />
          </IconButton>
          <Typography variant="h4" sx={styles.title}>
            Création de la fiche contact
          </Typography>
          <Box sx={styles.flexColumn}>
            <TextField
              sx={styles.field}
              name="lastName"
              label="Nom"
              placeholder="Doe"
              value={formValues.lastName}
              error={errorValues.hasLastNameError}
              helperText={errorValues.lastNameText}
              onChange={handleInputChange}
              type="text"
              required
            />
            <TextField
              sx={styles.field}
              name="firstName"
              label="Prénom"
              placeholder="John"
              value={formValues.firstName}
              error={errorValues.hasFirstNameError}
              helperText={errorValues.firstNameText}
              onChange={handleInputChange}
              type="text"
              required
            />

            <TextField
              name="fullName"
              sx={styles.field}
              label="Désignation complète"
              placeholder="John Doe"
              value={formValues.fullName}
              error={errorValues.hasFullNameError}
              helperText={errorValues.fullNameText}
              onChange={handleInputChange}
              type="text"
              required
            />
            <TextField
              name="email"
              sx={styles.field}
              label="Adresse mail"
              placeholder="john.doe@mail.com"
              value={formValues.email}
              onChange={handleInputChange}
              type="email"
            />

            <TextField
              name="organization"
              sx={styles.field}
              label="Entreprise"
              placeholder="OctopusQR"
              value={formValues.organization}
              onChange={handleInputChange}
              type="text"
            />
            <TextField
              name="title"
              sx={styles.field}
              label="Poste occupé"
              placeholder="Développeur"
              value={formValues.title}
              onChange={handleInputChange}
              type="text"
            />

            <TextField
              name="work"
              sx={styles.field}
              label="Téléphone professionel"
              placeholder="+33123456789"
              value={formValues.work}
              error={errorValues.hasWorkError}
              helperText={errorValues.workText}
              onChange={handleInputChange}
              type="tel"
            />
            <TextField
              name="cell"
              sx={styles.field}
              label="Téléphone personnel"
              placeholder="+33123456789"
              value={formValues.cell}
              error={errorValues.hasCellError}
              helperText={errorValues.cellText}
              onChange={handleInputChange}
              type="tel"
            />

            <TextField
              name="home"
              sx={styles.field}
              label="Téléphone domicile"
              placeholder="+33123456789"
              value={formValues.home}
              error={errorValues.hasHomeError}
              helperText={errorValues.homeText}
              onChange={handleInputChange}
              type="tel"
            />
          </Box>
          <OctopusQRButton sx={styles.button} onClick={handleSubmit}>
            Sauvegarder
          </OctopusQRButton>
          <br />
          {hasError ? (
            <Typography variant="overline" sx={styles.errorText}>
              Une erreur est survenue, veuillez réessayer
            </Typography>
          ) : null}
        </Card>
      </Box>
    </>
  );
};

export default VirtualCardContent;
