import { Typography } from "@mui/material";

const Billing = () => {
  return (
    <Typography variant="h3" textAlign={"center"} sx={{ mt: 5 }}>
      En attente de MEG
    </Typography>
  );
};

export default Billing;
