import { useState } from "react";
import QRCodeStyling from "qr-code-styling";

import { styles } from "./QRCodes.style";

import {
  FormControl,
  TextField,
  InputAdornment,
  Toolbar,
  Typography,
  Divider,
  Select,
  Box,
  MenuItem,
  IconButton,
} from "@mui/material";
import { downloadQr } from "../../../api/qrCodes/qrCodesHandler";
import { PromiseQueue } from "../../../utils/PromiseQueue";

import SearchIcon from "@mui/icons-material/Search";
import { WhiteButton } from "../../../utils/styled/Styled";
import { saveAs } from "file-saver";
import { retrieveQrCodeOptions } from "../../../utils/Utils";
import SelectAllIcon from "@mui/icons-material/SelectAll";
import CloseIcon from "@mui/icons-material/Close";

const JSZip = require("jszip");

const QRToolbar = ({
  searchValue,
  handleSearchChange,
  selectedQrs,
  setSelectedQrs,
  qrFolders,
  moveCards,
  isHome,
  parent,
  qrCodes,
}) => {
  const [action, setAction] = useState("");
  const [downloadFormat, setDownloadFormat] = useState("");
  const [folderId, setFolderId] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const moveCardsToFolder = async () => {
    setIsLoading(true);

    await moveCards(selectedQrs, folderId);
    setFolderId("");
    setAction("");
    setIsLoading(false);
  };

  const downloadQRs = async () => {
    setIsLoading(true);
    const zip = new JSZip();
    const queue = new PromiseQueue(5);

    for (const [index, qrCode] of selectedQrs.entries()) {
      queue.push(async () => {
        if (downloadFormat === "pdf") {
          const response = await downloadQr(qrCode, downloadFormat);
          const blob = new Blob([response], {
            type:
              downloadFormat === "pdf"
                ? "application/pdf"
                : `image/${downloadFormat}`,
          });
          zip.file(`${qrCode.name}_${index}.${downloadFormat}`, blob);
        } else {
          const aQrCodeOption = await retrieveQrCodeOptions(qrCode);
          const HighResolutionQR = new QRCodeStyling({
            ...aQrCodeOption,
            width: 1024,
            height: 1024,
          });
          const blob = await HighResolutionQR.getRawData(downloadFormat);
          zip.file(`${qrCode.name}_${index}.${downloadFormat}`, blob);
        }
      });
    }

    while (queue.running > 0 || queue.queue.length > 0) {
      await new Promise((resolve) => setTimeout(resolve, 100));
    }

    zip.generateAsync({ type: "blob" }).then(function (content) {
      saveAs(content, "qrcodes.zip");
    });

    setSelectedQrs([]);
    setDownloadFormat("");
    setAction("");
    setIsLoading(false);
  };

  return (
    <Toolbar
      sx={{
        alignItems: "center",
        backgroundColor: "#58738D",
        mt: 2,
        borderRadius: "20px",
        mx: 2,
        display: { xs: "none", lg: "flex" },
      }}
    >
      <FormControl>
        <TextField
          id="searchName-input"
          name="searchName"
          placeholder="Rechercher un QR Code..."
          type="text"
          variant="outlined"
          value={searchValue}
          onChange={handleSearchChange}
          sx={styles.searchBar}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ color: "#58738D" }} />
              </InputAdornment>
            ),
          }}
        />
      </FormControl>
      {selectedQrs.length > 0 && (
        <>
          <Divider
            orientation="vertical"
            flexItem
            sx={{ mx: 4, color: "white" }}
          />

          <Typography variant="overline" color="white" sx={{ mr: 3 }}>
            {selectedQrs.length} QR Code(s) sélectionné(s)
          </Typography>
          {!isLoading && (
            <>
              <Select
                displayEmpty
                value={action}
                onChange={(e) => {
                  setAction(e.target.value);
                }}
                sx={styles.groupedAction}
              >
                <MenuItem value={""} disabled>
                  Action groupée
                </MenuItem>
                <MenuItem value={"move"}>Déplacer</MenuItem>
                <MenuItem value={"download"}>Télécharger</MenuItem>
              </Select>

              {action === "move" && (
                <Box ml={3}>
                  <Select
                    displayEmpty
                    value={folderId}
                    onChange={(e) => {
                      setFolderId(e.target.value);
                    }}
                    sx={styles.groupedAction}
                  >
                    <MenuItem value={""} disabled>
                      Dossier
                    </MenuItem>
                    {!parent && !isHome && (
                      <MenuItem value={"home"}>Accueil</MenuItem>
                    )}
                    {parent && (
                      <MenuItem value={parent._id}>{parent.name}</MenuItem>
                    )}
                    {qrFolders.map((folder) => (
                      <MenuItem value={folder._id} key={folder._id}>
                        {folder.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {folderId && (
                    <WhiteButton sx={{ ml: 3 }} onClick={moveCardsToFolder}>
                      Déplacer
                    </WhiteButton>
                  )}
                </Box>
              )}
              {action === "download" && (
                <>
                  <Box sx={{ ml: 3 }}>
                    <Select
                      displayEmpty
                      value={downloadFormat}
                      onChange={(e) => {
                        setDownloadFormat(e.target.value);
                      }}
                      sx={styles.groupedAction}
                    >
                      <MenuItem value={""} disabled>
                        Format
                      </MenuItem>
                      <MenuItem value={"png"}>PNG</MenuItem>
                      <MenuItem value={"svg"}>SVG</MenuItem>
                      <MenuItem value={"jpeg"}>JPEG</MenuItem>
                      <MenuItem value={"webp"}>WEBP</MenuItem>
                      <MenuItem value={"pdf"}>PDF</MenuItem>
                    </Select>
                    {downloadFormat && (
                      <WhiteButton sx={{ ml: 3 }} onClick={downloadQRs}>
                        Télécharger
                      </WhiteButton>
                    )}
                  </Box>
                </>
              )}
            </>
          )}
        </>
      )}
      {isLoading && (
        <Typography variant="overline" color="white" sx={{ ml: 2 }}>
          Chargement en cours ...
        </Typography>
      )}
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {selectedQrs.length !== qrCodes.length && (
          <>
            <Typography variant="overline" color="white" sx={{ mr: 2, ml: 2 }}>
              Tout sélectionner
            </Typography>
            <IconButton
              sx={{ color: "white" }}
              onClick={() => setSelectedQrs(qrCodes)}
            >
              <SelectAllIcon />
            </IconButton>
          </>
        )}

        {selectedQrs.length > 0 && (
          <>
            <Typography variant="overline" color="white" sx={{ mr: 2, ml: 2 }}>
              Tout désélectionner
            </Typography>
            <IconButton
              sx={{ color: "white" }}
              onClick={() => setSelectedQrs([])}
              disabled={selectedQrs.length === 0}
            >
              <CloseIcon />
            </IconButton>
          </>
        )}
      </Box>
    </Toolbar>
  );
};

export default QRToolbar;
