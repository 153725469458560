import api from "../axios";
import {
  verifyName,
  verifyPhoneNumber,
  verifyMail,
  verifyPassword,
} from "../../utils/forms/FormsVerification";

export const registerUser = async (iFormValues) => {
  const aResponse = await api.post(`/users/register`, iFormValues);
  return aResponse.data;
};

export const verifyRegisterData = (iFormValues) => {
  const aErrorValues = {
    hasNameError: false,
    name: "",
    hasPhoneNumberError: false,
    phoneNumber: "",
    hasMailError: false,
    mail: "",
    hasPasswordError: false,
    password: "",
  };

  verifyName(iFormValues.name, aErrorValues);
  verifyPhoneNumber(iFormValues.phoneNumber, aErrorValues);
  verifyMail(iFormValues.mail, aErrorValues);
  verifyPassword(iFormValues.password, aErrorValues);

  return aErrorValues;
};
