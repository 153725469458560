import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { FileUploader } from "react-drag-drop-files";

import { ADD_QR_ACTIONS } from "../../../../../utils/constants/Constants";
import { styles } from "./FileContent.style";

import {
  deleteQrCode,
  postQrLogo,
  postQrCode,
} from "../../../../../api/qrCodes/qrCodesHandler";
import Loading from "../../../../../components/loading/Loading";
import { STORAGE_USER_ID } from "../../../../../utils/constants/Constants";
import {
  OctopusQRButton,
  UploaderBox,
} from "../../../../../utils/styled/Styled";

import { Box, Card, Typography, IconButton } from "@mui/material";
import WestIcon from "@mui/icons-material/West";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { postFileQr } from "../../../../../api/qrCodes/file/fileQrHandler";

export default function FileContent({
  currentAction,
  QRCode,
  file,
  putQRCode,
}) {
  const fileTypes = ["JPG", "PNG", "JPEG", "PDF", "MP4"];
  const [qrFile, setQrFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const navigateTo = useNavigate();

  const resetErrors = () => {
    setHasError(false);
    setErrorText("");
  };

  const handleChange = (file) => {
    resetErrors();
    if (file.size > 35000000) {
      setHasError(true);
      setErrorText("Fichier trop volumineux, 35Mo maximum autorisé");
      return;
    }
    setQrFile(file);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    resetErrors();
    if (!qrFile) {
      setHasError(true);
      setErrorText("Vous devez importer un fichier");
      setIsLoading(false);
      return;
    }
    const aUserId = localStorage.getItem(STORAGE_USER_ID);
    if (!aUserId) {
      setHasError(true);
      setErrorText("Vous n'êtes pas autorisé à faire cela");
      setIsLoading(false);
      return;
    }

    let aQRCodeId;
    try {
      if (!QRCode?._id) {
        const aQRCode = await postQrCode(aUserId, QRCode);
        aQRCodeId = aQRCode._id;
        if (file !== null) {
          const aQrLogoData = new FormData();
          aQrLogoData.append("file", file);
          await postQrLogo(aUserId, aQRCodeId, aQrLogoData);
        }
      } else {
        await putQRCode();
        aQRCodeId = QRCode._id;
      }
      const aQrFileData = new FormData();
      aQrFileData.append("file", qrFile);
      await postFileQr(aUserId, aQRCodeId, aQrFileData);
      navigateTo("/qrcodes");
    } catch {
      if (!QRCode?._id) {
        await deleteQrCode(aUserId, aQRCodeId);
      }
      setHasError(true);
      setErrorText("Une erreur est survenue, veuillez réessayer");
      setIsLoading(false);
      return;
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  const aUploadBox = (
    <UploaderBox style={styles.center}>
      <CloudUploadIcon style={styles.centeredIcon} />
      <Typography sx={{ mt: 5, mr: 2, ml: 2 }}>
        <strong style={{ textDecoration: "underline" }}>Sélectionner</strong> ou
        glisser pour importer un fichier
      </Typography>
      <Typography sx={{ mt: 3, mr: 2, ml: 2, mb: 4 }}>
        Accepté : PNG, JPG, JPEG, PDF, MP4
      </Typography>
    </UploaderBox>
  );

  return (
    <>
      <Box
        sx={{
          ...styles.flexCenter,
          width: { xs: "100%", md: "95%", lg: "90%" },
        }}
      >
        <Card sx={styles.card}>
          <IconButton
            sx={styles.backArrow}
            onClick={() => {
              currentAction(ADD_QR_ACTIONS.CUSOMIZATION);
            }}
          >
            <WestIcon />
          </IconButton>
          <Typography variant="h4" sx={styles.titleContainer}>
            Importation du fichier
          </Typography>
          <Box sx={styles.uploader}>
            <FileUploader
              children={aUploadBox}
              handleChange={handleChange}
              name="file"
              types={fileTypes}
              label="Sélectionner ou glisser déposer un fichier."
              hoverTitle="Déposez ici"
            />
          </Box>
          {qrFile ? (
            <Typography variant="overline" sx={styles.text}>
              Fichier importé : {qrFile.name}
            </Typography>
          ) : (
            <Typography variant="overline" sx={styles.text}>
              Aucun fichier pour le moment
            </Typography>
          )}
          <br />
          <OctopusQRButton sx={styles.button} onClick={handleSubmit}>
            Terminer
          </OctopusQRButton>
          <br />
          {hasError ? (
            <Typography variant="overline" sx={styles.errorText}>
              {errorText}
            </Typography>
          ) : null}
        </Card>
      </Box>
    </>
  );
}
