import { useState } from "react";

import { styles } from "../../CustomPageContent.style";
import { OctopusQRButton } from "../../../../../../../utils/styled/Styled";

import { FormControl, TextField, InputAdornment } from "@mui/material";

import LinkIcon from "@mui/icons-material/Link";
import AbcIcon from "@mui/icons-material/Abc";
import { DEVICE_SORTABLE_INDEX } from "../../../../../../../utils/constants/Constants";

export default function CustomLinks({
  linksAndFiles,
  setLinksAndFiles,
  items,
  setItems,
}) {
  const initialErrorState = {
    value: "",
    hasError: false,
  };
  const [linkName, setLinkName] = useState("");
  const [linkNameError, setLinkNameError] = useState(initialErrorState);
  const updateLinkName = (iEvent) => {
    setLinkName(iEvent.target.value);
  };
  const [link, setLink] = useState("");
  const [linkError, setLinkError] = useState(initialErrorState);
  const [numberOfItemsCreated, setNumberOfItemsCreated] = useState(
    linksAndFiles.length
  );
  const updateLink = (iEvent) => {
    setLink(iEvent.target.value);
  };

  const addLink = () => {
    setLinkError(initialErrorState);
    setLinkNameError(initialErrorState);

    if (!linkName) {
      setLinkNameError({
        value: "Champs requis",
        hasError: true,
      });
      return;
    }

    const aRegex = new RegExp(
      /^(https?:\/\/)[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
      "i"
    );
    if (!aRegex.test(link)) {
      setLinkError({
        value: "Le lien n'est pas valide, il doit démarrer par http:// ou https://",
        hasError: true,
      });
      return;
    }

    const aLinks = Array.from(linksAndFiles);
    aLinks.push({
      isLink: true,
      name: linkName,
      path: link,
      id: `link_${numberOfItemsCreated + 1}`,
      position: aLinks.length + 1,
    });

    setNumberOfItemsCreated(numberOfItemsCreated + 1);

    const aItems = Array.from(items);
    aItems.find(
      (aItem) => aItem.id === DEVICE_SORTABLE_INDEX.LINKS_AND_FILES
    ).content = aLinks;
    setItems(aItems);
    setLinksAndFiles(aLinks);
    setLink("");
    setLinkName("");
  };

  return (
    <>
      <FormControl sx={{ width: "88%" }}>
        <TextField
          label="Nom du lien"
          variant="outlined"
          value={linkName}
          onChange={updateLinkName}
          error={linkNameError.hasError}
          helperText={linkNameError.value}
          sx={styles.field}
          placeholder="Site web"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AbcIcon style={styles.icon} />
              </InputAdornment>
            ),
          }}
        />
        <br />
        <TextField
          label="Lien"
          variant="outlined"
          value={link}
          onChange={updateLink}
          error={linkError.hasError}
          helperText={linkError.value}
          sx={styles.field}
          placeholder="https://octopusqr.com"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LinkIcon style={styles.icon} />
              </InputAdornment>
            ),
          }}
        />
      </FormControl>
      <br />
      <OctopusQRButton sx={{ ...styles.button, mb: 2 }} onClick={addLink}>
        Ajouter
      </OctopusQRButton>
    </>
  );
}
