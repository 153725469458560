import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";
import { OctopusQRButton, RedButton } from "../../../utils/styled/Styled";

const DeleteQRFolder = ({ open, onClose, folder, handleDelete }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>
        Supprimer le dossier <strong>"{folder.name}"</strong>
      </DialogTitle>
      <DialogContent>
        <Typography>
          Si vous supprimez ce dossier, l'ensemble des QRCodes à l'intérieur
          seront déplacés vers la page d'accueil, ou le dossier parent.
        </Typography>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <OctopusQRButton onClick={onClose} sx={{ width: "150px" }}>
          Annuler
        </OctopusQRButton>
        <RedButton
          onClick={() => {
            handleDelete(folder);
          }}
          sx={{ width: "150px" }}
        >
          Supprimer
        </RedButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteQRFolder;
