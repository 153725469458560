import React from "react";
import { store } from "../../../../index.js";
import { useNavigate } from "react-router-dom";

import allActions from "../../../../redux/actions/allActions.js";
import { CustomRouterLink } from "../../../../utils/styled/Styled.js";
import {
  STORAGE_ACCESS_TOKEN,
  STORAGE_USER_ID,
  USER_ROLE,
} from "../../../../utils/constants/Constants.js";

import {
  Menu,
  MenuItem,
  Typography,
  Tooltip,
  IconButton,
  Avatar,
  Divider,
  Grid,
} from "@mui/material";

import QrCode2Icon from "@mui/icons-material/QrCode2";
import LogoutIcon from "@mui/icons-material/Logout";
import CottageIcon from "@mui/icons-material/Cottage";
import { styles } from "../LoggedMenu.style.js";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import HelpIcon from "@mui/icons-material/Help";
import InfoIcon from "@mui/icons-material/Info";
import ContactPageIcon from "@mui/icons-material/ContactPage";

export default function WideScreenLoggedMenu({ user }) {
  const navigateTo = useNavigate();

  const [anchorElAccount, setAnchorElAccount] = React.useState(null);
  const isAccountOpen = Boolean(anchorElAccount);
  const handleClickAccount = (event) => {
    setAnchorElAccount(event.currentTarget);
  };
  const handleCloseAccount = () => {
    setAnchorElAccount(null);
  };

  const handleClickQRCode = () => {
    navigateTo("/qrcodes");
  };

  const logout = () => {
    store.dispatch(allActions.userActions.logout());
    localStorage.removeItem(STORAGE_ACCESS_TOKEN);
    localStorage.removeItem(STORAGE_USER_ID);
    navigateTo("/login");
  };

  return (
    <>
      <CustomRouterLink to="/">
        <MenuItem key="Home" sx={{ borderRadius: "50px", mr: 1 }}>
          <Typography
            sx={{ display: "flex", alignItems: "center" }}
            textAlign="center"
          >
            <CottageIcon sx={styles.icon} />
            Accueil
          </Typography>
        </MenuItem>
      </CustomRouterLink>
      <Tooltip title="Espace QR">
        <MenuItem sx={{ borderRadius: "50px" }} onClick={handleClickQRCode}>
          <Typography
            sx={{ display: "flex", alignItems: "center" }}
            textAlign="center"
          >
            <QrCode2Icon sx={styles.icon} />
            Mes QRCodes
          </Typography>
        </MenuItem>
      </Tooltip>
      <Tooltip title="Mon compte">
        <IconButton
          onClick={handleClickAccount}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={isAccountOpen ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={isAccountOpen ? "true" : undefined}
        >
          {user.logoPath ? (
            <Avatar sx={{ width: 40, height: 40 }} src={user.logoPath}></Avatar>
          ) : (
            <Avatar sx={{ width: 40, height: 40 }}>
              {user.name.charAt(0).toUpperCase()}
            </Avatar>
          )}
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={anchorElAccount}
        id="account-menu"
        open={isAccountOpen}
        onClose={handleCloseAccount}
        onClick={handleCloseAccount}
        slotProps={{ paper: styles.paperMenuProps }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <CustomRouterLink to="/profile">
          <MenuItem key="Profil">
            <Grid sx={styles.iconText}>
              {user.logoPath ? (
                <Avatar
                  sx={{ mr: "12px", width: 20, height: 20 }}
                  src={user.logoPath}
                ></Avatar>
              ) : (
                <Avatar sx={{ mr: "12px", width: 20, height: 20 }}>
                  {user.name.charAt(0).toUpperCase()}
                </Avatar>
              )}
              <Typography>Profil</Typography>
            </Grid>
          </MenuItem>
        </CustomRouterLink>

        {user.role === USER_ROLE.ADMIN && (
          <CustomRouterLink to="/admin">
            <MenuItem key="Admin">
              <Typography sx={styles.iconText} textAlign="center">
                <SupervisorAccountIcon sx={styles.icon} />
                Administration
              </Typography>
            </MenuItem>
          </CustomRouterLink>
        )}

        <Divider sx={{ m: 1 }} />

        <CustomRouterLink to="/contact">
          <MenuItem key="Contact">
            <Typography sx={styles.iconText} textAlign="center">
              <ContactPageIcon sx={styles.icon} />
              Contact
            </Typography>
          </MenuItem>
        </CustomRouterLink>

        <CustomRouterLink to="/about">
          <MenuItem key="About">
            <Typography sx={styles.iconText} textAlign="center">
              <InfoIcon sx={styles.icon} />À propos
            </Typography>
          </MenuItem>
        </CustomRouterLink>

        <CustomRouterLink to="/faq">
          <MenuItem key="FAQ">
            <Typography sx={styles.iconText} textAlign="center">
              <HelpIcon sx={styles.icon} />
              FAQ
            </Typography>
          </MenuItem>
        </CustomRouterLink>

        <Divider sx={{ m: 1 }} />

        <MenuItem key="Logout" onClick={logout}>
          <Typography sx={styles.iconText} textAlign="center">
            <LogoutIcon sx={styles.icon} />
            Déconnexion
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
}
