export const styles = {
  pageContainer: { minHeight: "calc(100vh - 250px)" },

  errorTitle: { textAlign: "center", color: "#8B0000", marginTop: "60px" },

  qrsContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    mt: 2,
    mb: 4,
  },

  flexCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  flexColumnCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },

  searchBar: {
    my: 2,
    backgroundColor: "white",
    borderRadius: "20px",

    minWidth: "300px",
    [`& fieldset`]: {
      borderRadius: "20px",
      border: "1px",
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    },
  },

  card: {
    mx: 2,
    p: 2,
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    borderRadius: "20px",
    textAlign: "center",
    width: "300px",
  },

  droppableCard: {
    backgroundColor: "rgba(255,255,255,0.03)",
    p: 1,
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    borderRadius: "20px",
    textAlign: "center",
    width: "200px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  folderContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "20px",
  },

  folderCard: {
    display: { xs: "none", md: "none", lg: "block" },
    minWidth: "330px",
    mx: 2,
    my: 5,
    py: 2,
    height: "auto",
    borderRadius: "20px",
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
  },

  folderItem: {
    display: "flex",
    alignItems: "center",
    mx: 1,
    py: 1,
    px: 1,
    width: "80%",
    borderRadius: "20px",
  },

  phoneFolderItem: {
    display: "flex",
    alignItems: "center",
    mx: 1,
    p: 1,
    height: "60px",
    borderRadius: "20px",
  },

  hidden: {
    height: 0,
    overflow: "hidden",
    visibility: "hidden",
  },

  imageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  icon: {
    color: "#58738D",
    fontSize: "30px",
    m: 1,
  },

  iconButton: {
    width: "45px",
    height: "45px",
    mx: 1,
  },

  downIconContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
  },

  addIcon: {
    color: "#58738D",
    fontSize: "100px",
  },

  groupedAction: {
    backgroundColor: "white",
    borderRadius: "20px",
    width: "200px",
    [`& fieldset`]: {
      borderRadius: "20px",
      border: "1px",
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    },
  },

  addIconButton: { mt: 2, width: "110px", height: "110px" },

  popOverTransform: {
    vertical: "top",
    horizontal: "left",
  },

  popOverAnchor: {
    vertical: "bottom",
    horizontal: "left",
  },

  popOver: {
    pointerEvents: "none",
  },

  divider: {
    mb: 3,
    mt: 1,
  },

  menuContainer: {
    display: "flex",
    justifyContent: { xs: "space-between", lg: "center" },
    alignItems: "center",
    flexWrap: "wrap",
    background: "#58738D",
    color: "white",
    py: { xs: 1, md: 2 },

    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
  },

  paperMenuProps: {
    elevation: 0,
    sx: {
      width: "300px",
      overflow: "visible",
      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
      mt: 1.5,
      "& .MuiAvatar-root": {
        width: 32,
        height: 32,
        ml: -0.5,
        mr: 1,
      },
      "&:before": {
        content: '""',
        display: "block",
        position: "absolute",
        top: 0,
        left: 15,
        width: 10,
        height: 10,
        bgcolor: "background.paper",
        transform: "translateY(-50%) rotate(45deg)",
        zIndex: 0,
      },
    },
  },

  cardContainer: {
    display: "flex",
    gap: 2,
    margin: "auto",
    my: 5,
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
  },
};
