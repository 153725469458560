import ErrorIcon from "@mui/icons-material/Error";
import { Typography, Card, Box } from "@mui/material";

const styles = {
  card: {
    my: 20,
    p: 3,
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    borderRadius: "20px",
    textAlign: "center",
    width: "300px",
    color: "#8B0000",
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

const ErrorCard = () => {
  return (
    <Box sx={styles.container}>
      <Card sx={styles.card}>
        <ErrorIcon sx={{ fontSize: "40px", my: 3 }} />
        <Typography>
          Une erreur est survenue, nous sommes désolés. Veuillez réessayer.
        </Typography>
      </Card>
    </Box>
  );
};

export default ErrorCard;
