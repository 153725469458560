import { useState } from "react";
import { Box, Typography } from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import { RedButton } from "../../../../utils/styled/Styled";
import { styles } from "../QRCodeView.style";
import QRCodeDisplay from "../../main/display/QRCodeDisplay";
import { deleteQrCode } from "../../../../api/qrCodes/qrCodesHandler";
import Loading from "../../../../components/loading/Loading";
import { useNavigate } from "react-router-dom";

export default function DeleteQRCode({ user, qrCode }) {
  const initialError = {
    hasError: false,
    errorText: "",
  };
  const navigateTo = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(initialError);

  const handleDeleteQRCode = async () => {
    setIsLoading(true);
    setError(initialError);

    try {
      await deleteQrCode(user._id, qrCode._id);
      navigateTo("/qrcodes");
    } catch {
      setError({
        hasError: true,
        errorText: "Une erreur est survenue, merci de réessayer",
      });
    }
    setIsLoading(false);
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Box sx={{ display: "flex", justifyContent: "center", m: 3 }}>
      <Box
        sx={{ width: { xs: "300px", md: "450px" }, m: 2, textAlign: "center" }}
      >
        <QRCodeDisplay qrCode={qrCode} />

        <Typography
          variant="overline"
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            textAlign: "justify",
            color: "#8B0000",
            fontSize: "16px",
          }}
        >
          <WarningIcon sx={{ mb: 2 }} />
          Si vous décidez de supprimer votre QRCode, vous perdrez l'ensemble des
          données liées à ce QRCode. Le lien ne sera plus valide et les
          statistiques seront effacées.
        </Typography>
        <RedButton sx={styles.button} onClick={handleDeleteQRCode}>
          Supprimer
        </RedButton>
        {error.hasError ? (
          <Typography sx={styles.errorText}>{error.errorText}</Typography>
        ) : null}
      </Box>
    </Box>
  );
}
