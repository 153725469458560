import api from "../../axios";

export const postTextQr = async (iUserId, iQRCodeId, iData) => {
  const aResponse = await api.post(
    `/users/${iUserId}/qrcodes/${iQRCodeId}/texts`,
    iData
  );
  return aResponse.data;
};

export const putTextQr = async (iUserId, iQRCodeId, iTextId, iData) => {
  const aResponse = await api.post(
    `/users/${iUserId}/qrcodes/${iQRCodeId}/texts/${iTextId}`,
    iData
  );
  return aResponse.data;
};
