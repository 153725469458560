import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Card,
  Typography,
  FormControl,
  TextField,
  InputAdornment,
} from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import { OctopusQRButton } from "../../../../../utils/styled/Styled";
import { styles } from "../../QRCodeView.style";
import Loading from "../../../../../components/loading/Loading";
import {
  putLinkQr,
  verifyLinkData,
} from "../../../../../api/qrCodes/link/linkQrHandler";

export default function EditLinkContent({
  user,
  qrCode,
  qrContent,
  setQrContent,
  setHasBeenSaved,
}) {
  const navigateTo = useNavigate();
  const [linkValue, setLinkValue] = useState(
    qrContent?.path ? qrContent.path : ""
  );
  const [initialLinkValue, setInitialLinkValue] = useState(
    qrContent?.path ? qrContent.path : ""
  );
  const [hasChanged, setHasChanged] = useState(false);
  const [linkError, setLinkError] = useState({
    link: "",
    hasLinkError: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const handleInputChange = (iEvent) => {
    setHasBeenSaved(false);
    const aLinkValue = iEvent.target.value;
    if (aLinkValue !== initialLinkValue) {
      setHasChanged(true);
    } else {
      setHasChanged(false);
    }

    setLinkValue(aLinkValue);
  };

  const saveChanges = async () => {
    setIsLoading(true);
    setHasError(false);
    const aLinkError = verifyLinkData(linkValue);
    setLinkError({ ...aLinkError });
    if (aLinkError.hasLinkError) {
      setIsLoading(false);
      return;
    }

    try {
      const aNewLink = await putLinkQr(user._id, qrCode._id, qrContent._id, {
        path: linkValue,
      });
      setQrContent({ ...aNewLink });
      setInitialLinkValue(linkValue);
      setHasChanged(false);
      setHasBeenSaved(true);
      if (qrCode.folderId) {
        navigateTo(`/qrcodes/folder/${qrCode.folderId}`);
      } else {
        navigateTo(`/qrcodes`);
      }
    } catch {
      setHasError(true);
    }
    setIsLoading(false);
  };
  if (isLoading) {
    return <Loading />;
  }
  return (
    <Box
      sx={{
        ...styles.flexCenter,
        width: "100%",
        mt: 6,
      }}
    >
      <Card sx={styles.card}>
        <Typography variant="h4">Modification du lien</Typography>
        <FormControl sx={{ ...styles.formControl, mt: 6 }}>
          <TextField
            id="link-input"
            name="link"
            label="Lien"
            required={true}
            variant="outlined"
            value={linkValue}
            error={linkError.hasLinkError}
            helperText={linkError.link}
            onChange={handleInputChange}
            sx={styles.field}
            placeholder="https://octopusqr.com"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LinkIcon style={styles.icon} />
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
        <br />
        <OctopusQRButton
          sx={styles.button}
          onClick={saveChanges}
          disabled={!hasChanged}
        >
          Sauvegarder
        </OctopusQRButton>
        <br />
        {hasError ? (
          <Typography variant="overline" sx={styles.errorText}>
            Une erreur est survenue, veuillez réessayer
          </Typography>
        ) : null}
      </Card>
    </Box>
  );
}
