export const styles = {
  card: {
    mt: 2,
    mr: 3,
    ml: 3,
    mb: { xs: 1, md: 3, lg: 6 },
    py: 3,
    px: 2,
    width: { lg: "40%", md: "60%", xs: "95%" },
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    borderRadius: "20px",
    textAlign: "center",
  },

  rawDataTitle: { fontSize: "15px", fontWeight: "bold" },

  rawDataNumber: {
    fontSize: "50px",
  },

  statsCard: {
    mt: 2,
    mx: 2,
    mb: 2,
    py: 1,
    px: 1,
    width: { lg: "22%", md: "45%", xs: "100%" },
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    borderRadius: "20px",
    textAlign: "center",
  },

  columnCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },

  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  excelDownload: {
    width: "130px",
    height: "40px",
    display: { xs: "none", md: "flex" },
    justifyContent: "space-evenly",
    alignItems: "center",
    mr: 2,
    mb: -3,
  },
};
