import { HexColorInput, HexColorPicker } from "react-colorful";
import { RGBToHex } from "../../../../../../../utils/Utils";

import {
  Box,
  Typography,
  AccordionSummary,
  Accordion,
  AccordionDetails,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import {
  FONT_TYPE,
  FONT_SIZE,
} from "../../../../../../../utils/constants/Constants";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styles } from "../../CustomPageContent.style";

export default function LinksAndFilesCustomization({
  customization,
  setCustomization,
  expanded,
  handleChangeExpanded,
}) {
  const updateFontType = (iEvent) => {
    setCustomization({
      ...customization,
      buttonFontType: iEvent.target.value,
    });
  };

  const updateFontSize = (iEvent) => {
    setCustomization({
      ...customization,
      buttonFontSize: iEvent.target.value,
    });
  };

  const updateButtonFontColor = (iEvent) => {
    const aRgb = iEvent.target.querySelector("div").style.backgroundColor;
    const aHex = RGBToHex(aRgb);
    setCustomization({ ...customization, buttonFontColor: aHex });
  };

  const updateButtonFontColorFromInput = (iEvent) => {
    setCustomization({ ...customization, buttonFontColor: iEvent });
  };

  const updateButtonColor = (iEvent) => {
    const aRgb = iEvent.target.querySelector("div").style.backgroundColor;
    const aHex = RGBToHex(aRgb);
    setCustomization({ ...customization, buttonColor: aHex });
  };

  const updateButtonColorFromInput = (iEvent) => {
    setCustomization({ ...customization, buttonColor: iEvent });
  };

  return (
    <Accordion
      sx={styles.accordionStyle}
      expanded={expanded === "panel3"}
      onChange={handleChangeExpanded("panel3")}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography sx={styles.accordionTitle}>Personnalisation</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant="overline">Police des liens et fichiers</Typography>
        <br />
        <FormControl sx={{ width: "88%", mt: 3 }}>
          <InputLabel id="font-label">Type</InputLabel>
          <Select
            labelId="font-label"
            sx={styles.field}
            MenuProps={styles.menuProps}
            value={customization.buttonFontType}
            onChange={updateFontType}
            label="Votre choix"
          >
            {FONT_TYPE.map((aFontType) => {
              return (
                <MenuItem value={aFontType} key={aFontType}>
                  <Typography sx={{ fontFamily: aFontType }}>
                    {aFontType}
                  </Typography>
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl sx={{ width: "88%", mt: 3 }}>
          <InputLabel id="font-label">Taille</InputLabel>
          <Select
            labelId="font-label"
            sx={styles.field}
            MenuProps={styles.menuProps}
            value={customization.buttonFontSize}
            onChange={updateFontSize}
            label="Votre choix"
          >
            {FONT_SIZE.map((aFontSize) => {
              return (
                <MenuItem value={aFontSize} key={aFontSize}>
                  <Typography>{aFontSize}</Typography>
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <Box sx={{ mt: 3 }}>
          <Typography variant="overline">Couleur texte</Typography>
          <HexColorPicker
            color={customization.buttonFontColor}
            onMouseUp={updateButtonFontColor}
            style={styles.colorPicker}
          />
          <HexColorInput
            color={customization.buttonFontColor}
            onChange={updateButtonFontColorFromInput}
          />
        </Box>
        <Box sx={{ mt: 3 }}>
          <Typography variant="overline">Couleur boutton</Typography>
          <HexColorPicker
            color={customization.buttonColor}
            onMouseUp={updateButtonColor}
            style={styles.colorPicker}
          />
          <HexColorInput
            color={customization.buttonColor}
            onChange={updateButtonColorFromInput}
          />
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}
