import api from "../axios";

export const getUser = async (iUserId) => {
  const aResponse = await api.get(`/users/${iUserId}`);
  return aResponse.data;
};

export const getRenderUser = async (iUserId) => {
  const aResponse = await api.get(`/users/${iUserId}/render`);
  return aResponse.data;
};
