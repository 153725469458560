import { styles } from "../../CustomPageContent.style";

import {
  Typography,
  FormControl,
  TextField,
  InputAdornment,
  AccordionDetails,
  AccordionSummary,
  Accordion,
} from "@mui/material";
import AbcIcon from "@mui/icons-material/Abc";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function CustomDescription({
  description,
  updateDescription,
  expanded,
  handleChangeExpanded,
}) {
  return (
    <Accordion
      sx={styles.accordionStyle}
      expanded={expanded === "panel2"}
      onChange={handleChangeExpanded("panel2")}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography sx={styles.accordionTitle}>
          Ajouter une description
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FormControl sx={{ width: "88%" }}>
          <TextField
            id="description-input"
            name="description"
            label="Description"
            variant="outlined"
            value={description}
            onChange={updateDescription}
            sx={styles.field}
            fullWidth
            multiline={true}
            rows={2}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AbcIcon style={styles.icon} />
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
      </AccordionDetails>
    </Accordion>
  );
}
