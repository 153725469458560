import { useNavigate } from "react-router-dom";
import { useState } from "react";

import { styles } from "../QRCodes.style";

import { Box, Typography, MenuItem, Menu } from "@mui/material";

import FolderIcon from "@mui/icons-material/Folder";

const PhoneQRFolderDisplay = ({ qrFolders, isHome }) => {
  const navigateTo = useNavigate();

  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const isMenuOpen = Boolean(anchorElMenu);

  const handleClickMenu = (event) => {
    setAnchorElMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  return (
    <Box
      sx={{
        display: { xs: "block", md: "block", lg: "none" },
      }}
    >
      <Box sx={styles.menuContainer}>
        <MenuItem
          onClick={handleClickMenu}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={isMenuOpen ? "menu" : undefined}
          aria-haspopup="true"
          aria-expanded={isMenuOpen ? "true" : undefined}
        >
          <FolderIcon sx={{ color: "white" }} />
          <Typography variant="overline" sx={{ ml: 2 }}>
            {isHome ? "Dossiers" : "Sous-dossiers"}
          </Typography>
        </MenuItem>

        <Menu
          anchorEl={anchorElMenu}
          id="-menu"
          open={isMenuOpen}
          onClose={handleCloseMenu}
          onClick={handleCloseMenu}
          slotProps={{ paper: styles.paperMenuProps }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          sx={{ width: "300px" }}
        >
          {qrFolders.map((aQrFolder) => (
            <MenuItem
              sx={styles.phoneFolderItem}
              onClick={() => navigateTo(`/qrcodes/folder/${aQrFolder._id}`)}
              key={aQrFolder._id}
            >
              <FolderIcon
                sx={{
                  mr: 2,
                  color: "rgba(88, 115, 141, 0.7)",
                }}
              />
              <Typography
                variant="overline"
                sx={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                {aQrFolder.name}
              </Typography>
            </MenuItem>
          ))}
        </Menu>
      </Box>
    </Box>
  );
};

export default PhoneQRFolderDisplay;
