import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../../../components/loading/Loading";

export default function LegacyCdartQr() {
  const navigateTo = useNavigate();
  useEffect(() => {
    navigateTo("/render/63ecf277223e4c9bbbab893a");
  }, [navigateTo]);

  return <Loading />;
}
