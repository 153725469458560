export const styles = {
  pageContainer: { minHeight: "calc(100vh - 250px)" },

  menuContainer: {
    display: "flex",
    justifyContent: { xs: "space-between", lg: "center" },
    alignItems: "center",
    flexWrap: "wrap",
    background: "#58738D",
    color: "white",
    py: { xs: 1, md: 2 },

    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
  },

  menuItem: { borderRadius: "50px", mx: { xs: 1, md: 2 } },

  menuTypo: {
    fontSize: { xs: "15px", md: "14px" },
    fontWeight: { xs: "light", md: "bold" },
  },
};
