import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Loading from "../../../assets/adr_loading.gif";

import { OctopusQRButton } from "../../../utils/styled/Styled";
import { styles } from "./DeleteLogo.style";

import { deleteLogo } from "../../../api/user/logo/deleteLogo";
import { store } from "../../../index";
import allActions from "../../../redux/actions/allActions";
import { STORAGE_USER_ID } from "../../../utils/constants/Constants.js";

import {
  Dialog,
  Grid,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CloseIcon from "@mui/icons-material/Close";

export default function DeleteLogo() {
  const navigateTo = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [hasDeleteLogoErrors, setHasDeleteLogoErrors] = useState(false);

  const handleClose = () => {
    navigateTo("/profile");
  };

  const handleDeletion = () => {
    setHasDeleteLogoErrors(false);
    setIsLoading(true);
    deleteLogo(localStorage.getItem(STORAGE_USER_ID))
      .then(() => {
        store.dispatch(allActions.userActions.updateLogo(""));
        setIsLoading(false);
        navigateTo("/profile");
      })
      .catch(() => {
        setHasDeleteLogoErrors(true);
        setIsLoading(false);
      });
  };

  return (
    <Dialog open={true} onClose={handleClose}>
      <DialogTitle>
        <IconButton
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DeleteForeverIcon style={styles.centeredIcon} />
        <Typography style={styles.title}>
          Voulez vous vraiment supprimer votre logo ?
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={0}>
          <Grid item xs={6} sm={6} md={6}>
            <OctopusQRButton style={styles.button} onClick={handleClose}>
              Annuler
            </OctopusQRButton>
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <OctopusQRButton style={styles.button} onClick={handleDeletion}>
              Confirmer
            </OctopusQRButton>
          </Grid>
        </Grid>
        {isLoading ? (
          <img src={Loading} style={styles.center} height="50" alt="Loading" />
        ) : null}
        {hasDeleteLogoErrors ? (
          <p style={styles.error}>
            Une erreur est survenue, veuillez réessayer
          </p>
        ) : null}
      </DialogContent>
    </Dialog>
  );
}
