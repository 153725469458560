import React from "react";

import { Box, Typography } from "@mui/material";
import plateform from "../../../assets/home/plateform.png";
import service from "../../../assets/home/service.png";

const styles = {
  benefitsContainer: {
    p: 3,
    display: "flex",
    justifyContent: "space-around",
    flexDirection: { xs: "column", md: "row" },
    gap: "20px",
    minHeight: "800px",
    backgroundColor: "#282828",
  },
  contentContainer: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    flexDirection: "column",
  },
  title: {
    color: "#58738D",
    fontWeight: "bold",
    fontSize: "26px",
    textAlign: "center",
    py: 3,
  },
  content: {
    color: "white",
    textAlign: "justify",
  },
};

export default function Benefits() {
  return (
    <>
      <Box sx={styles.benefitsContainer}>
        <Box sx={styles.contentContainer}>
          <img src={service} alt="service" width="300" />
          <Box sx={{ maxWidth: "600px" }}>
            <Typography variant="h2" sx={styles.title}>
              Un Service Premium à Votre Écoute
            </Typography>
            <Typography sx={styles.content}>
              Avec OctopusQR, vous bénéficiez d'un service premium sur-mesure
              dédié à vos besoins spécifiques en matière de QR Codes. Nous
              comprenons que chaque entreprise est unique, c'est pourquoi nous
              proposons un accompagnement personnalisé pour vous aider à créer
              des QR Codes et des pages correspondant précisément à vos
              attentes. Notre équipe d'experts se tient à votre disposition pour
              vous offrir une expérience exceptionnelle, du premier contact
              jusqu'à la réalisation finale. De plus, nos tarifs sont flexibles
              et adaptés en fonction de vos besoins, vous garantissant un
              service de haute qualité à un prix compétitif. Faites confiance à
              OctopusQR pour vous fournir un service premium de QR Codes taillé
              sur-mesure pour votre entreprise.
            </Typography>
          </Box>
        </Box>
        <Box sx={styles.contentContainer}>
          <Box sx={{ display: { xs: "block", md: "none" } }}>
            <img src={plateform} alt="plateform" width="300" />
          </Box>
          <Box sx={{ maxWidth: "600px" }}>
            <Typography variant="h2" sx={styles.title}>
              Une Plateforme Conviviale pour Gérer Vos QR Codes
            </Typography>
            <Typography sx={styles.content}>
              OctopusQR propose une interface moderne et conviviale qui vous
              permet de gérer vos propres QR Codes. Que vous soyez un débutant
              ou un expert en marketing numérique, notre back-office est conçu
              pour être simple à utiliser, tout en offrant des fonctionnalités
              avancées. Créez, personnalisez et suivez vos QR Codes en toute
              simplicité. Accédez à des analyses détaillées pour comprendre
              l'efficacité de vos campagnes et affinez votre stratégie marketing
              en fonction des données. Avec OctopusQR, vous avez le contrôle
              total sur vos QR Codes, tout en bénéficiant de notre support
              client d'élite prêt à vous aider à chaque étape. Gérer vos QR
              Codes n'a jamais été aussi facile et efficace.
            </Typography>
          </Box>
          <Box sx={{ display: { xs: "none", md: "block" } }}>
            <img src={plateform} alt="plateform" width="300" />
          </Box>
        </Box>
      </Box>
      <Box sx={{ width: "100%", height: "30px", backgroundColor: "#F9F9F9" }} />
    </>
  );
}
