import api from "../../axios";

export const postCustomPage = async (iUserId, iQRCodeId, iData) => {
  const aResponse = await api.post(
    `/users/${iUserId}/qrcodes/${iQRCodeId}/custom-pages`,
    iData
  );
  return aResponse.data;
};

export const putCustomPage = async (
  iUserId,
  iQRCodeId,
  iCustomPageId,
  iData
) => {
  const aResponse = await api.put(
    `/users/${iUserId}/qrcodes/${iQRCodeId}/custom-pages/${iCustomPageId}`,
    iData
  );
  return aResponse.data;
};

export const patchCustomPageMail = async (
  iUserId,
  iQRCodeId,
  iCustomPageId,
  iData
) => {
  const aResponse = await api.patch(
    `/users/${iUserId}/qrcodes/${iQRCodeId}/custom-pages/${iCustomPageId}/mails`,
    iData
  );
  return aResponse.data;
};

export const notifyCustomPageSubscribers = async (
  iUserId,
  iQRCodeId,
  iCustomPageId,
  iData
) => {
  const aResponse = await api.post(
    `/users/${iUserId}/qrcodes/${iQRCodeId}/custom-pages/${iCustomPageId}/notify`,
    iData
  );
  return aResponse.data;
};

export const unsubscribeCustomPageUser = async (
  iUserId,
  iQRCodeId,
  iCustomPageId,
  iData
) => {
  const aResponse = await api.patch(
    `/users/${iUserId}/qrcodes/${iQRCodeId}/custom-pages/${iCustomPageId}/unsubscribe`,
    iData
  );
  return aResponse.data;
};

export const deleteCustomPage = async (iUserId, iQRCodeId, iCustomPageId) => {
  const aResponse = await api.delete(
    `/users/${iUserId}/qrcodes/${iQRCodeId}/custom-pages/${iCustomPageId}`
  );
  return aResponse.data;
};

export const postCustomPageLogo = async (
  iUserId,
  iQRCodeId,
  iCustomPageId,
  iData
) => {
  const aResponse = await api.post(
    `/users/${iUserId}/qrcodes/${iQRCodeId}/custom-pages/${iCustomPageId}/logo`,
    iData,
    { timeout: 10 * 60 * 1000 }
  );
  return aResponse.data;
};

export const deleteCustomPageLogo = async (
  iUserId,
  iQRCodeId,
  iCustomPageId
) => {
  const aResponse = await api.delete(
    `/users/${iUserId}/qrcodes/${iQRCodeId}/custom-pages/${iCustomPageId}/logo`
  );
  return aResponse.data;
};

export const postCustomPageLink = async (
  iUserId,
  iQRCodeId,
  iCustomPageId,
  iData
) => {
  const aResponse = await api.post(
    `/users/${iUserId}/qrcodes/${iQRCodeId}/custom-pages/${iCustomPageId}/links`,
    iData
  );
  return aResponse.data;
};

export const putCustomPageLink = async (
  iUserId,
  iQRCodeId,
  iCustomPageId,
  iLinkId,
  iData
) => {
  const aResponse = await api.put(
    `/users/${iUserId}/qrcodes/${iQRCodeId}/custom-pages/${iCustomPageId}/links/${iLinkId}`,
    iData
  );
  return aResponse.data;
};

export const deleteCustomPageLink = async (
  iUserId,
  iQRCodeId,
  iCustomPageId,
  iLinkId
) => {
  const aResponse = await api.delete(
    `/users/${iUserId}/qrcodes/${iQRCodeId}/custom-pages/${iCustomPageId}/links/${iLinkId}`
  );
  return aResponse.data;
};

export const postCustomPageFile = async (
  iUserId,
  iQRCodeId,
  iCustomPageId,
  iData
) => {
  const aResponse = await api.post(
    `/users/${iUserId}/qrcodes/${iQRCodeId}/custom-pages/${iCustomPageId}/files`,
    iData,
    { timeout: 10 * 60 * 1000 }
  );
  return aResponse.data;
};

export const deleteCustomPageFile = async (
  iUserId,
  iQRCodeId,
  iCustomPageId,
  iFileId
) => {
  const aResponse = await api.delete(
    `/users/${iUserId}/qrcodes/${iQRCodeId}/custom-pages/${iCustomPageId}/files/${iFileId}`
  );
  return aResponse.data;
};

export const putCustomPageFile = async (
  iUserId,
  iQRCodeId,
  iCustomPageId,
  iFileId,
  iData
) => {
  const aResponse = await api.put(
    `/users/${iUserId}/qrcodes/${iQRCodeId}/custom-pages/${iCustomPageId}/files/${iFileId}`,
    iData,
    { timeout: 10 * 60 * 1000 }
  );
  return aResponse.data;
};

export const postCustomPageSocialNetwork = async (
  iUserId,
  iQRCodeId,
  iCustomPageId,
  iData
) => {
  const aResponse = await api.post(
    `/users/${iUserId}/qrcodes/${iQRCodeId}/custom-pages/${iCustomPageId}/social-networks`,
    iData
  );
  return aResponse.data;
};

export const deleteCustomSocialNetwork = async (
  iUserId,
  iQRCodeId,
  iCustomPageId,
  iSocialNetworkId
) => {
  const aResponse = await api.delete(
    `/users/${iUserId}/qrcodes/${iQRCodeId}/custom-pages/${iCustomPageId}/social-networks/${iSocialNetworkId}`
  );
  return aResponse.data;
};
