import { HexColorInput, HexColorPicker } from "react-colorful";

import { styles } from "../../CustomPageContent.style";
import {
  FONT_TYPE,
  FONT_SIZE,
  CUSTOMIZATION_POSITION,
} from "../../../../../../../utils/constants/Constants";
import { RGBToHex } from "../../../../../../../utils/Utils";

import {
  Typography,
  AccordionSummary,
  Accordion,
  AccordionDetails,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function LogoAndDescriptionCustomization({
  customization,
  setCustomization,
  expanded,
  handleChangeExpanded,
}) {
  const updateFontType = (iEvent) => {
    setCustomization({
      ...customization,
      descriptionFontType: iEvent.target.value,
    });
  };

  const updateFontSize = (iEvent) => {
    setCustomization({
      ...customization,
      descriptionFontSize: iEvent.target.value,
    });
  };

  const updateBackgroundColor = (iEvent) => {
    const aRgb = iEvent.target.querySelector("div").style.backgroundColor;
    const aHex = RGBToHex(aRgb);
    setCustomization({ ...customization, backgroundColor: aHex });
  };

  const updateBackgroundColorFromInput = (iEvent) => {
    setCustomization({ ...customization, backgroundColor: iEvent });
  };

  const updateDescriptionFontColor = (iEvent) => {
    const aRgb = iEvent.target.querySelector("div").style.backgroundColor;
    const aHex = RGBToHex(aRgb);
    setCustomization({ ...customization, descriptionFontColor: aHex });
  };

  const updateDescriptionFontColorFromInput = (iEvent) => {
    setCustomization({ ...customization, descriptionFontColor: iEvent });
  };

  const changePosition = () => {
    if (customization.position === CUSTOMIZATION_POSITION.TOP) {
      setCustomization({
        ...customization,
        position: CUSTOMIZATION_POSITION.CENTER,
      });
    } else {
      setCustomization({
        ...customization,
        position: CUSTOMIZATION_POSITION.TOP,
      });
    }
  };

  return (
    <>
      <Accordion
        sx={styles.accordionStyle}
        expanded={expanded === "panel3"}
        onChange={handleChangeExpanded("panel3")}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography sx={styles.accordionTitle}>Personnalisation</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <Typography variant="overline">Position du contenu</Typography>
            <RadioGroup
              row
              sx={{ justifyContent: "space-evenly", mb: 2 }}
              onChange={changePosition}
              value={customization.position}
            >
              <FormControlLabel
                value={CUSTOMIZATION_POSITION.TOP}
                control={<Radio />}
                label="Haut"
              />
              <FormControlLabel
                value={CUSTOMIZATION_POSITION.CENTER}
                control={<Radio />}
                label="Centre"
              />
            </RadioGroup>
          </Box>
          <Typography variant="overline">Police</Typography>
          <br />
          <FormControl sx={{ width: "88%", mt: 3 }}>
            <InputLabel id="font-label">Type</InputLabel>
            <Select
              labelId="font-label"
              sx={styles.field}
              MenuProps={styles.menuProps}
              value={customization.descriptionFontType}
              onChange={updateFontType}
              label="Votre choix"
            >
              {FONT_TYPE.map((aFontType) => {
                return (
                  <MenuItem value={aFontType} key={aFontType}>
                    <Typography sx={{ fontFamily: aFontType }}>
                      {aFontType}
                    </Typography>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl sx={{ width: "88%", mt: 3 }}>
            <InputLabel id="font-label">Taille</InputLabel>
            <Select
              labelId="font-label"
              sx={styles.field}
              MenuProps={styles.menuProps}
              value={customization.descriptionFontSize}
              onChange={updateFontSize}
              label="Votre choix"
            >
              {FONT_SIZE.map((aFontSize) => {
                return (
                  <MenuItem value={aFontSize} key={aFontSize}>
                    <Typography>{aFontSize}</Typography>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <Box sx={{ mt: 3 }}>
            <Typography variant="overline">
              Couleur texte description
            </Typography>
            <HexColorPicker
              color={customization.descriptionFontColor}
              onMouseUp={updateDescriptionFontColor}
              style={styles.colorPicker}
            />
            <HexColorInput
              color={customization.descriptionFontColor}
              onChange={updateDescriptionFontColorFromInput}
            />
          </Box>
          <Box sx={{ mt: 3 }}>
            <Typography variant="overline">Couleur d'arrière plan</Typography>
            <HexColorPicker
              color={customization.backgroundColor}
              onMouseUp={updateBackgroundColor}
              style={styles.colorPicker}
            />
            <HexColorInput
              color={customization.backgroundColor}
              onChange={updateBackgroundColorFromInput}
            />
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
