export const styles = {
  phoneContainer: {
    flexGrow: 0,
    alignItems: "center",
    textAlign: "center",
    display: { xs: "flex", sm: "none", md: "none", lg: "none" },
  },
  wideScreenContainer: {
    flexGrow: 0,
    alignItems: "center",
    textAlign: "center",
    display: { xs: "none", sm: "flex", md: "flex", lg: "flex" },
  },
  icon: { mr: "12px", color: "#58738D" },
  paperMenuProps: {
    elevation: 0,
    sx: {
      overflow: "visible",
      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
      mt: 1.5,
      "& .MuiAvatar-root": {
        width: 32,
        height: 32,
        ml: -0.5,
        mr: 1,
      },
      "&:before": {
        content: '""',
        display: "block",
        position: "absolute",
        top: 0,
        right: 14,
        width: 10,
        height: 10,
        bgcolor: "background.paper",
        transform: "translateY(-50%) rotate(45deg)",
        zIndex: 0,
      },
    },
  },

  iconText: { display: "flex", alignItems: "center" },
};
