export class PromiseQueue {
  constructor(concurrency) {
    this.concurrency = concurrency;
    this.queue = [];
    this.running = 0;
  }

  push(promise) {
    this.queue.push(promise);
    this.next();
  }

  next() {
    if (this.running >= this.concurrency || this.queue.length === 0) {
      return;
    }

    this.running++;
    this.queue
      .shift()()
      .finally(() => {
        this.running--;
        this.next();
      });
  }
}
