import { useNavigate } from "react-router-dom";
import { useState } from "react";

import {
  deleteQrFolder,
  renameQrFolder,
} from "../../../../api/qrCodes/qrFolder/qrFoldersHandler";

import { styles } from "../QRCodes.style";

import {
  Box,
  IconButton,
  Typography,
  MenuItem,
  Card,
  Divider,
} from "@mui/material";

import FolderIcon from "@mui/icons-material/Folder";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DeleteQRFolder from "../../qrFolders/DeleteQRFolder";
import EditIcon from "@mui/icons-material/Edit";
import ModifyQrHandler from "../../qrFolders/ModifyQrFolder";

import { useDrop } from "react-dnd";

const QRFolder = ({ qrFolder, user, getFolders, moveCard, setSelectedQrs }) => {
  const navigateTo = useNavigate();
  const [isDeleteFolderOpen, setIsDeleteFolderOpen] = useState(false);
  const [isMofidyFolderOpen, setIsModifyFolderOpen] = useState(false);

  const closeDeleteFolder = () => {
    setIsDeleteFolderOpen(false);
  };

  const handleDelete = async (folder) => {
    try {
      await deleteQrFolder(user._id, folder._id);
      setIsDeleteFolderOpen(false);
      setSelectedQrs([]);
      await getFolders();
    } catch {}
  };

  const closeModifyFolder = () => {
    setIsModifyFolderOpen(false);
  };

  const handleModify = async (iFolderName) => {
    try {
      await renameQrFolder(user._id, qrFolder._id, { name: iFolderName });
      setIsModifyFolderOpen(false);
      setSelectedQrs([]);
      await getFolders();
    } catch {}
  };

  const [{ isOver }, drop] = useDrop({
    accept: "card",
    drop: (item) => moveCard(item.id, qrFolder._id),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  return (
    <div ref={drop}>
      <Box sx={styles.folderContainer}>
        <MenuItem
          sx={{
            ...styles.folderItem,
            backgroundColor: isOver ? "rgba(88, 115, 141, 0.3)" : "transparent",
          }}
          onClick={() => {
            setSelectedQrs([]);
            navigateTo(`/qrcodes/folder/${qrFolder._id}`);
          }}
        >
          <FolderIcon
            sx={{
              mr: 2,
              color: "rgba(88, 115, 141, 0.7)",
            }}
          />
          <Typography
            variant="overline"
            sx={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {qrFolder.name}
          </Typography>
        </MenuItem>
        <IconButton
          onClick={(event) => {
            event.stopPropagation();
            setIsModifyFolderOpen(true);
          }}
        >
          <EditIcon sx={{ color: "#58738D" }} />
        </IconButton>
        <IconButton
          onClick={(event) => {
            event.stopPropagation();
            setIsDeleteFolderOpen(true);
          }}
        >
          <DeleteForeverIcon sx={{ color: "#8B0000" }} />
        </IconButton>
      </Box>

      <DeleteQRFolder
        open={isDeleteFolderOpen}
        onClose={closeDeleteFolder}
        folder={qrFolder}
        handleDelete={handleDelete}
      />
      <ModifyQrHandler
        open={isMofidyFolderOpen}
        onClose={closeModifyFolder}
        qrFolder={qrFolder}
        handleModify={handleModify}
      />
    </div>
  );
};

const QRFoldersDisplay = ({
  user,
  getFolders,
  handleOpen,
  qrFolders,
  isHome,
  parent,
  moveCard,
  setSelectedQrs,
}) => {
  const navigateTo = useNavigate();
  const [{ isOver }, drop] = useDrop({
    accept: "card",
    drop: (item) => moveCard(item.id, parent ? parent._id : "home"),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  return (
    <>
      <Card sx={styles.folderCard}>
        <Box sx={styles.downIconContainer}>
          <IconButton
            sx={{ mt: 2, width: "60px", height: "60px" }}
            onClick={handleOpen}
          >
            <CreateNewFolderIcon sx={{ color: "#58738D", fontSize: "50px" }} />
          </IconButton>
        </Box>
        <Box sx={styles.flexCenter}>
          <Typography variant="overline">
            {isHome ? "Nouveau dossier" : "Nouveau sous-dossier"}
          </Typography>
        </Box>
        <Box>
          {!isHome && (
            <>
              <Divider sx={{ my: 1, mx: 2 }} />
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Typography variant="overline">Dossier parent</Typography>
              </Box>
              <Divider sx={{ my: 1, mx: 2 }} />
              <div ref={drop}>
                <MenuItem
                  sx={{
                    ...styles.folderItem,
                    width: "95%",
                    backgroundColor: isOver
                      ? "rgba(88, 115, 141, 0.3)"
                      : "transparent",
                  }}
                  onClick={() => {
                    setSelectedQrs([]);
                    if (parent) {
                      navigateTo(`/qrcodes/folder/${parent._id}`);
                    } else {
                      navigateTo(`/qrcodes`);
                    }
                  }}
                >
                  <FolderIcon
                    sx={{
                      mr: 2,
                      color: "rgba(88, 115, 141, 0.7)",
                    }}
                  />
                  <Typography
                    variant="overline"
                    sx={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {parent ? parent.name : "Accueil"}
                  </Typography>
                </MenuItem>
              </div>
              {qrFolders.length > 0 && (
                <>
                  <Divider sx={{ my: 1, mx: 2 }} />
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Typography variant="overline">Dossiers enfants</Typography>
                  </Box>
                  <Divider sx={{ my: 1, mx: 2 }} />
                </>
              )}
            </>
          )}
          {qrFolders.map((aQrFolder) => (
            <QRFolder
              qrFolder={aQrFolder}
              user={user}
              getFolders={getFolders}
              key={aQrFolder._id}
              moveCard={moveCard}
              setSelectedQrs={setSelectedQrs}
            />
          ))}
        </Box>
      </Card>
    </>
  );
};

export default QRFoldersDisplay;
