import { Types } from "./actionTypes.js";

const userActions = {
  login: (iUser) => {
    return { type: Types.LOGIN, payload: iUser };
  },

  logout: () => {
    return { type: Types.LOGOUT };
  },

  initializeUserData: (iUser) => ({
    type: Types.INITIALIZE_USER_DATA,
    payload: iUser,
  }),

  updateLogo: (iLogoPath) => ({
    type: Types.UPDATE_LOGO,
    payload: iLogoPath,
  }),
};

export default userActions;
