import OQRBlack from "../../assets/press/OQRBlack.png";
import OQRWhite from "../../assets/press/OQRWhite.png";
import Poulpie from "../../assets/press/Poulpie.png";
import Logo from "../../assets/press/logo.png";
import Footer from "../../components/footer/Footer";
import NavMenu from "../../components/navMenu/NavMenu";
import { motion } from "framer-motion";
import { Box, Grid, Typography, IconButton } from "@mui/material";
import { GetApp } from "@mui/icons-material";

const styles = {
  card: {
    borderRadius: "20px",
    textAlign: "center",
    mt: 3,
    mb: 3,
    p: 3,
    backgroundColor: "#F1F1F1",
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    "&:hover": {
      transform: "scale(1.01)",
      boxShadow: "rgba(100, 100, 111, 0.2) 0px 10px 32px 0px",
      cursor: "pointer",
    },
  },
  pageContainer: { minHeight: "calc(100vh - 250px)" },
};

const variants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

const Press = () => {
  const handleDownload = (imageSrc) => {
    const link = document.createElement("a");
    link.href = imageSrc;
    link.download = "pressOctopusQR";
    link.click();
  };

  return (
    <>
      <Box sx={styles.pageContainer}>
        <NavMenu />
        <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
          <Typography
            variant="overline"
            sx={{ fontSize: "25px", fontWeight: "bold" }}
          >
            Kit Presse
          </Typography>
        </Box>
        <Grid container spacing={2} direction="column" alignItems="center">
          {[OQRBlack, OQRWhite, Poulpie, Logo].map((image, index) => (
            <Grid item key={index}>
              <motion.div
                initial="hidden"
                animate="visible"
                variants={variants}
              >
                <Box sx={styles.card}>
                  <img src={image} alt="" style={{ maxWidth: "240px" }} />
                  <IconButton
                    onClick={() => handleDownload(image)}
                    sx={{ color: "#58738D" }}
                    aria-label="download picture"
                  >
                    <GetApp />
                  </IconButton>
                </Box>
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Footer />
    </>
  );
};

export default Press;
