import { useState } from "react";
import { FileUploader } from "react-drag-drop-files";

import {
  CUSTOM_PAGE_LOGO_OPTIONS,
  DEVICE_SORTABLE_INDEX,
} from "../../../../../../../utils/constants/Constants";
import { styles } from "../../CustomPageContent.style";
import { UploaderBox } from "../../../../../../../utils/styled/Styled";

import {
  Box,
  Typography,
  Radio,
  FormControlLabel,
  RadioGroup,
  AccordionDetails,
  AccordionSummary,
  Accordion,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function CustomLogo({
  initialLogoPicture,
  logo,
  setLogo,
  items,
  setItems,
  user,
  expanded,
  handleChangeExpanded,
}) {
  const [uploadLogo, setUploadLogo] = useState(
    logo.logoOptionChosen === CUSTOM_PAGE_LOGO_OPTIONS.PERSONNAL
  );
  const [logoError, setLogoError] = useState(false);
  const [logoErrorText, setLogoErrorText] = useState("");
  const [customPageFile, setCustomPageFile] = useState(null);
  const fileTypes = ["JPG", "PNG", "JPEG"];
  const handleLogoAdded = (iFile) => {
    setLogoError(false);
    setLogoErrorText("");

    if (iFile.size > 2000000) {
      setLogoError(true);
      setLogoErrorText("Fichier trop volumineux, 2Mo maximum autorisés");
      return;
    }

    let reader = new FileReader();
    reader.onload = function (event) {
      const aNewLogoContent = {
        ...logo,
        picture: event.target.result,
        file: iFile,
      };
      setLogo(aNewLogoContent);
      const aItems = Array.from(items);
      aItems.find((aItem) => aItem.id === DEVICE_SORTABLE_INDEX.LOGO).content =
        aNewLogoContent;
      setItems(aItems);
    };

    reader.readAsDataURL(iFile);

    setCustomPageFile(iFile);
  };

  const changeLogoSource = (iEvent) => {
    let aNewLogoContent;
    if (iEvent.target.value === CUSTOM_PAGE_LOGO_OPTIONS.USER_LOGO) {
      aNewLogoContent = {
        ...logo,
        picture: user.logoPath,
        logoOptionChosen: iEvent.target.value,
      };
      setLogo(aNewLogoContent);
      const aItems = Array.from(items);
      aItems.find((aItem) => aItem.id === DEVICE_SORTABLE_INDEX.LOGO).content =
        aNewLogoContent;
      setItems(aItems);
      setUploadLogo(false);
    } else if (iEvent.target.value === CUSTOM_PAGE_LOGO_OPTIONS.PERSONNAL) {
      if (customPageFile) {
        let reader = new FileReader();
        reader.onload = function (event) {
          aNewLogoContent = {
            ...logo,
            picture: event.target.result,
            logoOptionChosen: iEvent.target.value,
          };
          setLogo(aNewLogoContent);
          const aItems = Array.from(items);
          aItems.find(
            (aItem) => aItem.id === DEVICE_SORTABLE_INDEX.LOGO
          ).content = aNewLogoContent;
          setItems(aItems);
        };
        reader.readAsDataURL(customPageFile);
      } else {
        aNewLogoContent = {
          ...logo,
          picture: initialLogoPicture ? initialLogoPicture : "",
          logoOptionChosen: iEvent.target.value,
        };
        setLogo(aNewLogoContent);
        const aItems = Array.from(items);
        aItems.find(
          (aItem) => aItem.id === DEVICE_SORTABLE_INDEX.LOGO
        ).content = aNewLogoContent;
        setItems(aItems);
      }
      setUploadLogo(true);
    } else if (iEvent.target.value === CUSTOM_PAGE_LOGO_OPTIONS.NONE) {
      aNewLogoContent = {
        ...logo,
        picture: "",
        logoOptionChosen: iEvent.target.value,
      };
      setLogo(aNewLogoContent);
      const aItems = Array.from(items);
      aItems.find((aItem) => aItem.id === DEVICE_SORTABLE_INDEX.LOGO).content =
        aNewLogoContent;
      setItems(aItems);
      setUploadLogo(false);
    }
  };

  const aUploadBox = (
    <UploaderBox style={styles.uploader}>
      <CloudUploadIcon style={styles.centeredIcon} />
      <Typography sx={{ mt: 5, mr: 2, ml: 2 }}>
        <strong style={{ textDecoration: "underline" }}>Sélectionner</strong> ou
        glisser pour importer un fichier
      </Typography>
      <Typography sx={{ mt: 3, mr: 2, ml: 2, mb: 4 }}>
        Accepté : PNG, JPG, JPEG
      </Typography>
    </UploaderBox>
  );

  return (
    <Accordion
      sx={styles.accordionStyle}
      expanded={expanded === "panel1"}
      onChange={handleChangeExpanded("panel1")}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography sx={styles.accordionTitle}>Ajouter un logo</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <RadioGroup
          row
          sx={{ justifyContent: "space-evenly", mb: 2 }}
          value={logo.logoOptionChosen}
          onChange={changeLogoSource}
        >
          <FormControlLabel
            value={CUSTOM_PAGE_LOGO_OPTIONS.NONE}
            control={<Radio />}
            label="Aucun logo"
          />
          <FormControlLabel
            value={CUSTOM_PAGE_LOGO_OPTIONS.PERSONNAL}
            control={<Radio />}
            label="Ajouter un logo"
          />
          {user.logoPath ? (
            <FormControlLabel
              value={CUSTOM_PAGE_LOGO_OPTIONS.USER_LOGO}
              control={<Radio />}
              label="Utiliser mon logo"
            />
          ) : null}
        </RadioGroup>
        {uploadLogo ? (
          <Box sx={styles.uploaderContainer}>
            <FileUploader
              style={styles.uploader}
              children={aUploadBox}
              handleChange={handleLogoAdded}
              name="file"
              types={fileTypes}
              label="Sélectionner ou glisser déposer un fichier."
              hoverTitle="Déposez ici"
            />
            {customPageFile ? (
              <Typography variant="overline" sx={styles.text}>
                Fichier importé : {customPageFile.name}
              </Typography>
            ) : (
              <Typography variant="overline" sx={styles.text}>
                Aucun fichier pour le moment
              </Typography>
            )}
            {logoError ? (
              <>
                <br />
                <Typography variant="overline" sx={styles.errorText}>
                  {logoErrorText}
                </Typography>
              </>
            ) : null}
          </Box>
        ) : null}
      </AccordionDetails>
    </Accordion>
  );
}
