import React from "react";
import { useNavigate } from "react-router-dom";

import { OctopusQRButton } from "../../../utils/styled/Styled.js";

import { Grid } from "@mui/material";

import CancelIcon from "@mui/icons-material/Cancel";
import KeyIcon from "@mui/icons-material/Key";

const styles = {
  button: {
    display: "block",
    margin: "10px auto",
  },
  icon: { color: "#58738D", fontSize: "50px", mt: 4, mb: 2 },
};

export default function ProfileActions() {
  const navigate = useNavigate();
  return (
    <Grid container spacing={0} sx={{ mt: 5, mb: 10, textAlign: "center" }}>
      <Grid item md={1} lg={1} />
      <Grid item xs={6} sm={6} md={5} lg={4} sx={{ p: 2 }}>
        <KeyIcon sx={styles.icon} />
        <OctopusQRButton
          style={styles.button}
          onClick={() => {
            navigate("/profile/update-password");
          }}
        >
          Modifier mon mot de passe
        </OctopusQRButton>
      </Grid>
      <Grid item lg={2} />
      <Grid item xs={6} sm={6} md={5} lg={4} sx={{ p: 2 }}>
        <CancelIcon sx={styles.icon} />
        <OctopusQRButton
          style={styles.button}
          onClick={() => {
            navigate("/profile/delete-account");
          }}
        >
          Supprimer mon compte
        </OctopusQRButton>
      </Grid>
      <Grid item md={1} lg={1} />
    </Grid>
  );
}
