export const styles = {
  pageContainer: { minHeight: "calc(100vh - 250px)" },
  qrContainer: {
    display: "flex",
    gap: 2,
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  flexColumnCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    my: 3,
  },
  searchBar: {
    my: 2,
    backgroundColor: "white",
    borderRadius: "20px",

    minWidth: "300px",
    [`& fieldset`]: {
      borderRadius: "20px",
      border: "1px",
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    },
  },

  cardContainer: {
    display: "flex",
    gap: 2,
    margin: "auto",
    my: 5,
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
  },
};
