import { useState } from "react";

import { putUser, verifyPutData } from "../../../../../api/admin/adminApi";

import { OctopusQRButton } from "../../../../../utils/styled/Styled";
import { styles } from "./EditUser.style";

import Loading from "../../../../../components/loading/Loading";

import {
  Dialog,
  DialogTitle,
  TextField,
  InputAdornment,
  Typography,
  Select,
  MenuItem,
  FormControl,
  DialogActions,
  Box,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EmailIcon from "@mui/icons-material/Email";
import AbcIcon from "@mui/icons-material/Abc";
import FrenchFlag from "../../../../../assets/icons/french_flag.svg";
import {
  SUBSCRIPTION_PLANS_ARRAY,
  USER_ROLE_ARRAY,
} from "../../../../../utils/constants/Constants";

const EditUser = ({ isOpen, setIsOpen, adminUser, user, setUsers }) => {
  const defaultErrorValues = {
    hasNameError: false,
    nameText: "",
    hasMailError: false,
    mailText: "",
    hasPhoneNumberError: false,
    phoneNumberText: "",
  };

  const defaultFormValues = {
    name: user.name,
    mail: user.mail,
    phoneNumber: user.phoneNumber,
    subscriptionPlan: user.subscriptionPlan,
    role: user.role,
    isSubscribed: user.isSubscribed ? "subscribe" : "notSubscribe",
    hasPush: user.hasPush ? "hasPush" : "notHasPush",
  };

  const [errorValues, setErrorValues] = useState(defaultErrorValues);
  const [formValues, setFormValues] = useState(defaultFormValues);
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState({
    hasError: false,
    text: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handlePhoneChange = (e) => {
    let target = e.target;
    let position = target.selectionEnd;
    let length = target.value.length;

    if (length >= 15) {
      return;
    }

    target.value = target.value
      .replace(/[^\d]/g, "")
      .replace(/(.{2})/g, "$1 ")
      .trim();
    target.selectionEnd = position +=
      target.value.charAt(position - 1) === " " &&
      target.value.charAt(length - 1) === " " &&
      length !== target.value.length
        ? 1
        : 0;
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  const handleSave = async () => {
    setIsLoading(true);
    setErrorValues(defaultErrorValues);
    setApiError({
      hasError: false,
      text: "",
    });
    const aErrorValues = verifyPutData(formValues);

    if (
      aErrorValues.hasNameError ||
      aErrorValues.hasEmailError ||
      aErrorValues.hasPhoneNumberError
    ) {
      setErrorValues(aErrorValues);
      setIsLoading(false);
      return;
    }

    try {
      const updatedUser = await putUser(adminUser._id, user._id, {
        name: formValues.name,
        mail: formValues.mail,
        phoneNumber: formValues.phoneNumber,
        subscriptionPlan: formValues.subscriptionPlan,
        role: formValues.role,
        isSubscribed: formValues.isSubscribed === "subscribe",
        hasPush: formValues.hasPush === "hasPush",
      });

      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user._id === updatedUser._id ? updatedUser : user
        )
      );
      setIsLoading(false);
      setIsOpen(false);
    } catch (error) {
      if (error.status === 409) {
        setApiError({
          hasError: true,
          text: "Un compte avec cette adresse mail existe déjà",
        });
      } else {
        setApiError({
          hasError: true,
          text: "Une erreur est survenue, veuillez réessayer.",
        });
      }
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
      <IconButton
        onClick={() => setIsOpen(false)}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <Box sx={styles.container}>
        <DialogTitle>Modification de {user.name}</DialogTitle>
        <FormControl>
          <TextField
            sx={styles.field}
            name="name"
            type="text"
            value={formValues.name}
            error={errorValues.hasNameError}
            helperText={errorValues.nameText}
            onChange={handleInputChange}
            placeholder="Nom complet"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AbcIcon style={styles.icon} />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            sx={styles.field}
            name="mail"
            type="mail"
            value={formValues.mail}
            error={errorValues.hasMailError}
            helperText={errorValues.mailText}
            onChange={handleInputChange}
            placeholder="Adresse mail"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon style={styles.icon} />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            sx={styles.field}
            name="phoneNumber"
            type="text"
            value={formValues.phoneNumber}
            error={errorValues.hasPhoneNumberError}
            helperText={errorValues.phoneNumberText}
            onChange={handlePhoneChange}
            placeholder="exemple@mail.com"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <img
                  src={FrenchFlag}
                  style={styles.imgAdornment}
                  width="23"
                  alt="french-flag"
                />
              ),
            }}
          />
          <FormControl>
            <Select
              sx={styles.field}
              value={formValues.role}
              onChange={(e) =>
                setFormValues({ ...formValues, role: e.target.value })
              }
              defaultValue={""}
              variant="outlined"
              displayEmpty
            >
              <MenuItem value={""} disabled>
                Choisissez un rôle
              </MenuItem>
              {USER_ROLE_ARRAY.map((role) => (
                <MenuItem key={role} value={role.value}>
                  {role.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <Select
              sx={styles.field}
              value={formValues.subscriptionPlan}
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  subscriptionPlan: e.target.value,
                })
              }
              defaultValue={""}
              variant="outlined"
              displayEmpty
            >
              <MenuItem value={""} disabled>
                Choisissez un abonnement
              </MenuItem>
              {SUBSCRIPTION_PLANS_ARRAY.map((subscriptionPlan) => (
                <MenuItem key={subscriptionPlan} value={subscriptionPlan.value}>
                  {subscriptionPlan.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <Select
              sx={styles.field}
              value={formValues.isSubscribed}
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  isSubscribed: e.target.value,
                })
              }
              defaultValue={""}
              variant="outlined"
              displayEmpty
            >
              <MenuItem value={""} disabled>
                Utilisateur abonné ?
              </MenuItem>
              <MenuItem value={"subscribe"}>Abonné</MenuItem>
              <MenuItem value={"notSubscribe"}>Non abonné</MenuItem>
            </Select>
          </FormControl>
          <FormControl>
            <Select
              sx={styles.field}
              value={formValues.hasPush}
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  hasPush: e.target.value,
                })
              }
              defaultValue={""}
              variant="outlined"
              displayEmpty
            >
              <MenuItem value={""} disabled>
                Notifications PUSH ?
              </MenuItem>
              <MenuItem value={"hasPush"}>Notifications PUSH activées</MenuItem>
              <MenuItem value={"notHasPush"}>
                Notifications PUSH desactivées
              </MenuItem>
            </Select>
          </FormControl>
        </FormControl>
        <DialogActions>
          <OctopusQRButton sx={styles.submitButton} onClick={handleSave}>
            Valider
          </OctopusQRButton>
        </DialogActions>
        {isLoading && <Loading />}
        {apiError.hasError && (
          <Typography variant="overline" sx={styles.errorText}>
            {apiError.text}
          </Typography>
        )}
      </Box>
    </Dialog>
  );
};

export default EditUser;
