import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Divider,
} from "@mui/material";

import { RedButton, OctopusQRButton } from "../../../../utils/styled/Styled";

const VerifyEmailModal = ({
  open,
  onClose,
  sendEmail,
  htmlContent,
  subject,
}) => {
  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
        <DialogTitle>Confirmez vous l'envoi du mail ?</DialogTitle>
        <DialogContent>
          <Divider sx={{ mb: 2 }} />
          <Typography sx={{ fontSize: "18px" }}>
            <strong>Objet :</strong> {subject}
          </Typography>
          <Divider sx={{ my: 2 }} />
          <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          <RedButton onClick={onClose} sx={{ width: "150px" }}>
            Annuler
          </RedButton>
          <OctopusQRButton onClick={sendEmail} sx={{ width: "150px" }}>
            Envoyer
          </OctopusQRButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default VerifyEmailModal;
