import { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  putSocialNetworkQr,
  verifySocialNetworksData,
} from "../../../../../api/qrCodes/socialNetwork/socialNetworkQrHandler";

import Loading from "../../../../../components/loading/Loading";
import {
  SOCIAL_NETWORK_ARRAY,
  SOCIAL_NETWORK_ICON_MAP,
  SOCIAL_NETWORK_LINK_MAP,
} from "../../../../../utils/constants/Constants";
import { capitalizeFirstLetter } from "../../../../../utils/Utils";
import { OctopusQRButton } from "../../../../../utils/styled/Styled";
import { styles } from "../../../add/content/socialNetwork/SocialNetworkContent.style";
import {
  Box,
  Card,
  Typography,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  TextField,
} from "@mui/material";

export default function EditSocialNetworkContent({
  user,
  qrCode,
  qrContent,
  setQrContent,
  setHasBeenSaved,
}) {
  const navigateTo = useNavigate();
  const [initialSocialNetworkType, setInitialSocialNetworkType] = useState(
    qrContent?.type
  );
  const [socialNetworkSelected, setSocialNetworkSelected] = useState(
    qrContent?.type
  );

  const [initialPseudonym, setInitialPseudonym] = useState(
    qrContent?.path.split(SOCIAL_NETWORK_LINK_MAP[qrContent?.type])[1]
  );
  const [pseudonym, setPseudonym] = useState(
    qrContent?.path.split(SOCIAL_NETWORK_LINK_MAP[qrContent?.type])[1]
  );
  const [error, setError] = useState({
    errorText: "",
    hasError: false,
  });
  const [hasChange, setHasChange] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const updateSocialNetworkSelected = (iEvent) => {
    setHasChange(true);
    const aSocialNetworkSelected = iEvent.target.value;
    if (
      aSocialNetworkSelected === initialSocialNetworkType &&
      pseudonym === initialPseudonym
    ) {
      setHasChange(false);
    }
    setSocialNetworkSelected(aSocialNetworkSelected);
  };
  const handleInputChange = (iEvent) => {
    setHasChange(true);
    const aPseudonym = iEvent.target.value;
    if (
      aPseudonym === initialPseudonym &&
      socialNetworkSelected === initialSocialNetworkType
    ) {
      setHasChange(false);
    }
    setPseudonym(aPseudonym);
  };

  const saveChanges = async () => {
    setIsLoading(true);
    const aErrorValues = verifySocialNetworksData(pseudonym);
    if (aErrorValues.hasPseudonymError) {
      setIsLoading(false);
      setError({
        hasError: true,
        errorText: "Champs requis manquant",
      });
      return;
    }

    try {
      const aNewSocialNetwork = await putSocialNetworkQr(
        user._id,
        qrCode._id,
        qrContent._id,
        {
          type: socialNetworkSelected,
          path: SOCIAL_NETWORK_LINK_MAP[socialNetworkSelected] + pseudonym,
        }
      );

      setHasChange(false);
      setInitialPseudonym(pseudonym);
      setInitialSocialNetworkType(socialNetworkSelected);
      setHasBeenSaved(true);
      setQrContent({ ...aNewSocialNetwork });
    } catch {
      setError({
        hasError: true,
        errorText: "Une erreur est survenue, veuillez réessayer",
      });
    }
    setIsLoading(false);
    if (qrCode.folderId) {
      navigateTo(`/qrcodes/folder/${qrCode.folderId}`);
    } else {
      navigateTo(`/qrcodes`);
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Box
        sx={{
          ...styles.flexCenter,
          width: "100%",
          mt: 6,
        }}
      >
        <Card sx={styles.card}>
          <Typography variant="h4" sx={{ my: 3 }}>
            Modification du réseau social
          </Typography>
          <FormControl sx={styles.formControl}>
            <InputLabel id="font-label">Sélection du réseau</InputLabel>
            <Select
              labelId="font-label"
              value={socialNetworkSelected}
              sx={styles.field}
              MenuProps={styles.menuProps}
              onChange={updateSocialNetworkSelected}
              label="Votre choix"
            >
              {SOCIAL_NETWORK_ARRAY.map((aSocialNetwork) => {
                return (
                  <MenuItem value={aSocialNetwork} key={aSocialNetwork}>
                    <Box sx={styles.flexCenter}>
                      {SOCIAL_NETWORK_ICON_MAP[aSocialNetwork]}

                      <Typography sx={{ ml: 2 }}>
                        {capitalizeFirstLetter(aSocialNetwork)}
                      </Typography>
                    </Box>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <Box
            sx={{
              display: { xs: "block", md: "flex" },
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="overline">
              {SOCIAL_NETWORK_LINK_MAP[socialNetworkSelected]}
            </Typography>
            <FormControl sx={styles.pseudonymForm}>
              <TextField
                id="pseudonym-input"
                name="pseudonym"
                label="Pseudonyme"
                required={true}
                variant="outlined"
                value={pseudonym}
                onChange={handleInputChange}
                sx={styles.field}
              />
            </FormControl>
          </Box>
          <OctopusQRButton
            sx={styles.button}
            onClick={saveChanges}
            disabled={!hasChange}
          >
            Sauvegarder
          </OctopusQRButton>
          <br />
          {error.hasError ? (
            <Typography variant="overline" sx={styles.errorText}>
              {error.errorText}
            </Typography>
          ) : null}
        </Card>
      </Box>
    </>
  );
}
