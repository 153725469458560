export const styles = {
  titleContainer: { marginTop: { xs: 3, lg: -7, md: -5 }, marginBottom: 3 },

  center: {
    display: "block",
    margin: "20px auto",
  },

  centeredIcon: {
    display: "block",
    margin: "10px auto",
    color: "#58738D",
  },

  card: {
    mt: 2,
    mx: 2,
    mb: 6,
    width: { lg: "55%", md: "70%", xs: "95%" },
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    borderRadius: "20px",
    textAlign: "center",
  },

  formControl: {
    m: 4,
    width: "70%",
  },

  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  errorText: {
    color: "#8B0000",
    fontWeight: "bold",
    fontSize: 12,
  },

  button: {
    width: "200px",
    mt: 3,
    mb: 3,
  },

  backArrow: {
    display: "flex",
    justifyContent: "start",
    m: { lg: 2, md: 1, xs: 0 },
    fontSize: "230px",
    color: "#58738D",
  },

  uploader: {
    mt: 4,
    mb: 2,
    mr: "15%",
    ml: "15%",
  },
};
