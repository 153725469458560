import { useState } from "react";

import {
  CUSTOMIZATION_POSITION,
  CUSTOM_PAGE_LOGO_OPTIONS,
  DEVICE_SORTABLE_INDEX,
  DEVICE_SORTABLE_TYPE,
} from "../../../../../utils/constants/Constants";
import { useNavigate } from "react-router-dom";
import { styles } from "../../../add/content/customPage/CustomPageContent.style";
import DeviceWrapper from "../../../add/content/deviceWrapper/DeviceWrapper";

import { Box, Card, Typography, Grid, Checkbox } from "@mui/material";

import CustomLogoAndDescription from "../../../add/content/customPage/logoAndDescription/CustomLogoAndDescription";
import CustomLinksAndFiles from "../../../add/content/customPage/linksAndFiles/CustomLinksAndFiles";
import CustomSocialNetworks from "../../../add/content/customPage/socialNetworks/CustomSocialNetworks";

import DeviceSortable from "../../../add/content/customPage/deviceSortable/DeviceSortable";
import { OctopusQRButton } from "../../../../../utils/styled/Styled";
import Loading from "../../../../../components/loading/Loading";
import { sortArrayByPosition } from "../../../../../utils/Utils";
import {
  deleteCustomPageFile,
  deleteCustomPageLink,
  deleteCustomPageLogo,
  deleteCustomSocialNetwork,
  postCustomPageFile,
  postCustomPageLink,
  postCustomPageLogo,
  postCustomPageSocialNetwork,
  putCustomPage,
  putCustomPageFile,
  putCustomPageLink,
} from "../../../../../api/qrCodes/customPage/customPageQrHandler";

const buildLinksAndFilesArray = (iLinks, iFiles) => {
  let aLinksAndFilesArray = [];
  iLinks.forEach((aLink) => {
    aLinksAndFilesArray.push({
      isLink: true,
      name: aLink.name,
      path: aLink.path,
      id: aLink._id ? aLink._id : aLink.id,
      position: aLink.position,
    });
  });
  iFiles.forEach((aFile) => {
    aLinksAndFilesArray.push({
      isLink: false,
      file: null,
      fileName: aFile.path,
      name: aFile.name,
      path: aFile.path,
      type: aFile.type,
      id: aFile._id ? aFile._id : aFile.id,
      position: aFile.position,
    });
  });

  sortArrayByPosition(aLinksAndFilesArray);
  return aLinksAndFilesArray;
};

export default function EditCustomPageContent({
  user,
  qrCode,
  qrContent,
  setQrContent,
  setHasBeenSaved,
}) {
  const navigateTo = useNavigate();
  const initialError = {
    hasError: false,
    errorText: "",
  };
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(initialError);

  //LogoAndDescription
  let aLogoOption;
  if (!qrContent?.customPage?.picture) {
    aLogoOption = CUSTOM_PAGE_LOGO_OPTIONS.NONE;
  } else if (qrContent?.customPage?.picture === user.logoPath) {
    aLogoOption = CUSTOM_PAGE_LOGO_OPTIONS.USER_LOGO;
  } else {
    aLogoOption = CUSTOM_PAGE_LOGO_OPTIONS.PERSONNAL;
  }
  const logoInitalState = {
    logoOptionChosen: aLogoOption,
    file: null,
    picture: qrContent?.customPage?.picture,
  };
  const [descritpion, setDescription] = useState(
    qrContent?.customPage?.description
  );
  const [logo, setLogo] = useState(logoInitalState);

  //linksAndFiles
  let aLinksAndFilesArray = buildLinksAndFilesArray(
    qrContent?.links,
    qrContent?.files
  );
  const [linksAndFiles, setLinksAndFiles] = useState(
    Array.from(aLinksAndFilesArray)
  );
  const [initialLinksAndFiles, setInitialLinksAndFiles] = useState(
    Array.from(aLinksAndFilesArray)
  );

  //socialNetworks
  let aSocialNetworks = qrContent?.socialNetworks;
  const [socialNetworks, setSocialNetworks] = useState(
    Array.from(aSocialNetworks)
  );
  const [initialSocialNetworks, setInitialSocialNetworks] = useState(
    Array.from(aSocialNetworks)
  );

  //Push notification part
  const [hasPush, setHasPush] = useState(qrContent?.customPage?.hasPush);

  const [items, setItems] = useState(
    sortArrayByPosition([
      {
        id: "item_1",
        type: DEVICE_SORTABLE_TYPE.LOGO,
        content: { ...logoInitalState },
        position: qrContent?.customPage?.order?.logo,
      },
      {
        id: "item_2",
        type: DEVICE_SORTABLE_TYPE.DESCRIPTION,
        content: qrContent?.customPage?.description,
        position: qrContent?.customPage?.order?.description,
      },
      {
        id: "item_3",
        type: DEVICE_SORTABLE_TYPE.LINKS_AND_FILES,
        content: aLinksAndFilesArray,
        position: qrContent?.customPage?.order?.linksAndFiles,
      },
      {
        id: "item_4",
        type: DEVICE_SORTABLE_TYPE.SOCIAL_NETWORKS,
        content: qrContent?.socialNetworks,
        position: qrContent?.customPage?.order?.socialNetworks,
      },
    ])
  );

  //Customization
  const [customization, setCustomization] = useState({
    descriptionFontColor: qrContent?.customPage?.descriptionFontColor,
    descriptionFontType: qrContent?.customPage?.descriptionFontType,
    descriptionFontSize: qrContent?.customPage?.descriptionFontSize,
    position: qrContent?.customPage?.position,
    backgroundColor: qrContent?.customPage?.backgroundColor,
    socialNetworksColor: qrContent?.customPage?.socialNetworksColor,
    socialNetworksSize: qrContent?.customPage?.socialNetworksSize,
    buttonColor: qrContent?.customPage?.buttonColor,
    buttonFontColor: qrContent?.customPage?.buttonFontColor,
    buttonFontType: qrContent?.customPage?.buttonFontType,
    buttonFontSize: qrContent?.customPage?.buttonFontSize,
  });

  const saveChanges = async () => {
    setIsLoading(true);
    setHasBeenSaved(false);
    setError(initialError);

    let aItems = Array.from(items);

    const aSocialNetworksObject = aItems.find(
      (aItem) => aItem.id === DEVICE_SORTABLE_INDEX.SOCIAL_NETWORKS
    );

    let aLogoObject = aItems.find(
      (aItem) => aItem.id === DEVICE_SORTABLE_INDEX.LOGO
    );

    let aLogo = aLogoObject?.content;

    const aCustomPage = qrContent?.customPage;
    let aCustomPagePicture = "";
    if (aLogo.logoOptionChosen === CUSTOM_PAGE_LOGO_OPTIONS.USER_LOGO) {
      aCustomPagePicture = aLogo.picture;
    } else if (
      aLogo.logoOptionChosen === CUSTOM_PAGE_LOGO_OPTIONS.PERSONNAL &&
      aLogo.logoOptionChosen === logoInitalState.logoOptionChosen
    ) {
      aCustomPagePicture = logoInitalState.picture;
    }

    const aDescription = aItems.find(
      (aItem) => aItem.id === DEVICE_SORTABLE_INDEX.DESCRIPTION
    );
    const aLinksAndFiles = aItems.find(
      (aItem) => aItem.id === DEVICE_SORTABLE_INDEX.LINKS_AND_FILES
    );

    const aLinksAndFilesToDelete = initialLinksAndFiles.filter(
      (element1) =>
        !linksAndFiles.some((element2) => element2.id === element1.id)
    );
    aLinksAndFilesArray = aLinksAndFilesArray.filter(
      (element1) =>
        !aLinksAndFilesToDelete.some((element2) => element2.id === element1.id)
    );
    const aLinksAndFilesToPut = initialLinksAndFiles.filter((element1) =>
      linksAndFiles.some((element2) => element2.id === element1.id)
    );
    const aLinksAndFilesToPost = linksAndFiles.filter(
      (element1) =>
        !initialLinksAndFiles.some((element2) => element2.id === element1.id)
    );

    const aLinksToDelete = aLinksAndFilesToDelete.filter(
      (aLink) => aLink.isLink
    );
    const aLinksToPost = aLinksAndFilesToPost.filter((aLink) => aLink.isLink);
    const aLinksToPut = aLinksAndFilesToPut.filter((aLink) => aLink.isLink);
    const aFilesToDelete = aLinksAndFilesToDelete.filter(
      (aFile) => !aFile.isLink
    );
    const aFilesToPost = aLinksAndFilesToPost.filter((aFile) => !aFile.isLink);
    const aFilesToPut = aLinksAndFilesToPut.filter((aFile) => !aFile.isLink);
    const aSocialNetworksToDelete = initialSocialNetworks.filter(
      (element1) =>
        !socialNetworks.some((element2) => element2._id === element1._id)
    );
    aSocialNetworks = aSocialNetworks.filter(
      (element1) =>
        !aSocialNetworksToDelete.some(
          (element2) => element2._id === element1._id
        )
    );

    const aSocialNetworksToPost = socialNetworks.filter(
      (element1) =>
        !initialSocialNetworks.some((element2) => element2._id === element1._id)
    );

    try {
      const aNewCustomPage = await putCustomPage(
        user._id,
        qrCode._id,
        aCustomPage._id,
        {
          picture: aCustomPagePicture,
          description: aDescription.content,
          customization: customization,
          order: {
            logo: aLogoObject.position,
            description: aDescription.position,
            linksAndFiles: aLinksAndFiles.position,
            socialNetworks: aSocialNetworksObject.position,
          },
          hasPush: hasPush,
        }
      );

      if (
        aLogo.logoOptionChosen === CUSTOM_PAGE_LOGO_OPTIONS.PERSONNAL &&
        aLogo.file !== null
      ) {
        const aCustomPageLogoData = new FormData();
        aCustomPageLogoData.append("file", aLogo.file);
        await postCustomPageLogo(
          user._id,
          qrCode._id,
          aCustomPage._id,
          aCustomPageLogoData
        );
      } else if (
        logoInitalState.logoOptionChosen !== aLogo.logoOptionChosen &&
        aLogo.logoOptionChosen === CUSTOM_PAGE_LOGO_OPTIONS.PERSONNAL
      ) {
        setError({
          hasError: true,
          errorText:
            "Si vous utilisez votre propre logo, il est obligatoire de l'importer - Section 'Ajouter un Logo'",
        });
        setIsLoading(false);
        return;
      }

      if (
        aLogo.logoOptionChosen !== CUSTOM_PAGE_LOGO_OPTIONS.PERSONNAL &&
        logoInitalState.logoOptionChosen === CUSTOM_PAGE_LOGO_OPTIONS.PERSONNAL
      ) {
        await deleteCustomPageLogo(user._id, qrCode._id, aCustomPage._id);
      }

      for (const aLink of aLinksToPost) {
        const aNewLink = await postCustomPageLink(
          user._id,
          qrCode._id,
          aCustomPage._id,
          aLink
        );
        aLinksAndFilesArray.push({
          isLink: true,
          name: aNewLink.name,
          path: aNewLink.path,
          id: aNewLink._id,
          position: aNewLink.position,
        });
      }

      for (const aLink of aLinksToPut) {
        await putCustomPageLink(
          user._id,
          qrCode._id,
          aCustomPage._id,
          aLink.id,
          { position: aLink.position }
        );
      }

      for (const aLink of aLinksToDelete) {
        await deleteCustomPageLink(
          user._id,
          qrCode._id,
          aCustomPage._id,
          aLink.id
        );
      }

      for (const aFile of aFilesToPost) {
        const aFileData = new FormData();
        aFileData.append("file", aFile.file);
        aFileData.append("position", aFile.position);
        aFileData.append("name", aFile.name);
        const aNewFile = await postCustomPageFile(
          user._id,
          qrCode._id,
          aCustomPage._id,
          aFileData
        );
        aLinksAndFilesArray.push({
          isLink: false,
          file: null,
          fileName: aNewFile.path,
          name: aNewFile.name,
          path: aNewFile.path,
          type: aNewFile.type,
          id: aNewFile._id,
          position: aNewFile.position,
        });
      }

      for (const aFile of aFilesToPut) {
        await putCustomPageFile(
          user._id,
          qrCode._id,
          aCustomPage._id,
          aFile.id,
          { position: aFile.position }
        );
      }

      for (const aFile of aFilesToDelete) {
        await deleteCustomPageFile(
          user._id,
          qrCode._id,
          aCustomPage._id,
          aFile.id
        );
      }

      for (const aSocialNetwork of aSocialNetworksToPost) {
        const aNewSocialNetwork = await postCustomPageSocialNetwork(
          user._id,
          qrCode._id,
          aCustomPage._id,
          aSocialNetwork
        );
        aSocialNetworks.push(aNewSocialNetwork);
      }

      for (const aSocialNetwork of aSocialNetworksToDelete) {
        await deleteCustomSocialNetwork(
          user._id,
          qrCode._id,
          aCustomPage._id,
          aSocialNetwork._id
        );
      }

      const aLinkArray = aLinksAndFilesArray.filter((aLink) => aLink.isLink);

      const aFileArray = aLinksAndFilesArray.filter((aFile) => !aFile.isLink);

      setQrContent({
        customPage: aNewCustomPage,
        files: aFileArray,
        links: aLinkArray,
        socialNetworks: aSocialNetworks,
      });
      setSocialNetworks(Array.from(aSocialNetworks));
      setInitialSocialNetworks(Array.from(aSocialNetworks));
      setInitialLinksAndFiles(Array.from(aLinksAndFilesArray));
      setLinksAndFiles(Array.from(aLinksAndFilesArray));

      setIsLoading(false);
      if (qrCode.folderId) {
        navigateTo(`/qrcodes/folder/${qrCode.folderId}`);
      } else {
        navigateTo(`/qrcodes`);
      }
    } catch {
      setError({
        hasError: true,
        errorText: "Une erreur est survenue, merci de réessayer.",
      });
      setIsLoading(false);
      return;
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  const boxSx =
    customization.position === CUSTOMIZATION_POSITION.CENTER
      ? {
          position: { xs: "block", lg: "absolute" },
          top: { xs: "", lg: "50%" },
          transform: { xs: "", lg: "translate(0%, -50%)" },
          width: "100%",
        }
      : {};

  const deviceContent = (
    <Box sx={boxSx}>
      <DeviceSortable
        items={items}
        setItems={setItems}
        hasPush={hasPush}
        customization={customization}
      />
    </Box>
  );

  return (
    <Box
      sx={{
        ...styles.flexCenter,
        width: "100%",
        mt: 3,
      }}
    >
      <Card sx={{ ...styles.card, mx: 2 }}>
        <Typography variant="h4" sx={{ my: 3 }}>
          Modification de la page personnalisée
        </Typography>
        <Grid container spacing={0} sx={styles.flexCenter}>
          <Grid item md={12} lg={6} sx={{ margin: "auto" }}>
            <CustomLogoAndDescription
              user={user}
              initialLogoPicture={logoInitalState.picture}
              logo={logo}
              setLogo={setLogo}
              description={descritpion}
              setDescription={setDescription}
              items={items}
              setItems={setItems}
              customization={customization}
              setCustomization={setCustomization}
            />

            <CustomLinksAndFiles
              linksAndFiles={linksAndFiles}
              setLinksAndFiles={setLinksAndFiles}
              items={items}
              setItems={setItems}
              customization={customization}
              setCustomization={setCustomization}
            />

            <CustomSocialNetworks
              socialNetworks={socialNetworks}
              setSocialNetworks={setSocialNetworks}
              items={items}
              setItems={setItems}
              customization={customization}
              setCustomization={setCustomization}
            />

            {user.hasPush && (
              <Box sx={styles.flexCenter}>
                <Checkbox
                  checked={hasPush}
                  onChange={() => setHasPush(!hasPush)}
                />
                <Typography>
                  Activer les notifications push sur cette page
                </Typography>
              </Box>
            )}

            <OctopusQRButton sx={styles.button} onClick={saveChanges}>
              Sauvegarder
            </OctopusQRButton>
            <br />
            {error.hasError ? (
              <Typography variant="overline" sx={styles.errorText}>
                {error.errorText}
              </Typography>
            ) : null}
          </Grid>

          <Grid item md={12} lg={6} sx={{ margin: "auto" }}>
            <Box sx={{ display: { xs: "none", md: "block" } }}>
              <DeviceWrapper
                content={deviceContent}
                backgroundColor={customization.backgroundColor}
              />
            </Box>
            <Box sx={{ display: { xs: "block", md: "none" } }}>
              <Typography variant="overline" sx={{ my: 2 }}>
                Rendu
              </Typography>
              <Card
                sx={{
                  ...styles.card,
                  width: "300px",
                  background: customization.backgroundColor,
                }}
              >
                {deviceContent}
              </Card>
            </Box>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
}
