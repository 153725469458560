import { useNavigate } from "react-router-dom";

import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import HelpIcon from "@mui/icons-material/Help";

const styles = {
  title: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "30px",
    marginBottom: "0px",
    marginTop: "0px",
  },
  centeredIcon: {
    margin: "0px auto",
    display: "block",
    color: "#58738D",
  },
  commonText: {
    textAlign: "justify",
    m: 3,
  },
  closeIcon: {
    position: "absolute",
    right: 1,
    top: 8,
    color: (theme) => theme.palette.grey[500],
  },
};

export default function PhoneNumberHelp({ iFromPage }) {
  const navigate = useNavigate();

  const handleClose = () => {
    navigate(iFromPage);
  };

  return (
    <Dialog open={true} onClose={handleClose}>
      <DialogTitle>
        <IconButton onClick={handleClose} sx={styles.closeIcon}>
          <CloseIcon />
        </IconButton>

        <Typography style={styles.title}>Numéro de téléphone</Typography>
      </DialogTitle>
      <DialogContent>
        <HelpIcon sx={styles.centeredIcon} />
        <Typography sx={styles.commonText}>
          Votre numéro de téléphone est utilisé uniquement comme point de
          contact pour OctopusQR. Aucun de vos clients n'y aura accès, sauf si vous
          désirez l'ajouter dans votre description.
        </Typography>
      </DialogContent>
    </Dialog>
  );
}
