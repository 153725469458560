import EiffageLogo from "../../../../assets/private_customers/eiffage/eiffage_logo.png";
import { styles } from "./FormSucceed.style";

import { useSearchParams } from "react-router-dom";

import { Box, Typography } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";

export default function FormSucceed() {
  const [searchParams] = useSearchParams();
  const aCurrentDate = new Date();
  return (
    <Box sx={styles.pageContainer}>
      <Box sx={styles.container}>
        <img
          src={EiffageLogo}
          width="200"
          alt="eiffage logo"
          style={{ margin: "40px auto" }}
        />
        <Box>
          <DoneIcon sx={{ fontSize: "45px", color: "#E4010B", mb: 3 }} />
        </Box>
        <Box>
          <Typography variant="overline" fontSize={"18px"}>
            {searchParams.get("formName")}
          </Typography>
        </Box>
        <Box>
          <Typography variant="overline" fontSize={"18px"}>
            Contrôle réalisé avec succès le{" "}
            {aCurrentDate.toLocaleDateString("fr")} par{" "}
            {searchParams.get("lastName")} {searchParams.get("firstName")}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
