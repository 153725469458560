import { Box } from "@mui/material";

export default function LogoDisplay({ picture }) {
  return (
    <>
      {picture ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <img
            src={picture}
            style={{
              maxWidth: "250px",
              maxHeight: "100px",
              borderRadius: "50px",
            }}
            alt="custom-logo"
          />
        </Box>
      ) : null}
    </>
  );
}
