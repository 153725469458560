import { useState } from "react";

import {
  Box,
  Card,
  Typography,
  FormControl,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { styles } from "../AddQRCode.style";
import AbcIcon from "@mui/icons-material/Abc";
import { ADD_QR_ACTIONS } from "../../../../utils/constants/Constants";
import { OctopusQRButton } from "../../../../utils/styled/Styled";
import { useNavigate } from "react-router-dom";
import WestIcon from "@mui/icons-material/West";

export default function QRCodeName({
  currentAction,
  qrCodeName,
  setQrCodeName,
}) {
  const navigateTo = useNavigate();
  const [formValues, setFormValues] = useState({
    name: qrCodeName,
  });
  const [errorValues, setErrorValues] = useState({
    hasNameError: false,
    name: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    if (!formValues.name) {
      setErrorValues({
        name: "Champs requis",
        hasNameError: true,
      });
      return;
    }

    const aNameRegex = new RegExp(/^[A-Za-z0-9_. ]+$/);
    if (!aNameRegex.test(formValues.name)) {
      setErrorValues({
        name: "Caractères spéciaux interdits exceptés _ et .",
        hasNameError: true,
      });
      return;
    }

    setQrCodeName(formValues.name);
    currentAction(ADD_QR_ACTIONS.TYPE);
  };
  return (
    <>
      <Box
        sx={{
          ...styles.flexCenter,
          width: { xs: "100%", md: "90%", lg: "70%" },
        }}
      >
        <Card sx={styles.card}>
          <IconButton
            sx={styles.backArrow}
            onClick={() => {
              navigateTo("/qrcodes");
            }}
          >
            <WestIcon />
          </IconButton>
          <Typography variant="h4" sx={styles.titleContainer}>
            Nom du QRCode
          </Typography>
          <Box sx={{ display: "block" }}>
            <FormControl sx={styles.formControl}>
              <TextField
                id="name-input"
                name="name"
                label="Nom"
                required={true}
                variant="outlined"
                value={formValues.name}
                error={errorValues.hasNameError}
                helperText={errorValues.name}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                sx={styles.field}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AbcIcon style={styles.icon} />
                    </InputAdornment>
                  ),
                }}
              />
              <OctopusQRButton
                style={{
                  width: "150px",
                  display: "block",
                  margin: "20px auto",
                }}
                onClick={handleSubmit}
              >
                Suivant
              </OctopusQRButton>
            </FormControl>
          </Box>
        </Card>
      </Box>
    </>
  );
}
