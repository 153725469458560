import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";

import ProfileLogo from "./profileLogo/ProfileLogo";
import ProfileForm from "./profileForm/ProfileForm";
import ProfileActions from "./profileActions/ProfileActions";
import AskToVerifyEmailCard from "../../components/askToVerifyEmail/AskToVerifyEmailCard";
import NavMenu from "../../components/navMenu/NavMenu";
import Footer from "../../components/footer/Footer";
import LoadingWithMenu from "../../components/loading/LoadingWithMenu";

import { getUser } from "../../api/user/getUser.js";
import { STORAGE_USER_ID } from "../../utils/constants/Constants.js";
import { Grid } from "@mui/material";
import NotFoundWithMenu from "../notFound/NotFoundWithMenu";

export default function Profile() {
  const [user, setUser] = useState();
  const location = useLocation();
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    getUser(localStorage.getItem(STORAGE_USER_ID))
      .then((aResponse) => {
        setUser(aResponse);
      })
      .catch(() => {
        setHasError(true);
        setUser({});
      });
  }, [location]);

  if (!user) {
    return <LoadingWithMenu />;
  }

  if (hasError) {
    return <NotFoundWithMenu />;
  }

  if (!user.isMailVerified) {
    return <AskToVerifyEmailCard user={user} />;
  }

  return (
    <>
      <div style={{ minHeight: "calc(100vh - 250px)" }}>
        <NavMenu />
        <Grid container spacing={0} sx={{ my: 4 }}>
          <Grid item sm={1} md={2} />
          <Grid item xs={12} sm={10} md={8}>
            <ProfileLogo iLogoPath={user.logoPath} />
          </Grid>
          <Grid item sm={1} md={2} />
        </Grid>
        <Grid container spacing={0} sx={{ backgroundColor: "white" }}>
          <Grid item sm={1} md={2} />
          <Grid item xs={12} sm={10} md={8}>
            <ProfileForm iUserProfile={user} />
          </Grid>
          <Grid item sm={1} md={2} />
        </Grid>
        <Grid container spacing={0} sx={{ backgroundColor: "white" }}>
          <Grid item sm={1} md={2} />
          <Grid item xs={12} sm={10} md={8}>
            <ProfileActions />
          </Grid>
          <Grid item sm={1} md={2} />
        </Grid>
      </div>
      <Footer />
      <Outlet />
    </>
  );
}
