export const styles = {
  boxError: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "300px",
    margin: "auto",
    my: 5,
  },

  field: {
    background: "white",
    [`& fieldset`]: {
      borderRadius: "20px",
      border: "1px",
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    },
  },

  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  flexStart: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
  },

  flexColumnCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },

  flexColumnStart: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    flexDirection: "column",
  },

  radioContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    py: 2,
  },

  radioType: {
    mx: 2,
  },

  formField: {
    backgroundColor: "white",
    borderRadius: "20px",
    [`& fieldset`]: {
      borderRadius: "20px",
      border: "1px",
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    },
  },

  formControl: {
    width: { xs: "85%", md: "70%", lg: "65%" },
    backgroundColor: "white",
  },

  errorText: {
    color: "#8B0000",
    fontWeight: "bold",
    fontSize: 12,
    m: 1,
  },

  answerCard: {
    my: 2,
    p: 2,
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    borderRadius: "20px",
    textAlign: "center",
    minHeight: "690px",
  },
};
