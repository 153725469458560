import { useCallback, useEffect, useState } from "react";

import Footer from "../../../components/footer/Footer";
import NavMenu from "../../../components/navMenu/NavMenu";
import QRCodeType from "./type/QRCodeType";
import QRCodeName from "./name/QRCodeName";
import QRCodeCustomization from "./customization/QRCodeCustomization";
import QRCodeContent from "./content/QRCodeContent";

import {
  ADD_QR_ACTIONS,
  PLAN_NUMBER_OF_QR_MAP,
  QR_CODE_LOGO_OPTIONS,
  QR_CODE_TYPE,
  STORAGE_USER_ID,
  USER_ROLE,
} from "../../../utils/constants/Constants";
import { styles } from "./AddQRCode.style";
import { getUser } from "../../../api/user/getUser";

import { Box, Typography } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import LoadingWithMenu from "../../../components/loading/LoadingWithMenu";
import NotFoundWithMenu from "../../notFound/NotFoundWithMenu";
import WarningIcon from "@mui/icons-material/Warning";
import { getNumberOfQrCodes } from "../../../api/qrCodes/qrCodesHandler";
import { useSearchParams } from "react-router-dom";

export default function AddQRCode() {
  const [user, setUser] = useState();
  const [hasError, setHasError] = useState();
  const [numberOfQr, setNumberOfQr] = useState(0);
  const [qrCodeType, setQrCodeType] = useState(QR_CODE_TYPE.CUSTOM_PAGE);
  const [qrCodeName, setQrCodeName] = useState("");
  const [qrCodeCustomization, setQrCodeCustomization] = useState({
    logoOptionChosen: "none",
    picture: "",
    dotsType: "",
    dotsColor: "#000000",
    cornerSquaresType: "",
    cornerSquaresColor: "#000000",
    cornerDotType: "",
    cornerDotColor: "#000000",
    backgroundOptionChosen: "default",
    backgroundColor: "transparent",
  });

  const [searchParams, setSearchParams] = useSearchParams();

  const retrieveUser = useCallback(async () => {
    try {
      const aUser = await getUser(localStorage.getItem(STORAGE_USER_ID));
      setUser(aUser);
      const aResponse = await getNumberOfQrCodes(aUser._id);
      setNumberOfQr(aResponse.count);
    } catch {
      setHasError(true);
    }
  }, []);

  useEffect(() => {
    retrieveUser();
  }, [retrieveUser]);

  if (hasError) {
    return <NotFoundWithMenu />;
  }

  if (!user) {
    return <LoadingWithMenu />;
  }

  if (
    numberOfQr >= PLAN_NUMBER_OF_QR_MAP[user.subscriptionPlan] ||
    (!user.isSubscribed && user.role !== USER_ROLE.PRIVATE)
  ) {
    return <NotFoundWithMenu />;
  }

  let buildQrCodeData = () => {
    let aQrCode = {
      userId: user._id,
      type: qrCodeType,
      name: qrCodeName,
      picture: "",
      dotsType: qrCodeCustomization.dotsType,
      dotsColor: qrCodeCustomization.dotsColor,
      cornerSquaresType: qrCodeCustomization.cornerSquaresType,
      cornerSquaresColor: qrCodeCustomization.cornerSquaresColor,
      cornerDotType: qrCodeCustomization.cornerDotType,
      cornerDotColor: qrCodeCustomization.cornerDotColor,
      backgroundColor: qrCodeCustomization.backgroundColor,
      backgroundOptionChosen: qrCodeCustomization.backgroundOptionChosen,
    };

    switch (qrCodeCustomization.logoOptionChosen) {
      case QR_CODE_LOGO_OPTIONS.USER_LOGO:
        aQrCode.picture = user.logoPath;
        break;
      default:
        break;
    }

    return aQrCode;
  };

  const buildFileData = () => {
    let aFile = null;
    switch (qrCodeCustomization.logoOptionChosen) {
      case QR_CODE_LOGO_OPTIONS.PERSONNAL:
        aFile = qrCodeCustomization.file;
        break;
      default:
        break;
    }

    return aFile;
  };

  const modifyCurrentAction = (iAction) => {
    setSearchParams({ action: iAction });
  };

  let content;
  switch (searchParams.get("action")) {
    case ADD_QR_ACTIONS.NAME:
      content = (
        <QRCodeName
          currentAction={modifyCurrentAction}
          qrCodeName={qrCodeName}
          setQrCodeName={setQrCodeName}
        />
      );
      break;
    case ADD_QR_ACTIONS.TYPE:
      content = (
        <QRCodeType
          currentAction={modifyCurrentAction}
          qrCodeType={setQrCodeType}
          qrCodeName={qrCodeName}
        />
      );
      break;
    case ADD_QR_ACTIONS.CUSOMIZATION:
      content = (
        <QRCodeCustomization
          currentAction={modifyCurrentAction}
          qrCodeCustomization={qrCodeCustomization}
          setQrCodeCustomization={setQrCodeCustomization}
          qrCodeName={qrCodeName}
        />
      );
      break;
    case ADD_QR_ACTIONS.CONTENT:
      content = (
        <QRCodeContent
          currentAction={modifyCurrentAction}
          QRCode={buildQrCodeData()}
          file={buildFileData()}
          qrCodeName={qrCodeName}
        />
      );
      break;
    default:
      break;
  }

  return (
    <>
      <Box sx={styles.pageContainer}>
        <NavMenu />
        <Box sx={styles.flexColumnCenter}>
          <AddCircleOutlineIcon sx={styles.titleIcon} />
          <Box
            sx={{
              display: { xs: "flex", md: "none" },
              alignItems: "center",
              mx: 2,
            }}
          >
            <WarningIcon sx={{ mr: 3, color: "#6D071A" }} />
            <Typography variant="overline">
              L'ajout d'un QR Code est plus simple sur Ordinateur. Cela vous est
              fortement conseillé.
            </Typography>
          </Box>
          {content}
        </Box>
      </Box>
      <Footer />
    </>
  );
}
