import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { styles } from "../WorksiteControl.style";
import { RedButton } from "../../../../../../utils/styled/Styled";
import { processScaffoldSubmit } from "../WorksiteUtils";
import { postWorksiteControlForm } from "../apiWorksiteHandler";

import {
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  TextField,
  Divider,
} from "@mui/material";

export default function Scaffold({
  userId,
  formId,
  name,
  formValues,
  setFormValues,
}) {
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const handleFormChange = (iEvent) => {
    const { name, value } = iEvent.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    setErrorMessage("");
    const isSubmitCorrect = processScaffoldSubmit(formValues);
    if (!isSubmitCorrect) {
      setErrorMessage("Vous n'avez pas rempli tous les champs requis");
      return;
    }

    try {
      await postWorksiteControlForm(userId, formId, formValues);
      navigate(
        `/forms/eiffage/success?formName=${"Chantier Charles de Gaulle Express"}&lastName=${
          formValues.lastName
        }&firstName=${formValues.firstName}`
      );
    } catch {
      setErrorMessage("Une erreur est survenue, merci de réessayer plus tard");
    }
  };

  const buildRadioGroup = (iTitle, iName, iIsConcerned) => {
    if (iIsConcerned) {
      return (
        <>
          <Divider sx={styles.divider} />

          <Typography
            variant="overline"
            fontSize={"13px"}
            sx={styles.radioTypo}
          >
            {iTitle}
          </Typography>

          <RadioGroup
            row
            sx={styles.eiffageRadio}
            name={iName}
            onChange={handleFormChange}
          >
            <FormControlLabel
              sx={{ flexBasis: "50%" }}
              value="Conforme"
              control={<Radio />}
              label="Conforme"
            />
            <FormControlLabel
              sx={{ flexBasis: "50%" }}
              value="Non conforme"
              control={<Radio />}
              label="Non conforme"
            />
            <FormControlLabel
              sx={{ flexBasis: "50%" }}
              value="Non concerné"
              control={<Radio />}
              label="Non concerné"
            />
          </RadioGroup>
          <Box sx={{ mt: 2 }} />
          <TextField
            placeholder="Observations"
            name={`${iName}_obs`}
            multiline
            rows={2}
            onChange={handleFormChange}
            sx={styles.inputText}
          />
        </>
      );
    } else {
      return (
        <>
          <Divider sx={styles.divider} />

          <Typography
            variant="overline"
            fontSize={"13px"}
            sx={styles.radioTypo}
          >
            {iTitle}
          </Typography>

          <RadioGroup
            row
            sx={styles.eiffageRadio}
            name={iName}
            onChange={handleFormChange}
          >
            <FormControlLabel
              value="Conforme"
              control={<Radio />}
              label="Conforme"
            />
            <FormControlLabel
              value="Non conforme"
              control={<Radio />}
              label="Non conforme"
            />
          </RadioGroup>
          <Box sx={{ mt: 2 }} />
          <TextField
            placeholder="Observations"
            name={`${iName}_obs`}
            multiline
            rows={2}
            onChange={handleFormChange}
            sx={styles.inputText}
          />
        </>
      );
    }
  };

  return (
    <>
      <Typography variant="overline" fontSize={"13px"} sx={{ mt: 3 }}>
        PV de réception Echafaudage N°
      </Typography>
      <TextField
        sx={styles.inputText}
        name="numeroEscal"
        value={name}
        disabled
      />

      <Typography variant="overline" fontSize={"13px"} sx={{ mt: 3 }}>
        Type d'échafaudage :
      </Typography>
      <RadioGroup
        row
        sx={styles.eiffageRadio}
        name="echafType"
        onChange={handleFormChange}
      >
        <FormControlLabel
          sx={{ flexBasis: "50%", mt: 1 }}
          value="Echafaudage de pied"
          control={<Radio />}
          label="Echafaudage de pied"
        />
        <FormControlLabel
          sx={{ flexBasis: "50%", mt: 2 }}
          value="Echafaudage roulant"
          control={<Radio />}
          label="Echafaudage roulant"
        />
      </RadioGroup>

      <Typography variant="overline" fontSize={"13px"} sx={{ mt: 3 }}>
        Type de contrôle
      </Typography>
      <RadioGroup
        row
        sx={styles.eiffageRadio}
        name="controlType"
        onChange={handleFormChange}
      >
        <FormControlLabel
          sx={{ flexBasis: "50%" }}
          value="Déplacement"
          control={<Radio />}
          label="Déplacement"
        />
        <FormControlLabel
          sx={{ flexBasis: "50%" }}
          value="Modification"
          control={<Radio />}
          label="Modification"
        />
        <FormControlLabel
          sx={{ flexBasis: "50%" }}
          value="Mise en service"
          control={<Radio />}
          label="Mise en service"
        />
      </RadioGroup>

      <Typography variant="overline" fontSize={"13px"} sx={{ mt: 3 }}>
        Localisation
      </Typography>
      <TextField
        sx={styles.inputText}
        name="loc"
        value={formValues.loc}
        onChange={handleFormChange}
      />

      <Divider sx={styles.divider} />
      <Typography variant="h2" sx={styles.subTitle}>
        Protections collectives
      </Typography>

      {buildRadioGroup("Plinthes", "prot_pli", false)}

      {buildRadioGroup("Lisses / sous-lisses", "prot_liss", false)}

      {buildRadioGroup(
        "Lisses supplémentaires sur la travée d'accès",
        "prot_liss_plus",
        true
      )}

      {buildRadioGroup(
        "Garde-corps si échafaudage à + de 20cm de l’ouvrage",
        "prot_gard",
        true
      )}

      <Divider sx={styles.divider} />
      <Typography variant="h2" sx={styles.subTitle}>
        Accès
      </Typography>

      {buildRadioGroup(
        "Planchers / paliers correctement montés, continus, non encombrés",
        "acc_plan",
        false
      )}

      {buildRadioGroup("Echelles / escaliers", "acc_ech", false)}

      {buildRadioGroup("Trappes", "acc_trap", false)}

      {buildRadioGroup(
        "Absence de charges dépassants les limites admisibles",
        "acc_abs",
        false
      )}

      <Divider sx={styles.divider} />
      <Typography variant="h2" sx={styles.subTitle}>
        Fixations
      </Typography>

      {buildRadioGroup("Liaisons garde-corps / poteaux", "fix_lia_gar", false)}

      {buildRadioGroup(
        "Liaisons contreventements / poteaux",
        "fix_lia_con",
        false
      )}

      {buildRadioGroup("Patte anti-soulèvement des plateaux", "fix_pat", false)}

      {buildRadioGroup("Liaisons poteaux / poteaux", "fix_lia_pot", false)}

      {buildRadioGroup("Liaisons pieds / poteaux", "fix_lia_pie", false)}

      {buildRadioGroup(
        "Si présence de filets ou bâches  : bon état et fixtion",
        "fix_pres",
        true
      )}

      <Divider sx={styles.divider} />
      <Typography variant="h2" sx={styles.subTitle}>
        Amarrage / stabilisation
      </Typography>

      {buildRadioGroup("Amarrage sur ancrage à l’ouvrage", "ama_ancr")}

      {buildRadioGroup("Stabilisateurs", "ama_stab")}

      {buildRadioGroup("Portance du terrain", "port_terr", false)}

      {buildRadioGroup("Calage des pieds", "ama_cal")}

      {buildRadioGroup(
        "Freins en place et en bon état sur chaques roues",
        "ama_fre"
      )}

      <Divider sx={styles.divider} />
      <Typography variant="overline" fontSize={"13px"} sx={styles.radioTypo}>
        Montage & matériel conforme à la notice du fabricant
      </Typography>

      <RadioGroup
        row
        sx={styles.eiffageRadio}
        name="mont"
        onChange={handleFormChange}
      >
        <Box sx={styles.radioContainer}>
          <FormControlLabel value="Oui" control={<Radio />} label="Oui" />
          <FormControlLabel value="Non" control={<Radio />} label="Non" />
        </Box>
      </RadioGroup>

      <Divider sx={styles.divider} />
      <Typography variant="overline" fontSize={"13px"} sx={styles.radioTypo}>
        Aspect visuel, état du matériel, corrosion
      </Typography>

      <RadioGroup
        row
        sx={styles.eiffageRadio}
        name="aspe"
        onChange={handleFormChange}
      >
        <Box sx={styles.radioContainer}>
          <FormControlLabel
            value="Conforme"
            control={<Radio />}
            label="Conforme"
          />
          <FormControlLabel
            value="Non conforme"
            control={<Radio />}
            label="Non conforme"
          />
        </Box>
      </RadioGroup>

      <Divider sx={styles.divider} />
      <Typography variant="overline" fontSize={"13px"} sx={{ mt: 3 }}>
        Commentaires
      </Typography>
      <TextField
        sx={styles.inputText}
        multiline
        rows={3}
        name="comm"
        value={formValues.comm}
        onChange={handleFormChange}
      />

      <Typography variant="overline" fontSize={"13px"} sx={styles.radioTypo}>
        Autorisation d'utilisation
      </Typography>

      <RadioGroup
        row
        sx={styles.eiffageRadio}
        name="autor"
        onChange={handleFormChange}
      >
        <Box sx={styles.radioContainer}>
          <FormControlLabel value="Oui" control={<Radio />} label="Oui" />
          <FormControlLabel value="Non" control={<Radio />} label="Non" />
        </Box>
      </RadioGroup>

      {errorMessage ? (
        <Typography variant="overline" color="red" sx={{ mt: 3 }}>
          {errorMessage}
        </Typography>
      ) : null}

      <RedButton sx={styles.submitButton} onClick={handleSubmit}>
        Envoyer
      </RedButton>
    </>
  );
}
