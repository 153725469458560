export const styles = {
  title: {
    textAlign: "center",
    marginBottom: "0px",
    fontSize: "20px",
    fontWeight: "bold",
  },
  formControl: {
    minWidth: "330px",
    backgroundColor: "white",
  },
  field: {
    
    marginTop: "10px",
    marginBottom: "30px",
    backgroundColor: "white",
    borderRadius: "20px",
    [`& fieldset`]: {
      borderRadius: "20px",
      border: "1px",
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    },
  },
  button: {
    width: "100%",
    display: "block",
    margin: "10px auto",
  },
  center: {
    margin: "30px auto",
    display: "block",
  },
  centeredIcon: {
    margin: "auto",
    marginBottom: "10px",
    display: "block",
    color: "#58738D",
  },
  doneIcon: {
    margin: "0px auto",
    display: "block",
    color: "#58738D",
  },
  icon: { color: "#58738D" },
  error: {
    textAlign: "center",
    fontSize: "16px",
    color: "#8B0000",
  },
};
