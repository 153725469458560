export const styles = {
  title: {
    textAlign: "center",
    marginBottom: "0px  ",
    fontSize: "24px",
    fontWeight: "bold",
  },
  text: {
    textAlign: "center",
  },
  center: {
    display: "block",
    margin: "20px auto",
  },
  centeredIcon: {
    display: "block",
    margin: "10px auto",
    color: "#58738D",
  },
  error: {
    textAlign: "center",
    fontSize: "16px",
    color: "#8B0000",
  },
};
