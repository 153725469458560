import { useState } from "react";
import {
  Box,
  Typography,
  FormControl,
  TextField,
  InputAdornment,
} from "@mui/material";
import { styles } from "../QRCodeView.style";
import { OctopusQRButton } from "../../../../utils/styled/Styled";
import AbcIcon from "@mui/icons-material/Abc";
import { patchQrCodeName } from "../../../../api/qrCodes/qrCodesHandler";
import Loading from "../../../../components/loading/Loading";

export default function EditQRCodeName({ user, qrCode }) {
  const initialErrorValues = {
    name: "",
    hasNameError: false,
  };
  const [qrName, setQrName] = useState(qrCode.name);
  const [errorValues, setErrorValues] = useState(initialErrorValues);
  const [hasError, setHasError] = useState(false);
  const [hasBeenSaved, setHasBeenSaved] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);

  const handleNameChange = (e) => {
    setQrName(e.target.value);
    setHasChanged(true);
    setErrorValues(initialErrorValues);
    setHasError(false);
    setHasBeenSaved(false);
  };

  const handleSaveQrName = async () => {
    setIsLoading(true);

    if (!qrName) {
      setErrorValues({
        name: "Champs requis",
        hasNameError: true,
      });
      return;
    }

    const aNameRegex = new RegExp(/^[A-Za-z0-9_. ]+$/);
    if (!aNameRegex.test(qrName)) {
      setErrorValues({
        name: "Caractères spéciaux interdits exceptés _ et .",
        hasNameError: true,
      });
      return;
    }

    try {
      await patchQrCodeName(user._id, qrCode._id, { name: qrName });
      setHasBeenSaved(true);
      setHasChanged(false);
    } catch {
      setHasError(true);
      setErrorValues({
        name: "",
        hasNameError: true,
      });
    }

    setIsLoading(false);
  };

  return (
    <Box sx={styles.flexColumnCenter}>
      <Typography variant="h4" sx={{ m: 1 }}>
        Nom du QR Code
      </Typography>
      <FormControl sx={{ ...styles.formControl, mt: 3 }}>
        <TextField
          id="qrname-input"
          name="qrname"
          label="Nom"
          required={true}
          variant="outlined"
          value={qrName}
          error={errorValues.hasNameError}
          helperText={errorValues.name}
          onChange={handleNameChange}
          sx={{
            ...styles.field,
            width: { xs: "266px", md: "350px" },
            margin: "auto",
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AbcIcon style={styles.icon} />
              </InputAdornment>
            ),
          }}
        />
      </FormControl>
      <OctopusQRButton
        sx={{ mt: 4, mb: 2, width: "150px" }}
        disabled={!hasChanged}
        onClick={handleSaveQrName}
      >
        Sauvegarder
      </OctopusQRButton>
      {hasError ? (
        <Typography variant="overline" sx={styles.errorText}>
          Une erreur est survenue, veuillez réessayer
        </Typography>
      ) : null}
      {hasBeenSaved ? (
        <Typography variant="overline" sx={styles.savedText}>
          Modifications enregistrées avec succès
        </Typography>
      ) : null}
      {isLoading ? <Loading /> : null}
    </Box>
  );
}
