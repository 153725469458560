import { Box } from "@mui/material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { getVisibleColor } from "../../../../../../../utils/Utils";

export default function LogoDeviceComponent({ content, customization }) {
  return (
    <>
      {content.picture ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <DragIndicatorIcon
            sx={{ color: getVisibleColor(customization.backgroundColor) }}
          />
          <img
            src={content.picture}
            style={{
              maxWidth: "250px",
              maxHeight: "100px",
              borderRadius: "50px",
            }}
            alt="custom-logo"
          />
          <Box sx={{ width: "40px" }} />
        </Box>
      ) : null}
    </>
  );
}
