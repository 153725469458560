import { useCallback, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import {
  getQrCode,
  postQrLogo,
  putQrCode,
} from "../../../api/qrCodes/qrCodesHandler";
import LoadingWithMenu from "../../../components/loading/LoadingWithMenu";
import {
  QR_CODES_VIEW,
  QR_CODE_LOGO_OPTIONS,
  STORAGE_USER_ID,
  USER_ROLE,
} from "../../../utils/constants/Constants";
import { updateQrCodeOptions } from "../../../utils/Utils";
import NotFoundWithMenu from "../../notFound/NotFoundWithMenu";
import NavMenu from "../../../components/navMenu/NavMenu";
import { Box, Typography } from "@mui/material";
import Footer from "../../../components/footer/Footer";
import { styles } from "./QRCodeView.style";

import WideScreenViewMenu from "./viewMenu/WideScreenViewMenu";
import PhoneViewMenu from "./viewMenu/PhoneViewMenu";
import DeleteQRCode from "./delete/DeleteQRCode";
import EditQRCodeCustomization from "./customize/EditQRCodeCustomization";
import { getUser } from "../../../api/user/getUser";
import DoneIcon from "@mui/icons-material/Done";
import EditQRCodeType from "./type/EditQRCodeType";
import EditQRCodeContent from "./editContent/EditQRCodeContent";
import Statistics from "./statistics/Statistics";
import FormPreview from "./formView/preview/FormPreview";
import FormAnswers from "./formView/answers/FormAnswers";
import NotifySubscribers from "./notify/NotifySubscribers";

export default function QRCodeView() {
  const [qrCode, setQrCode] = useState();
  const [qrContent, setQrContent] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState();
  const [hasBeenSaved, setHasBeenSaved] = useState(false);
  const [hasError, setHasError] = useState(false);
  const { qrCodeId } = useParams();
  const [qrType, setQrType] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [hasSaveError, setHasSaveError] = useState(false);
  const [defaultType, setDefaultType] = useState("form");

  const [qrCodeCustomization, setQrCodeCustomization] = useState({
    logoOptionChosen: QR_CODE_LOGO_OPTIONS.NONE,
    picture: "",
    dotsType: "",
    dotsColor: "#000000",
    cornerSquaresType: "",
    cornerSquaresColor: "#000000",
    cornerDotType: "",
    cornerDotColor: "#000000",
    backgroundOptionChosen: "default",
    backgroundColor: "transparent",
  });

  const modifySearchParams = (iType) => {
    setHasBeenSaved(false);
    setHasError(false);
    setHasSaveError(false);
    if (iType !== "type") {
      setQrType(qrCode.type);
    }
    setSearchParams({ viewType: iType });
  };

  const apiErrorAction = () => {
    setUser({});
    setQrCode({});
    setHasError(true);
  };

  const getData = useCallback(async () => {
    const aUserId = localStorage.getItem(STORAGE_USER_ID);
    try {
      const aUser = await getUser(aUserId);
      setUser(aUser);
      const aGetQrResponse = await getQrCode(qrCodeId);
      const aQrCode = aGetQrResponse.qrCode;
      setQrContent(aGetQrResponse.content);
      setQrCode(aQrCode);
      setQrType(aQrCode?.type);
      setQrCodeCustomization({
        logoOptionChosen:
          aQrCode.picture === aUser.logoPath
            ? aUser.logoPath
              ? QR_CODE_LOGO_OPTIONS.USER_LOGO
              : QR_CODE_LOGO_OPTIONS.NONE
            : aQrCode.picture
            ? QR_CODE_LOGO_OPTIONS.PERSONNAL
            : QR_CODE_LOGO_OPTIONS.NONE,
        file: null,
        picture: aQrCode.picture,
        dotsType: aQrCode.dotsType,
        dotsColor: aQrCode.dotsColor,
        cornerSquaresType: aQrCode.cornerSquaresType,
        cornerSquaresColor: aQrCode.cornerSquaresColor,
        cornerDotType: aQrCode.cornerDotType,
        cornerDotColor: aQrCode.cornerDotColor,
        backgroundColor: aQrCode.backgroundColor,
        backgroundOptionChosen:
          aQrCode.backgroundColor === "transparent" ? "default" : "custom",
      });
      setDefaultType(aQrCode.type);
    } catch {
      apiErrorAction();
    }
  }, [qrCodeId]);

  useEffect(() => {
    getData();
  }, [getData]);

  const putQRCode = async () => {
    setIsLoading(true);
    setHasSaveError(false);
    setHasBeenSaved(false);

    try {
      let aPicture = qrCodeCustomization.picture;

      if (qrCodeCustomization.file) {
        const aQrLogoData = new FormData();
        aQrLogoData.append("file", qrCodeCustomization.file);
        const aQrWithLogo = await postQrLogo(user._id, qrCode._id, aQrLogoData);
        aPicture = aQrWithLogo.picture;
      }
      const aQrCode = {
        name: qrCode.name,
        type: qrType,
        picture: aPicture,
        dotsType: qrCodeCustomization.dotsType,
        dotsColor: qrCodeCustomization.dotsColor,
        cornerSquaresType: qrCodeCustomization.cornerSquaresType,
        cornerSquaresColor: qrCodeCustomization.cornerSquaresColor,
        cornerDotType: qrCodeCustomization.cornerDotType,
        cornerDotColor: qrCodeCustomization.cornerDotColor,
        backgroundColor: qrCodeCustomization.backgroundColor,
      };

      const aNewQrCode = await putQrCode(user._id, qrCode._id, aQrCode);
      await updateQrCodeOptions(aNewQrCode);
      if (qrType === qrCode.type) {
        setHasBeenSaved(true);
        setQrCode(aNewQrCode);
        setIsLoading(false);
      }
    } catch {
      setHasSaveError(true);
      setIsLoading(false);
    }
  };

  if (isLoading || !qrCode) {
    return <LoadingWithMenu />;
  }

  if (hasError || (!user.isSubscribed && user.role !== USER_ROLE.PRIVATE)) {
    return <NotFoundWithMenu />;
  }

  let content;
  switch (searchParams.get("viewType")) {
    case QR_CODES_VIEW.CUSTOMIZE:
      content = (
        <EditQRCodeCustomization
          user={user}
          qrCode={qrCode}
          qrCodeCustomization={qrCodeCustomization}
          setQrCodeCustomization={setQrCodeCustomization}
          putQRCode={putQRCode}
        />
      );
      break;
    case QR_CODES_VIEW.EDIT:
      content = (
        <EditQRCodeContent
          user={user}
          qrCode={qrCode}
          qrContent={qrContent}
          setQrContent={setQrContent}
          setHasBeenSaved={setHasBeenSaved}
        />
      );
      break;
    case QR_CODES_VIEW.TYPE:
      content = (
        <EditQRCodeType
          qrCode={qrCode}
          qrType={qrType}
          setQrType={setQrType}
          setQrCode={setQrCode}
          setQrContent={setQrContent}
          putQRCode={putQRCode}
          defaultType={defaultType}
          setHasBeenSaved={setHasBeenSaved}
        />
      );
      break;
    case QR_CODES_VIEW.STATISTIC:
      content = (
        <Statistics user={user} qrCode={qrCode} qrContent={qrContent} />
      );
      break;
    case QR_CODES_VIEW.ANSWERS:
      content = (
        <FormAnswers user={user} qrCode={qrCode} qrContent={qrContent} />
      );
      break;
    case QR_CODES_VIEW.PREVIEW:
      content = <FormPreview qrCode={qrCode} qrContent={qrContent} />;
      break;
    case QR_CODES_VIEW.DELETE:
      content = <DeleteQRCode user={user} qrCode={qrCode} />;
      break;
    case QR_CODES_VIEW.NOTIFY:
      content = (
        <NotifySubscribers user={user} qrCode={qrCode} qrContent={qrContent} />
      );
      break;

    default:
      content = (
        <EditQRCodeContent
          user={user}
          qrCode={qrCode}
          qrContent={qrContent}
          setQrContent={setQrContent}
          setHasBeenSaved={setHasBeenSaved}
        />
      );
      break;
  }

  return (
    <>
      <Box sx={styles.pageContainer}>
        <NavMenu />
        <Box sx={{ display: { xs: "none", lg: "block" } }}>
          <WideScreenViewMenu
            modifySearchParams={modifySearchParams}
            qrCode={qrCode}
            qrContent={qrContent}
          />
        </Box>
        <Box sx={{ display: { xs: "block", lg: "none" } }}>
          <PhoneViewMenu
            modifySearchParams={modifySearchParams}
            qrContent={qrContent}
            qrCode={qrCode}
          />
        </Box>

        {hasBeenSaved ? (
          <Box sx={{ ...styles.flexColumnCenter, mt: 6 }}>
            <DoneIcon sx={{ fontSize: "30px", color: "#58738D" }} />
            <Typography variant="overline">
              Modifications enregistrées avec succès.
            </Typography>
          </Box>
        ) : null}
        {hasSaveError ? (
          <Box sx={{ ...styles.flexCenter, mt: 6 }}>
            <Typography variant="overline" sx={styles.errorText}>
              Une erreur est survenue, veuillez réessayer
            </Typography>
          </Box>
        ) : null}

        {content}
      </Box>
      <Footer />
    </>
  );
}
