import { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  deleteQrCode,
  postQrCode,
  postQrLogo,
} from "../../../../../api/qrCodes/qrCodesHandler";

import Loading from "../../../../../components/loading/Loading";
import { OctopusQRButton } from "../../../../../utils/styled/Styled";
import { styles } from "./WifiContent.style";

import {
  ADD_QR_ACTIONS,
  STORAGE_USER_ID,
} from "../../../../../utils/constants/Constants";

import {
  Box,
  Card,
  Typography,
  IconButton,
  TextField,
  InputLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import WestIcon from "@mui/icons-material/West";
import {
  postWifiQr,
  verifyWifiData,
} from "../../../../../api/qrCodes/wifi/wifiQrHandler";

const defaultErrorValues = {
  hasSsidError: false,
  ssidErrorText: "",
  hasSecurityTypeError: false,
  securityTypeText: "",
};

const defaultFormValues = {
  ssid: "",
  securityType: "WPA",
  password: "",
};

const WifiContent = ({ currentAction, QRCode, file, putQRCode }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorValues, setErrorValues] = useState(defaultErrorValues);
  const [formValues, setFormValues] = useState(defaultFormValues);
  const [hasError, setHasError] = useState(false);
  const navigateTo = useNavigate();

  const handleSubmit = async () => {
    setIsLoading(true);
    setHasError(false);
    const { aErrorValues, hasFormErrors } = verifyWifiData(formValues);
    setErrorValues({ ...aErrorValues });
    if (hasFormErrors) {
      setIsLoading(false);
      return;
    }

    const aUserId = localStorage.getItem(STORAGE_USER_ID);
    if (!aUserId) {
      setHasError(true);
      setIsLoading(false);
      return;
    }

    let aQRCodeId;
    const isQrExisting = QRCode?._id;

    try {
      if (!isQrExisting) {
        const aQRCode = await postQrCode(aUserId, QRCode);
        aQRCodeId = aQRCode._id;
        if (file !== null) {
          const aQrLogoData = new FormData();
          aQrLogoData.append("file", file);
          await postQrLogo(aUserId, aQRCodeId, aQrLogoData);
        }
      } else {
        aQRCodeId = QRCode._id;
      }

      await postWifiQr(aUserId, aQRCodeId, formValues);

      if (isQrExisting) {
        await putQRCode();
      }

      navigateTo("/qrcodes");
    } catch {
      setIsLoading(false);
      if (!isQrExisting) {
        //Delete the QR In case error happens
        await deleteQrCode(aUserId, aQRCodeId);
      }
      setHasError(true);
      return;
    }
    setIsLoading(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Box
        sx={{
          ...styles.flexCenter,
          width: "100%",
        }}
      >
        <Card sx={styles.card}>
          <IconButton
            sx={styles.backArrow}
            onClick={() => {
              currentAction(ADD_QR_ACTIONS.CUSOMIZATION);
            }}
          >
            <WestIcon />
          </IconButton>
          <Typography variant="h4" sx={styles.title}>
            Informations WIFI
          </Typography>
          <Box sx={styles.flexColumn}>
            <TextField
              sx={styles.field}
              name="ssid"
              label="SSID"
              placeholder="Box OctopusQR"
              value={formValues.ssid}
              error={errorValues.hasSsidError}
              helperText={errorValues.ssidErrorText}
              onChange={handleInputChange}
              type="text"
              required
            />

            <TextField
              name="password"
              sx={styles.field}
              label="Mot de passe"
              placeholder="abcdef123456"
              value={formValues.password}
              onChange={handleInputChange}
              type="text"
              required
            />

            <InputLabel id="encryption-label" sx={{ my: 2 }}>
              Type de sécurité du réseau
            </InputLabel>
            <RadioGroup
              aria-labelledby="encryption-label"
              name="securityType"
              sx={{ width: "290px" }}
              value={formValues.securityType}
              onChange={(e) =>
                setFormValues({ ...formValues, securityType: e.target.value })
              }
            >
              <FormControlLabel value="" control={<Radio />} label="Aucune" />
              <FormControlLabel
                value="WPA"
                control={<Radio />}
                label="WPA/WPA2"
              />
              <FormControlLabel value="WEP" control={<Radio />} label="WEP" />
            </RadioGroup>
          </Box>
          <OctopusQRButton sx={styles.button} onClick={handleSubmit}>
            Sauvegarder
          </OctopusQRButton>
          <br />
          {hasError ? (
            <Typography variant="overline" sx={styles.errorText}>
              Une erreur est survenue, veuillez réessayer
            </Typography>
          ) : null}
        </Card>
      </Box>
    </>
  );
};

export default WifiContent;
