import { Typography, Box, Card } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { getStatistic } from "../../../../api/qrCodes/statisticsHandler";
import Loading from "../../../../components/loading/Loading";
import NotFound from "../../../notFound/NotFound";
import { Line, Bar } from "react-chartjs-2";
import { CategoryScale } from "chart.js";
import { Chart as ChartJS } from "chart.js/auto";
import * as XLSX from "xlsx";
import { styles } from "./Statistics.style";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import {
  QR_CODE_TYPE,
  TRANSLATE_MONTH,
  SOCIAL_NETWORK_LINK_MAP,
  DYNAMIC_QR_TYPE,
} from "../../../../utils/constants/Constants";
import {
  capitalizeFirstLetter,
  generateColorArray,
} from "../../../../utils/Utils";
import { OctopusQRButton } from "../../../../utils/styled/Styled";
import DownloadIcon from "@mui/icons-material/Download";

ChartJS.register(CategoryScale);

function exportToExcel(iTitle, iChart) {
  let chartData = [];
  for (let i = 0; i < iChart.datasets[0].data.length; i++) {
    chartData.push([iChart.labels[i], iChart.datasets[0].data[i]]);
  }

  const ws = XLSX.utils.aoa_to_sheet(chartData);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Statistiques");
  XLSX.writeFile(wb, `${iTitle}.xlsx`);
}

function BuildLineChart({ iCurrentYear, iLastYear }) {
  let currentYearLabels = [];
  let currentYearData = [];
  let lastYearLabels = [];
  let lastYearData = [];
  for (const [key, value] of Object.entries(iCurrentYear)) {
    currentYearLabels.push(TRANSLATE_MONTH[key]);
    currentYearData.push(value);
  }

  for (const [key, value] of Object.entries(iLastYear)) {
    lastYearLabels.push(TRANSLATE_MONTH[key]);
    lastYearData.push(value);
  }

  const currentYearLine = {
    labels: currentYearLabels,
    datasets: [
      {
        label: "Nombre de scans",
        data: currentYearData,
        borderColor: "#58738D",
        backgroundColor: "#58738D",
      },
    ],
  };

  const lastYearLine = {
    labels: lastYearLabels,
    datasets: [
      {
        label: "Nombre de scans",
        data: lastYearData,
        borderColor: "#58738D",
        backgroundColor: "#58738D",
      },
    ],
  };
  const aNow = new Date();
  return (
    <Box sx={{ ...styles.flexCenter, flexWrap: "wrap" }}>
      {lastYearData.length !== 0 ? (
        <Card sx={styles.card}>
          <OctopusQRButton
            sx={styles.excelDownload}
            onClick={() => {
              exportToExcel(
                `${aNow.toLocaleDateString("fr")}_scans_qrcode_${
                  aNow.getFullYear() - 1
                }`,
                lastYearLine
              );
            }}
          >
            <DownloadIcon />
            <Typography variant="overline">Excel</Typography>
          </OctopusQRButton>
          <Typography variant="h5" sx={{ mb: 2 }}>
            L'année dernière
          </Typography>
          <Line
            data={lastYearLine}
            options={{
              responsive: true,
            }}
          />
        </Card>
      ) : null}

      <Card sx={styles.card}>
        <OctopusQRButton
          sx={styles.excelDownload}
          onClick={() => {
            exportToExcel(
              `${aNow.toLocaleDateString(
                "fr"
              )}_scans_qrcode_${aNow.getFullYear()}`,
              currentYearLine
            );
          }}
        >
          <DownloadIcon />
          <Typography variant="overline">Excel</Typography>
        </OctopusQRButton>
        <Typography variant="h5" sx={{ mb: 2 }}>
          Cette année
        </Typography>
        <Line
          data={currentYearLine}
          options={{
            responsive: true,
          }}
        />
      </Card>
    </Box>
  );
}

function BuildHourAndDayGraph({ iHours, iDays }) {
  const hourBarData = {
    labels: iHours.hoursLabels,
    datasets: [
      {
        label: "Nombre de scans selon l'heure",
        data: iHours.hoursData,
        backgroundColor: generateColorArray(iHours.hoursLabels.length),
      },
    ],
  };

  const dayBarData = {
    labels: iDays.daysLabels,
    datasets: [
      {
        label: "Nombre de scans selon le jour",
        data: iDays.daysData,
        backgroundColor: generateColorArray(iDays.daysLabels.length),
      },
    ],
  };

  return (
    <>
      <Card sx={styles.card}>
        <Typography variant="h5" sx={{ mb: 2 }}>
          Heure des scans
        </Typography>
        <Bar
          data={hourBarData}
          options={{
            responsive: true,
          }}
        />
      </Card>
      <Card sx={styles.card}>
        <Typography variant="h5" sx={{ mb: 2 }}>
          Jour des scans
        </Typography>
        <Bar
          data={dayBarData}
          options={{
            responsive: true,
          }}
        />
      </Card>
    </>
  );
}

function BuildBarGraph({ iElementData }) {
  let labels = [];
  let dayData = [];
  let weekData = [];
  let yearData = [];

  for (const aElement of iElementData) {
    labels.push(aElement?.name);
    dayData.push(aElement?.stats.data.todayCount);
    weekData.push(aElement?.stats.data.weekCount);
    yearData.push(aElement?.stats.data.yearCount);
  }

  const yearBarData = {
    labels: labels,
    datasets: [
      {
        label: "Nombre de clicks",
        data: yearData,
        backgroundColor: generateColorArray(yearData.length),
      },
    ],
  };

  const weekBarData = {
    labels: labels,
    datasets: [
      {
        label: "Nombre de clicks",
        data: weekData,
        backgroundColor: generateColorArray(weekData.length),
      },
    ],
  };

  const dayBarData = {
    labels: labels,
    datasets: [
      {
        label: "Nombre de clicks",
        data: dayData,
        backgroundColor: generateColorArray(dayData.length),
      },
    ],
  };

  const aNow = new Date();
  return (
    <Box
      sx={{
        ...styles.flexCenter,
        flexWrap: { xs: "wrap", md: "wrap", lg: "nowrap" },
      }}
    >
      <Card sx={styles.card}>
        <OctopusQRButton
          sx={styles.excelDownload}
          onClick={() => {
            exportToExcel(
              `${aNow.toLocaleDateString("fr")}_statistiques_clicks_annee`,
              yearBarData
            );
          }}
        >
          <DownloadIcon />
          <Typography variant="overline">Excel</Typography>
        </OctopusQRButton>
        <Typography variant="h5" sx={{ mb: 2 }}>
          Cette année
        </Typography>
        <Bar
          data={yearBarData}
          options={{
            responsive: true,
          }}
        />
      </Card>
      <Card sx={styles.card}>
        <OctopusQRButton
          sx={styles.excelDownload}
          onClick={() => {
            exportToExcel(
              `${aNow.toLocaleDateString("fr")}_statistiques_clicks_semaine`,
              weekBarData
            );
          }}
        >
          <DownloadIcon />
          <Typography variant="overline">Excel</Typography>
        </OctopusQRButton>
        <Typography variant="h5" sx={{ mb: 2 }}>
          Cette semaine
        </Typography>
        <Bar
          data={weekBarData}
          options={{
            responsive: true,
          }}
        />
      </Card>
      <Card sx={styles.card}>
        <OctopusQRButton
          sx={styles.excelDownload}
          onClick={() => {
            exportToExcel(
              `${aNow.toLocaleDateString("fr")}_statistiques_clicks_jour`,
              dayBarData
            );
          }}
        >
          <DownloadIcon />
          <Typography variant="overline">Excel</Typography>
        </OctopusQRButton>
        <Typography variant="h5" sx={{ mb: 2 }}>
          Aujourd'hui
        </Typography>
        <Bar
          data={dayBarData}
          options={{
            responsive: true,
          }}
        />
      </Card>
    </Box>
  );
}

export default function Statistics({ user, qrCode, qrContent }) {
  const [statistics, setStatistics] = useState();
  const [yearCount, setYearCount] = useState();
  const [rawData, setRawData] = useState();
  const [isUserAuthorized, setIsUserAuthorized] = useState(true);
  const [apiError, setApiError] = useState(false);
  const [linksAndFilesStats, setLinksAndFilesStats] = useState();
  const [socialNetworksStats, setSocialNetworksStats] = useState();
  const currentYear = new Date().getFullYear();
  const lastYear = new Date().getFullYear() - 1;

  const getData = useCallback(async () => {
    if (!DYNAMIC_QR_TYPE.includes(qrCode.type)) {
      setIsUserAuthorized(false);
      return;
    }
    try {
      const aStatistic = await getStatistic(user?._id, qrCode?._id);
      setYearCount(aStatistic.yearCount);
      setStatistics(aStatistic);
      setRawData(aStatistic.data);
      if (qrCode.type === QR_CODE_TYPE.CUSTOM_PAGE) {
        let aLinksAndFilesStats = [];
        let aSocialNetworksStats = [];

        for (const aLink of qrContent.links) {
          aLinksAndFilesStats.push({
            stats: await getStatistic(
              user?._id,
              aLink?._id ? aLink._id : aLink?.id
            ),
            name: `Lien | ${aLink?.name}`,
          });
        }

        for (const aFile of qrContent.files) {
          aLinksAndFilesStats.push({
            stats: await getStatistic(
              user?._id,
              aFile?._id ? aFile._id : aFile.id
            ),
            name: `Fichier | ${aFile?.name}`,
          });
        }

        for (const aSocialNetwork of qrContent.socialNetworks) {
          const aPseudonym = aSocialNetwork?.path.split(
            SOCIAL_NETWORK_LINK_MAP[aSocialNetwork?.type]
          )[1];
          aSocialNetworksStats.push({
            stats: await getStatistic(user?._id, aSocialNetwork?._id),
            name: `${capitalizeFirstLetter(
              aSocialNetwork.type
            )} | ${aPseudonym}`,
          });
        }
        setLinksAndFilesStats(aLinksAndFilesStats);
        setSocialNetworksStats(aSocialNetworksStats);
      }
    } catch {
      setApiError(true);
    }
  }, [qrCode, qrContent, user]);

  useEffect(() => {
    getData();
  }, [getData]);

  if (!isUserAuthorized) {
    return <NotFound />;
  }

  if (apiError) {
    return <NotFound />;
  }

  if (!statistics) {
    return <Loading />;
  }

  let currentYearData = {};
  let lastYearData = {};
  for (const [key, value] of Object.entries(yearCount)) {
    if (key === currentYear.toString()) {
      currentYearData = value;
    } else if (key === lastYear.toString()) {
      lastYearData = value;
    }
  }

  return (
    <>
      <Box id="charts">
        <Typography variant="h4" sx={{ textAlign: "center", mt: 10, mb: 5 }}>
          Suivi des scans
        </Typography>
        <Box sx={{ ...styles.flexCenter, flexWrap: "wrap" }}>
          <Card sx={styles.statsCard}>
            <Typography variant="overline" sx={styles.rawDataTitle}>
              Aujourd'hui
            </Typography>
            <Typography sx={styles.rawDataNumber}>
              {rawData.todayCount}
            </Typography>
          </Card>
          <Card sx={styles.statsCard}>
            <Typography variant="overline" sx={styles.rawDataTitle}>
              Cette semaine
            </Typography>
            <Typography sx={styles.rawDataNumber}>
              {rawData.weekCount}
            </Typography>
          </Card>
          <Card sx={styles.statsCard}>
            <Typography variant="overline" sx={styles.rawDataTitle}>
              Cette année
            </Typography>
            <Typography sx={styles.rawDataNumber}>
              {rawData.yearCount}
            </Typography>
          </Card>
          <Card sx={styles.statsCard}>
            <Typography variant="overline" sx={styles.rawDataTitle}>
              Sur les deux dernières semaines
            </Typography>
            <Box
              sx={{
                ...styles.flexCenter,
                color:
                  rawData.weekEvolutionRate < 0
                    ? "red"
                    : rawData.weekEvolutionRate === 0
                    ? "black"
                    : "green",
              }}
            >
              {rawData.weekEvolutionRate < 0 ? (
                <TrendingDownIcon sx={{ mr: 2 }} />
              ) : rawData.weekEvolutionRate === 0 ? (
                <TrendingFlatIcon sx={{ mr: 2 }} />
              ) : (
                <TrendingUpIcon sx={{ mr: 2 }} />
              )}
              <Typography sx={styles.rawDataNumber}>
                {rawData.weekEvolutionRate}%
              </Typography>
            </Box>
          </Card>
        </Box>
        <Typography variant="h4" sx={{ textAlign: "center", mt: 8, mb: 3 }}>
          Les courbes
        </Typography>
        <Box
          sx={{
            ...styles.flexCenter,
            flexWrap: { xs: "wrap", md: "nowrap", lg: "nowrap" },
          }}
        >
          <BuildHourAndDayGraph
            iHours={statistics.hourCount}
            iDays={statistics.dayCount}
          />
        </Box>
        <BuildLineChart
          iCurrentYear={currentYearData}
          iLastYear={lastYearData}
        />

        {linksAndFilesStats ? (
          <>
            <Typography variant="h4" sx={{ textAlign: "center", m: 3 }}>
              Vos liens et fichiers
            </Typography>
            <BuildBarGraph iElementData={linksAndFilesStats} />
          </>
        ) : null}

        {socialNetworksStats ? (
          <>
            <Typography variant="h4" sx={{ textAlign: "center", m: 3 }}>
              Vos réseaux sociaux
            </Typography>
            <BuildBarGraph iElementData={socialNetworksStats} />
          </>
        ) : null}
      </Box>
    </>
  );
}
