import React from "react";
import { useSelector } from "react-redux";

import { OctopusQRButton, CustomRouterLink } from "../../utils/styled/Styled";
import { styles } from "./NotFound.style";

import { Grid, Card } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

export default function NotFound() {
  const user = useSelector((state) => state.userReducer);
  return (
    <>
      <Grid container spacing={0}>
        <Grid item sm={2} md={3} />
        <Grid item xs={12} sm={8} md={6}>
          <Card sx={styles.card}>
            <h2>Page non trouvée</h2>
            <CancelIcon sx={styles.icon} />
            <h4>La page à laquelle vous tentez d'accéder n'existe pas</h4>
            {user.loggedIn ? (
              <CustomRouterLink to="/qrcodes">
                <OctopusQRButton style={styles.button}>
                  Accéder à mes QR Codes
                </OctopusQRButton>
              </CustomRouterLink>
            ) : (
              <CustomRouterLink to="/login">
                <OctopusQRButton style={styles.button}>
                  Se connecter
                </OctopusQRButton>
              </CustomRouterLink>
            )}
          </Card>
        </Grid>
        <Grid item sm={2} md={3} />
      </Grid>
    </>
  );
}
