import { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import {
  getQrCodes,
  patchQrCodeFolder,
} from "../../../api/qrCodes/qrCodesHandler";
import {
  getQrFolders,
  postQrFolder,
} from "../../../api/qrCodes/qrFolder/qrFoldersHandler";
import { getUser } from "../../../api/user/getUser";

import Footer from "../../../components/footer/Footer";
import LoadingWithMenu from "../../../components/loading/LoadingWithMenu";
import NavMenu from "../../../components/navMenu/NavMenu";
import {
  PLAN_NUMBER_OF_QR_MAP,
  STORAGE_USER_ID,
  USER_ROLE,
} from "../../../utils/constants/Constants";
import QRCodeCard from "./display/QRCodeCard";
import AskToVerifyEmailCard from "../../../components/askToVerifyEmail/AskToVerifyEmailCard";
import AddQrFolder from "../qrFolders/AddQrFolder";
import QRFoldersDisplay from "./qrFolder/QRFoldersDisplay";
import QRToolbar from "./QRToolbar";

import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";

import { styles } from "./QRCodes.style";

import { Box, IconButton, Typography, MenuItem } from "@mui/material";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import FolderIcon from "@mui/icons-material/Folder";
import PhoneQRFolderDisplay from "./qrFolder/PhoneQRFoldersDisplay";
import ErrorCardWithMenu from "../../../components/error/ErrorCardWithMenu";

export default function QRCodes() {
  const [initialQrCodes, setInitialQrCodes] = useState();
  const [qrCodes, setQrCodes] = useState();
  const [qrFolders, setQrFolders] = useState();
  const [user, setUser] = useState();
  const [hasError, setHasError] = useState(false);
  const [selectedQrs, setSelectedQrs] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const userId = localStorage.getItem(STORAGE_USER_ID);
  const [isFolderDialogOpen, setIsFolderDialogOpen] = useState(false);

  const moveCard = async (cardId, folderId) => {
    if (!cardId || !folderId) return;
    const aQrCodes = qrCodes.filter((qrCode) => qrCode._id !== cardId);
    setQrCodes(aQrCodes);
    if (aQrCodes.length === 0) {
      getData();
    }
    await patchQrCodeFolder(user._id, cardId, {
      folderId: folderId,
    });
  };

  const moveCards = async (cards, folderId) => {
    if (!cards || !folderId) return;
    const aQrCodes = qrCodes.filter(
      (qrCode) => !cards.some((card) => card._id === qrCode._id)
    );
    setQrCodes(aQrCodes);
    if (aQrCodes.length === 0) {
      getData();
    }
    await Promise.all(
      cards.map((card) =>
        patchQrCodeFolder(user._id, card._id, {
          folderId: folderId,
        })
      )
    );
  };

  const handleOpenFolderDialog = () => {
    setIsFolderDialogOpen(true);
  };

  const handleCloseFolderDialog = () => {
    setIsFolderDialogOpen(false);
  };

  const handleSaveFolder = async (folderName) => {
    await postQrFolder(user._id, { name: folderName, parent: "home" });
    setSelectedQrs([]);
    await getFolders();
  };

  const getFolders = async () => {
    try {
      const aQrFolders = await getQrFolders(userId, "home");
      setQrFolders(aQrFolders);
    } catch {
      setHasError(true);
    }
  };

  const navigateTo = useNavigate();

  const getData = useCallback(async () => {
    try {
      const aUser = await getUser(userId);
      setUser(aUser);
      const qrCodes = await getQrCodes(userId);
      setQrCodes(qrCodes);
      setInitialQrCodes(qrCodes);
      const aQrFolders = await getQrFolders(userId, "home");
      setQrFolders(aQrFolders);
    } catch {
      setHasError(true);
    }
  }, [userId]);

  useEffect(() => {
    getData();
  }, [getData]);

  const handleSearchChange = (iEvent) => {
    setSearchValue(iEvent.target.value);
    if (iEvent.target.value.length <= 2) {
      setQrCodes(initialQrCodes);
      return;
    }
    setQrCodes(
      qrCodes.filter((qrCode) =>
        qrCode.name
          .toLowerCase()
          .normalize()
          .includes(iEvent.target.value.toLowerCase().normalize())
      )
    );
  };

  if (hasError) {
    return <ErrorCardWithMenu />;
  }

  if (!qrCodes || !user || qrFolders === undefined) {
    return <LoadingWithMenu />;
  }

  if (!user.isMailVerified) {
    return <AskToVerifyEmailCard user={user} />;
  }

  return (
    <>
      <Box style={styles.pageContainer}>
        <NavMenu />
        <PhoneQRFolderDisplay qrFolders={qrFolders} isHome={true} />
        {!user.isSubscribed && user.role !== USER_ROLE.PRIVATE && (
          <Box
            sx={{ ...styles.flexCenter, width: "300px", margin: "auto", pt: 3 }}
          >
            <Typography variant="overline" sx={{ color: "#8B0000" }}>
              Vous n'êtes plus abonné à nos services. L'ensemble de vos QR Codes
              sont donc désactivés. Merci de vous rendre dans la rubrique{" "}
              <strong>"Abonnement"</strong> afin de résoudre cela.
            </Typography>
          </Box>
        )}
        <Box>
          <Box sx={{ width: "100%" }}>
            {initialQrCodes.length >=
              PLAN_NUMBER_OF_QR_MAP[user.subscriptionPlan] ||
            !user.isSubscribed ? null : (
              <Box
                sx={{
                  ml: {
                    md: 0,
                    lg: qrCodes.length === 0 ? "0px" : "345px",
                  },
                }}
              >
                <Box sx={styles.downIconContainer}>
                  <IconButton
                    sx={styles.addIconButton}
                    onClick={() => {
                      navigateTo("/qrcodes/add?action=name");
                    }}
                  >
                    <AddCircleOutlineIcon sx={styles.addIcon} />
                  </IconButton>
                </Box>
                <Box sx={styles.flexCenter}>
                  <Typography variant="overline">Ajouter un QR Code</Typography>
                </Box>
              </Box>
            )}

            {initialQrCodes.length > 0 ? (
              <QRToolbar
                searchValue={searchValue}
                handleSearchChange={handleSearchChange}
                selectedQrs={selectedQrs}
                setSelectedQrs={setSelectedQrs}
                qrFolders={qrFolders}
                moveCards={moveCards}
                isHome={true}
                qrCodes={qrCodes}
              />
            ) : (
              <>
                {qrFolders.length > 0 ? (
                  <>
                    <Typography sx={{ textAlign: "center", my: 3 }}>
                      Vous n'avez aucun QR Codes sur la page d'accueil,
                      consultez vos dossiers.
                    </Typography>
                    <Box sx={{ ...styles.cardContainer, my: 3 }}>
                      {qrFolders.map((aQrFolder) => (
                        <MenuItem
                          sx={{
                            width: "150px",
                            borderRadius: "20px",
                            ...styles.flexColumnCenter,
                          }}
                          key={aQrFolder._id}
                          onClick={() =>
                            navigateTo(`/qrcodes/folder/${aQrFolder._id}`)
                          }
                        >
                          <FolderIcon
                            sx={{ color: "#58738D", fontSize: "100px" }}
                          />
                          <Typography variant="overline">
                            {aQrFolder.name}
                          </Typography>
                        </MenuItem>
                      ))}
                    </Box>
                  </>
                ) : (
                  <Typography sx={{ textAlign: "center", my: 3 }}>
                    Vous n'avez aucun QR Codes pour le moment
                  </Typography>
                )}
              </>
            )}
            <Box sx={{ display: "flex" }}>
              <DndProvider backend={HTML5Backend}>
                <Box
                  sx={{
                    display: qrCodes.length === 0 ? "none" : "flex",
                  }}
                >
                  <QRFoldersDisplay
                    user={user}
                    getFolders={getFolders}
                    handleOpen={handleOpenFolderDialog}
                    qrFolders={qrFolders}
                    isHome={true}
                    moveCard={moveCard}
                    setSelectedQrs={setSelectedQrs}
                  />
                </Box>

                <Box sx={styles.cardContainer}>
                  {qrCodes.map((aQrCode, index) => {
                    return (
                      <QRCodeCard
                        user={user}
                        qrCode={aQrCode}
                        index={index}
                        selectedQrs={selectedQrs}
                        setSelectedQrs={setSelectedQrs}
                        qrCodes={qrCodes}
                        key={aQrCode._id}
                      />
                    );
                  })}
                </Box>
              </DndProvider>
            </Box>
          </Box>
        </Box>
      </Box>
      <Footer />
      <AddQrFolder
        open={isFolderDialogOpen}
        onClose={handleCloseFolderDialog}
        onSave={handleSaveFolder}
      />
    </>
  );
}
