import React, { useState } from "react";

import FrenchFlag from "../../../../assets/icons/french_flag.svg";

import { OctopusQRButton } from "../../../../utils/styled/Styled";
import Loading from "../../../../components/loading/Loading";
import { styles } from "./RegisterUser.style";
import {
  registerUserAdmin,
  verifyRegisterUserData,
} from "../../../../api/admin/adminApi";

import {
  Card,
  InputAdornment,
  TextField,
  Box,
  Grid,
  Typography,
  Select,
  MenuItem,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import DoneIcon from "@mui/icons-material/Done";
import { useNavigate } from "react-router-dom";

const RegisterUser = ({ user }) => {
  const defaultFormValues = {
    name: "",
    phoneNumber: "",
    mail: "",
    hasPush: "",
    subscriptionPlan: "",
    isTrial: "",
  };

  const defaultErrorValues = {
    hasNameError: false,
    name: "",
    hasMailError: false,
    mail: "",
    hasPhoneNumberError: false,
    phoneNumber: "",
    hasPushError: false,
    hasSubscriptionPlanError: false,
    hasIsTrialError: false,
  };

  const [formValues, setFormValues] = useState(defaultFormValues);
  const [errorValues, setErrorValues] = useState(defaultErrorValues);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isRegistered, setIsRegistered] = useState(false);
  const navigateTo = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handlePhoneChange = (e) => {
    let target = e.target;
    let position = target.selectionEnd;
    let length = target.value.length;

    if (length >= 15) {
      return;
    }

    target.value = target.value
      .replace(/[^\d]/g, "")
      .replace(/(.{2})/g, "$1 ")
      .trim();
    target.selectionEnd = position +=
      target.value.charAt(position - 1) === " " &&
      target.value.charAt(length - 1) === " " &&
      length !== target.value.length
        ? 1
        : 0;
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    setErrorMessage("");
    setErrorValues(defaultErrorValues);
    const { aErrorValues, aHasError } = verifyRegisterUserData(formValues);

    if (aHasError) {
      setErrorValues(aErrorValues);
      setIsLoading(false);
      return;
    }

    try {
      await registerUserAdmin(user._id, formValues);
      setIsRegistered(true);
    } catch (iError) {
      if (iError.status === 400) {
        setErrorMessage("Un compte avec cette adresse mail existe déjà");
      } else {
        setErrorMessage("Une erreur est survenue, veuillez réessayer");
      }
    }
    setIsLoading(false);
  };

  return (
    <>
      {isRegistered ? (
        <Card
          sx={{ ...styles.card, width: "500px", margin: "30px auto", p: 3 }}
        >
          <DoneIcon sx={{ color: "#58738D", fontSize: "40px", mb: 2 }} />
          <Typography>Utilisateur enregistré avec succès</Typography>
          <br />
          <strong>
            <Typography variant="overline">
              Un mail a été envoyé à l'adresse {formValues.mail} <br />
              (Bien vérifier les spams)
            </Typography>
          </strong>
          <br />
          <Box sx={{ ...styles.flexCenter, mt: 3 }}>
            <OctopusQRButton
              sx={{ width: "200px", mx: 2 }}
              onClick={() => navigateTo("/admin?viewType=quotation")}
            >
              Créer un devis
            </OctopusQRButton>
            <OctopusQRButton
              sx={{ width: "200px", mx: 2 }}
              onClick={() => navigateTo("/admin?viewType=billing")}
            >
              Créer une facture
            </OctopusQRButton>
          </Box>
        </Card>
      ) : (
        <Grid container spacing={0}>
          <Grid item sm={2} md={3} />
          <Grid item xs={12} sm={8} md={6}>
            <Box
              component="form"
              sx={{ mb: 10, ml: 2, mr: 2, mt: 5 }}
              noValidate
              autoComplete="off"
            >
              <Card sx={styles.card}>
                <Box sx={styles.formControl}>
                  <PersonAddIcon sx={styles.titleIcon} />
                  <TextField
                    id="name-input"
                    name="name"
                    label="Nom d'utilisateur"
                    required={true}
                    variant="outlined"
                    value={formValues.name}
                    error={errorValues.hasNameError}
                    helperText={errorValues.name}
                    onChange={handleInputChange}
                    sx={styles.field}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonIcon style={styles.icon} />
                        </InputAdornment>
                      ),
                    }}
                  />

                  <TextField
                    id="phone-input"
                    name="phoneNumber"
                    type="tel"
                    label="Numéro de  téléphone"
                    required={true}
                    variant="outlined"
                    placeholder="01 23 45 67 89"
                    value={formValues.phoneNumber}
                    error={errorValues.hasPhoneNumberError}
                    helperText={errorValues.phoneNumber}
                    onChange={handlePhoneChange}
                    sx={styles.field}
                    InputProps={{
                      startAdornment: (
                        <img
                          src={FrenchFlag}
                          style={styles.imgAdornment}
                          width="23"
                          alt="french-flag"
                        />
                      ),
                    }}
                  />

                  <TextField
                    id="mail-input"
                    name="mail"
                    type="email"
                    label="Adresse Mail"
                    required={true}
                    variant="outlined"
                    value={formValues.mail}
                    error={errorValues.hasMailError}
                    helperText={errorValues.mail}
                    onChange={handleInputChange}
                    sx={styles.field}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <EmailIcon style={styles.icon} />
                        </InputAdornment>
                      ),
                    }}
                  />

                  <Select
                    sx={{ ...styles.field, textAlign: "left" }}
                    value={formValues.hasPush}
                    onChange={(e) =>
                      setFormValues({
                        ...formValues,
                        hasPush: e.target.value,
                      })
                    }
                    displayEmpty
                  >
                    <MenuItem value={""} disabled>
                      Activer les notifications PUSH ?
                    </MenuItem>
                    <MenuItem value={"activated"}>Oui</MenuItem>
                    <MenuItem value={"deactivated"}>Non</MenuItem>
                  </Select>

                  <Select
                    sx={{ ...styles.field, textAlign: "left" }}
                    value={formValues.subscriptionPlan}
                    error={errorValues.hasSubscriptionPlanError}
                    onChange={(e) =>
                      setFormValues({
                        ...formValues,
                        subscriptionPlan: e.target.value,
                      })
                    }
                    displayEmpty
                  >
                    <MenuItem value={""} disabled>
                      Nombre de QR Codes
                    </MenuItem>
                    <MenuItem value={"ONE_AS"}>1 QR Code</MenuItem>
                    <MenuItem value={"THREE_AS"}>3 QR Codes</MenuItem>
                    <MenuItem value={"HEIGHT_AS"}>8 QR Codes</MenuItem>
                    <MenuItem value={"FIFTEEN_AS"}>15 QR Codes</MenuItem>
                    <MenuItem value={"UNLI_AS"}>QR Codes illimités</MenuItem>
                  </Select>

                  <Select
                    sx={{ ...styles.field, textAlign: "left" }}
                    value={formValues.isTrial}
                    onChange={(e) =>
                      setFormValues({
                        ...formValues,
                        isTrial: e.target.value,
                      })
                    }
                    displayEmpty
                  >
                    <MenuItem value={""} disabled>
                      Période d'essai ?
                    </MenuItem>
                    <MenuItem value={"activated"}>Oui</MenuItem>
                    <MenuItem value={"deactivated"}>Non</MenuItem>
                  </Select>
                </Box>
                {/* Make this button disabled if hasPush is empty, subscriptionPLan is empty or isTrial is empty */}

                <OctopusQRButton
                  style={styles.button}
                  onClick={handleSubmit}
                  disabled={
                    formValues.hasPush === "" ||
                    formValues.isTrial === "" ||
                    formValues.subscriptionPlan === ""
                  }
                >
                  Inscription
                </OctopusQRButton>
                {isLoading && <Loading />}
                {errorMessage && (
                  <Typography sx={styles.error}>{errorMessage}</Typography>
                )}
                {(errorValues.hasPushError ||
                  errorValues.hasSubscriptionPlanError ||
                  errorValues.hasIsTrialError) && (
                  <Typography sx={styles.error}>
                    Les champs "Notifications PUSH", "Nombre de QR Codes" et
                    "Période d'essai" doivent être remplis.
                  </Typography>
                )}
              </Card>
            </Box>
          </Grid>
          <Grid item sm={2} md={3} />
        </Grid>
      )}
    </>
  );
};
export default RegisterUser;
