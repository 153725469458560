import React from "react";

import { Box } from "@mui/material";

import PhoneLoggedMenu from "./phone/PhoneLoggedMenu.js";
import WideScreenLoggedMenu from "./wideScreen/WideScreenLoggedMenu.js";
import { styles } from "./LoggedMenu.style.js";

export default function LoggedMenu({ user }) {
  return (
    <>
      <Box sx={styles.phoneContainer}>
        <PhoneLoggedMenu user={user} />
      </Box>

      <Box sx={styles.wideScreenContainer}>
        <WideScreenLoggedMenu user={user} />
      </Box>
    </>
  );
}
